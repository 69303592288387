import React, { useState, useEffect, Component } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { DelayInput } from "react-delay-input";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import AddBoxIcon from "@material-ui/icons/AddBox";

import "./Templates.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import Loader from "../common/loader/Loader";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";

import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import CreateGame from "../CreateGame";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
} from "@mui/material";
import { Close } from "@material-ui/icons";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Templates(props) {
  const [games, setgames] = useState();
  const [selectedGame, setSelectedGame] = useState();
  const [filter, setFilter] = useState("");
  const [foundTeams, setFoundTeams] = useState();
  const [templateToEdit, setTemplateToEdit] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [templateIdSport, setTemplateIdSport] = useState();
  const [templateName, setTemplateName] = useState();
  const [templateTeams, setTemplateTeams] = useState([]);
  const [record, setRecord] = useState();
  const [teamName, setTeamName] = useState();
  const [showInfo, setshowInfo] = useState(true);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );
  const [isEditTemplate, setIsEditTemplate] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [isNew, setIsNew] = React.useState(false);
  const [selectedTeamId, setSelectedTeamId] = React.useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGameStat, setSelectedGameStat] = React.useState("O");
  const [optionsAutoComplete, setOptionsAutoComplete] = React.useState([]);
  const onTagsChange = (event, values) => {
    values && values.id && props.callBackFn(values.Label);
  };

  const handleCloseCreate = function () {
    setIsNew(false);
  };
  let history = useHistory();
  const useStyles = makeStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: "#3f51b5",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "darkcyan !important",
      },
    },

    tableRow: {
      "&:hover": {
        backgroundColor: "lightgrey !important",
      },
    },
    headerCell: {
      color: "white",
    },
  });
  const d = new Date();
  const dformat = d.toLocaleString("en-US", {
    timeZone: "America/New_York",
    hour12: false,
  });

  let dformatToISO = new Date()
    .toISOString()
    .replace(/T/, " ")
    .replace(/\..+/, "");
  const classes = useStyles();
  

  useEffect(() => {
    LoadData();
  }, [filter]);

  const searchTeam = async () => {
    const url = `${process.env.REACT_APP_API_URL}/TEAM?&filter=TeamName,cs,${teamName}`;

    await axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setFoundTeams(data.records);
      });
  };

  const LoadData = async () => {
    setIsLoading(true);
    let tmpSize = props.callBackFn ? 1000 : 25000;
    const url = `${process.env.REACT_APP_API_URL}/AddOnsTEMPLATE?&filter1=templateName,cs,${filter}&filter2=templateDescription,cs,${filter}&size=${tmpSize}`;

    await axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {

        if (props.callBackFn) {
          let tmpOptionsAutoComplete = [];
          data.records.map((key) => {
            tmpOptionsAutoComplete.push({
              label: key.LongName,
              id: key.IdTeam,
            });
          });
          setOptionsAutoComplete(tmpOptionsAutoComplete);
        } else {
          setRecord(data.records[0]);
          setgames(data.records);
        }

        setIsLoading(false);
      });
  };

  function onAfterDeleteRow(rowKeys) {
    rowKeys.map((row) => {
      axios({
        method: "delete",
        url: process.env.REACT_APP_API_URL + `/AddOnsTEMPLATE/${row}`,
        //url: 'https://localhost:44337/api/Game/PlayerGame_Update',
        //    data: reqTeam,
      }).then((response) => {
        console.log("response", response);
      });
    });
  }

  function deleteTeamFromSelectedTemplate(team) {
    console.log("team", team);
    axios({
      method: "delete",
      url:
        process.env.REACT_APP_API_URL +
        `/AddOnsTEMPLATEITEMS/${team.IdTemplateItem}`,
      //url: 'https://localhost:44337/api/Game/PlayerGame_Update',
      //  data: { IdTeam: idTeam, IdTemplate: templateToEdit },
    }).then((response) => {
      findTeamsPerTemplate(team.IdTemplate);
    });
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(",");
    if (
      window.confirm(
        `(It's a custom confirm)Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      // If the confirmation is true, call the function that
      // continues the deletion of the record.
      next();
    }
  }
  

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "500",
        value: 500,
      },
      {
        text: "1000",
        value: 1000,
      },
    ], // you can change the dropdown list for size per page

    sizePerPage: 50, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    paginationPosition: "top", // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    handleConfirmDeleteRow: customConfirm,
    // onRowClick: onRowSelect,
    beforeInsertRow: onAfterInsertRow, // A hook for after insert rows
    afterInsertRow: onAfterInsertRow, // A hook for after insert rows
  };

  const cellEditProp = {
    mode: "click", //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  const selectRowProp = {
    mode: "checkbox",

    //clickToSelect: false, // enable click to select

    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    //onSelect: onRowSelect,
  };

  function onAfterInsertRow(row) {
    let reqTeam = {
      //IdTeam: row.IdTeam,

      // IdTemplate: row.IdTemplate,
      templateName: row.templateName,
      templateDescription: row.templateDescription,
      templateCreateDate: dformatToISO,
      templateLastModificationDate: dformatToISO,
      templateLastModificationUser: 487,
    };

    //=> dformat => '05/17/2012 10:52:21'

    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + `/AddOnsTEMPLATE/`,
      data: reqTeam,
    }).then((response) => {
      console.log("response", response);
    });
  }

  function onRowSelect(row, isSelected, e) {  
    //Not implemented
  }

  function onAfterSaveCell(row, cellName, cellValue) {
    let reqTeam = {
      //IdTeam: row.IdTeam,

      IdTemplate: row.IdTemplate,
      [cellName]: cellValue,
    };
    axios({
      method: "put",
      url: process.env.REACT_APP_API_URL + `/AddOnsTEMPLATE/${row.IdTemplate}`,
      //url: 'https://localhost:44337/api/Game/PlayerGame_Update',
      data: reqTeam,
    }).then((response) => {
      console.log("response", response);
    });
  }

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }

  const editTemplate = async (cell, row, enumObject, rowIndex) => {
    setTemplateToEdit(row.IdTemplate);
    await findTeamsPerTemplate(row.IdTemplate);

    setIsEditTemplate(true);
  };

  const findTeamsPerTemplate = async (idTemplate) => {
    let url = `${process.env.REACT_APP_API_URL}/AddOnsTEMPLATEITEMS/?&filter1=IdTemplate,eq,${filter}${idTemplate}`;

    await axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        if (data.records.length == 0) {
          setTemplateTeams((oldVal) => []);
        }
        setTemplateTeams(data.records);

        // for (let i = 0; i < data.records.length; i++) {
        //   console.log("data.records", data.records);
        //   let idTeam = data.records[i].IdTeam.toString();
        //   let idTemplateItem = data.records[i].IdTemplateItem.toString();
        //   let name = data.records[i].Name;
        //   let idSport = data.records[i].IdSport;
        //   setTemplateTeams((oldVal) => [...oldVal, data2]);
        //   // axios
        //   //   .get(`${process.env.REACT_APP_API_URL}/TEAM/${idTeam}`)
        //   //   .then((response) => response.data)
        //   //   .then((data2) => {
        //   //     data2.IdTemplateItem = idTemplateItem;
        //   //     data2.IdTemplate = idTemplate;
        //   //     setTemplateTeams((oldVal) => [...oldVal, data2]);
        //   //   });
        // }

        //setRecord(data);
      });
  };
  const cellButton = (cell, row, enumObject, rowIndex) => {
    return (
      <Button
        onClick={() => editTemplate(cell, row, enumObject, rowIndex)}
        variant="outlined"
        color="primary"
      >
        Add Templates
      </Button>
    );
  };
  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const saveTeam = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + `/AddOnsTEMPLATEITEMS/`,
      data: {
        IdTemplate: templateToEdit,
        Name: templateName,
        IdSport: templateIdSport,
        type: 1,
      },
    })
      .then((response) => {
        findTeamsPerTemplate(templateToEdit);
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <React.Fragment>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Template Saved"
        action={action}
      />
      <Dialog open={isEditTemplate} onClose={() => setIsEditTemplate(false)}>
        <Grid container spacing={3} style={{ marginLeft: 16 }}>
          <Grid item xs={12}>
            <Typography variant="h6">Current Templates</Typography>
          </Grid>
          {templateTeams.length > 0 &&
            templateTeams.map((team, i) => (
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {i + 1}. {team.Name} - {team.IdSport}
                  <CancelIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteTeamFromSelectedTemplate(team)}
                  />
                </Typography>
              </Grid>
            ))}
        </Grid>
        <TextField
          variant="outlined"
          label="Name"
          placeholder="Enter Name"
          style={{ margin: 16 }}
          value={teamName}
          onChange={(e) => setTemplateName(e.target.value)}
        />

        <FormLabel
          style={{ flexDirection: "row", alignSelf: "center" }}
          id="demo-radio-buttons-group-label"
        >
          IdSport
        </FormLabel>
        <RadioGroup
          style={{ flexDirection: "row", alignSelf: "center" }}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="PROP"
          name="radio-buttons-group"
          value={templateIdSport}
          onChange={(event) => {
            setTemplateIdSport(event.target.value);
          }}
        >
          <FormControlLabel value="PROP" control={<Radio />} label="PROP" />
          <FormControlLabel value="MU" control={<Radio />} label="MU" />
          <FormControlLabel value="TNT" control={<Radio />} label="TNT" />
        </RadioGroup>
        <Button
          style={{ margin: 16 }}
          variant="outlined"
          onClick={() => saveTeam()}
        >
          Add
        </Button>
        {foundTeams && foundTeams.length > 0 ? (
          <Select
            value={selectedTeamId}
            style={{ margin: 16 }}
            onChange={(e) => setSelectedTeamId(e.target.value)}
          >
            {foundTeams &&
              foundTeams.length > 0 &&
              foundTeams.map((row) => (
                <MenuItem value={row.IdTeam}>
                  {row.TeamName + "-" + row.IdSport}
                </MenuItem>
              ))}
          </Select>
        ) : (
          ""
        )}
        <DialogActions>
          {isLoading ? (
            <Loader height={100} width={100}></Loader>
          ) : (
            <div>
              <Button
                variant="outlined"
                color="inherit"
                startIcon={<CancelIcon />}
                onClick={() => {
                  setIsEditTemplate(false);
                  setTeamName("");
                }}
              >
                Close
              </Button>
              {/* <Button
                startIcon={<SaveIcon />}
                color="inherit"
                onClick={() => saveTeam()}
              >
                Ok
              </Button> */}
            </div>
          )}
        </DialogActions>
      </Dialog>

      {isNew ? (
        <CreateGame
          onClose={handleCloseCreate}
          searchGameValues={LoadData}
          IdSport={""}
          selectedGame={selectedGame}
        />
      ) : (
        ""
      )}
      {props.callBackFn ? (
        <div>
          ###
          <Autocomplete
            onChange={onTagsChange}
            disablePortal
            fullWidth
            id="combo-box-demo"
            options={optionsAutoComplete}
            sx={{ width: 400 }}
            renderInput={(params) => <TextField {...params} label="Teams" />}
          />
        </div>
      ) : (
        <div id="main-div">
          <Paper className={classes.root}>
            {!props.callBackFn && (
              <div>
                <h2>Templates</h2>
                <br />
              </div>
            )}
            {isLoading ? <Loader></Loader> : ""}
            <Table className={classes.table} aria-label="simple table">
              <TableBody className={showInfo ? "show-table" : "hide-table"}>
                {games && (
                  <div>
                    <BootstrapTable
                      pagination={true}
                      options={options}
                      editable={false}
                      insertRow={true}
                      cellEdit={cellEditProp} //DBClick daba problemas con el Expand
                      selectRow={selectRowProp} //Da problemas con el Expand
                      version="4"
                      data={games}
                      deleteRow={true}
                      hover
                    >
                      <TableHeaderColumn
                        dataField="IdTemplate"
                        dataFormat={cellButton}
                      ></TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="IdTemplate"
                        hiddenOnInsert
                        isKey={true}
                        autoValue={true}
                        hidden
                      >
                        IdTemplate
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        filter={{ type: "TextFilter", delay: 1000 }}
                        dataField="templateName"
                        // hiddenOnInsert
                      >
                        templateName
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        filter={{ type: "TextFilter", delay: 1000 }}
                        dataField="templateDescription"
                      >
                        templateDescription
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        filter={{ type: "TextFilter", delay: 1000 }}
                        dataField="IdSport"
                     //   hiddenOnInsert
                      >
                        IdSport
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        filter={{ type: "TextFilter", delay: 1000 }}
                        dataField="templateCreateDate"
                        hiddenOnInsert
                        editable={false}
                      >
                        templateCreateDate
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        filter={{ type: "TextFilter", delay: 1000 }}
                        dataField="templateLastModificationDate"
                        hiddenOnInsert
                        editable={false}
                      >
                        templateLastModificationDate
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        filter={{ type: "TextFilter", delay: 1000 }}
                        dataField="templateLastModificationUser"
                        hiddenOnInsert
                        editable={false}
                      >
                        templateLastModificationUser
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                )}
              </TableBody>
            </Table>
          </Paper>
        </div>
      )}
    </React.Fragment>
  );
}

export default Templates;
