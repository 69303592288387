import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import { makeStyles } from "@material-ui/core//styles";
import Paper from '@material-ui/core/Paper';
import './Profiles.css';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import { DataGrid } from '@material-ui/data-grid'
import PropTypes from 'prop-types';
import Loader from '../common/loader/Loader';
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import ProfileNew from './ProfileNew';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function Profiles(props) {
  const [agents, setAgents] = useState();
  const [books, setBooks] = useState([]);
  const [record, setRecord] = useState();
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [selectedProfile, setSelectedProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewProfile, setIsNewProfile] = useState(false);
  const [filterProfile, setFilterProfile] = useState("");
  const [filterNotes, setFilterNotes] = useState("");

  let history = useHistory();
  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    },
  });

  const classes = useStyles();

  useEffect(() => {
    LoadProfile();
  }, []);

  const LoadProfile = () => {
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/PLAYERPROFILE';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setAgents(data.records);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + '/BOOK';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let bookInfoInfo = data.records.map((v) => {
          v.value = v.IdBook;
          v.text = v.Description;
          return v;
        });
        setBooks(bookInfoInfo);
      });
  }, []);

  const deleteProfile = () => {
    if(window.confirm("Are you sure you want to delete the selected item? ("+selectedProfile.IdProfile+")")){
      axios({
        method: 'post',
        url:
          process.env.REACT_APP_API2_URL +
          '/Profile/PlayerProfile_Delete?idProfile=' +
          selectedProfile.IdProfile +
          '&idUser=' +
          userInfo.UserId,
      }).then((response) => {
        setSelectedProfile(false);
        LoadProfile();
      });
      alert('Data Successfully Deleted');      
    }
  }

  function renderManage(params) { 
    return( 
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          window.location.href='/profile/'+params.row.IdProfile
        }}
      >
        Manage
      </Button>
    )
  }

  function onRowSelect(row) {
    setSelectedProfile(agents.filter(x => x.IdProfile == row[0])[0])    
  }

  const handleChangeFilterNotes = (event) => {
    setFilterNotes(event.target.value);
  }

  const handleChangeFilterProfile = (event) => {
    setFilterProfile(event.target.value);
  }

  const handleCloseCreate = function () {
    setIsNewProfile(false); 
  };

  const filterProfiles = () => {
    let aux = agents
    if(filterProfile !== ""){
      aux = agents.filter(x => x.ProfileName.toUpperCase().trim().includes(filterProfile.toUpperCase()))
    }
    if(filterNotes !== ""){
      aux = aux.filter(x => x.Notes.toUpperCase().trim().includes(filterNotes.toUpperCase()))
    }

    return aux;
  }

  if (redirect) {
    let pushURL = '/profile/' + redirect.IdProfile;

    return (
      <React.Fragment>
        <div>
          {history.push(pushURL)}          
        </div>
      </React.Fragment>
    );
  }

  return (
    <div id="main-div">
      <Paper className={classes.root}>
        <br />
        <br /> <h2>Profiles</h2>
        <FormGroup row style={{marginBottom:"2%"}}> 
          <Button
            variant="contained"
            color="primary"
            onClick={()=>{setIsNewProfile(true)}}
          >
            New Profile
          </Button>
          {selectedProfile ? (
            <Button
                style={{ marginLeft: 8 }}
                variant="contained"
                color="secondary"
                onClick={deleteProfile}
              >
                Delete Profile
              </Button>
            ) : (
              ""
            )}
        </FormGroup>
        <FormGroup row>
          <TextField      
            style={{padding:"8px"}}                          
            variant="outlined"                            
            label="Filter by Profile"
            type="text"
            value={filterProfile}
            onChange={handleChangeFilterProfile}                                                             
          />
          <TextField      
            style={{padding:"8px"}}                          
            variant="outlined"                            
            label="Filter by Notes"
            type="text"
            value={filterNotes}
            onChange={handleChangeFilterNotes}                                                             
          />
        </FormGroup>
        {isLoading ? <Loader></Loader> : ''}
        <Table className={classes.table} aria-label="simple table">
          <TableBody className={showInfo ? 'show-table' : 'hide-table'}>
            {agents && (
              <div>
                <Box sx={{ height: 520, width: '100%' }}>
                  <DataGrid
                      rows={filterProfiles()}
                      getRowId={(row) => Object.values(row)[0]}
                      columns={[ 
                        {
                          field: "",
                          headerName: "",
                          align: "right",
                          headerAlign: "center",
                          width: 100,
                          editable: false,
                          sortable: false,
                          filterable: false,
                          renderCell: renderManage,
                        },                    
                        {field: 'IdProfile' , headerName: 'Profile ID', width: 150, editable: false, sortable: false, filterable: false},
                        {field: 'ProfileName' , headerName: 'Profile', width: 250, editable: false, sortable: false, filterable: false},
                        {field: 'Notes' , headerName: 'Notes', width: 200, editable: false, sortable: false, filterable: false},
                        {field: 'AlternateProfile' , headerName: 'AlternateProfile', width: 150, editable: false, sortable: false, filterable: false},
                        {field: 'AlternateEnable' , headerName: 'AlternateEnable', width: 150, editable: false, sortable: false, filterable: false},
                        {field: 'SL_IfBets' , headerName: 'SL_IfBets', width: 150, editable: false, sortable: false, filterable: false},
                        {field: 'SL_AlwaysActionMLBTotals' , headerName: 'SL_AlwaysActionMLBTotals', width: 250, editable: false, sortable: false, filterable: false},
                        {field: 'PL_AllowProps' , PL_AllowProps: 'Name', width: 150, editable: false, sortable: false, filterable: false},
                      ]}
                      pageSize={100}
                      rowsPerPageOptions={[5]}            
                      disableColumnSelector
                      disableColumnMenu
                      disableMultipleSelection={true}  
                      onRowSelectionModelChange={onRowSelect}             
                  />
                </Box> 
                {isNewProfile?(        
                    <ProfileNew
                      onClose={handleCloseCreate}
                      LoadProfile={LoadProfile}
                      books={books}
                    />          
                ):(
                  "" 
                )} 
              </div>
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default Profiles;
