import { createStore } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session';

const initialState = {
  lineManagerGames: [],
  selectedLinetype: null,
  selectedSport: null,
  userData: null,
};

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, reducer);

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LINEMANAGER_GAMES':
      return Object.assign({}, state, {
        lineManagerGames: action.value,
      });
    case 'SET_SELECTED_LINETYPE':
      return Object.assign({}, state, {
        selectedLinetype: action.value,
      });
    case 'SET_SELECTED_SPORT':
      return Object.assign({}, state, {
        selectedSport: action.value,
      });
    case 'SET_USER_DATA':
      return Object.assign({}, state, {
        userData: action.value,
      });
    default: {
      return Object.assign({}, state);
    }
  }
}

export const store = createStore(persistedReducer, initialState);
export const persistor = persistStore(store);
