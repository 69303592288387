import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from '../../common/InputSelect';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import './AgentSettings.css';
const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function AgentSettings(props) {
  
  const [lineTypeID, setLineTypeID] = useState(props.AgentInfo.IdLineType);
  const [groupingID, setGroupingID] = useState(props.AgentInfo.IdGrouping);
  const [commHorses, setCommHorses] = useState(props.AgentInfo.CommHorses);
  const [commCasino, setCommCasino] = useState(props.AgentInfo.CommCasino);
  const [commSports, setCommSports] = useState(props.AgentInfo.CommSports);
  const [idBook, setIdBook] = useState(props.AgentInfo.IdBook);
  const [currencyID, setCurrencyID] = useState(props.AgentInfo.IdCurrency);
  const [agentPerHeadID, setAgentPerHeadID] = useState(
    props.AgentInfo.IdAgentPerHead
  ); 
  const [languageID, setLanguageID] = useState(props.AgentInfo.IdLanguage);
  const [enable, setEnable] = React.useState(props.AgentInfo.Enable);
  const [agentTypeID, setAgentTypeID] = useState(props.AgentInfo.IdAgentType);
  const [masterAgentId, setMasterAgentId] = useState(props.AgentInfo.IdDistributor);
  const [makeUp, setMakeUp] = useState(Math.round(props.agentStatistics[0].MakeUp));
  const [isDistributed, setIsDistributed] = React.useState(
    props.AgentInfo.IsDistributed
  );
  
  const [onlineAcces, setOnlineAccess] = React.useState(
    props.AgentInfo.OnlineAccess
  );
  const [dontXferPlayerActivity, setDontXferPlayerActivity] = React.useState(
    props.AgentInfo.DontXferPlayerActivity
  );
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );

  function changeBookID(value) {
    setIdBook(props.books[value].IdBook);
  }
  function changeGroupingID(value) {
    setGroupingID( props.grouping[value].IdGrouping );
  }
  function clearGroupingId() {
    setGroupingID( null );
  }
  function changeCurrencyID(value) {
    setCurrencyID( props.currency[value].IdCurrency );
  }
  function changeLineTypeID(value) {
    setLineTypeID( props.lineType[value].IdLineType );
  }

  function changeAgentPerHeadID(value) {
    setAgentPerHeadID( props.agentPerHead[value].IdAgentPerHead );
  }

  
  function cleanAgentPerHeadId() {
    setAgentPerHeadID( null );
  }  
  function cleanMasterAgentId() {
    setMasterAgentId( null );
  }
  function changeLanguageID(value) {
    setLanguageID( props.language[value].IdLanguage );
  }

  function cleanLanguageID() {
    setLanguageID( null );
  }

  
  function changeAgentTypeID(value) {
    setAgentTypeID( props.agentType[value].IdAgentType );
  }

  function changeMasterAgentId(value) {
    setMasterAgentId( props.agents[value].IdAgent );
  }
  
  

  const handleChangeEnable = () => {
    setEnable(!enable);
  };
  const handleChangeIsDistributed = () => {
    setIsDistributed(!isDistributed);
  };
  const handleChangeOnlineAccess = () => {
    setOnlineAccess(!onlineAcces);
  };
  const handleChangeDontXferPlayerActivity = () => {
    setDontXferPlayerActivity(!dontXferPlayerActivity);
  };

  const handleSave = () => {
    AgentUpdate();
  };

  const AgentUpdate = () => {
    let AgentRequest = {
      IdAgent: props.AgentInfo.IdAgent,
      Enable: enable,
      DontXferPlayerActivity: dontXferPlayerActivity,
      IsDistributor: isDistributed,
      Distributor: masterAgentId,
      CommSports: commSports,
      CommCasino: commCasino,
      CommHorses: commHorses,
      IdAgentType: agentTypeID,
      IdCurrency: currencyID,
      IdBook: idBook,
      IdGrouping: groupingID,
      Agent1: props.AgentInfo.Agent,
      Name: props.AgentInfo.Name,
      Password: props.AgentInfo.Password,
      City: props.AgentInfo.City,
      State: props.AgentInfo.State,
      Country: props.AgentInfo.Country,
      Address1: props.AgentInfo.Address1,
      Address2: props.AgentInfo.Address2,
      Email: props.AgentInfo.Email,
      Phone: props.AgentInfo.Phone,
      Fax: props.AgentInfo.Fax,
      Zip: props.AgentInfo.Zip,
      OnlineAccess: onlineAcces,
      OnlineMessage: props.AgentInfo.OnlineMessage,
      IdLineType: lineTypeID,
      IdAgentPerHead: agentPerHeadID,
      IdLanguage: languageID,
      LastModificationUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Agent/Agent_Update',
      data: AgentRequest,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
        props.setTrigger(!props.trigger)
      }
      console.log('response', response);
    });
  };

  const handleChangeMakeUp = (event) => {
    setMakeUp(event.target.value);
  };

  const handleChangeCommCasino = (event) => {
    setCommCasino(event.target.value);
  };

  const handleChangeCommHorses = (event) => {
    setCommHorses(event.target.value);
  };

  const handleChangeCommSports = (event) => {
    setCommSports(event.target.value);
  };


  return (
    <React.Fragment>
      <br />

      {props.AgentInfo && (
        <form>
          <Table>
            <TableRow>
              <TableCell>
                <Table>
                  <TableRow>
                    <TableCell class="MuiTableCell-root">Type</TableCell>
                    <TableCell class="MuiTableCell-root">
                      <InputSelect
                        variant="outlined"
                        name="name"
                        data={props.agentType}
                        placeholder="Agent Type"
                        setSelectValue={changeAgentTypeID}
                        startValue={props.agentType.findIndex(x => x.IdAgentType === agentTypeID)}
                      />
                    </TableCell>

                    <TableCell class="MuiTableCell-root"></TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell class="MuiTableCell-root">Master Agent</TableCell>
                    <TableCell class="MuiTableCell-root">
                      <InputSelect
                        key={masterAgentId}
                        variant="outlined"
                        name="name"
                        data={props.agents}
                        placeholder="Master Agent"
                        setSelectValue={changeMasterAgentId}
                        startValue={props.agents.findIndex(x => x.IdAgent === masterAgentId)}
                      />
                    </TableCell>
                    <TableCell class="MuiTableCell-root">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={cleanMasterAgentId}
                      >
                        Clean
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell class="MuiTableCell-root">Book</TableCell>
                    <TableCell class="MuiTableCell-root">
                      <InputSelect
                        variant="outlined"
                        name="name"
                        data={props.books}
                        placeholder="Id Book"
                        setSelectValue={changeBookID}
                        startValue={props.books.findIndex(x => x.IdBook === idBook)}
                      />
                    </TableCell>
                    <TableCell class="MuiTableCell-root"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell class="MuiTableCell-root">Grouping</TableCell>
                    <TableCell class="MuiTableCell-root">
                      <InputSelect
                        key={groupingID}
                        variant="outlined"
                        name="name"
                        data={props.grouping}
                        placeholder="Grouping"
                        setSelectValue={changeGroupingID}
                        startValue={props.grouping.findIndex(x => x.IdGrouping === groupingID)}
                      />
                    </TableCell>
                    <TableCell class="MuiTableCell-root">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={clearGroupingId}
                      >
                        Clean
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell class="MuiTableCell-root">Currency</TableCell>
                    <TableCell class="MuiTableCell-root">
                      <InputSelect
                        variant="outlined"
                        name="name"
                        data={props.currency}
                        placeholder="Currency"
                        setSelectValue={changeCurrencyID}
                        startValue={props.currency.findIndex(x => x.IdCurrency === currencyID)}
                      />
                    </TableCell>
                    <TableCell class="MuiTableCell-root"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell class="MuiTableCell-root">Line Type</TableCell>
                    <TableCell class="MuiTableCell-root">
                      <InputSelect
                        variant="outlined"
                        name="name"
                        data={props.lineType}
                        placeholder="Line Type"
                        setSelectValue={changeLineTypeID}
                        startValue={props.lineType.findIndex(x => x.IdLineType === lineTypeID)}
                      />
                    </TableCell>
                    <TableCell class="MuiTableCell-root"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell class="MuiTableCell-root">Per Head</TableCell>
                    <TableCell class="MuiTableCell-root">
                      <InputSelect
                        key={agentPerHeadID}
                        variant="outlined"
                        name="name"
                        data={props.agentPerHead}
                        placeholder="Agent per head"
                        setSelectValue={changeAgentPerHeadID}
                        startValue={props.agentPerHead.findIndex(x => x.IdAgentPerHead === agentPerHeadID)}
                      />
                    </TableCell>
                    <TableCell class="MuiTableCell-root">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={cleanAgentPerHeadId}
                      >
                        Clean
                      </Button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell class="MuiTableCell-root">Language</TableCell>
                    <TableCell class="MuiTableCell-root">
                      <InputSelect
                        key={languageID}
                        variant="outlined"
                        name="name"
                        data={props.language}
                        placeholder="Language"
                        setSelectValue={changeLanguageID}
                        startValue={props.language.findIndex(x => x.IdLanguage === languageID)}
                      />
                    </TableCell>
                    <TableCell class="MuiTableCell-root">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={cleanLanguageID}
                      >
                        Clean
                      </Button>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableCell>
              <TableCell>
                <Table>
                  <TableRow>
                    <Checkbox
                      checked={enable}
                      onChange={handleChangeEnable}
                      color="primary"
                    />
                    Enable
                  </TableRow>
                  <TableRow>
                    <Checkbox
                      checked={isDistributed}
                      onChange={handleChangeIsDistributed}
                      color="primary"
                    />
                    Distributor
                  </TableRow>
                  <TableRow>
                    <Checkbox
                      checked={onlineAcces}
                      onChange={handleChangeOnlineAccess}
                      color="primary"
                    />
                    Online Access
                  </TableRow>

                  <TableRow>
                    <Checkbox
                      checked={dontXferPlayerActivity}
                      onChange={handleChangeDontXferPlayerActivity}
                      color="primary"
                    />
                    Don't Xfer Player Activity
                  </TableRow>
                  <TableRow>
                    <TableCell class="MuiTableCell-root">Make Up</TableCell>
                    <TableCell class="MuiTableCell-root">
                      <TextField
                        variant="outlined"
                        type="number"
                        label="Make Up"
                        value={makeUp}
                        onChange={handleChangeMakeUp}
                        multiline={false}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell class="MuiTableCell-root">
                      Comm % Sports
                    </TableCell>
                    <TableCell class="MuiTableCell-root">
                      <TextField
                        variant="outlined"
                        type="number"
                        label="Comm % Sports"
                        value={commSports}
                        multiline={false}
                        onChange={handleChangeCommSports}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell class="MuiTableCell-root">
                      Comm % Casino
                    </TableCell>
                    <TableCell class="MuiTableCell-root">
                      <TextField
                        variant="outlined"
                        type="number"
                        label="Comm % Casino"
                        value={commCasino}
                        multiline={false}
                        onChange={handleChangeCommCasino}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell class="MuiTableCell-root">
                      Comm % Sports
                    </TableCell>
                    <TableCell class="MuiTableCell-root">
                      <TextField
                        variant="outlined"
                        type="number"
                        label="Comm % Horses"
                        value={commHorses}
                        multiline={false}                        
                        onChange={handleChangeCommHorses}
                      />
                    </TableCell>
                  </TableRow>
                </Table>
              </TableCell>
            </TableRow>
          </Table>
          <Button color="primary" variant="outlined" onClick={handleSave}>
            Save
          </Button>
        </form>
      )}
    </React.Fragment>
  );
}
