import React, { useState, setState, useEffect, useContext } from 'react';
import { forwardRef, useRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import FormGroup from '@material-ui/core/FormGroup';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

/*user Components*/
import UserProfile from './UserProfile';
import DepartmentsList from '../../departments/departmentsComponents/DepartmentsList';
import LanguageList from '../../languages/languagesComponents/LanguageList';

import './UserProfile.css';

const User = forwardRef((props) => {
  const [sysUser, setSysUser] = React.useState([]);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isSearched, setIsSearched] = useState(false);
  const [idUserProfile, setIdUserProfile] = useState(0);
  const [idDepartment, setIdDepartment] = useState(0);
  const [idlanguage, setIdLanguage] = useState(0);
  const [language, setLanguage] = useState([]);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [profiles, setProfiles] = useState();
  const [departments, setDepartments] = useState();

  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      useState,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '100%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  let history = useHistory();

  const getDepartments = () => {
    const url =
      process.env.REACT_APP_API_URL + '/DEPARTMENT?order=IdDepartment';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setDepartments(data.records);
      });
  }

  const getProfiles = () => {
    const url =
      process.env.REACT_APP_API_URL + '/USERPROFILE?order=IdUserProfile';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setProfiles(data.records);
      });
  }

  const getLanguages = () => {
    const url = process.env.REACT_APP_API_URL + '/Language';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let languageInfo = data.records.map((v) => {
          v.IdLineType = v.IdLanguage;
          v.Description = v.LangDesc;
          return v;
        });
        setLanguage(languageInfo);
      });
  }

  useEffect(() => {
    getDepartments();
    getProfiles();
    getLanguages();
  }, []);

  const GetUserInfo = () => {
    const url =
      process.env.REACT_APP_API_URL +
      '/USERS?filter=LoginName,eq,' +
      props.sysUser.UserName;
    const timeout = setTimeout(() => {
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setSysUser(data.records);

          console.log('User data props', data);
        }, 500);

      return () => clearTimeout(timeout);
    });
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  function onAfterSaveCell(row, cellName, cellValue) {
    props.UpdateSysUser(row);
  }
  //subscribeToTimer((err, timestamp) => settimestamp(timestamp));

  const UpdateUserPass = () => {
    let userRequest = {
      IdUser: props.sysUser.IdUser,
      Password: password,
      Reset_Password: true,
      LastModUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/User/Users_ResetPassword',
      data: userRequest,
    }).then((response) => {
      if (response.data === '0') {
        alert('Data Successfully Updated');
        setPassword('');
      }
      console.log('response', response);
    });
  };

  const handleUserProfileChange = (event, row) => {
    setIdUserProfileOnMem(event.target.value, row);
  };

  const setIdUserProfileOnMem = (idUserProfile, row) => {
    setIdUserProfile(idUserProfile);
    row.IdUserProfile = idUserProfile;
    props.UpdateSysUser(row);
  };

  const handleDepartmentChange = (event, row) => {
    setIdDepartmentOnMem(event.target.value, row);
  };

  const setIdDepartmentOnMem = (idDepartment, row) => {
    setIdDepartment(idDepartment);
    row.IdDepartment = idDepartment;
    props.UpdateSysUser(row);
  };

  const handleLanguageChange = (event, row) => {
    setIdLanguageOnMem(event.target.value, row);
  };

  const setIdLanguageOnMem = (idLanguage, row) => {
    setIdLanguage(idLanguage);
    row.IdLanguage = idLanguage;
    props.UpdateSysUser(row);
  };

  const userProfileFormatter = (cell, row) => {
    return (
      <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        value={cell}
        onChange={(e) => handleUserProfileChange(e,row)}
        displayEmpty
      >
        {profiles &&
          profiles.map((row) => (
            <MenuItem value={row.IdUserProfile}>{row.ProfileName}</MenuItem>
          ))}
      </Select>
    );
  };

  const departmentListFormatter = (cell, row) => {
    return (
      <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        value={cell}
        onChange={(e) => handleDepartmentChange(e,row)}
        displayEmpty
      >
        {departments &&
          departments.map((row) => (
            <MenuItem value={row.IdDepartment}>{row.Description}</MenuItem>
          ))}
      </Select>
    );
  };

  const languageListFormatter = (cell, row) => {
    return (
      <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        value={cell}
        onChange={(e) => handleLanguageChange(e,row)}
        displayEmpty
      >
        {language &&
          language.map((row) => (
            <MenuItem value={row.IdLanguage}>{row.Description}</MenuItem>
          ))}
      </Select>
    );
  };

  return (
    <React.Fragment>
      {console.log('in user props', props)}
      {sysUser && (
        <div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="users-right-div cell-top-align">
                  <div className="users-right-div">
                    <h5>User Information</h5>

                    <BootstrapTable
                      cellEdit={cellEditProp}
                      version="4"
                      data={[props.sysUser]}
                      hover
                    >
                      <TableHeaderColumn dataField="IdUser" hidden isKey={true}>
                        IdLineType
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="LoginName">
                        Login Name
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField="Name">
                        Name
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        editable={false}
                        dataField="IdUserProfile"
                        dataFormat={userProfileFormatter}
                      >
                        User Profile
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  cellEdit={cellEditProp}
                  className="users-right-div cell-top-align"
                >
                  <div className="users-right-div">
                    <BootstrapTable
                      version="4"
                      data={[props.sysUser]}
                      cellEdit={cellEditProp}
                    >
                      <TableHeaderColumn dataField="IdUser" hidden isKey={true}>
                        IdLineType
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="Status"
                        editable={{
                          type: 'checkbox',
                          options: {
                            values: 'true:false',
                          },
                        }}
                      >
                        Enable
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataFormat={departmentListFormatter}
                        editable={false}
                        dataField="IdDepartment"
                      >
                        Department
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="IdLanguage"
                        dataFormat={languageListFormatter}
                        editable={false}
                      >
                        Language
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className={classes.center}>
                    <TextField
                      style={{ width: '55%' }}
                      type="text"
                      value={password != '' ? password : ''}
                      variant="outlined"
                      multiline={true}
                      onChange={handleChangePassword}
                    />
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={UpdateUserPass}
                    >
                      Reset Password
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
});

export default User;
