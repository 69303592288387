import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import EventsLeagues from '../events/eventsLeagues';
import EventsPerLeagues from '../events/eventsPerLeague';

function Events(props) {
  //const user = useContext(UserContext);

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );

  const [eventsLeagues, setEventsLeagues] = useState([]);

  const GetEventsLeagues = (idLeague) => {
    let url =
      process.env.REACT_APP_API2_URL +
      '/Events/GetEventsByLeague?idLeague=' +
      idLeague;
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      let temp = response.data.filter((obj, key, array) =>
        array.map((obj2) => obj.IdEvent !== obj2.IdEvent)
      );
      let array = response.data.map((x) => {
        return {
          IdEvent: x.IdEvent,
          Description: x.Description,
        };
      });
      setEventsLeagues(array);
    });
  };

  return (
    <React.Fragment>
      <div>
        <br />
        <Table>
          <TableRow>
            <TableCell>
              <EventsLeagues GetEventsLeagues={GetEventsLeagues} />
            </TableCell>
            <TableCell>
              <EventsPerLeagues eventsLeagues={eventsLeagues} />
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </React.Fragment>
  );
}

export default Events;
