import React, {
  useState,
  setState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { forwardRef, useRef, useImperativeHandle } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import InputSelect from "../components/common/InputSelect";
import Schedule from "../components/common/Schedule";
import SchedulePS from "../components/common/SchedulePS";
import { UserContext } from "../contexts/UserContext";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { DelayInput } from "react-delay-input";
import { useSelector, useDispatch } from "react-redux";
import KeyboardEventHandler from "react-keyboard-event-handler";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../components/common/loader/Loader";
import "./LinesManager.css";
import Axios from "axios";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CreateGame from "../components/CreateGame";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { set } from "date-fns/esm";
//import { callSp } from "../components/common/dgs-sp-api";

const LinesManager = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const lineManagerGames = useSelector((state) => state.lineManagerGames);
  const [IdSport, setIdSport] = useState(0);
  const [lineTypeID, setlineTypeID] = useState(55);
  const [lineTypes, setLineTypes] = useState();

  const [lineTypeIndex, setLineTypeIndex] = useState(13);
  const [sports, setSports] = useState();
  const [leagues, setLeagues] = useState();
  const [leagueID, setLeagueID] = useState(-1);
  const [isActive, setisActive] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState(true);
  const [predictiveText, setPredictiveText] = React.useState(true);
  const [lineTypeAction, setLineTypeAction] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [recentUpdatedGame, setRecentUpdatedGame] = useState("");
  const [filterStr, setFilterStr] = useState("");
  const [idGameTNT, setIdGameTNT] = useState(0);
  const [selectedGame, setSelectedGame] = useState([]);
  const [scheduleCount, setScheduleCount] = useState(0);
  const [schedulePagination, setSchedulePagination] = useState([])
  const [gameActions,setGameActions] = useState([]);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );
  const [gameActionsReadyTNT,setGameActionsReadyTNT] = useState(false);
  let lastChangeId = 0;
  const [isNew, setIsNew] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [pageNumber, setPageNumber] = useState(0);
  const [systemColors, setSystemColors] = useState([]);
  const [gameTypes, setGameTypes] = React.useState([]);
  const [tempRecords, setTempRecords] = useState([]);
  const [previousPageNumber, setPreviousPageNumber] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [arrayOffSet, setArrayOffSet] = useState([0]);

  const setEventKey = function (key) {
    if (key === "f4") {
      changeSportID("NFL");
    }
    if (key === "f6") {
      changeSportID("CFB");
    }
    if (key === "f7") {
      changeSportID("NBA");
    }
    if (key === "f8") {
      changeSportID("CBB");
    }
    if (key === "f9") {
      changeSportID("MLB");
    }
    if (key === "f10") {
      changeSportID("NHL");
    }
    if (key === "f11") {
      changeSportID("MU");
    }
    if (key === "ctrl+f9") {
      changeSportID("ESOC");
    }
    if (key === "ctrl+f10") {
      changeSportID("SOC");
    }
    if (key === "ctrl+f11") {
      changeSportID("TNT");
    }
    if (key === "ctrl+f12") {
      changeSportID("PROP");
    }

    if (key === "ctrl+right") {
      let index = lineTypeIndex;
      changeLineTypeID(index === lineTypes.length-1? 0 : index + 1);      
    }
    if (key === "ctrl+/") {
      let index = lineTypeIndex;
      setShowHelp(!showHelp);
    }
    if (key === "ctrl+left") {
      let index = lineTypeIndex;
      changeLineTypeID(index === 0 ? 0 : index + -1);
    }
    if (key === "ctrl+-") {
    }
  };

  const user = useContext(UserContext);

  const inputRef = useRef(null);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchGameValues()
    }
  };

 

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      useState,
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 200,
      },
      "& .MuiFormGroup-row": {
        justifyContent: "center",
      },
      "& .MuiFormControl-root": {
        justifyContent: "center",
      },
    },
  }));

  const classes = useStyles();

  const handleKeyDown = (event) => {
    if (isLoading && event.key === 'Tab') {
      event.preventDefault(); 
    }
  };

  const gamesGetAction = () => {
      return axios({
        method: "post",
        url: process.env.REACT_APP_DEF_PHP_URL + "/GamesGetAction.php",
  
        data: {
          idsport: IdSport,
          idLeague:-1,
          chartType: 0,
          idlinetype: lineTypeAction === -1? lineTypeAction : lineTypeID,
          idUser: 498        
        },
      })        
  }

  const gamesGetShchedule = () => {  
    
    if(IdSport == 'TNT'){
      return axios({
        method: "post",
        url: process.env.REACT_APP_DEF_PHP_URL  + "/GetSchedule.php",
        data: {
          Idsport: IdSport,
          Idlinetype: lineTypeID,
          Idleague: leagueID,
          OrderType: 2,
          DisplayPeriod: "A",
          MoveCloseGames: true,
          IdUser: 498,
        },
      })
    }else{
      return axios({
        method: "post",
        url: process.env.REACT_APP_API2_URL + "/Game/Games_GetSchedule",
        data: {
          Idsport: IdSport,
          Idlinetype: lineTypeID,
          Idleague: leagueID,
          Action: lineTypeID,
          OrderType: 2,
          DisplayPeriod: "A",
          MoveCloseGames: true,
          IdUser: 498,
        },
      })
    }    
  }

  const gamesGetData = (idGame) => {
    return axios({
        method: "post",
        url: process.env.REACT_APP_DEF_PHP_URL + "/GamesGameData.php",
        data: {
          idgame: idGame,
          idlinetype: lineTypeID,
        },
      })    
  }

  const getGameType = () => {
    return axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/GAMETYPE"
    })
  }

  const GetGameTypes = () => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/GAMETYPE',
    }).then((response) => { 
      setGameTypes(response.data.records);
    });
  };

  const getGamesValue = () => {
    return axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/GAMEVALUES?filter=IdLineType,eq," + lineTypeID
    })
  }

  const assignMoneyLines = (data) => {
    data.data.map((x) => {
      return {
        Period: x[17],
        VisitorOdds: x[38],
        HomeOdds: x[39],
        TotalOver: x[40],
        OverOdds: x[41],
        TotalUnder: x[42],
        UnderOdds: x[43],
        VisitorSpread: x[44],
        VisitorSpreadOdds: x[45],
        HomeSpread: x[46],
        HomeSpreadOdds: x[47],
        VisitorSpecial: x[48],
        VisitorSpecialOdds: x[49],
        HomeSpecial: x[50],
        HomeSpecialOdds: x[51],
        IdGameType: x[5],
        GameStat: x[11],
        Description: x[36],
        ParentGame: x[21],
        GameDateTime: x[7],
        Printable: x[62],
      };
    });
  }



  const searchGameValues = async () => {
    
    ClearArrar();
    setIsLoading(true);
    setisActive(true);
  
    try {
      const response = await getSchedulePagination(pageNumber + 1, rowsPerPage);
      const data = response.data;
  
      if (data.data) {
        assignPrintableValues(data.data);
        assignPeriodHeaders(data.data);
        setSchedulePagination(data.data);
  
        if (open) {
          data.data = data.data.filter((g) => g.GameStat === "O" || g.GameStat === "B");
        }
  
        const gameActionsResponse = await gamesGetAction();
        const gameActions = gameActionsResponse.data.data;
        setGameActions(gameActions);
  
        if (IdSport !== 'TNT') {
          data.data = data.data.map((game) => {
            const filteredGameAction = gameActions.filter((gameAction) => gameAction.IdGame === game.id);
            if (filteredGameAction.length > 0) {
              game.GameAction = filteredGameAction[0];
            }
            return game;
          });
        } else {
          data.data = data.data.map((game) => {
            const filteredGameAction = gameActions.filter((gameAction) => gameAction.IdGame === game.IdGame && gameAction.TeamNumber === game.id);
            if (filteredGameAction.length > 0) {
              game.GameAction = filteredGameAction[0];
            }
            return game;
          });
        }
  
        const gamesValuesResponse = await getGamesValue();
        const gamesValues = gamesValuesResponse.data.records;
        data.data = data.data.map((v) => {
          const gamesValue = gamesValues.find((el) => el.IdGame === v.id && lineTypeID === el.IdLineType);
          v.GameValues = gamesValue;
          return v;
        });
  
        dispatch({ type: "SET_LINEMANAGER_GAMES", value: data.data });
  
        const gameTypeResponse = await getGameType();
        const gameTypes = gameTypeResponse.data.records;
        data.data = data.data.map((v) => {
          const gametype = gameTypes.find((el) => el.IdGameType === v.IdGameType);
          v.GameType = gametype;
          return v;
        });
  
        dispatch({ type: "SET_LINEMANAGER_GAMES", value: data.data });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const setLastChangedGamedID = (idgame) => {
    setRecentUpdatedGame(idgame);
  };
  
  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + "/LINETYPE";
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setLineTypes(data.records);
      });
  }, [setLineTypes]);



  const filterGames = (e) => {
    setFilterStr(e.target.value);
  };
  const handleCloseCreate = function () {
    setIsNew(false);
  };

  useEffect(() => {
    getLeagues(IdSport);
    getSports();    
    dispatch({
      type: "SET_LINEMANAGER_GAMES",
      value: [],
    });
    getSystemColors();
    GetGameTypes();
  }, []);

  useEffect(() => {
    setPageNumber(0)
    setRowsPerPage(100)
  }, [IdSport]);

  useEffect(() => {
    if(pageNumber > 0 && (currentPageNumber > previousPageNumber) && (currentPageNumber + 1 > arrayOffSet.length ))
      if(offSet === 0)
        setOffSet([rowsPerPage] - leftOfGames(schedulePagination))
      else{
        setOffSet(prevValue => prevValue + ([rowsPerPage] - leftOfGames(schedulePagination)));
      }
  }, [pageNumber]);

  useEffect(() => {  
    if(offSet > 0){
      const offSetAux = [...arrayOffSet, offSet];
      setArrayOffSet(offSetAux);
    }
  }, [offSet]);

 

  function leftOfGames(array) {   
    const bCount = array
    .slice(0, rowsPerPage-1)
    .reduce((acc, item) => (item.id && typeof item.id === 'string' && item.id.includes('B')  ? acc + 1 : acc), 0);
    return bCount
    
  }



 

  const getLeagues = (idSport) => {
    const url =
      process.env.REACT_APP_API_URL + "/LEAGUE?filter=IdSport,eq," + idSport;
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let leagueInfo = data.records.map((v) => {
          v.IdLineType = v.IdLeague;
          v.Description = v.Description;
          return v;  
        });
        setLeagues(leagueInfo);
      });
  };

  const getSports = () => {
    const url = process.env.REACT_APP_API_URL + "/SPORT";
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let groupingInfo = data.records.map((v) => {
          v.IdSport = v.IdSport;
          v.SportName = v.SportName;
          return v;
        });
        setSports(groupingInfo);
      });
  }

  const getScheduleCount = () => {
  
    axios({
      method: "post",
      url: process.env.REACT_APP_DEF_PHP_URL + "/GetScheduleCount.php",
      data: {
        idSport : IdSport,
        idLineType : lineTypeID,
        idLeague : leagueID,        
        orderType : 2,
        displayPeriod : 'A',
        moverCloseGames : true,
        idUser : userInfo.UserId,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        setScheduleCount(data.data[0][0]);
      })    
  }

  const getSchedulePagination = (pageNumber, recordsPerPage) => { 
    if(filterStr === ""){
      return(
        axios({
          method: "post",
          url: process.env.REACT_APP_DEF_PHP_URL + "/GetSchedulePagination.php",
          data: {
            idSport : IdSport,
            idLineType : lineTypeID,
            idLeague : leagueID,        
            orderType : 2,
            displayPeriod : 'A',
            moverCloseGames : true,
            idUser : userInfo.UserId,
            pageNumber : pageNumber,
            recordsPerPage : recordsPerPage,
            offSet: arrayOffSet[pageNumber-1]
          },
        })
      )
    }else{
      return(
        axios({
          method: "post",
          url: process.env.REACT_APP_DEF_PHP_URL + "/GetSchedulePaginationFilter.php",
          data: {
            idSport : IdSport,
            idLineType : lineTypeID,
            idLeague : leagueID,        
            orderType : 2,
            displayPeriod : 'A',
            moverCloseGames : true,
            idUser : userInfo.UserId,
            filter : filterStr,
          },
        })
      )
    }  
    
  }



  const getSystemColors = async ()  => {
    const url = process.env.REACT_APP_API_URL + "/SYSTEMCOLORS";      
    await axios
    .get(url)
    .then((response) => response.data)
    .then((data) => {
      setSystemColors(data.records);
    });
  }

  const getBannerSuffix = (row) => {
    let suffix = "";
    switch (row.Period) {
      case 1:
        suffix = " - First Half Lines ";
        break;
      case 2:
        suffix = " - Second Half Lines ";
        break;
      case 3:
        suffix = " - First Quarters ";
        break;
      case 4:
        suffix = " - Second Quarters ";
        break;
      case 5:
        suffix = " - Third Quarters ";
        break;
      case 6:
        suffix = " - Forth Quarters ";
        break;
      case 7:
        suffix = " - First Periods ";
        break;
      case 8:
        suffix = " - Second Periods ";
        break;
      case 9:
        suffix = " - Third Periods ";
        break;
      default:
        suffix = "";
        break;
    }
    return suffix;
  };

  let currentCombination = [];

  const arethereMultiplePeriods = (row, games) => {
      if (currentCombination.length === 0) {
        for (let i = 0; i < games.length; i++) {
          if (
            games[i].Period !== 0 &&
            games[i].Period === row.Period &&
            games[i].GameDateTime.substr(0, 9) ===
              row.GameDateTime.substr(0, 9) &&
            games[i].LeagueDescription === row.LeagueDescription &&
            leagueDescriptionContainsPeriod(row) === 0
          ) {
            currentCombination.push(row.Period);
            currentCombination.push(row.GameDateTime.substr(0, 9));
            currentCombination.push(row.LeagueDescription);
            return true;
          }
        }
      } else if (
        currentCombination[0] != row.Period ||
        currentCombination[1] != row.GameDateTime.substr(0, 9) ||
        currentCombination[2] != row.LeagueDescription
      ) {
        for (let i = 0; i < games.length; i++) {
          if (
            games[i].Period !== 0 && games[i].Period === row.Period &&
            games[i].GameDateTime.substr(0, 9) ===
              row.GameDateTime.substr(0, 9) &&
            games[i].LeagueDescription === row.LeagueDescription &&
            leagueDescriptionContainsPeriod(row) === 0
          ) {
            currentCombination.pop();
            currentCombination.pop();
            currentCombination.pop();
            currentCombination.push(row.Period);
            currentCombination.push(row.GameDateTime.substr(0, 9));
            currentCombination.push(row.LeagueDescription);
            return true;
          }
        }
      }
    
  };

  const assignPrintableValues = (schedulePagination) => { 
    if(schedulePagination.length > 0){
      let currentIdLeague = schedulePagination[0].IdLeague
      let currentDate = schedulePagination[0].GameDateTime.substring(0,9)
      schedulePagination[0].Printable = 1
      schedulePagination.splice(
        0,
        0,
        {
          id: schedulePagination[0].id + 'B',
          LeagueDescription: schedulePagination[0].LeagueDescription,
          GameDateTime: schedulePagination[0].GameDateTime.substring(0,9),
          HomeTeam: schedulePagination[0].HomeTeam,
          VisitorTeam: schedulePagination[0].VisitorTeam,
          VisitorNumber: schedulePagination[0].VisitorNumber,
          HomeNumber: schedulePagination[0].HomeNumber
        }
      )
  
      for(let i = 1; i < schedulePagination.length ; i++){
        if(schedulePagination[i].IdLeague === currentIdLeague && schedulePagination[i].GameDateTime.substring(0,9) === currentDate){
          schedulePagination[i].Printable = 0
        }else{
          schedulePagination[i].Printable = 1
          currentIdLeague = schedulePagination[i].IdLeague
          currentDate = schedulePagination[i].GameDateTime.substring(0,9)
          schedulePagination.splice(
            i,
            0,
            {
              id: schedulePagination[i].id + 'B',
              LeagueDescription:schedulePagination[i].LeagueDescription,
              GameDateTime:schedulePagination[i].GameDateTime.substring(0,9),
              HomeTeam: schedulePagination[i].HomeTeam,
              VisitorTeam: schedulePagination[i].VisitorTeam,
              VisitorNumber: schedulePagination[i].VisitorNumber,
              HomeNumber: schedulePagination[i].HomeNumber
            }
          )
        }
      }
    }
  }

  const leagueDescriptionContainsPeriod = (row) => {
    const periodsWords = [
      "1ST HALVES",
      "2ND HALVES",
      "1H",
      "2H",
      "FIRST HALF LINES",
      "SECOND HALF LINES",
      "1ST HALF",
      "2ND HALF",
      "FIRST HALF",
      "SECOND HALF",
      "FIRST HALVE",
      "SECOND HALVE",
    ];

    let cont = 0;
    for (let i = 0; i < periodsWords.length; i++) {
      if (row.LeagueDescription.includes(periodsWords[i])) {
        cont++;
      }
    }
    return cont;
  };

  const assignPeriodHeaders = (schedulePagination) => {
    if(schedulePagination.length > 0){
      for(let i = 1; i < schedulePagination.length ; i++){   
        if(typeof schedulePagination[i].id !== 'string'){
          if(arethereMultiplePeriods(schedulePagination[i],schedulePagination)){
            schedulePagination.splice(
              i,
              0,
              {
                id: schedulePagination[i].id + 'B',
                LeagueDescription: schedulePagination[i].LeagueDescription + getBannerSuffix(schedulePagination[i]),
                GameDateTime:schedulePagination[i].GameDateTime.substring(0,9),
                HomeNumber: schedulePagination[i].HomeNumber,
                VisitorNumber: schedulePagination[i].VisitorNumber,
                HomeTeam: schedulePagination[i].HomeTeam,
                VisitorTeam: schedulePagination[i].VisitorTeam
              }
            )
          }          
        }
      }
    }
  }

  function changeSportID(value) {
    setIdSport(value);
    ClearArrar();
    getLeagues(value);
    dispatch({ type: "SET_SELECTED_SPORT", value: value });
  }

  function changeLineTypeID(value) {    
    let linetype = lineTypes[value];
    setLineTypeIndex(value);    
    setlineTypeID(linetype.IdLineType);
    dispatch({ type: "SET_SELECTED_LINETYPE", value: linetype.IdLineType });
    ClearArrar();
  }

  function changeLeagueID(value) {
    setLeagueID(value);
  }

  function showOverlay(value) {
    value && setisActive(true);
    !value && setisActive(false);
  }

  function runChange() {
    props.announce();
  }

  const setLineTypeIdAsync = (state) => {
    return new Promise((resolve) => {
      setLineTypeAction(state,resolve)      
    })
  }

  

  const handleChangePredictiveText = async () => {    
    setPredictiveText(!predictiveText);        
  }

  const handleChangeAction = async () => {    
    if (action) {
      setAction(!action);
      await setLineTypeIdAsync(lineTypeID)           
    } else {
      setAction(!action);
      await setLineTypeIdAsync(-1)     
    }      
  }
    
  useEffect(() => {
    if(IdSport !== 0)
    searchGameValues(pageNumber+1,rowsPerPage)
  }, [action,arrayOffSet]);


  useEffect(() => {    
    if(IdSport !== 0){
      setRowsPerPage(100)
      setPageNumber(0)
      searchGameValues(1,100);
      getScheduleCount();
    }      
  }, [IdSport]);

  useEffect(() => {
    if(IdSport !== 0){
      searchGameValues(pageNumber+1,rowsPerPage);
    }    
  }, [lineTypeID,action,pageNumber,rowsPerPage]);


  const handleSelectedGame = (idGame) => {
    if (!action) {
      setSelectedGame((selectedGame) => selectedGame.concat(idGame));
      setAction(!action);
      //setLineTypeAction(-1);
    } else {
      setAction(!action);
      let filteredArray = selectedGame.filter((item) => item !== idGame);
      setSelectedGame({ selectedGame: filteredArray });
    }
  };

  const handleChangeRecordsPerPage = (newValue) => {
    setRowsPerPage(newValue)
  } 

  const handleChangePageNumber = (newValue) => {
    if(previousPageNumber === 0 && currentPageNumber === 0){
      setCurrentPageNumber(newValue)
    }else{
      setCurrentPageNumber(newValue)
      setPreviousPageNumber(currentPageNumber)
    }    
    setPageNumber(newValue);
  } 

  const ClearArrar = () => {
    setSelectedGame([]);
  };

  const handleClickCheck = (
    name,
    visitorOdds
  ) => {
    const selectedIndex = selectedGame.indexOf(name);
    let newSelected = [];
    if (IdSport != "TNT") {
      const selectedIndex = selectedGame.indexOf(name);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedGame, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedGame.slice(1));
      } else if (selectedIndex === selectedGame.length - 1) {
        newSelected = newSelected.concat(selectedGame.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedGame.slice(0, selectedIndex),
          selectedGame.slice(selectedIndex + 1)
        );
      }
    } else {
      const selectedIndex = selectedGame.indexOf(name);
      if (selectedIndex === -1) {
        setIdGameTNT(name);
        newSelected = newSelected.concat(selectedGame, name);
      } else if (selectedIndex === 0) {
        setIdGameTNT(name);
        newSelected = newSelected.concat(selectedGame.slice(1));
      } else if (selectedIndex === selectedGame.length - 1) {
        setIdGameTNT(name);
        newSelected = newSelected.concat(selectedGame.slice(0, -1));
      } else if (selectedIndex > 0) {
        setIdGameTNT(0);
        newSelected = newSelected.concat(
          selectedGame.slice(0, selectedIndex),
          selectedGame.slice(selectedIndex + 1)
        );
      }
    }
    setSelectedGame(newSelected);
  };

  return (
    <div onKeyDown={handleKeyDown} tabIndex={-1}> 
      <Paper className={classes.root}>
        {/* test:{lineTypeIndex && lineTypeIndex}
        test:{lineTypeID && lineTypeID} */}
        {showHelp && (
          <div>
            <h1> Help</h1>
            <div>
              {" "}
              <ul>
                {/* <li> f4 NFL </li>
                <li>f6 CFB</li>
                <li> f7 NBA</li>
                <li> f8 CBB </li>
                <li>f9 MLB </li>
                <li>f10 NHL</li>
                <li> f11 MU </li>
                <li>ctrl+f9 ESOC</li>
                <li>ctrl+f10 SOC</li>
                <li> ctrl+f11 TNT</li> <li> ctrl+f12 Props</li> */}
                <li> ctrl+right lineset up</li>
                <li> ctrl+left lineset down</li>
                <li> ctrl+/ help</li>
              </ul>
            </div>
          </div>
        )}
        <KeyboardEventHandler
          handleKeys={[
            "function",
            "ctrl+right",
            "ctrl+left",
            "ctrl+/",
            "ctrl+f9",
            "ctrl+f10",
            "ctrl+f11",
            "ctrl+f12",
          ]}
          onKeyEvent={(key) => setEventKey(key)}
        />
        <Table>
          <TableRow>
            <TableCell>
              <div style={{ float: "left" }}>
                <InputSelect
                  variant="outlined"
                  name="name"
                  data={sports}
                  placeholder="Sports"
                  setSelectValue={changeSportID}
                  startValue={IdSport}
                />
              </div>
              <div style={{ float: "left", display:"flex", alignItems:"center"}}>    
                <ArrowLeftIcon
                  color={"primary"}
                  onClick={() => {
                    let index = lineTypeIndex;
                    changeLineTypeID(index === 0 ? 0 : index + -1);
                  }}   
                >
                </ArrowLeftIcon>          
                <InputSelect                             
                  variant="outlined"
                  name="name"
                  data={lineTypes}
                  placeholder="LineTypes"
                  setSelectValue={changeLineTypeID}  
                  idLineType={lineTypeIndex}                         
                />
                <ArrowRightIcon                
                  color={"primary"}
                  onClick={() => {
                    let index = lineTypeIndex;
                    changeLineTypeID(index === lineTypes.length-1? 0 : index + 1);
                  }}
                >                
                </ArrowRightIcon>            
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={searchGameValues}
              >
                Search
              </Button>
              <label>
                <Checkbox
                  checked={open}
                  onChange={(ev) => setOpen(ev.currentTarget.checked)}
                  color="primary"
                />
                Open Games Only
              </label>
              <label>
                <Checkbox
                  checked={action}
                  onChange={handleChangeAction}
                  color="primary"
                />
                Action
              </label>
              <label>
                <Checkbox
                  checked={predictiveText}
                  onChange={handleChangePredictiveText}
                  color="primary"
                />
                Predictive Text
              </label>
            </TableCell>
            <TableCell>
              <Autocomplete
                disablePortal
                id="League"
                options={leagues}
                getOptionLabel={(option) =>
                  option.Description ? option.Description : ""
                }
                disabled={IdSport === 0 ? true : false}
                onChange={(event, newValue) => {
                  if (newValue === null) {
                    setLeagueID(-1);
                  } else {
                    setLeagueID(newValue.IdLeague);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Leagues" fullWidth />
                )}
              />
            </TableCell>
            <TableCell>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Filter
              </InputLabel>
              <DelayInput
                minLength={1}
                delayTimeout={500}
                className="filtered-box"
                value=""
                placeholder="Filter By Team Name or Rotation Number"
                onChange={filterGames}
                onKeyDown={handleKeyPress} 
                ref={inputRef}
              />
            </TableCell>
            <TableCell style={{ width: "20%" }}>
              {isLoading ? <Loader height={80} width={80}></Loader> : ""}
            </TableCell>
            <TableCell>
              <label style={{ padding: "8px" }}>
                <AddBoxIcon
                  aria-label="newGame"
                  size="small"
                  className={classes.fab}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (lineTypeID === 0 || IdSport === 0) {
                      alert("Please select Sport and Line Type");
                    } else {
                      setIsNew(true);
                    }
                  }}
                />{" "}
                Add Game
              </label>
            </TableCell>
          </TableRow>
        </Table>
        {/*<Button
          onClick={() => {
            console.log("what");
            callSp("GetLeaguesBySport", ["NFL"]).then((res) => console.log(res));
          }}
          variant="contained"
        >
          A TEST
        </Button>*/}
        {lineManagerGames && lineManagerGames.length > 0 && gameTypes.length > 0 && (
          /*IdSport === "MLB" || IdSport === "SOC" || IdSport === "NHL" ? (*/
          <SchedulePS
            predictiveText={predictiveText}
            isLoading={isLoading}
            IdLinetype={lineTypeID}
            IdSport={IdSport}
            showOverlay={showOverlay}
            announce={props.announce}
            filterStr={filterStr}
            getRecentGame={props.getRecentGame}
            searchGameValues={searchGameValues}
            setLastChangedGamedID={setLastChangedGamedID}
            lastChangedGamedID={recentUpdatedGame}
            isChanged={isChanged}
            selectedGame={selectedGame}
            handleClickCheck={handleClickCheck}
            ClearArrar={ClearArrar}
            IdGameTNT={idGameTNT}
            lineTypes={lineTypes}
            action={action}
            scheduleCount={scheduleCount}
            getSchedulePagination={getSchedulePagination}
            schedulePagination={schedulePagination}
            handleChangePageNumber={handleChangePageNumber}
            handleChangeRecordsPerPage={handleChangeRecordsPerPage}
            systemColors={systemColors}
            gameTypes={gameTypes}
            gameActionsReadyTNT = {gameActionsReadyTNT}
          ></SchedulePS>
        )}
        {isNew ? (
          <CreateGame
            IdLinetype={lineTypeID}
            predictiveText={predictiveText}
            IdSport={IdSport}
            onClose={handleCloseCreate}
            searchGameValues={searchGameValues}
          />
        ) : (
          ""
        )}
      </Paper>
    </div>
  );
});

export default LinesManager;
