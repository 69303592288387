import React, { useState, useEffect, Component } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { DelayInput } from "react-delay-input";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import "./Teams.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import Loader from "../common/loader/Loader";

import CreateGame from "../CreateGame";
import Autocomplete,{createFilterOptions} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputSelect from "../common/InputSelect";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Teams(props) {
  const [teams, setTeams] = useState();
  const [selectedGame, setSelectedGame] = useState();
  const [filter, setFilter] = useState("");

  const [books, setBooks] = useState([]);
  const [schema, setSchema] = useState();
  const [record, setRecord] = useState();
  const [language, setLanguage] = useState();
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );
  const [value, setValue] = React.useState(0);
  const [isNew, setIsNew] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGameStat, setSelectedGameStat] = React.useState("O");
  const [optionsAutoComplete, setOptionsAutoComplete] = React.useState([]);
  
  const [isFiltered, setIsFiltered] = useState(false);
  const [sports, setSports] = useState([]);
  const [idSport, setIdSport] = useState(0);
  const [teamName, setTeamName] = useState("");
  const [clearTeam, setClearTeam] = useState(false);
  const [filteredbyTeamName, setFilteredbyTeamName] = useState(false);

  const onTagsChange = (event, values) => {   
    if(values!==null && values!=="" && values!== undefined){                  
      props.callBackFn(values);           
    }else{        
      props.callBackFn("");
    }   
  };

  const GetSports = () => {
    const url = process.env.REACT_APP_API_URL + "/SPORT?order=SportName";
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let groupingInfo = data.records.map((v) => {
          v.IdSport = v.IdSport;
          v.SportName = v.SportName;
          return v;
        });
        setSports(groupingInfo);
      });
  };

  function changeSportID(value) {
    setIdSport(value);   
    teamName!==""? setIsFiltered(true):setIsFiltered(false);
  }

  const handleChangeTeamName = (event) => {
    setTeamName(event.target.value);
    setFilteredbyTeamName(true);
    idSport!==0 && event.target.value !== ""? setIsFiltered(true):setIsFiltered(false);
  }; 
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleGameStatChange = (event, newValue) => {
    console.log("setSelectedGameStat", newValue.props.value);
    setSelectedGameStat(newValue.props.value);
  };

  const handleCloseCreate = function () {
    setIsNew(false);
  };
  let history = useHistory();
  const useStyles = makeStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: "#3f51b5",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "darkcyan !important",
      },
    },

    tableRow: {
      "&:hover": {
        backgroundColor: "lightgrey !important",
      },
    },
    headerCell: {
      color: "white",
    },    
  });

  const classes = useStyles();  

  useEffect(() => {
    LoadTeams();       
  }, [isFiltered,idSport,teamName]);

  useEffect(() => {    
    GetSports();     
  }, []);
  
  useEffect(() => {
    setIdSport (props.idSport);    
  }, [props.idSport]);

  const onChange = (event) => {             
    setFilteredbyTeamName(true);
    setTeamName(event.target.value);   
  }
  const LoadTeams = async () => {   
    setIsLoading(true);      
    let URL = ""
    if(filteredbyTeamName){
      URL = process.env.REACT_APP_API_URL + "/TEAM?filter=IdSport,eq," + idSport +"&filter2=LongName,cs,"+teamName+"&filter1=TeamName,cs,"+teamName
      
    }else if(!props.filterByTeamName){
      URL = process.env.REACT_APP_API_URL + "/TEAM?filter=IdSport,eq," + idSport
    }           
      
    await axios
      .get(URL)
      .then((response) => response.data)
      .then((data) => {       
        if (props.callBackFn) {
          let tmpOptionsAutoComplete = [];
          data.records.map((key) => {
            if(!key.LongName.toString().includes("DELETE"))
            tmpOptionsAutoComplete.push(
              key.LongName                        
            );
          });
          setOptionsAutoComplete(tmpOptionsAutoComplete);
        } else {
          setRecord(data.records[0]);
          setTeams(data.records);
        }
        setIsLoading(false);
      });
  };
  

  function onAfterDeleteRow(rowKeys) {
    console.log("The rowkey you drop: ", rowKeys);
    
    rowKeys.map((row) => {
      console.log("reqTeam to delete", row);

      axios({
        method: "delete",
        url: process.env.REACT_APP_API_URL + `/TEAM/${row}`,        
      }).then((response) => {
        alert("Team deleted successfully");
      });
    });
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(",");
    if (
      window.confirm(
        `Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      // If the confirmation is true, call the function that
      // continues the deletion of the record.
      next();
    }
  }

  function selectedValues(row) {
    console.log(`find parent You click row id Games: ${row.IdGame}`, props);
    console.log(`find parent You click row id Games: ${row.IdGame}`);
    props.callBackFn &&
      console.log(`!!!!!find parent You click row id Games: ${row.IdGame}`);

    props.callBackFn ? props.callBackFn(row) : setSelectedGame(row);
    setIsNew(true);
    //setRedirect(row);
  }

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "15",
        value: 15,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "500",
        value: 500,
      },
      {
        text: "1000",
        value: 1000,
      },
    ], // you can change the dropdown list for size per page

    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    paginationPosition: "top", // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    handleConfirmDeleteRow: customConfirm,
    // onRowClick: onRowSelect,
    beforeInsertRow: onAfterInsertRow, // A hook for after insert rows
    afterInsertRow: onAfterInsertRow, // A hook for after insert rows
  };

  const cellEditProp = {
    mode: "click", //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  const selectRowProp = {
    mode: "checkbox",

    //clickToSelect: false, // enable click to select

    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    //onSelect: onRowSelect,
  };

  function onAfterInsertRow(row) {
    const d = new Date();    
    let dformat = d.toLocaleString("en-CA", {
      timeZone: "America/New_York",
      hour12: false,
    });    
    let reqTeam = {
      //IdTeam: row.IdTeam,
      TeamName: row.TeamName,
      LongName: row.LongName,
      IdSport: idSport,
      LastModification: dformat.toString().slice(0, -1).replaceAll(",", " "),
      LastModificationUser: 487,
    };
    console.log("reqTeam", reqTeam);
    console.log("reqTeam", row);   

    axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + `/TEAM`,
      data: reqTeam,
    }).then((response) => {
      alert("The team has been added",LoadTeams());
    });
  }

  function onRowSelect(row, isSelected, e) {
    let rowStr = "";
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }
    console.log(e);
    alert(`is selected: ${isSelected}, ${rowStr}`);
  }

  function onAfterSaveCell(row, cellName, cellValue) {
    if(sports.find(x => x.IdSport.trim() == row.IdSport.trim().toUpperCase())){
      let reqTeam = {
        IdTeam: row.IdTeam,
        TeamName: row.TeamName,
        LongName: row.LongName,
        IdSport: row.IdSport.trim().toUpperCase(),
      };
      console.log("reqTeam", reqTeam);

      axios({
        method: "put",
        url: process.env.REACT_APP_API_URL + `/TEAM/${reqTeam.IdTeam}`,        
        data: reqTeam,
      }).then((response) => {
        alert("The team has been succesfully updated",LoadTeams()) 
      });
    }else(
      alert("The input sport is not valid",LoadTeams())      
    )
  }

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }
  

  const toggleTable = () => {
    if (showInfo) {
      setshowInfo(false);
    } else {
      setshowInfo(true);
    }
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();
  
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  
  if (redirect) {
    let pushURL = "/Game/" + redirect.IdGame;

    return (
      <React.Fragment>
        {/* //this.props.location.state. asi se agarran los datos en el otro componente */}

        {console.log("Game Info props", redirect)}
        <div>{history.push(pushURL)}</div>
      </React.Fragment>
      /*<Redirect
        to={{
          pathname: '/entityContainer',
          state: { row: redirect },
        }}
      />

      */
    );
  }
  return (
    <React.Fragment>
      {isNew ? (
        <CreateGame
          onClose={handleCloseCreate}
          searchGameValues={LoadTeams}
          IdSport={""}
          selectedGame={selectedGame}
        />
      ) : (
        ""
      )}

      {props.callBackFn ? (
        <div>          
          <Autocomplete  
            filterOptions={filterOptions}    
            disabled={props.idSport === 0 ? true : false}   
            color="secondary"         
            key={clearTeam ? clearTeam : null}
            freeSolo
            onChange={onTagsChange}
            autoSelect
            {...clearTeam ? { value: "" } : null}
            disablePortal
            fullWidth
            id="combo-box-demo"
            options={optionsAutoComplete}
            sx={{ width: 400 }}
            renderInput={(params) => 
              <TextField {...params}  
                helperText={props.doesTeamExist ? "the input Team will be created" : ""} 
                error={props.error} 
                onChange={onChange}              
                label={props.idSport === 0 ? "Please Select the Sport first" : props.label} 
                color={props.doesTeamExist ? 'success' : 'primary'}
                focused={props.doesTeamExist}  
                inputRef={props.inputRef}                                                            
              />
            }
            value= {props.teamName.toUpperCase()}                                   
          />
        </div>
      ) : (
        <div id="main-div">
          <Paper className={classes.root}>
            {!props.callBackFn && (
              <div style={{ float: "left"}}>
                <h2>Teams</h2>
                <br />
                Please Filter by Sport and Team Name to see the Teams
                <div style={{display:"flex", marginTop:"10px",justifyContent:"space-between" }}>
                  <InputSelect
                  variant="outlined"
                  name="name"
                  data={sports}
                  placeholder="Sports"
                  setSelectValue={changeSportID}
                  startValue={idSport}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Team Name"
                    style={{ textTransform: "uppercase" }}
                    multiline={true}
                    onChange={handleChangeTeamName}
                    value={teamName}
                  />
                </div>
              </div>
            )}
            {isLoading ? <Loader></Loader> : ""}
            <Table className={classes.table} aria-label="simple table">
              <TableBody className={showInfo ? "show-table" : "hide-table"}>
                {teams && isFiltered && (
                  <div>
                    <BootstrapTable
                      pagination={true}
                      options={options}
                      editable={false}
                      insertRow={true}
                      cellEdit={cellEditProp} 
                      selectRow={selectRowProp} 
                      version="4"
                      data={teams}
                      deleteRow={true}
                      hover
                    >
                      <TableHeaderColumn
                        dataField="IdTeam"
                        hiddenOnInsert
                        isKey={true}
                        autoValue={true}
                        hidden
                      >
                        IdTeam
                      </TableHeaderColumn>
                      <TableHeaderColumn                        
                        dataField="IdSport"
                        autoValue={true}                                               
                      >
                        IdSport
                      </TableHeaderColumn>
                      <TableHeaderColumn                        
                        dataField="TeamName"
                      >
                        TeamName
                      </TableHeaderColumn>
                      <TableHeaderColumn                        
                        dataField="LongName"
                      >
                        LongName
                      </TableHeaderColumn>
                      <TableHeaderColumn                        
                        dataField="LastModification"
                        hiddenOnInsert
                        editable={false}
                      >
                        LastModification
                      </TableHeaderColumn>
                      <TableHeaderColumn                        
                        dataField="LastModificationUser"
                        hiddenOnInsert
                        editable={false}
                      >
                        LastModificationUser
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                )}
              </TableBody>
            </Table>
          </Paper>
        </div>
      )}
    </React.Fragment>
  );
}

export default Teams;
