import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SketchPicker } from 'react-color';

export default ({ setSelectedGametype, selectedGametype, saveData }) => {
  const [backgroundColor, setBackgroundColor] = useState();
  const [fontColor, setFontColor] = useState();

  const handleChangeBackground = (e) => {
    let parsedColor = parseInt(e.hex.substring(1), 16);
    let newGametype = { ...selectedGametype, BkColor: parsedColor };
    saveData(newGametype);
    setSelectedGametype(newGametype);
  };

  const handleChangeFont = (e) => {
    let parsedColor = parseInt(e.hex.substring(1), 16);
    let newGametype = { ...selectedGametype, FkColor: parsedColor };
    saveData(newGametype);
    setSelectedGametype(newGametype);
  };

  useEffect(() => {
    setFontColor('#' + selectedGametype.FkColor.toString(16));
    setBackgroundColor('#' + selectedGametype.BkColor.toString(16));
  }, [selectedGametype]);
  return (
    <Grid container spacing={1}>
      <>
        <Grid item xs={12} align="center">
          <Typography variant="h5">{`${selectedGametype.Description}`}</Typography>
        </Grid>
        <Grid align="center" item xs={6}>
          <Typography variant="h6">Background</Typography>
          <SketchPicker
            color={backgroundColor}
            onChangeComplete={handleChangeBackground}
          />
        </Grid>
        <Grid align="center" item xs={6}>
          <Typography variant="h6">Font</Typography>
          <SketchPicker color={fontColor} onChangeComplete={handleChangeFont} />
        </Grid>
      </>
    </Grid>
  );
};
