import React, { useState, useEffect } from 'react';
import Loader from "../../components/common/loader/Loader";
import Axios from 'axios';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputSelect from "../../components/common/InputSelect";
import { DataGrid, esES } from '@material-ui/data-grid'
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from "@material-ui/icons/AddBox";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import FormLabel from "@material-ui/core/FormLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"

function JuiceRebate (props) {

    const [lineTypes,setLineTypes] = useState([]);
    const [gameTypes, setGameTypes] = useState([]);
    const [sports, setSports] = useState([]);
    const [isLoading,setIsLoading] = useState(false)
    const [idSport,setIdSport] = useState('CBB');
    const [lineTypeID, setlineTypeID] = useState(55);
    const [gameTypeId, setGameTypeId] = useState(1);
    const [lineTypeIndex, setLineTypeIndex] = useState(13);
    const [row, setRow] = useState([]);
    const [selectedRow,setSelectedRow] = useState([]);
    const [juiceRebate,setJuiceRebate] = useState([]);
    const [range,setRange] = useState(0)
    const [juiceRebateValue,setJuiceRebateValue] = useState(0)
    const [open, setOpen] = useState(false);
    const [isRed, setIsRed] = useState(false);
    const [isInserted, setIsInserted] = useState(false);
    const [lines, setLines] = useState([])
    const [type, setType] = useState("Local");    
    const [line, setLine] = useState("Money Line");
    const [positiveFlag, setPositiveFlag] = useState("Positive");
    
    const columns = [
      { field: 'id', headerName: 'Id', width: 0, editable: false, sortable: false, filterable: false, hide:true },  
      { field: 'range', headerName: 'Range', width: 150, editable: false, sortable: false, filterable: false },
      { field: 'juiceRebate', headerName: 'Juice Rebate', width: 150, editable: true, sortable: false, filterable: false}
    ]
    const [userInfo, setUserInfo] = useState(
      JSON.parse(sessionStorage.getItem("userInfo"))
    );
    
    const useStyles = makeStyles((theme) => ({        
        root: {
          width: '100%',
          overflowX: 'auto',
        },
        appBar: {
          position: "relative",
        },
        center: {
          marginTop:"10px",
          display:"flex",
          margin: "0 auto",
          border: "1px grey black",
          textAlign: "center",
          height: "300px",
          "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: 200,
          },
          "& .MuiFormGroup-row": {
            justifyContent: "center",
          },
          "& .MuiFormControl-root": {
            justifyContent: "center"            
          }
        },
        marginMaker: {
          margin:"5px"
        }
    }));
    const classes = useStyles();
    
  
    const getLineTypes = async () => {
        const url = process.env.REACT_APP_API_URL + "/LINETYPE";
        await Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setLineTypes(data.records);
          });
    };

    const getGameTypes = async () => {
        const url = process.env.REACT_APP_API_URL + "/GAMETYPE";
        await Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setGameTypes(data.records);
          });
    };

    const getSports = async () => {
        const url = process.env.REACT_APP_API_URL + "/SPORT";
        await Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setSports(data.records);
          });
    };

    const getJuiceRebate = async () => {
      const url = process.env.REACT_APP_API_URL +
      '/JUICEREBATE?filter=IdLineType,eq,' +
      lineTypeID +
      '&filter=IdSport,eq,' +
      idSport +
      '&filter=IdGameType,eq,' +
      gameTypeId +
      '&filter=Line,eq,' +
      calculateLineId(line) +
      '&filter=Online,eq,' + 
      calculateOnline(type) +
      calculateRange(positiveFlag) + 0      
      
      await Axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setJuiceRebate(data.records);
          feedRow(data.records)
          console.log("data.records",data.records)
          setIsLoading(false)
        });
    }
    
    const calculateLines = () => {
      let aux = [];
      if(idSport === 'CBB' || idSport === 'CFB' || idSport === 'NBA' || idSport === 'NFL' ){
        aux.push(
          {id:1,Description:'Spread'},
          {id:2,Description:'Total'},
          {id:3,Description:'Money Line'}
        )
      }else 
        if(idSport === 'TNT' || idSport === 'ESOC' ||  idSport === 'PROP'){
          aux.push(
            {id:1,Description:'Money Line'}
          )
        }else 
          if(idSport === 'MLB'){
            aux.push(
              {id:1,Description:'Money Line'},
              {id:2,Description:'Total'},
              {id:3,Description:'Run Line'}
            )
          }else 
            if(idSport === 'MU'){
              aux.push(
                {id:1,Description:'Money Line'},
                {id:2,Description:'Total'},
                {id:3,Description:'Spread'}
              )
            }else 
              if(idSport === 'NHL'){
                aux.push(
                  {id:1,Description:'Money Line'},
                  {id:2,Description:'Total'},
                  {id:3,Description:'Canadian Lines'},
                  {id:4,Description:'American Lines'}
                )
              }else 
                if(idSport === 'NHL'){
                  aux.push(
                    {id:1,Description:'Money Line'},
                    {id:2,Description:'Total'},
                    {id:3,Description:'Goals'}
                  )
                }  
      setLines(aux);
    }  

    const updateJuiceRebate = async (e) => {
      setIsLoading(true)

      let juiceRebate={
        idSport: idSport,
        idLineType: lineTypeID,
        line: calculateLineId(line),
        idGameType: gameTypeId,
        range:range,
        online:calculateOnline(type),
        juiceRebate: e.value,
        idUser: userInfo.UserId
      }     
      
      const url = process.env.REACT_APP_DEF_PHP_URL + '/JuiceRebateUpdate.php'
      Axios({
        method: 'post',
        url: url,
        data: juiceRebate,
      }).then((response) => {
          setIsLoading(false)
          console.log("Juice response",response)
        });
    }

    const insertJuiceRebate = async () => {
      let juiceRebate={
        idSport: idSport,
        idLineType: lineTypeID,
        line: calculateLineId(line),
        idGameType: gameTypeId,
        range:range,
        online:calculateOnline(type),
        juiceRebate: juiceRebateValue,
        idUser: userInfo.UserId
      }     
      
      const url = process.env.REACT_APP_DEF_PHP_URL + '/JuiceRebateInsert.php'
      Axios({
        method: 'post',
        url: url,
        data: juiceRebate,
      }).then((response) => {
          setIsLoading(false)
          setIsInserted(true)
          getJuiceRebate()
          setRange(0)
          setJuiceRebateValue(0)
        });
    }

    const deleteJuiceRebate = async () => {
      let juiceRebate = {
        idSport: idSport,
        idLineType: lineTypeID,
        line: calculateLineId(line),
        idGameType: gameTypeId,
        range:range,
        online:calculateOnline(type),
        idUser: userInfo.UserId
      }    

      const url = process.env.REACT_APP_DEF_PHP_URL + '/JuiceRebateDelete.php'
      Axios({
        method: 'post',
        url: url,
        data: juiceRebate,
      }).then((response) => {
          setIsLoading(false)
          setIsInserted(true)
          console.log("juiceRebate response",response)
          getJuiceRebate();
        });
    }

    useEffect (() => { 
        getLineTypes();
        getGameTypes();
        getSports();
        calculateLines();  
        getJuiceRebate();             
    },[])  

    useEffect(() => {
        setLoader();
        getJuiceRebate();
        calculateLines();
    }, [idSport, lineTypeID, gameTypeId,line,positiveFlag]);

    const setLoader = () => {
        if(sports.length === 0 || lineTypes.length === 0 || gameTypes.length === 0) {
            setIsLoading(true);
        }else{
            setIsLoading(false);
        }      
    }
    
    const feedRow = (juiceRebate) => {            
      let rowtemp = []
      for (let i = 0; i < juiceRebate.length; i++) {
        rowtemp.push({
          id:i,
          range: juiceRebate[i].Range,
          juiceRebate: juiceRebate[i].JuiceRebate
        })
      }
      setRow(rowtemp);    
    }
    
    const handleRowSelection = (row) => {
      setSelectedRow(row)                  
    }

    function changeSportID(value) {
        setIdSport(value);        
    }

    function changegameTypeId(value) {
        setGameTypeId(gameTypes[value].IdGameType);
    }

    function changeLineTypeID(value) {    
        let linetype = lineTypes[value];
        setLineTypeIndex(value);    
        setlineTypeID(linetype.IdLineType);
    }   

    function handleCell (e){
      console.log(e)
      updateJuiceRebate(e)
    }    
    
    function handleRow (e){
      console.log(e)
      setRange(e.row.range)
    }

    function handleDelete () {
      if(selectedRow.length==0){
        alert("Please select a row to edit");
      }else{
        if (window.confirm("Are you sure you want to delete the selected item?")){
          deleteJuiceRebate();  
        }        
      }
    }
    function handleNew () {
      setOpen(true)
    }

    function handleClose () {
      setOpen(false);
      setIsRed(false);
      setRange(0);
      setJuiceRebateValue(0);
    }

    const handleChangeRange = (event) => {
      setRange(event.target.value);
    }

    const handleChangeJuiceRebate = (event) => {
      setJuiceRebateValue(event.target.value);
    }

    const handleOk = () => {
      if(range === 0 || juiceRebateValue === 0){
          setIsRed(true);
          alert("Please provide the required fields");
      }else{
        insertJuiceRebate();            
      }
    }   

    const handleChangeType = (event) => {
      setType(event.target.value)
    }     

    const handleChangePositiveFlag = (event) => {
      setPositiveFlag(event.target.value)
    }

    const handleChangeLine = (event) => {
      setLine(event.target.value)
    }

    const calculateLineId = (x) => {
      let aux = 1;
      if(idSport === 'CBB' || idSport === 'CFB' || idSport === 'NBA' || idSport === 'NFL' ){
        switch (x){
          case 'Spread':
            aux = 1
            break;
          case 'Total':
            aux=2
            break;
          case 'Money Line':
            aux=3
            break;
        }
      }else 
          if(idSport === 'MLB'){
            switch (x){
              case 'Money Line':
                aux = 1
                break;
              case 'Total':
                aux=2
                break;
              case 'Run Line':
                aux=3
                break;
            }
          }else 
            if(idSport === 'MU'){
              switch (x){
                case 'Money Line':
                  aux = 1
                  break;
                case 'Total':
                  aux=2
                  break;
                case 'Spread':
                  aux=3
                  break;
              }
            }else 
              if(idSport === 'NHL'){
                switch (x){
                  case 'Money Line':
                    aux = 1
                    break;
                  case 'Total':
                    aux=2
                    break;
                  case 'Canadian Lines':
                    aux=3
                    break;
                  case 'American Lines':
                    aux=4
                    break;
                }
              }else 
                if(idSport === 'NHL'){
                  switch (x){
                    case 'Money Line':
                      aux = 1
                      break;
                    case 'Total':
                      aux=2
                      break;
                    case 'Goals':
                      aux=3
                      break;
                  }
                }  
      return aux
    }  

    const calculateOnline = (type) => {
      if (type == "Online") 
        return 1 
      else 
        return 0
    }

    
    const calculateRange = (postiveFlag) => {
      if(positiveFlag=="Positive")
        return '&filter=Range,ge,' 
      else
        return '&filter=Range,lt,'
    }

    const newJuiceRebate = () => {
      return(
        <React.Fragment>
            <Dialog  style={{width: "50%", height: "38%", margin: "0 auto", marginTop: "100px"}} open={open} onClose={handleClose}>
                <AppBar className={classes.appBar}>            
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          New Juice Rebate
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form className={classes.center}>
                  <FormGroup row>                                
                    <TextField                                
                      variant="outlined"                            
                      label="Range"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      value={range}
                      {...isRed && range === 0?(
                          {error:true}
                      ):("")}
                      onChange={handleChangeRange}
                      fullWidth                                                                       
                    />                        
                  </FormGroup>
                  <FormGroup row>                                
                    <TextField                                
                      variant="outlined"                            
                      label="Juice Rebate"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      value={juiceRebateValue}
                      {...isRed && juiceRebateValue === 0?(
                          {error:true}
                      ):("")}
                      onChange={handleChangeJuiceRebate}
                      fullWidth                                                                       
                    />                        
                  </FormGroup>
                  </form>
                <React.Fragment>
                  <DialogActions>
                      {isInserted ? (                                
                          <FormControl className={classes.formControl}>
                              <InputBase
                                style={{
                                    color: "red",
                                    fontSize: 35,
                                    fontWeight: "bold",
                                }}
                                className={classes.margin}
                                defaultValue="Done!"
                                inputProps={{ "aria-label": "naked" }}
                              />
                          </FormControl>                                
                      ) : (
                          ""
                      )}
                      {isLoading ? (
                      <Loader height={100} width={100}></Loader>
                      ) : (
                      <div>                                
                          <Button
                          startIcon={<SaveIcon />}
                          color="inherit"
                          onClick={handleOk}
                          >
                          Ok
                          </Button>
                      </div>
                      )}                            
                  </DialogActions> 
                </React.Fragment>
            </Dialog>
          </React.Fragment>
      )
    }

    return(
        <React.Fragment>
            {isLoading ? (                  
            <Loader height={100} width={100}></Loader>
            ):("")}
            <Paper className={classes.root}>
              <br />
              <br /> 
              <h2 style={{margin:"10px"}}>Juice Rebate</h2>
            </Paper>
            {open ? (                  
              newJuiceRebate()
            ):("")}
            <div style={{display:"flex", gap:"100px"}}>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '50ch' },
                        borderRight:"1px solid",
                        padding:"10px",
                        display:"grid"
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <FormControl
                      style={{border:"1px solid grey",borderRadius:"6px", margin:"5px"}}
                    >
                      <FormLabel 
                        className={classes.marginMaker}  
                        id="demo-radio-buttons-group-label"
                      >
                        Range View
                      </FormLabel>
                      <RadioGroup
                        className={classes.marginMaker}  
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={"Positive"}
                        name="radio-buttons-group"                        
                        onChange={handleChangePositiveFlag}
                      >
                        <FormControlLabel value="Positive" control={<Radio />} label="Positive" />
                        <FormControlLabel value="Negative" control={<Radio />} label="Negative" />
                      </RadioGroup>
                    </FormControl>
                    <InputSelect                             
                        variant="outlined"
                        name="name"
                        data={lineTypes}
                        placeholder="LineTypes"
                        setSelectValue={changeLineTypeID}                        
                        startValue={lineTypeID}
                        idLineType={lineTypeIndex}  
                        fixedWidth={true}                      
                    />
                    <InputSelect
                        variant="outlined"
                        name="name"
                        data={gameTypes}
                        placeholder="Game Type"
                        setSelectValue={changegameTypeId}
                        startValue={gameTypes.find(id => id.IdGameType == gameTypeId)}
                        fixedWidth={true} 
                    />
                    <InputSelect
                        variant="outlined"
                        name="name"
                        data={sports}
                        placeholder="Sports"
                        setSelectValue={changeSportID}
                        startValue={idSport}
                        fixedWidth={true} 
                    />
                    <FormControl style={{border:"1px solid grey",borderRadius:"6px", margin:"5px"}} >
                      <FormLabel 
                        className={classes.marginMaker}  
                        id="demo-radio-buttons-group-label"
                      >
                        Type
                      </FormLabel>
                      <RadioGroup 
                        className={classes.marginMaker}                         
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={"Local"}
                        name="radio-buttons-group"
                        onChange={handleChangeType}
                      >
                        <FormControlLabel value="Local" control={<Radio />} label="Local" />
                        <FormControlLabel value="Online" control={<Radio />} label="Online" />
                      </RadioGroup>
                    </FormControl>
                    <FormControl
                      style={{border:"1px solid grey",borderRadius:"6px", margin:"5px"}}
                    >
                      <FormLabel 
                        className={classes.marginMaker}   
                        id="demo-radio-buttons-group-label"
                      >
                        Line
                      </FormLabel>
                      <RadioGroup                             
                        className={classes.marginMaker}                   
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={"Money Line"}
                        name="radio-buttons-group"
                        key={lines}
                        onChange={handleChangeLine}
                      >
                        {lines.map(line =>
                          <FormControlLabel
                            value={line.Description} 
                            control={<Radio />} 
                            label={line.Description} />
                          )}
                        
                      </RadioGroup>
                    </FormControl>
                    
                </Box>
                <Box sx={{ height: 500, width: '350px', marginBottom:"10px",marginTop:"20px"}}>
                  <DataGrid                  
                    rows={row}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[5]}            
                    disableColumnSelector
                    disableColumnMenu
                    onSelectionModelChange={handleRowSelection}                                    
                    disableMultipleSelection={true}    
                    onCellEditCommit={handleCell}   
                    onRowClick={handleRow}                              
                  />                
                </Box>
                <div style={{marginTop:"20px"}}>
                  <label style={{padding:"8px",fontSize:"150%"}}>
                    <DeleteIcon                 
                      aria-label="Delete"
                      size="small"                  
                      style={{ cursor: "pointer" }}
                      onClick={handleDelete}
                    />{" "}
                    Delete
                  </label>  
                  <br />
                  <label style={{padding:"8px",fontSize:"150%"}}>
                    <AddBoxIcon                 
                      aria-label="newGame"
                      size="small"
                      className={classes.fab}
                      style={{ cursor: "pointer" }}
                      onClick={handleNew}
                    />{" "}
                    Add New
                  </label> 
                </div>
            </div>   
            <Divider></Divider>  
        </React.Fragment>
             
    )
}
export default JuiceRebate