import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';

import {
  BootstrapTable,
  TableHeaderColumn,
} from 'react-bootstrap-table';
import './OpenBets.css';

import { Button } from "@material-ui/core"; 

function OpenBets(props) {
  const [openBets, setOpenBets] = React.useState([]);
  const [lastHoldWagerId, setLastHoldWagerId] = useState(0)   
  

  useEffect(() => { 
    const interval = setInterval(() => {      
      getLastHoldWagerId();
      if(lastHoldWagerId !== 0)
        getOpenBets();
    }, 5000);
    return () => clearTimeout(interval);
  }, [lastHoldWagerId]);

  const getLastHoldWagerId = () => {
    const url = process.env.REACT_APP_DEF_PHP_URL + '/BetTickerGetLastIdWager.php'
      axios({
        method: 'post',
        url: url,
      }).then((response) => {         
          setLastHoldWagerId((response.data.data[0][0]));
        });
  }

  const getOpenBets = () => {   
    const url = process.env.REACT_APP_DEF_PHP_URL + '/BetTickerGetOpenBets.php'
      axios({
        method: 'post',
        url: url,
        data: {lastIdWager : lastHoldWagerId},
      }).then((response) => {
        if(response.data.data.length > 0){
          response.data.data.map(
            (x)=>{
              setOpenBets(current => [x , ...current])
            }
          )
        } 
        
      });
  }

  const addHeadersToBets = (aux) => {
    let ObjectifiedArray = aux.map(x => ({ 
      IdWagerHeader: x[0], 
      Player: x[15],
      WagerType: x[2],
      Description: x[26],
      Risk: Math.round(parseInt(x[7])),
      Win: Math.round(parseInt(x[6]))
    }));
    return ObjectifiedArray
  }
  
  const GetGameTypes = () => {
    const url =
      process.env.REACT_APP_API2_URL + '/BetTicker/GetOpenBets?limit=100';
    axios({
      method: 'get',
      url: url,
    }).then((response) => {
      setOpenBets(response.data);
    });
  };

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 50, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 1, // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: 'top',
    // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    withFirstAndLast: false,
    // Hide the going to First and Last page button

    // onRowClick: selectedValues,
  };

  function rowClassNameFormat(row, rowIdx) {

    let tmpRisk = row.Risk;

    if (tmpRisk > 0 && tmpRisk < 99) {
      return 'row-100-500';
    } else if (tmpRisk > 99 && tmpRisk <= 499) {
      return 'row-500-1000';
    } else if (tmpRisk > 499 && tmpRisk <= 999) {
      return 'row-1000-3000';
    } else if (tmpRisk > 999 && tmpRisk <= 2999) {
      return 'row-3000-5000';
    } else if (tmpRisk > 2999) {
      return 'row-5000';
    }
  }

  function columnWidth() {
    return 'column-width';
  }

  const isExpandableRow = (row) => {
    return true;
  };

  class BSTable extends React.Component {
    render() {
      if (this.props.data) {
        return (
          <div style={{ margin: 'auto' }}>
            <p>Player: {this.props.data.Player} </p>
            <p>Date: {this.props.data.PlacedDate} </p>
            <p>Ticket: {this.props.data.TicketNumber} </p>
            <p>Description: {this.props.data.Description} </p>
            <p>User: {this.props.data.User} </p>
            <p>
              Risk/Win: {this.props.data.Risk + ' / ' + this.props.data.Win}{' '}
            </p>
          </div>
        );
      } else {
        return <p>?</p>;
      }
    }
  }

  const expandComponent = (row) => {
    return <BSTable data={row} />;
  };

  return (    
    <React.Fragment>
      {' '}
      {openBets && (
        <div className="open-bets">
          <h4 style={{marginTop:16}}>Open Bets:</h4>
          <br />       
          <div style={{display: "flex", marginLeft:"20px", width:"13.5%"}}>
              <Button 
                style={{width:"-webkit-fill-available"}}
                variant="outlined" 
                onClick={()=>{
                  setOpenBets([])
                }}
              >
                Clear Open Bets 
              </Button>              
            </div>   
          <Table>
            <TableRow>
              <BootstrapTable
                data={openBets.length > 0 ? addHeadersToBets(openBets): []} 
                options={options}
                pagination={true}
                trClassName={rowClassNameFormat}
                version="4"
                expandableRow={isExpandableRow}
                expandComponent={expandComponent}
                hover
              >
                <TableHeaderColumn
                  dataField="Player"
                  filter={{
                    type: 'TextFilter',
                    delay: 1000,
                  }}
                  width="120"
                >
                  Player
                </TableHeaderColumn>                
                <TableHeaderColumn
                  hidden={true}
                  isKey
                  width="120"
                  dataField="TicketNumber"
                >
                  Ticket #
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="WagerType"
                  filter={{
                    type: 'TextFilter',
                    delay: 1000,
                  }}
                  width="260"
                >
                  Wager Type
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Description">
                  Description
                </TableHeaderColumn>
                <TableHeaderColumn width="120" dataField="Risk">
                  Risk
                </TableHeaderColumn>
                <TableHeaderColumn width="120" dataField="Win">
                  Win
                </TableHeaderColumn>
              </BootstrapTable>
            </TableRow>
          </Table>
        </div>
      )}{' '}
    </React.Fragment>
  );
}

export default OpenBets;
