import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import PaymentMethod from './../../common/PaymentMethods';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

import AdjustmentTypes from '../../common/AdjustmentTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

let playerPayment = {
  idPlayer: '',
  Description: '',
  Amount: '',
  Reference: '',
  Fee: '',
  Bonus: '',
  IdPaymentMethod: '',
  TransactionType: '',
  TransactionDate: '',
  IdUser: '',
  idAdjustmentType: '',
};
const PlayerPayment = (props) => {
  const [method, setMethod] = useState(2);
  const [adjustmentType, setAdjustmentType] = useState(1);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [insertTran, setInsertTran] = useState(false);
  const [statistic, setStatistic] = useState();

  const [selectedTransactionType, setSelectedTransactionType] = useState('A');

  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2014-08-18T21:11:54')
  );
  const classes = useStyles();
  const handleDateChange = (date) => {
    // setSelectedDate(date);
  };

  const setIdMethodMem = (IdMethod) => {
    console.log('IdMethod', IdMethod);

    setMethod(IdMethod);
  };
  const setIdPaymentOnMem = (event) => {
    console.log('IdPayment', event);

    setSelectedTransactionType(event.target.value);
  };
  const setDateOnMem = (event) => {
    console.log('IdPayment', event);
    playerPayment.TransactionDate = event.target.value;
  };
  const setIdAdjTypeMem = (adjustmentId) => {
    setAdjustmentType(adjustmentId);
  };
  const GetPlayerBalance = () => {
    console.log('PROPS League ======>', props);
    let url =
      process.env.REACT_APP_API_URL +
      '/PLAYERSTATISTIC?filter=IdPlayer,eq,' +
      props.Player.IdPlayer;

    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log('PROPS======League REST THEN>', response.data.records);
      setStatistic(response.data.records);
    });
  };
  useEffect(() => {
    playerPayment.TransactionType = 'A';
    GetPlayerBalance();
  }, []);

  const confirm = (e) => {
    console.log('confirm aaa  ', e);

    //e.preventDefault();
    GetPlayerBalance();
    setInsertTran(true);
  };
  const insertPayment = () => {
    playerPayment.idPlayer = props.Player.IdPlayer;
    playerPayment.IdUser = userInfo.UserId;
    playerPayment.idAdjustmentType = adjustmentType;
    playerPayment.IdPaymentMethod = method;
    playerPayment.TransactionType = selectedTransactionType;
    playerPayment.Amount = playerPayment.TransactionType == 'D' ? -playerPayment.Amount : playerPayment.Amount;

    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Player/Player_InsertTransaction',
      data: playerPayment,
    }).then((response) => {
      // props.searchGameValues();
      // handleClose();
    });
    props.setStatisticsToReload();
    setInsertTran(false);
  };
  const CalculateNewBalance = () => {
    let newBalance;
    if (
      selectedTransactionType == 'A' ||
      selectedTransactionType == 'AA' ||
      selectedTransactionType == 'R' ||
      selectedTransactionType == 'H'
    ) {
      newBalance =
        parseFloat(statistic[0].CurrentBalance) +
        parseFloat(playerPayment.Amount);
    } else {
      newBalance =
        parseFloat(statistic[0].CurrentBalance) -
        parseFloat(playerPayment.Amount);
    }
    return newBalance;
    props.setStatisticsToReload();
  };

  const renderTransactionType = () => {
    return (
      <FormControl style={{margin:"8px"}}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Transaction Type
        </InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={selectedTransactionType}
          onChange={setIdPaymentOnMem}
          //   input={<BootstrapInput />}
        >
          <MenuItem value="A">Adjustment</MenuItem>
          <MenuItem value="AA">Accrual Adjustment</MenuItem>
          <MenuItem value="H">Horse Adjustment</MenuItem>
          <MenuItem value="D">Disbursetment</MenuItem>
          <MenuItem value="R">Reciept</MenuItem>
          <MenuItem value="F">Free Play</MenuItem>
          <MenuItem value="C">Casino Adjustment</MenuItem>
        </Select>
        <FormHelperText>Select Transaction Type</FormHelperText>
      </FormControl>
    );
  };
  return (
    <div className="PlayerPayment">
      <h1 style={{ textAlign: 'center' }}>Payments</h1>
      <form role="form">
        <FormControl>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Grid item xs={12} md={6} lg={6}>
                Username: {props.Player.Player}
                {' / '} Password: {props.Player.Password}{' '}
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <FormControl>
                  <TextField
                    label="Amount"
                    required
                    onChange={(e) => {
                      playerPayment.Amount = e.target.value;
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <PaymentMethod label="Method" setIdMethodMem={setIdMethodMem} />
                <AdjustmentTypes
                  label="Method"
                  setIdAdjTypeMem={setIdAdjTypeMem}
                />
                {renderTransactionType()}{' '}
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  label="Description"
                  onChange={(e) => {
                    playerPayment.Description = e.target.value;
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} value={playerPayment.Reference}>
                <TextField
                  label="Reference"
                  onChange={(e) => {
                    playerPayment.Reference = e.target.value;
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}></Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  required
                  variant="outlined"
                  id="gameDateTime"
                  label="Game Date and Time"
                  type="datetime-local"
                  style={{ width: '80%' }}
                  InputLabelProps={{ shrink: true }}
                  onChange={setDateOnMem}
                />
              </Grid>

              {!insertTran ? (
                <Grid item xs={12} md={6} lg={6}>
                  <Button
                    onClick={(e) => {
                      confirm(e);
                    }}
                    variant="outlined"
                    type="submit"
                    color="secondary"
                    style={{padding:'2%'}}
                  >
                    Insert Payment
                  </Button>
                </Grid>
              ) : (
                ''
              )}
              {insertTran ? (
                <div className="transaction-confirm">
                  <Grid item xs={12} md={6} lg={6}>
                    <h2> Player Balance: {statistic[0].CurrentBalance}</h2>
                    <h3> Value: {playerPayment.Amount} </h3>
                    <h3> New Balance: {CalculateNewBalance()}</h3>
                    <br />
                    <Button
                      onClick={insertPayment}
                      variant="outlined"
                      color="success"
                    >
                      Commit
                    </Button>
                    <Button
                      onClick={() => {
                        setInsertTran(false);
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </div>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </div>
  );
};

export default PlayerPayment;
