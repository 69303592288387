import React, { useState, useEffect, useContext } from 'react';
import { forwardRef} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FinalStatsByAgent from './reportComponents/FinalStatsByAgent';
import GameDeleted from './reportComponents/GameDeleted';
import GameScore from './reportComponents/GameScore';
import GameStarted from './reportComponents/GameStarted';
import GameStatistic from './reportComponents/GameStatistic';
import TimeStatusChanged from './reportComponents/TimeStatusChanged';
import ActionByGame from './reportComponents/ActionByGame';
import ActionByPlayer from './reportComponents/ActionByPlayer';
import ActionByPlayerName from './reportComponents/ActionByPlayerName';
import WeeklyBalance from './reportComponents/WeeklyBalance';
import FreePlays from './reportComponents/FreePlays';
import FinantialAdjustments from './reportComponents/FinantialAdjustments';
import Paper from "@material-ui/core/Paper";
/*player Components*/

import './reportsMenu.css';

const Player = forwardRef((props) => {
  const [player, setPlayer] = React.useState(0);
  const [changesByGame, setChangesByGame] = useState(false);
  const [finalStatsByAgent, setfinalStatsByAgent] = useState(false);
  const [gameDeleted, setGameDeleted] = useState(false);
  const [playerStats, setPlayerStats] = React.useState();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [statsNeedsReload, setStatsNeedsReload] = useState(false);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    console.log('handleChange newValue', newValue);
    setValue(newValue);
  };

  const setStatisticsToReload = () => {
    console.log('getStatistics setStatisticsToReload');
    setTimeout(() => {
      statsNeedsReload ? setStatsNeedsReload(false) : setStatsNeedsReload(true);
    }, 2500);
  };
  const drawerWidth = 240;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      width: '100%',
    },

    root2: {
      width: '100%',
      useState,
      '& .MuiTextField-root2': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root2': {
        justifyContent: 'center',
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#192E41',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    pane: {
      backgroundColor:'#d2e1e6'
    },
  }));

  const classes = useStyles();
  const theme = useTheme();

  const handleClick = () => {
    setChangesByGame(true);
  };
  const finalStatsByAgentClick = () => {
    setfinalStatsByAgent(true);
    setChangesByGame(false);
  };
  const gameDeletedClick = () => {
    setGameDeleted(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

 
  return (
    <div id="Hola" className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ marginTop: '4.7%' }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Reports
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        style={{ marginTop: '9%' }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            key="WeeklyBalance"
            onClick={(e) => handleChange(e, 11)}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Weekly Balance" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            key="FinalStatsByAgent"
            onClick={(e) => handleChange(e, 0)}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Final Stats By Agent" />
          </ListItem>

          <ListItem
            button
            key="FinancialAdjustment"
            onClick={(e) => handleChange(e, 12)}
          >
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Adjustment" />
          </ListItem>

          <ListItem
            button
            key="GameDeleted"
            onClick={(e) => handleChange(e, 1)}
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Game Deleted" />
          </ListItem>
          <ListItem button key="GameScore" onClick={(e) => handleChange(e, 2)}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Game Score" />
          </ListItem>
          <ListItem
            button
            key="GameStarted"
            onClick={(e) => handleChange(e, 3)}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Game Started" />
          </ListItem>
          <ListItem
            button
            key="GameStatistic"
            onClick={(e) => handleChange(e, 4)}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Game Statistic" />
          </ListItem>
          <ListItem
            button
            key="TimeStatusChanged"
            onClick={(e) => handleChange(e, 10)}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Time Status Changed" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            key="ActionByGame"
            onClick={(e) => handleChange(e, 5)}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Action by Game" />
          </ListItem>
          <ListItem
            button
            key="ActionByPlayer"
            onClick={(e) => handleChange(e, 6)}
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Action by Player" />
          </ListItem>
          <ListItem
            button
            key="ActionByPlayerName"
            onClick={(e) => handleChange(e, 7)}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Action By Player Name" />
          </ListItem>
          <ListItem
            button
            key="PercentByGame"
            onClick={(e) => handleChange(e, 8)}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Action Percent By Game" />
          </ListItem>
          <ListItem
            button
            key="ChangedWager"
            onClick={(e) => handleChange(e, 15)}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Changed Wager" />
          </ListItem>
          <ListItem button key="FreePlays" onClick={(e) => handleChange(e, 9)}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Free Plays" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Paper className={classes.root2}>
          <TabPanel
            className={classes.pane}
            value={value}
            index={0}
          >
            <FinalStatsByAgent></FinalStatsByAgent>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={1}
          >
            <GameDeleted></GameDeleted>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={2}
          >
            <GameScore></GameScore>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={3}
          >
            <GameStarted></GameStarted>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={4}
          >
            <GameStatistic></GameStatistic>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={10}
          >
            <TimeStatusChanged></TimeStatusChanged>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={5}
          >
            <ActionByGame></ActionByGame>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={6}
          >
            <ActionByPlayer></ActionByPlayer>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={7}
          >
            <ActionByPlayerName></ActionByPlayerName>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={8}
          ></TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={9}
          >
            <FreePlays></FreePlays>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={11}
          >
            <WeeklyBalance></WeeklyBalance>
          </TabPanel>
          <TabPanel
            className={classes.pane}
            value={value}
            index={12}
          >
            <FinantialAdjustments></FinantialAdjustments>
          </TabPanel>
        </Paper>
      </main>
    </div>
  );
});

export default Player;


