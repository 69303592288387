import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import * as legoData from '../../common/animations/legoLoader.json';
import './WebConfiguratorRow.css';
import { DataGrid } from '@material-ui/data-grid' 
import Box from '@material-ui/core/Box';
import WebConfiguratorRowDetailNew from './WebConfiguratorRowDetailNew';


export default function WebConfiguratorRow(props) {
  const [webConfiguratorRow, setWebConfiguratorRow] = React.useState([]);
  const [order, setOrder] = React.useState();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [selectedRow, setSelectedRow] = useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isNewWebRowDetail, setIsNewWebRowDetail] = useState(false);
  let IdWebRow = props.WebRow.IdWebRow;
 
  useEffect(() => {
    GetWebRowDetails();
  }, [IdWebRow]);

  useEffect(() => {
    console.log("selected row changed", selectedRow)
  }, [selectedRow]);


  const GetWebRowDetails = () => {
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/WebConfigurator/WebRowDetail_GetListInfo?idRow=' +
        IdWebRow,
    }).then((response) => {
      setWebConfiguratorRow(response.data);
      console.log('response', response);
    });
    setIsLoading(false);
  }; 


  const handleCloseCreate = function () {
    setIsNewWebRowDetail(false); 
  };

  function deleteRowDetail(rowKeys) {

    if(window.confirm("Are you sure you want to delete the selected item? ("+selectedRow.IdWebRow+")")){
      let RequestDTO = {
        idWebRow: selectedRow.IdWebRow,
        idLeague: selectedRow.IdLeague,
        idUser: userInfo.UserId,
      };
      console.log("RequestDTO",RequestDTO)
      axios({
        method: 'post',
        url:
          process.env.REACT_APP_API2_URL + '/WebConfigurator/WebRowDetail_Delete',
        data: RequestDTO,
      }).then((response) => {                    
        GetWebRowDetails(); 
        alert('Data Successfully Deleted');  
        console.log("response", response)  
        setSelectedRow(false)     
      });  
    }    
  }

  function UpdateWebRow(row, field, newValue) {
    let WebConfiguratorRowRequest = {
      idWebRow: row.IdWebRow ,
      idLeague: field === "IdLeague" ? newValue : row.IdLeague ,
      rowOrder: field === "RowOrder" ? newValue : row.RowOrder,
      idUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + "/webRowDetailUpdate.php",
      data: WebConfiguratorRowRequest,
    }).then((response) => {
        alert('Data Successfully Updated');
        console.log('response', response);
    });
  }


  const handleStopEditingCell = (params,event) => {
    if(window.confirm("Are you sure you want to update the selected data?")){
      UpdateWebRow(params.row,params.field,event.target.value)
    }
  }

  function onRowSelect(row, isSelected, e) {
    setSelectedRow(webConfiguratorRow.filter(x => x.IdLeague == row[0])[0])
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}

        <Table>          
          <TableRow>
            <TableCell>
              <div>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Order"
                  value={order}
                  multiline={false}
                  onChange={handleChangeOrder}
                />
              </div>
            </TableCell>
            <TableCell>
              <div style={{display:"flex"}}>
                {selectedRow ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={deleteRowDetail}
                  >
                    Delete
                  </Button>
                  ) : (
                    ""
                  )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={()=>{setIsNewWebRowDetail(true)}}
                >
                  Add
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </Table>

        <Table>
          <TableRow>
            <TableCell>             
              <br></br>
              <Box sx={{ width: '750px' , paddingLeft: '5%'}}>
              <b>Profile Info</b>
                <DataGrid
                  rows={webConfiguratorRow}
                  getRowId={(row) => Object.values(row)[2]}
                  columns={[                                         
                    {field: 'LeagueName' , headerName: 'League Name', width: 450, editable: false, sortable: false, filterable: false},
                    {field: 'RowOrder' , headerName: 'Row Order', width: 120, editable: true, sortable: false, filterable: false},                      
                    {field: 'RegionName' , headerName: 'Region', width: 120, editable: false, sortable: false, filterable: false},                      
                  ]}
                  pageSize={100}
                  rowsPerPageOptions={[5]}            
                  disableColumnSelector
                  disableColumnMenu
                  disableMultipleSelection={true} 
                  autoHeight      
                  style={{backgroundColor:'white'}}        
                  onCellEditStop={handleStopEditingCell} 
                  onSelectionModelChange={onRowSelect}                 
                />
              </Box>
            </TableCell>
          </TableRow>
        </Table>
      </div>
      {isNewWebRowDetail?(        
            <WebConfiguratorRowDetailNew
              onClose={handleCloseCreate}
              GetWebRowDetails={GetWebRowDetails}
              IdWebRow={IdWebRow}
            />          
        ):(
          "" 
        )}
    </React.Fragment>
  );
}
