import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import Loader from "../../components/common/loader/Loader";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function CreateSport (props) {
    const useStyles = makeStyles((theme) => ({        
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },
        center: {
            margin: "0 auto",
            border: "1px grey black",
            textAlign: "center",
            width: "600px",
            height: "250px",
            "& .MuiTextField-root": {
              margin: theme.spacing(1),
              width: 500,
            },
            "& .MuiFormGroup-row": {
              //justifyContent: "center",
            },
            "& .MuiFormControl-root": {
              justifyContent: "center",
            },
            "& .MuiSelect-select": {
                width: 220,
                spacing: theme.spacing(1),
            }
        },        
    }));

    const classes = useStyles();
    const [open, setOpen] = useState(true);   
    const [isLoading, setIsLoading] = useState(false);
    const [isRed, setIsRed] = useState(false);
    const [sportName, setSportName] = useState(props.isEdit ? props.sportSelected[0].SportName : "");
    const [idSport, setIdSport] = useState(props.isEdit ? props.sportSelected[0].IdSport : "");
    const [sportOrder, setSportOrder] = useState(props.isEdit ? props.sportSelected[0].SportOrder : "");
    const [onlineStatus, setOnlineStatus] = useState(props.isEdit ? props.sportSelected[0].OnlineStatus : "");
    const [localStatus, setLocalStatus] = useState(props.isEdit ? props.sportSelected[0].LocalStatus : "");
    const [isInserted, setIsInserted] = useState(false);

    useEffect(() => {
        console.log("props", props);
    }, []);
    var today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;

    const [userInfo, setUserInfo] = useState(
        JSON.parse(sessionStorage.getItem("userInfo"))
      ); 

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
        setOpen(false);
    }

    const handleSportNameChange = (event) => {
        setSportName(event.target.value);
    }

    const handleSportOrderChange = (event) => {
        setSportOrder(event.target.value);
    }

    const handleIdSportChange = (event) => {
        setIdSport(event.target.value);
    }

    const insertSport = () => {
        setIsLoading(true);

        let sport = {
            IdSport: idSport,
            SportName: sportName,
            SportOrder: sportOrder,            
            OnlineStatus: onlineStatus,
            LocalStatus: localStatus,
            LastModification: today,
            LastModificationUser: userInfo.UserId          
        }
        Axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/SPORT",
            data: sport,
        }).then((response) => {
            console.log("Response",response);
            setIsInserted(true);
            setIsLoading(false);
            setSportName('');
            setSportOrder('');
            setIdSport('');
            setOnlineStatus(false);
            setLocalStatus(false);
            setIsRed(false);
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
    }

    const updateSport = () => {
        setIsLoading(true);

        let sport = {
            IdSport: idSport,
            SportName: sportName,
            SportOrder: sportOrder,            
            OnlineStatus: onlineStatus,
            LocalStatus: localStatus,
            LastModification: today,
            LastModificationUser: userInfo.UserId          
        }
        
        Axios({
            method: 'put',
            url: process.env.REACT_APP_API_URL + "/SPORT/"+props.sportSelected[0].IdSport,
            data: sport,
        }).then((response) => {
            console.log("Response",response);
            setIsInserted(true);
            setIsLoading(false);
            setIsRed(false);
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
    }

    const handleOk = () => {
        if(idSport==="" || sportName==="" || sportOrder===""){
            setIsRed(true);
            alert("Please provide the required fields");
        }else{
            props.isEdit? updateSport():insertSport();
        }
    }

    return(        
        <React.Fragment>
            <Dialog 
                open={open} 
                onClose={handleClose}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form className={classes.center}>
                    <Divider />
                    <FormGroup row>                                
                        <TextField                                
                        variant="outlined"                            
                        label="Id Sport"
                        type="text"
                        value={idSport}
                        {...isRed && idSport===''?(
                            {error:true}
                        ):("")}
                        onChange={handleIdSportChange}
                        onClick={()=>props.isEdit ? alert("The Id Sport can't be edited"):""}
                        fullWidth  
                        disabled={props.isEdit}                                                                     
                        />                        
                    </FormGroup> 
                    <FormGroup row>                                
                        <TextField                                
                        variant="outlined"                            
                        label="Sport Name"
                        type="text"
                        value={sportName}
                        {...isRed && sportName===''?(
                            {error:true}
                        ):("")}
                        onChange={handleSportNameChange}
                        fullWidth                                                                       
                        />                        
                    </FormGroup> 
                    <FormGroup row>                                
                        <TextField       
                        inputProps={{min: props.sports.length-1}}                         
                        variant="outlined"                            
                        label="Sport Order"
                        type="number"
                        value={sportOrder}
                        {...isRed && sportOrder===''?(
                            {error:true}
                        ):("")}
                        onChange={handleSportOrderChange}
                        fullWidth                                                                       
                        />                        
                    </FormGroup>
                    <FormGroup row style={{display:"flex",justifyContent:"space-between",width:"85%"}}>
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={onlineStatus}
                                    onChange={() => setOnlineStatus(!onlineStatus)}
                                    color="primary"
                                />
                            } 
                            label="Online Status" 
                        />         
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={localStatus}
                                    onChange={() => setLocalStatus(!localStatus)}
                                    color="primary"
                                />
                            } 
                            label="Local Status" 
                        />                
                    </FormGroup>                                       
                </form>
                <React.Fragment>
                    <DialogActions>
                        {isInserted ? (                                
                            <FormControl className={classes.formControl}>
                                <InputBase
                                style={{
                                    color: "red",
                                    fontSize: 35,
                                    fontWeight: "bold",
                                }}
                                className={classes.margin}
                                defaultValue="Done!"
                                inputProps={{ "aria-label": "naked" }}
                                />
                            </FormControl>                                
                        ) : (
                            ""
                        )}
                        {isLoading ? (
                        <Loader height={100} width={100}></Loader>
                        ) : (
                        <div>                                
                            <Button
                            startIcon={<SaveIcon />}
                            color="inherit"
                            onClick={handleOk}
                            >
                            Ok
                            </Button>
                        </div>
                        )}                            
                    </DialogActions>   
                                            
                </React.Fragment>
            </Dialog>
        </React.Fragment>
    );    
} 
export default CreateSport;