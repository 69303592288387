import React, { useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { UserContext } from "../contexts/UserContext";
import PitcherList from "../components/common/PitcherList";
import InputSelect from "../components/common/InputSelect";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import PText from "react-predictive-text";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import InputBase from "@material-ui/core/InputBase";
import Loader from "../components/common/loader/Loader";
import { TableHead } from "@material-ui/core";
import { getTeamNamesBySport } from "./common/apiHelper";
import Leagues from "./common/Leagues";

import Games from "./games/Games";
import Teams from "./teams/Teams";
import { set } from "date-fns";

export default function CreateGame(props) {
  const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    appBar: {
      position: "relative",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: "0 auto",
      border: "1px grey black",
      textAlign: "center",
      width: "600px",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        //  width: 200,
      },
      "& .MuiFormGroup-row": {
        justifyContent: "center",
      },
      "& .MuiFormControl-root": {
        justifyContent: "center",
      },
    },
  }));
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const [sports, setSports] = useState();
  const [teamNames, setTeamNames] = useState(0);
  const [idLeague, setIdLeague] = React.useState(0);
  const [visitorScore, setVisitorScore] = React.useState(0);
  const [homeScore, setHomeScore] = React.useState(0);
  const [visitorPitcher, setVisitorPitcher] = useState("");
  const [homePitcher, setHomePitcher] = React.useState("");
  const [gameStat, setGameStat] = React.useState("");
  const [graded, setGraded] = React.useState(false);
  const [gameType, setGameType] = React.useState(0);
  const [hookups, setHookUps] = React.useState(false);
  const [local, setLocal] = React.useState(false);
  const [online, setOnline] = React.useState(false);
  const [shortGame, setShortGame] = React.useState(false);
  const [eventDate, setEventDate] = React.useState("");
  const [dateChanged, setDateChanged] = React.useState(false);
  const [timeChanged, setTimeChanged] = React.useState(false);
  const [pitcherChanged, setPitcherChanged] = React.useState(0);
  const [period, setPeriod] = React.useState(0);
  const [gradedDate, setGradedDate] = React.useState("");
  const [numTeams, setNumTeams] = React.useState(0);
  const [idEvent, setIdEvent] = React.useState(0);
  const [hasChildren, setHasChildren] = React.useState(false);
  const [idTeamVisitor, setIdTeamVisitor] = React.useState(0);
  const [idTeamHome, setIdTeamHome] = React.useState(0);
  const [idBannerType, setIdBannerType] = React.useState(0);
  const [gameDateTime, setGameDateTime] = React.useState("");
  const [league, setLeague] = React.useState([]);
  const [leagueID, setLeagueID] = React.useState(0);
  const [typeID, setTypeID] = React.useState(0);
  const [type, setType] = React.useState([]);
  const [familyGame, setFamilyGame] = React.useState(0);
  const [parentGame, setParentGame] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [visitorNumber, setVisitorNumber] = React.useState(0);
  const [visitorTeam, setVisitorTeam] = React.useState("");
  const [homeNumber, setHomeNumber] = React.useState(0);
  const [homeTeam, setHomeTeam] = React.useState("");

  const [visitorSpread, setVisitorSpread] = React.useState(0);
  const [visitorSpreadOdds, setVisitorSpreadOdds] = React.useState(0);
  const [overTotal, setOverTotal] = React.useState(0);
  const [overOdds, setOverOdds] = React.useState(0);
  const [gameData, setGameData] = useState();
  const [homeSpread, setHomeSpread] = React.useState(0);
  const [homeSpreadOdds, setHomeSpreadOdds] = React.useState(0);
  const [underTotal, setUnderTotal] = React.useState(0);
  const [underOdds, setUnderOdds] = React.useState(0);
  const [idGameType, setIdGameType] = React.useState(0);
  const [visitorOdds, setVisitorOdds] = React.useState(null);
  const [homeOdds, setHomeOdds] = React.useState(null);
  const [TNTContenders, setTNTContenders] = useState(false);
  const [idGame, setIdGame] = React.useState(0);
  const [visitorPitcherOld, setVisitorPitcherOld] = React.useState('');
  const [homePitcherOld, setHomePitcherOld] = React.useState('');
  const [visitorMoneyline, setVisitorMoneyline] = React.useState(0);
  const [homeMoneyline, setHomeMoneyline] = React.useState(0);
  const [contender, setContender] = useState(1);
  const [name, setName] = useState("");
  const [odds, setOdds] = useState("");
  const [array, setArray] = useState([]);
  const [reset, setReset] = useState(false);
  const [normalGame, setNormalGame] = React.useState(0);
  const [games, setGames] = React.useState();
  const [gamesCreated, setGameCreated] = React.useState(false);
  const [showGamesPicker, setShowGamesPicker] = React.useState(false);

  const [useFindParent, setUseFindParent] = React.useState(false);

  const [idSport, setIdSport] = useState(0);
  const [gameStatus, setGameStatus] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [system, setSystem] = React.useState(0);
  const [noHookUps, setNoHookUps] = React.useState(false);
  const [acceptAutoChanges, setAcceptAutoChanges] = React.useState(false);
  const [completedGame, setCompletedGame] = React.useState("");
  const [isSport, setIsSport] = useState(false);
  const [inserted, setInserted] = useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );
  const [result, setResult] = React.useState(0);
  const [withCapsOn, setWithCapsOn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [useMatchupTemplates, setUseMatchupTemplates] = useState(false);
  const inputRef = useRef(null);
  const [leagueTemplate, setLeagueTemplate] = React.useState("");
  const [templateDraw, setTemplateDraw] = React.useState(0);
  
  const [flag, setFlag] = useState(false);
  const [idAction, setIdAction] = useState(0);
  const [pitcherSide, setPitcherSide] = useState('');
  const [pitchers, setPitchers] = useState([]);

  const [tmpTemplatePlayerlist, setTmpTemplatePlayerlist] = React.useState([
    "Phil Mickelson",
    "Tiger Woods",
    "Fred Couples",
    "Jim Furyk",
    "Steve Stricker",
    "Vijay Singh",
  ]);

  const handleChangeGameDateTime = (event) => {
    setGameDateTime(event.target.value);    
  };
  const handleChangeFamilyGame = (event) => {
    setFamilyGame(event);
    if(typeof(event)==="object"){
      setFamilyGame(0);
    }
  };

  const handleChangeParentGame = (event) => {
    setParentGame(event);
    if(typeof(event)==="object"){
      setParentGame(0);
    }
  };
  const handleFindParent = (event) => {    
    setParentGame(event);    
    setShowGamesPicker(false);
    // TODO
  };
  const handleFindFamily = (event) => {
    setFamilyGame(event);
    setShowGamesPicker(false);
    // TODO
  };
  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangeVisitorNumber = (event) => {
    let intToSet = 0;
    intToSet =
      event.target.value == "" || isNaN(event.target.value)
        ? 0
        : parseInt(event.target.value);

    setVisitorNumber(intToSet);
    setHomeNumber(intToSet + 1);
  };
  
  const handleChangeVisitorTeam = (event, values) => {
    setVisitorTeam(event);
  };

  const handleChangeVisitorTeamNonPT = (event) => {
    setVisitorTeam(event.target.value);
  };

  const handleChangeHomeTeamNonPT = (event) => {
    setHomeTeam(event.target.value);
  }; 

  
  const handleChangeVisitorTeamSelect = (event, values) => {
    setInserted(false);
    values ? setVisitorTeam(values) : setVisitorTeam("");
  };
  const handleChangeType = (event) => {
    setVisitorTeam(event.target.value);
  };
  const handleChangeVisitorPitcher = (event) => {
    setVisitorPitcher(event.target.value);
  };

  const handleChangeHomeNumber = (event) => {
    setHomeNumber(event.target.value);
  };

  const handleChangeHomeTeam = (event) => {    
    setHomeTeam(event);
  };
  const handleChangeHomeTeamSelect = (event, values) => {
    setHomeTeam(values);
  };
  const handleChangeHomePitcher = (event) => {
    setHomePitcher(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeContender = (event) => {
    setContender(event.target.value);
  };
  const handleChangeOdds = (event) => {
    setOdds(event.target.value);
  };
  const handleChangeNumber = (event) => {
    setVisitorNumber(event.target.value);
    setHomeNumber(event.target.value);
  };
  const setVisitorPitcherOnMem = (VisitorPitcher) => {
    if(props.isModify){
      console.log('Visitor Pitcher', VisitorPitcher);
      setVisitorPitcher(VisitorPitcher);
      setFlag(true);
      setIdAction(1);
      setPitcherSide('V');
      setPitcherChanged(3);
    }else{
      setVisitorPitcher(VisitorPitcher);
    }    
  };
  const setHomePitcherOnMem = (HomePitcher) => {
    if(props.isModify){
      console.log('Home Pitcher', HomePitcher);
      setHomePitcher(HomePitcher);
      setFlag(true);
      setIdAction(2);
      setPitcherSide('H');
      setPitcherChanged(3);
    }else{  
      setHomePitcher(HomePitcher);
    }   
  };

  const resetStates = () => {
    setIdGame(0);
    setVisitorTeam('');
    setHomeTeam('');
    setIdSport(0);
    setIdLeague(0);
    setIdGameType(0);
    setGameDateTime("");
    setVisitorNumber(0);
    setHomeNumber(0);
    setNormalGame(0);
    setGameStat(0);
    setGraded(false);
    setHookUps(false);
    setOnline(false);
    setLocal(false);
    setShortGame(false);
    setEventDate("");
    setPeriod(0);
    setDateChanged(false);
    setTimeChanged(false);
    setParentGame("");
    setVisitorScore(0);
    setHomeScore(0);
    setVisitorPitcher("");
    setVisitorPitcherOld("");
    setHomePitcher("");
    setHomePitcherOld("");
    setGradedDate("");
    setNumTeams(0);
    setPitcherChanged(0);
    setIdEvent(0);
    setFamilyGame("");
    setHasChildren(false);
    setIdTeamVisitor(0);
    setIdTeamHome(0);
    setIdBannerType(0);
    setDescription("");
    setTypeID(0);              
    setLeagueID(0);
    setVisitorSpread(0);
    setVisitorSpreadOdds(0);
    setOverTotal(0);
    setOverOdds(0);
    setVisitorMoneyline(0);
    setHomeSpread(0);
    setHomeSpreadOdds(0);
    setUnderTotal(0);
    setUnderOdds(0);
    setHomeMoneyline(0);
    setVisitorOdds(null);
    setHomeOdds(null);
  }

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
    resetStates();
  };

  const handleOk = () => {
    if(props.isModify){
      updateGame();
    }else{
      InsertGame();
      if (props.IdSport === "PROP") {
        setHomeNumber(parseInt(homeNumber) + 1);
        setVisitorNumber(parseInt(visitorNumber) + 1);
        setOdds("");
        setReset(true);
      } else {
        if (props.IdSport === "SOC") {
          setHomeNumber(parseInt(homeNumber) + 4);
          setVisitorNumber(parseInt(visitorNumber) + 4);
        } else {
          setHomeNumber(parseInt(homeNumber) + 2);
          setVisitorNumber(parseInt(visitorNumber) + 2);
        }
      }
      if(props.IdSport !== "PROP"){
        setVisitorTeam("");
      }     
      setHomeTeam("");
      setName("");
      setDescription("");
      GetGames(leagueID, typeID);
      setHomePitcher("");
      setVisitorPitcher("");
    }    
    //Create Pitcher if not exists    
    if(visitorPitcher!==""){
      if(!doesPitcherExist(visitorPitcher)) 
        insertPitcher(visitorPitcher)
    }
    if(homePitcher!==""){
      if(!doesPitcherExist(homePitcher)) 
        insertPitcher(homePitcher)
    }    
  }; 

  const updateGame = async () => {    
    if (gameStat != 'B') {
      setIsLoading(true);      
      
        if (flag) {
          console.log(flag, 'flag state');
          console.log(props, 'action state');
          setVisitorPitcherOld(visitorPitcher);
          setHomePitcherOld(homePitcher);
          setFlag(false);
          // if (props.MLVisitorAction != "" || props.MLVisitorCount != "" || props.MLHomeCount != "" || props.MLHomeAction != ""){
          //   setIsChange(true);
          //   return; 
          // }
        }
        let objTsend = {
          IdGame: idGame,
          IdSport: props.IdSport,
          IdLeague: leagueID.value ? leagueID.value : leagueID,
          IdGameType: typeID.value ? typeID.value : typeID,
          GameDateTime: gameDateTime.date
            ? gameDateTime.date.slice(0, -4)
            : gameDateTime,
          VisitorNumber: visitorNumber,
          HomeNumber: homeNumber,
          VisitorTeam: visitorTeam.toUpperCase(),
          HomeTeam: homeTeam.toUpperCase(),
          VisitorScore: visitorScore,
          HomeScore: homeScore,
          VisitorPitcher: visitorPitcher,
          HomePitcher: homePitcher,
          NormalGame: normalGame,
          GameStat: gameStat,
          Graded: graded,
          Hookups: hookups,
          Local: local,
          Online: online,
          ShortGame: shortGame,
          EventDate: eventDate.date
            ? eventDate.date.slice(0, -4)
            : eventDate.slice(0, -4),
          DateChanged: dateChanged,
          TimeChanged: timeChanged,
          PitcherChanged: pitcherChanged,
          Period: period,
          ParentGame: parentGame.idGame===undefined?parentGame:parentGame.idGame,
          GradedDate: gradedDate
            ? gradedDate.date
              ? gradedDate.date.slice(0, -4)
              : gradedDate.slice(0, -4)
            : null,
          NumTeams: numTeams,
          IdEvent: idEvent,
          FamilyGame: familyGame.idGame===undefined?familyGame:familyGame.idGame,
          HasChildren: hasChildren,
          IdTeamVisitor: idTeamVisitor,
          IdTeamHome: idTeamHome,
          IdBannerType: idBannerType,
          Description: description,
          AcceptAutoChanges: acceptAutoChanges,
          IdUser: 498,
          Result: result,
        };
        console.log('OBJECT SEND===>', objTsend);
        await axios({
          method: 'post',
          //url: process.env.REACT_APP_DEF_PHP_URL + '/GameUpdate.php',
          url: process.env.REACT_APP_API2_URL + '/Game/GameUpdate',
          //url: 'https://localhost:44337/api/Game/GameUpdate',
          data: objTsend,
        }).then((response) => {
          console.log('response', response);
          props.searchGameValues();
          setIsLoading(false);
          handleClose();
          alert('Game Updated');
        });
    }    
  }

  const handleChangePlayersList = (event) => {
    try {
      let tmpList = event.target.value.split(",");
      setTmpTemplatePlayerlist(tmpList);
    } catch (error) {
      // alert("Chec: " + error);
    }
  };


  const InsertGame = () => {
    if (leagueID != 0 && typeID != 0 && gameDateTime != "") {
      let gameRequest = {
        IdSport: props.IdSport == "" ? idSport : props.IdSport,
        IdLeague: leagueID,
        IdGameType: typeID,
        GameDateTime: gameDateTime
          ? gameDateTime.replace(" ", "T").split(".")[0]
          : "",
        VisitorNumber: props.IdSport == "TNT" ? 1 : visitorNumber,
        HomeNumber: props.IdSport == "TNT" ? 2 : homeNumber,
        VisitorTeam:
          props.IdSport == "TNT"
            ? withCapsOn
              ? description.toUpperCase()
              : description
            : withCapsOn
            ? visitorTeam.toUpperCase()
            : visitorTeam,
        HomeTeam:
          props.IdSport == "TNT"
            ? withCapsOn
              ? description.toUpperCase()
              : description
            : withCapsOn
            ? homeTeam.toUpperCase()
            : visitorTeam,
        VisitorScore: visitorScore,
        HomeScore: homeScore,
        VisitorPitcher: visitorPitcher,
        HomePitcher: homePitcher,
        NormalGame: normalGame,
        GameStat: "D",
        Graded: graded,
        Hookups: hookups,
        Local: local,
        Online: online,
        ShortGame: shortGame,
        EventDate: gameDateTime
          ? gameDateTime.replace(" ", "T").split(".")[0]
          : "",
        DateChanged: dateChanged,
        TimeChanged: timeChanged,
        PitcherChanged: pitcherChanged,
        Period: 0,
        ParentGame: parentGame.idGame===undefined?parentGame:parentGame.idGame,
        GradedDate: null,
        NumTeams:
          props.IdSport == "TNT" || props.IdSport == "PROP" ? array.length : 2,
        IdEvent: idEvent,
        FamilyGame: familyGame.idGame===undefined?familyGame:familyGame.idGame,
        HasChildren: hasChildren,
        IdTeamVisitor: idTeamVisitor,
        IdTeamHome: idTeamHome,
        IdBannerType: idBannerType,
        Description: description,
        AcceptAutoChanges: acceptAutoChanges,
        IdUser: userInfo.UserId,
        Result: result,
      };      
      setIsLoading(true);
      axios({
        method: "post",
        //url: 'https://localhost:44337/api/Game/InsertGame',
        url: process.env.REACT_APP_API2_URL + "/Game/InsertGame",
        data: gameRequest,
      }).then((response) => {
        let IdGame = response.data;
        if (props.IdSport == "TNT") {
          axios({
            method: "post",
            // url: 'https://localhost:44337/api/Game/InsertGameValuesTNT?idGame=' + IdGame,
            url:
              process.env.REACT_APP_API2_URL +
              "/Game/InsertGameValuesTNT?idGame=" +
              IdGame,
            data: array,
          }).then((response) => {
            setArray([]);
            props.searchGameValues();
          });
        } else if (props.IdSport == "PROP") {
          let propRequest = [];
          propRequest.push({ Id: visitorNumber, TeamName: name, Odds: odds });
          axios({
            method: "post",
            // url:'https://localhost:44337/api/Game/InsertGameValuesTNT?idGame=' +IdGame,
            url:
              process.env.REACT_APP_API2_URL +
              "/Game/InsertGameValuesTNT?idGame=" +
              IdGame,
            data: propRequest,
          }).then((response) => {
            setHomeTeam("");
            props.searchGameValues();
          });
        } else {
          props.searchGameValues();
        }
        setGameCreated(true)
        setInserted(true);
        setTimeout(() => {  setInserted(false); }, 3000);
        setIsLoading(false);
      });
    } else {
      alert("Date, League and Type are required");
    }
  };

  const handleArray = () => {
    let p = array.slice();
    p.push({ Id: contender, TeamName: name, Odds: odds });
    setArray(p);
    setContender(parseInt(contender) + 1);
    setName("");
  };

  const getContenders = () => {
    axios
      .get(
        process.env.REACT_APP_API2_URL +
          `/Game/Games_GameDataTNT?idGame=${props.IdGames[0]}&idLineType=${props.IdLinetype}`
      )
      .then((response) => {
        setGameData(response.data[0]);
        setGameDateTime(response.data[0].GameDateTime);
        setFamilyGame(response.data[0].FamilyGame);
        setParentGame(response.data[0].ParentGame);
        setDescription(response.data[0].Description);
        setTNTContenders(response.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (gamesCreated && inputRef.current) {
      if (inputRef.current) {
        inputRef.current.focus();
        setGameCreated(false);
      }
    }
  }, [gamesCreated]);

  const handleRef = (node) => {
    inputRef.current = node;
    if (gamesCreated && node) {
      node.focus();
      setGameCreated(false); // Reset focus flag
    }
  };

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + "/GAMETYPE?order=Description";
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setType(data.records);
      })
      .then(setLoading(false));
  }, [gameType]);

  let startingNumber = 56559;

  useEffect(() => {
    getPitchers();
    if (props.IdSport != "") {
      setIsSport(true);
      GetLeagues(props.IdSport);
      getTeamNamesBySport(props.IdSport, setTeamNames);   
      setIdSport(props.IdSport);   
    } else {
      GetSports();      
    }    
    setStates()    
  }, []);

  useEffect(() => {
    getTeamNamesBySport(idSport, setTeamNames)
  }, [idSport]);
 
  const setStates = async () => {
    if(props.isModify){
      console.log(props.IdLinetype,"line type")
      props.IdSport && getTeamNamesBySport(props.IdSport, setTeamNames);      
      props.IdSport == 'TNT'
      ? getContenders()
      : await axios
          .get(
            process.env.REACT_APP_API2_URL +
              `/Game/Games_GameData?idGame=${props.idGame}&idLineType=${props.IdLinetype}`
          )
          .then((response) => {
            console.log('responsegamedata', response);
            response.data.forEach((x) => {
              setIdGame(x['IdGame']);
              setVisitorTeam(x['VisitorTeam']);
              setHomeTeam(x['HomeTeam']);
              setIdSport(x['IdSport']);
              setIdLeague(x['IdLeague']);
              setIdGameType(x['IdGameType']);
              setGameDateTime(x['GameDateTime']);
              setVisitorNumber(x['VisitorNumber']);
              setHomeNumber(x['HomeNumber']);
              setNormalGame(x['NormalGame']);
              setGameStat(x['GameStat']);
              setGraded(x['Graded']);
              setHookUps(x['HookUps']);
              setOnline(x['Online']);
              setLocal(x['OnLocal']);
              setShortGame(x['ShortGame']);
              setEventDate(x['EventDate']);
              setPeriod(x['Period']);
              setDateChanged(x['DateChanged']);
              setTimeChanged(x['TimeChanged']);
              setParentGame(x['ParentGame']);              
              setVisitorScore(x['VisitorScore']);
              setHomeScore(x['HomeScore']);
              setVisitorPitcher(x['VisitorPitcher']);
              setVisitorPitcherOld(x['VisitorPitcher']);
              setHomePitcher(x['HomePitcher']);
              setHomePitcherOld(x['HomePitcher']);
              setGradedDate(x['GradedDate']);
              setNumTeams(x['NumTeams']);
              setPitcherChanged(x['PitcherChanged']);
              setIdEvent(x['IdEvent']);
              setFamilyGame(x['FamilyGame']);
              setHasChildren(x['HasChildren']);
              setIdTeamVisitor(x['IdTeamVisitor']);
              setIdTeamHome(x['IdTeamHome']);
              setIdBannerType(x['IdBannerType']);
              setDescription(x['GameDescription']);
              setTypeID(x['IdGameType']);              
              setLeagueID(x['IdLeague']);              
              setVisitorSpread(x['VisitorSpread']);
              setVisitorSpreadOdds(x['VisitorSpreadOdds']);
              setOverTotal(x['OverTotal']);
              setOverOdds(x['OverOdds']);
              setVisitorMoneyline(x['VisitorMoneyLine']);
              setHomeSpread(x['HomeSpread']);
              setHomeSpreadOdds(x['HomeSpreadOdds']);
              setUnderTotal(x['UnderTotal']);
              setUnderOdds(x['UnderOdds']);
              setHomeMoneyline(x['HomeMoneyLine']);
              setVisitorOdds(x['VisitorOdds']);
              setHomeOdds(x['HomeOdds']);
            });
          });
    }
  }

  const GetSports = () => {
    const url = process.env.REACT_APP_API_URL + "/SPORT?order=SportName";
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let groupingInfo = data.records.map((v) => {
          v.IdSport = v.IdSport;
          v.SportName = v.SportName;
          return v;
        });
        setSports(groupingInfo);
      });
  }

  const doesPitcherExist = (pitcherName) => {
    let doesExist = false;
    pitchers.forEach((x) => {
      if (x.PitcherName === pitcherName) {
        doesExist = true;
      }
    });
    return doesExist;
  }
  
  const doesTeamExist = (teamName) => {
    let doesExist = false;
    teamNames.forEach((x) => {
      if (x === teamName) {
        doesExist = true;
      }
    });
    return doesExist;
  }

  const getPitchers = () => {
    const url = process.env.REACT_APP_API_URL + '/PITCHER?order=IdBook';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {        
        setPitchers(data.records);
      });
  };

  const insertPitcher = (pitcher) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    console.log("today", today);
    
    let reqTeam = {
      PitcherName: pitcher,
      LastModification: today,
      LastModificationUser: 487
    }
    try{
      axios({
        method: 'POST',
        url: process.env.REACT_APP_API_URL + '/PITCHER',
        data: reqTeam,
      }).then((response) => {
        console.log("response", response);        
      });
    }catch(error){
      console.error("error.response.data",error.response.data)
    }  
  }

  const GetGames = (IdLeague, IdType) => {
    if (IdLeague != 0 && IdType != 0) {
      const url =
        process.env.REACT_APP_API_URL +
        "/GAME?filter=IdLeague,eq," +
        IdLeague +
        "&filter=IdGameType,eq," +
        IdType;
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setGames(data.records.filter((g) => g.GameStat != "B"));
        });
    }
  };
  const [parentHomeTeamName, setParentHomeTeamName] = useState("");
  const [familyHomeTeamName, setFamilyHomeTeamName] = useState("");

  const getParentHomeTeamNameByGameId = (id) => {
    const url = process.env.REACT_APP_API_URL + "/GAME?filter=IdGame,eq," + id;
    axios.get(url).then((response) => response.data).then((data) => {
      console.log("data",data)
      setParentHomeTeamName(data.records[0].HomeTeam);
    });
  };

  const getFamilyHomeTeamNameByGameId = (id) => {
    const url = process.env.REACT_APP_API_URL + "/GAME?filter=IdGame,eq," + id;
    axios.get(url).then((response) => response.data).then((data) => {
      console.log("data",data)
      setFamilyHomeTeamName(data.records[0].HomeTeam);
    });
  };

  useEffect(() => {
    if(parentGame !==0)
      getParentHomeTeamNameByGameId(parentGame)    
  }, [parentGame])

  useEffect(() => {
    if(familyGame !==0)
      getFamilyHomeTeamNameByGameId(familyGame)    
  }, [familyGame])

  const GetLeagues = (sport) => {
    const url =
      process.env.REACT_APP_API_URL +
      "/LEAGUE?filter=IdSport,eq," +
      sport +
      "&order=Description";
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setLeague(data.records);
      })
      .then(setLoading(false));
  };

  function changeLeagueID(value) {
    let tmpLeague = league.filter((l) => l.IdLeague == value);
    tmpLeague && tmpLeague[0] && setLeagueTemplate(tmpLeague[0].Description);

    setLeagueID(value);
    GetGames(value, typeID);
  }

  function changeTypeID(value) {
    setTypeID(value);
    GetGames(leagueID, value);
  }

  function changeSportID(value) {
    setIdSport(value);
    setIsSport(true);
    GetLeagues(value);
  }

  function onAfterDeleteRow(rowKeys) {
    setArray(array.filter((item) => item.Id !== rowKeys));
  }

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
  };

  const selectRowProp = {
    mode: "checkbox",
  };

  const getTemplateAppliedContent = () => {
    return (
      <div
      //style={{ position: "absolute", left: "0%", top: "11%" }}
      >
        <FormGroup row>
          <IconButton>
            <FormControlLabel
              style={{ color: "#333" }}
              label="Insert Draw"
              control={
                <Checkbox
                  checked={templateDraw != 0}
                  onChange={() => {
                    templateDraw != 0 ? setTemplateDraw(0) : setTemplateDraw(3);
                  }}
                  name="InsertCaps"
                  color="secondary"
                />
              }
            />
            <TextField
              type="text"
              value={templateDraw}
              variant="outlined"
              onChange={(e) => setTemplateDraw(e.target.value)}
            />
          </IconButton>
          <TextField
            fullWidth
            type="text"
            label="Player List"
            value={tmpTemplatePlayerlist.toString()}
            multiline={true}
            rows="4"
            onChange={handleChangePlayersList}
            variant="outlined"
          />
        </FormGroup>
        <Divider /> Templates
        {leagueTemplate !== "" &&
          tmpTemplatePlayerlist &&
          tmpTemplatePlayerlist.map((playerName, index) => {
            if (index % 2 && templateDraw != 0) {
              startingNumber = startingNumber + 3;
              return (
                <>
                  <p>
                    {startingNumber - 2} - {leagueTemplate} - {playerName}{" "}
                  </p>
                  <p>
                    {startingNumber - 1} - {leagueTemplate} - Draw
                  </p>
                </>
              );
            } else {
              startingNumber = startingNumber + 1;
              return (
                <>
                  <p>
                    !{startingNumber - 1} - {leagueTemplate} - {playerName}{" "}
                  </p>
                </>
              );
            }
          })}
      </div>
    );
  };

  const returnTemplatesCheckBox = () => {
    return (
      <IconButton>
        <FormControlLabel
          style={{ color: "#ffffff" }}
          label="Use Templates"
          control={
            <Checkbox
              checked={useMatchupTemplates}
              onChange={() => {
                setUseMatchupTemplates(!useMatchupTemplates);
              }}
              name="Use Matchup Templates"
              color="secondary"
            />
          }
        />
      </IconButton>
    );
  };

  useEffect(() => {
    console.log("teams.length", teamNames.length);
    console.log("teams", teamNames)
    console.log("props", props)
  }, [])

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>              
              {props.isModify?"Modify Game":"New Game"}
            </Typography>
            {idSport === "MU" && returnTemplatesCheckBox()}
            {props.IdSport && returnTemplatesCheckBox()}

            <IconButton>
              <FormControlLabel
                style={{ color: "#ffffff" }}
                label="Insert Caps"
                control={
                  <Checkbox
                    checked={withCapsOn}
                    onChange={() => {
                      setWithCapsOn(!withCapsOn);
                    }}
                    name="InsertCaps"
                    color="secondary"
                  />
                }
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        {/*{!noHookUps?(<GameIndexed games={gameType}/>):("")}*/}
        {loading ? (
          <CircularProgress style={{ margin: "0 auto" }} />
        ) : (
          <React.Fragment>
            <br />
            <form className={classes.center}>
              <Divider />
              <FormGroup row>
                <TextField
                  required
                  variant="outlined"
                  id="gameDateTime"
                  label="Game Date and Time"
                  type="datetime-local"
                  value={
                    gameDateTime
                      ? gameDateTime.replace(" ", "T").split(".")[0]
                      : ""
                  }
                  onChange={handleChangeGameDateTime}
                  className={classes.textField}
                  style={{ width: "80%" }}
                  InputLabelProps={{ shrink: true }}
                />
              </FormGroup>
              <Divider />
              {props.IdSport == "" ? (
                <FormGroup row>
                  <div style={{ float: "left" }}>
                    <InputSelect
                      required
                      variant="outlined"
                      name="name"
                      fullWidth
                      value={idSport}
                      data={sports}
                      placeholder="Sports"
                      setSelectValue={changeSportID}
                    />
                  </div>
                </FormGroup>
              ) : (
                ""
              )}
              <FormGroup row>
                <FormControl className={classes.formControl}>
                  {isSport ? (
                    <>
                      {/*     <InputSelect
                      variant="outlined"
                      name="name"
                      required
                      data={league}
                      placeholder="League"
                      setSelectValue={changeLeagueID}
                      startValue={leagueID}
                    />*/}

                      <Leagues
                        /* showDataTable={true}*/ setLeagueOnMem={
                          changeLeagueID
                        }
                        filterBy={
                          props.IdSport?(
                            props.IdSport === "ESOC" ? "SOC" : props.IdSport
                          ):(
                            idSport==="ESOC"?"SOC":idSport
                          )                          
                        }
                        key={props.IdSport?props.IdSport:idSport}                        
                        showDataTable={false}
                        isModify={props.isModify}                        
                        league={leagueID}                                              
                      />
                    </>
                  ) : (
                    ""
                  )}{" "}
                </FormControl>
                <FormControl className={classes.formControl}>
                  {props.isModify?(
                    typeID != 0 ? (
                      <InputSelect
                        variant="outlined"
                        name="name"
                        data={type}
                        placeholder="Type"
                        setSelectValue={changeTypeID}
                        startValue={typeID}
                      />
                          ) : ('')                         
                  ):(
                    <InputSelect
                    variant="outlined"
                    name="name"                    
                    data={type}
                    placeholder="Type"
                    setSelectValue={changeTypeID}                                  
                    startValue={typeID}
                  />
                  )}
                  
                </FormControl>
              </FormGroup>
              <Divider />
              {leagueID != 0 && typeID != 0 ? (
                <div style={{ position: "absolute", left: "76%", top: "11%" }}>
                  <br></br>
                  {props.isModify?("") : (
                    <div style={{ height: "400px", overflowY: "scroll" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>
                              Games are ready in the system for this league and
                              type
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {games &&
                        games.map((row) =>
                          props.IdSport != "TNT" ? (
                            row.GameStat != "B" ? (
                              <TableRow>
                                <TableCell>
                                  {"(" +
                                    row.VisitorNumber +
                                    ")" +
                                    row.VisitorTeam}
                                </TableCell>
                                <TableCell>
                                  {"(" + row.HomeNumber + ") " + row.HomeTeam}
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )
                          ) : row.GameStat != "B" ? (
                            <TableRow>
                              <TableCell>
                                {row.Description + row.VisitorTeam}
                              </TableCell>
                            </TableRow>
                          ) : (
                            ""
                          )
                        )}
                    </Table>
                  </div>
                  )}                  
                </div>
              ) : (
                ""
              )}
              <Divider style={{ color: "rebecapurple" }} />
              <FormGroup row style={{ minWidth: 375 }}>
                <TextField
                  //type="number"
                  label="Parent Game"
                  value={parentGame===0?'':parentGame}                 
                  InputLabelProps={{ shrink: parentGame && true }}
                  multiline={false}
                  onChange={handleChangeParentGame}
                  variant="outlined"
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    setUseFindParent(true);
                    setShowGamesPicker(!showGamesPicker);                    
                  }}
                  startIcon={<SearchIcon />}
                >
                  Find
                </Button>
              </FormGroup>
              {parentGame && (
                <div>
                  <h2>{parentHomeTeamName?parentHomeTeamName:null}</h2>
                </div>
              )}
              <Divider />
              <FormGroup row>
                <TextField
                  //type="number"
                  label="Family Game"
                  InputLabelProps={{ shrink: familyGame && true }}
                  value={familyGame===0?'':familyGame}
                  multiline={false}
                  onChange={handleChangeFamilyGame}
                  variant="outlined"
                />

                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    setUseFindParent(false);
                    setShowGamesPicker(!showGamesPicker);
                  }}
                  startIcon={<SearchIcon />}
                >
                  Find
                </Button>
              </FormGroup>
              {familyGame && (
                <div>
                  <h2>{familyHomeTeamName?familyHomeTeamName:null}</h2>
                </div>
              )}
              <Divider />
              {showGamesPicker && (
                <Games
                  callBackFn={
                    useFindParent ? handleFindParent : handleFindFamily
                  }                  
                ></Games>
              )}
              <FormGroup row>
                <TextField
                  fullWidth
                  type="text"
                  label="Description"
                  value={description}                  
                  multiline={false}
                  rows="4"
                  onChange={handleChangeDescription}
                  variant="outlined"
                />
              </FormGroup>
              <Divider />{" "}
              {idSport == "TNT" ||
              (idSport == "PROP" && !useMatchupTemplates) ? (
                <React.Fragment>
                  {" "}
                  {idSport == "TNT" ? (
                    <FormGroup row>
                      <Table>
                        <TableRow>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              type="number"
                              label="Contenders #"
                              value={contender}
                              multiline={false}
                              onChange={handleChangeContender}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              type="text"
                              label="Name"
                              value={name}
                              multiline={false}
                              onChange={handleChangeName}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              type="number"
                              label="Odds"
                              value={odds}
                              multiline={false}
                              onChange={handleChangeOdds}
                            />
                          </TableCell>
                          <TableCell>
                            <Button color="inherit" onClick={handleArray}>
                              Add
                            </Button>
                          </TableCell>
                        </TableRow>
                      </Table>
                      <Table>
                        <TableRow>
                          <BootstrapTable
                            data={array}
                            selectRow={selectRowProp}
                            options={options}
                          >
                            <TableHeaderColumn isKey={true} dataField="Id">
                              ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="TeamName">
                              Name
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="Odds">
                              Odds
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </TableRow>
                      </Table>
                    </FormGroup>
                  ) : (
                    <FormGroup row>
                      <Table>
                        <TableRow>
                          <TableCell colSpan={4}>
                          <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                              <Teams 
                                callBackFn={handleChangeVisitorTeam}
                                teamName={visitorTeam}                        
                                isModify={props.isModify}
                                idSport={props.IdSport?props.IdSport:idSport}
                                Key={props.IdSport?props.IdSport:idSport}
                                filterByTeamName={false} 
                                label={"Type"}    
                                doesTeamExist= {teamNames.length > 0 ? !doesTeamExist(visitorTeam):false}                         
                              ></Teams>
                              <Teams 
                                callBackFn={handleChangeHomeTeam}
                                teamName={homeTeam}                        
                                isModify={props.isModify}
                                idSport={props.IdSport?props.IdSport:idSport}
                                Key={props.IdSport?props.IdSport:idSport}
                                filterByTeamName={false} 
                                label={"Team Name"}     
                                doesTeamExist= {teamNames.length > 0 ? !doesTeamExist(homeTeam):false}                        
                              ></Teams>
                            </div>

                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              checked={useMatchupTemplates}
                              color="primary"
                            />
                            Insert with Template
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              type="number"
                              label="Number"
                              value={visitorNumber}
                              multiline={true}
                              onChange={handleChangeNumber}
                            />
                          </TableCell>
                          {/* <TableCell>
                            <TextField
                              variant="outlined"
                              type="text"
                              label="Type"
                              style={{ textTransform: "uppercase" }}
                              multiline={true}
                              onChange={handleChangeType}
                              value={visitorTeam}
                            />
                            {console.log(
                              teamNames &&
                                teamNames.length > 0 &&
                                console.log(
                                  "teamNames.length",
                                  teamNames.length
                                )
                            )}
                            {teamNames &&
                              teamNames.length > 0 &&
                              (props.IdSport != "MU" ||
                                props.IdSport != "SOC") && (
                                <Autocomplete
                                  key={reset}
                                  options={teamNames}
                                  getOptionLabel={(option) => option}
                                  onChange={handleChangeVisitorTeamSelect}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label="Select Description"
                                      placeholder="Description"
                                      margin="normal"
                                      fullWidth
                                    />
                                  )}
                                />
                              )}
                            {(props.IdSport == "MU" ||
                              props.IdSport == "SOC") && (
                              <TextField
                                variant="outlined"
                                type="text"
                                style={{ textTransform: "uppercase" }}
                                label="Visitor Team"
                                value={visitorTeam}
                                multiline={true}
                                onChange={handleChangeVisitorTeam}
                              />
                            )}
                          </TableCell> */}
                          <TableCell>
                            {/* {teamNames &&
                              teamNames.length > 0 &&
                              (props.IdSport != "MU" ||
                                props.IdSport != "SOC") && (
                                <Autocomplete
                                  key={reset}
                                  options={teamNames}
                                  getOptionLabel={(option) => option}
                                  onChange={handleChangeHomeTeamSelect}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label="Select Team"
                                      placeholder="Select Team"
                                      margin="normal"
                                      fullWidth
                                    />
                                  )}
                                />
                              )} */}
                            {(props.IdSport == "MU" ||
                              props.IdSport == "SOC") && (
                              <TextField
                                variant="outlined"
                                type="text"
                                style={{ textTransform: "uppercase" }}
                                label="Home Team"
                                value={homeTeam}
                                multiline={true}
                                onChange={handleChangeHomeTeam}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              type="number"
                              label="Odds"
                              value={odds}
                              multiline={true}
                              onChange={handleChangeOdds}
                            />
                          </TableCell>
                        </TableRow>
                      </Table>
                    </FormGroup>
                  )}{" "}
                </React.Fragment>
              ) : useMatchupTemplates ? (
                <React.Fragment>
                  {useMatchupTemplates && getTemplateAppliedContent()}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <FormGroup row>
                    <TextField
                      type="number"
                      label="Visitor Team #"
                      value={visitorNumber}
                      multiline={true}
                      onChange={handleChangeVisitorNumber}
                      variant="outlined"
                    />

                    <FormControl className={classes.formControl}>
                    {props.predictiveText ? (
                      <Teams 
                        callBackFn={handleChangeVisitorTeam}
                        teamName={visitorTeam}                        
                        isModify={false}
                        idSport={props.IdSport?props.IdSport:idSport}
                        Key={props.IdSport?props.IdSport:idSport}
                        filterByTeamName={false}
                        label={"Visitor Teams"}
                        doesTeamExist= {teamNames.length > 0 ? !doesTeamExist(visitorTeam):false}
                        inputRef={handleRef}                        
                        //{...true? console.log("idsport",idSport):null}
                      ></Teams>
                    ):(
                      <TextField
                        variant="outlined"
                        type="text"
                        label="Visitor Team"
                        style={{ textTransform: "uppercase" }}
                        value={visitorTeam}
                        multiline={true}
                        onChange={handleChangeVisitorTeamNonPT}
                        inputRef={handleRef}
                      />
                    )}
                      
                   
                      
                      {/* {teamNames &&
                        teamNames.length > 0 &&
                        (props.IdSport != "MU" || props.IdSport != "SOC") && (
                          <Autocomplete
                            options={teamNames}
                            getOptionLabel={(option) => option}
                            onChange={handleChangeVisitorTeamSelect}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Select Visitor Team"
                                placeholder="Favorites"
                                margin="normal"
                                fullWidth
                              />
                            )}
                          />
                        )} */}
                    </FormControl>
                  </FormGroup>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      type="number"
                      label="Home Team #"
                      value={homeNumber}
                      multiline={true}
                      onChange={handleChangeHomeNumber}
                    />

                    <FormControl className={classes.formControl}>
                      {props.predictiveText ? (
                        <Teams 
                          callBackFn={handleChangeHomeTeam}
                          teamName={homeTeam}                        
                          isModify={false}
                          idSport={props.IdSport?props.IdSport:idSport}
                          Key={props.IdSport?props.IdSport:idSport}
                          filterByTeamName={false}
                          label={"Home Teams"}
                          ddoesTeamExist= {teamNames.length > 0 ? !doesTeamExist(homeTeam):false} 
                        ></Teams>

                      ):(
                        <TextField
                          variant="outlined"
                          type="text"
                          label="Home Team"
                          style={{ textTransform: "uppercase" }}
                          value={homeTeam}
                          multiline={true}
                          onChange={handleChangeHomeTeamNonPT}
                        />
                      )}


                      {/* {teamNames &&
                        teamNames.length > 0 &&
                        (props.IdSport != "MU" || props.IdSport != "SOC") && (
                          <Autocomplete
                            options={teamNames}
                            getOptionLabel={(option) => option}
                            onChange={handleChangeHomeTeamSelect}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Select Home Team"
                                placeholder="Favorites"
                                margin="normal"
                                fullWidth
                              />
                            )}
                          />
                        )} */}
                    </FormControl>
                  </FormGroup>
                  {idSport === "MLB" || props.IdSport === "MLB" ? (
                    <FormGroup row>
                      <FormControl className={classes.formControl}>
                      {props.isModify?(
                        visitorPitcher != "" ? (                          
                            <PitcherList
                              isInput={true}
                              initialValue={visitorPitcher}
                              setPitcherOnMem={setVisitorPitcherOnMem} 
                              doesPitcherExist= {visitorPitcher !== '' ? !doesPitcherExist(visitorPitcher):false}                         
                            ></PitcherList>                         
                        ) : ('')                         
                      ):(
                        <PitcherList
                          key={visitorPitcher}
                          isInput={true}
                          initialValue={visitorPitcher}
                          setPitcherOnMem={setVisitorPitcherOnMem}
                          doesPitcherExist= {visitorPitcher !== '' ? !doesPitcherExist(visitorPitcher):false}                          
                        ></PitcherList>
                      )}                        
                      </FormControl>
                      
                      <FormControl className={classes.formControl}>
                      {props.isModify?(
                        homePitcher != "" ? (
                          <PitcherList
                          isInput={true}
                          initialValue={homePitcher}
                          setPitcherOnMem={setHomePitcherOnMem}
                          doesPitcherExist= {homePitcher !== '' ? !doesPitcherExist(homePitcher):false}
                          ></PitcherList>
                              ) : ('')                         
                      ):(
                        <PitcherList
                          key={homePitcher}
                          isInput={true}
                          initialValue={homePitcher}
                          setPitcherOnMem={setHomePitcherOnMem}
                          doesPitcherExist= {homePitcher !== '' ? !doesPitcherExist(homePitcher):false}
                        ></PitcherList>
                      )}                        
                      </FormControl>
                    </FormGroup>
                  ):("")}
                  {inserted ? (
                    <FormGroup row>
                      <FormControl className={classes.formControl}>
                        <InputBase
                          style={{
                            color: "red",
                            fontSize: 35,
                            fontWeight: "bold",
                          }}
                          className={classes.margin}
                          defaultValue="Done!"
                          inputProps={{ "aria-label": "naked" }}
                          readOnly
                        />
                      </FormControl>
                    </FormGroup>
                  ) : (
                    ""
                  )}{" "}
                </React.Fragment>
              )}{" "}
            </form>
          </React.Fragment>
        )}

        <React.Fragment>
          <DialogActions>
            {isLoading ? (
              <Loader height={100} width={100}></Loader>
            ) : (
              <div>
                <Button
                  color="inherit"
                  startIcon={<CancelIcon />}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<SaveIcon />}
                  color="inherit"
                  onClick={handleOk}
                >
                  Ok
                </Button>
              </div>
            )}
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  );
}
