import axios from  'axios' ;
import React, { useEffect, useState } from "react";

    export const getPlayerStats = (idPlayer,callback) => {
      let url = process.env.REACT_APP_API_URL + '/PLAYERSTATISTIC?filter=IdPlayer,eq,'+idPlayer;
   
      callback && axios
        .get(url)
        .then((response) => { 
          callback(response.data.records[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    
    export const getEntityById = (id, entity, idName, callback) => {
      let url = process.env.REACT_APP_API_URL + '/'+entity+'?filter='+idName+',eq,'+id;
   
      callback && axios
        .get(url)
        .then((response) => { 
          callback(response.data.records[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    };

   