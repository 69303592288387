import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function PaymentMethods(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [PaymentMethods, setPaymentMethods] = React.useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(2);

  useEffect(() => {
    GetPaymentMethods();
  }, []);

  const GetPaymentMethods = () => {
    console.log('PROPS PaymentMethod ======>', props);
    let url = process.env.REACT_APP_API_URL + '/PAYMENTMETHOD';

  
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log('PROPS======PaymentMethod REST THEN>', response.data.records);
      setPaymentMethods(response.data.records);
    });
  };

  const handleMethodChange =(event)=>{
    props.setIdMethodMem(event.target.value)
    setSelectedPaymentMethod(event.target.value)
  }

  const selectRowProp = {
    mode: 'checkbox',
  };

  
    return (
      <React.Fragment>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            PaymentMethod
          </InputLabel>
          {console.log('in PaymentMethod RENDERING SELeCT')}
          {PaymentMethods && (
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={selectedPaymentMethod}
              onChange={handleMethodChange}
              displayEmpty
              className={classes.selectEmpty}
            >

              {PaymentMethods.map((PaymentMethod) => (
                <MenuItem
                  /*key={PaymentMethod.NumberOfPaymentMethod}*/ value={PaymentMethod.IdPaymentMethod}
                >
                  {PaymentMethod.PaymentMethod}
                </MenuItem>
              ))}
            </Select>
          )}

          <FormHelperText>Select PaymentMethod</FormHelperText>
        </FormControl>
      </React.Fragment>
    );
  
}
