import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import InputSelect from '../../common/InputSelect';
/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './ChangesByGame.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FinalStatsByAgent(props) {
  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  const classes = useStyles();
  const [stats, setStats] = React.useState([]);
  const [groupingID, setGroupingID] = useState(-1);
  const [grouping, setGrouping] = useState();
  const [lineTypeID, setLineTypeID] = useState(-1);
  const [lineType, setLineType] = useState();
  const [officeID, setOfficeID] = useState(-1);
  const [office, setOffice] = useState();
  const [agentFrom, setAgentFrom] = useState('');
  const [agentTo, setAgentTo] = useState('');
  const [distributor, setDistributor] = useState('');
  const [adjType, setAdjType] = useState(0);
  const [filterBy, setFilterBy] = useState(0);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [bookID, setBookID] = useState(-1);
  const [playerType, setPlayerType] = useState(0);
  const [filterByDate, setFilterByDate] = useState(0)
  const [orderBy, setOrderBy] = useState(0);
  const [startDate, setStartDate] = React.useState(week[0]);
  const [endDate, setEndDate] = React.useState(week[0]);
  const [book, setBooks] = useState();
  const [profileLimits, setProfileLimits] = useState();
  const [profileLimitId, setProfileLimitId] = useState(-1);
  const [profiles, setProfiles] = useState();
  const [profileId, setProfileId] = useState(-1);
  const [groupByAgent, setGroupByAgent] = useState(false);

  
  const getBooks = () => {
    const url = process.env.REACT_APP_API_URL + '/BOOK';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let bookInfo = data.records.map((v) => {
          v.IdLineType = v.Description;
          v.Description = v.Description;
          return v;
        });
        setBooks(bookInfo);
      });
  }
  const getOffice = () => {
    const url = process.env.REACT_APP_API_URL + '/OFFICE';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let officeInfo = data.records.map((v) => {
          v.IdLineType = v.OfficeName;
          v.Description = v.OfficeName;
          return v;
        });
        setOffice(officeInfo);
      });
  }
  const getGroups = () => {
    const url = process.env.REACT_APP_API_URL + '/GROUPING';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let officeInfo = data.records.map((v) => {
          v.IdLineType = v.GroupName;
          v.Description = v.GroupName;
          return v;
        });
        setGrouping(officeInfo);
      });
  }
  const getPlayerProfiles = () => {
    const url =
      process.env.REACT_APP_API_URL + '/PLAYERPROFILE'

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let profileInfo = data.records.map((v) => {
          v.IdLineType = v.Description;
          v.Description = v.Description;
          return v;
        });
          setProfiles(profileInfo);
      });
  }
  const getLineTypes = () => {
    const url = process.env.REACT_APP_API_URL + '/LINETYPE';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let lineTypeInfo = data.records.map((v) => {
          v.IdLineType = v.Description;
          v.Description = v.Description;
          return v;
        });
        setLineType(lineTypeInfo);
      });
  }
  const getProfileLimits = () => {
    const url =
      process.env.REACT_APP_API_URL +
      "/PLAYERPROFILELIMITS?order=IdProfileLimits";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let limitsInfo = data.records.map((v) => {
          v.value = v.IdProfileLimits;
          v.text = v.ProfileName;
          return v;
        });
        setProfileLimits(limitsInfo);
      });
  }

  useEffect(() => {  
    const fetchData = async () => {        
      await Promise.all([
        getBooks(),
        getOffice(),
        getGroups(),
        getPlayerProfiles(),
        getLineTypes(),
        getProfileLimits(),
      ]);   
         
    };
    fetchData();
  }, []);
  
  useEffect(() => {  
    if(
        profileLimits && profileLimits.length > 0 &&
        grouping && grouping.length > 0 &&
        book && book.length > 0 &&
        profiles && profiles.length > 0 &&
        office && office.length > 0 &&
        lineType && lineType.length > 0 
      )
      setIsLoading(false);
  }, [profiles]);



  function formatDate(inputDate) {
    // Split the input date string into year, month, and day
    const [year, month, day] = inputDate.split('-');

    // Construct the formatted date string in "mm-dd-yyyy" format
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
}

  const getAdjustments = () => {
    setIsLoading(true);
    let request = {
      idUser: userInfo.UserId,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      book: bookID !== -1 ? bookID : '',      
      group: groupingID !== -1 ? groupingID : '',
      office: officeID !== -1 ? officeID : '',
      lineType: lineTypeID !== -1 ? lineTypeID : '',
      profile: profileId !== -1 ? profileId : '',
      profileLimits: profileLimitId !== -1 ? profileLimitId : '',
      agentFrom: agentFrom,
      agentTo: agentTo,     
      distributor: distributor,
      adjType: adjType, 
      orderBy: orderBy, 
      filterBy: filterByDate, 
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/Report_Adjustment.php',
      data: request,
    }).then((response) => response.data)
    .then((data) => {
      console.log('response', data.data);
      setStats(data.data);
      setIsLoading(false);
    })
  };

  const handleChangeStartDate = (event) => {   
    setStartDate(event.target.value);
  };
  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };
  const handleChangeAgentFrom = (event) => {
    setAgentFrom(event.target.value);
  };
  const handleChangeAgentTo = (event) => {
    setAgentTo(event.target.value);
  };
  const handleChangeDistributor = (event) => {
    setDistributor(event.target.value);
  };
  const handlePlaceDateChange = (event) => {
    setFilterByDate(event.target.value);
  };

  const handleTypeChange = (event) => {
    setAdjType(event.target.value);
  };

  
  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
  };


  const handleChangeGroupByAgent = () => {
    setGroupByAgent(!groupByAgent);
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/

  function changeBookID(value) {
    setBookID(value);
  }

  function changeProfileLimitId(value) {
    setProfileLimitId(value);
  }
  function changeProfileId(value) {
    setProfileId(value);
  }

  function changeLineTypeID(value) {
    setLineTypeID(value);
  }

  function changeGroupingID(value) {
    setGroupingID(value);
  }

  function changeOfficeID(value) {
    setOfficeID(value);
  }

  const dateFormatter = (cell) => {
    let [datePart, timePart] = cell.date.split(' ');
    return datePart.split(' ')[0]
  }

  const amountFormatter = (cell) => {
    return Math.round(cell);
  }

  function rowClassNameFormat(row, rowIdx) {
    return rowIdx % 2 === 0
      ? 'tr-function-example'
      : 'td-column-function-odd-example';
  }
  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <div>
          <form className={classes.container} noValidate>
            <Table>
              <TableRow>
                <TableCell>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    defaultValue={startDate}
                    className={classes.textField}
                    onChange={handleChangeStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    defaultValue={endDate}
                    className={classes.textField}
                    onChange={handleChangeEndDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={book}
                    placeholder="Book"
                    setSelectValue={changeBookID}
                    startValue={bookID}
                  />
                </TableCell>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={grouping}
                    placeholder="Group"
                    setSelectValue={changeGroupingID}
                    startValue={groupingID}
                  />                  
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={office}
                    placeholder="Office"
                    setSelectValue={changeOfficeID}
                    startValue={officeID}
                  />
                </TableCell>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={lineType}
                    placeholder="LineType"
                    setSelectValue={changeLineTypeID}
                    startValue={lineTypeID}
                  />                  
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={profiles}
                    placeholder="Id Profile"
                    onChange={changeProfileId}
                    startValue={profileId}
                  />
                </TableCell>
                <TableCell>                  
                <InputSelect
                    variant="outlined"
                    name="name"
                    data={profileLimits}
                    placeholder="Id Profile Limits"
                    setSelectValue={changeProfileLimitId}
                    startValue={profileLimitId}
                  />  
                </TableCell>      
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextField
                    type="text"
                    label="Agent From"
                    value={agentFrom}
                    multiline={true}
                    onChange={handleChangeAgentFrom}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    label="Agent To"
                    value={agentTo}
                    multiline={true}
                    onChange={handleChangeAgentTo}
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextField
                    type="text"
                    label="Distributor"
                    value={distributor}
                    multiline={true}
                    onChange={handleChangeDistributor}
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <h6>Filter By</h6>
                  <RadioGroup row>
                    <FormControlLabel
                      // value="None"
                      control={
                        <Radio
                          checked={filterByDate == '0'}
                          onChange={handlePlaceDateChange}
                          value="0"
                          label="Place Date"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'None' }}
                        />
                      }
                      label="Placed Date"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={filterByDate == '1'}
                          onChange={handlePlaceDateChange}
                          value="1"
                          label="Cash"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Cash"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <h6>Type</h6>
                  <RadioGroup row>
                    <FormControlLabel
                      // value="None"
                      control={
                        <Radio
                          checked={adjType == '0'}
                          onChange={handleTypeChange}
                          value="0"
                          label="All"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'None' }}
                          //disabled
                        />
                      }
                      label="All"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={adjType == '1'}
                          onChange={handleTypeChange}
                          value="1"
                          label="Adjustment"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Adjustment"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={adjType == '2'}
                          onChange={handleTypeChange}
                          value="2"
                          label="Accrual"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Accrual"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={adjType == '3'}
                          onChange={handleTypeChange}
                          value="3"
                          label="Horses"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Horses"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                  
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <h6>Order By</h6>
                  <RadioGroup row>
                    <FormControlLabel
                      // value="None"
                      control={
                        <Radio
                          checked={orderBy == '0'}
                          onChange={handleOrderByChange}
                          value="0"
                          label="Account"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'None' }}
                          //disabled
                        />
                      }
                      label="Account"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      // value="None"
                      control={
                        <Radio
                          checked={orderBy == '1'}
                          onChange={handleOrderByChange}
                          value="1"
                          label="Place Date"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'None' }}
                        />
                      }
                      label="Placed Date"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={orderBy == '2'}
                          onChange={handleOrderByChange}
                          value="2"
                          label="Settled Date"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Settled Date"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button color="inherit" onClick={getAdjustments}>
                    Show
                  </Button>
                </TableCell>
              </TableRow>
            </Table>
          </form>
          <BootstrapTable
            data={stats}
            trClassName={rowClassNameFormat}
            exportCSV
            pagination
          >
            <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
              IdGame
            </TableHeaderColumn>
            <TableHeaderColumn width='80px' dataField="Type">P/A</TableHeaderColumn>
            <TableHeaderColumn dataField="Account">Account</TableHeaderColumn>
            <TableHeaderColumn dataField="PlacedDate" dataFormat={dateFormatter}>Date</TableHeaderColumn>
            <TableHeaderColumn width='120px' dataField="Reference">Reference</TableHeaderColumn>
            <TableHeaderColumn width='90px' dataField="TransactionType">Type</TableHeaderColumn>
            <TableHeaderColumn width='550px' dataField="Description">Description</TableHeaderColumn>
            <TableHeaderColumn width='120px' dataField="LoginName">User</TableHeaderColumn>
            <TableHeaderColumn dataField="PlacedDate" dataFormat={dateFormatter}>PlacedDate</TableHeaderColumn>
            <TableHeaderColumn dataField="Amount" dataFormat={amountFormatter}>Amount</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    </React.Fragment>
  );
}
