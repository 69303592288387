import React, { useState, setState, useEffect, useContext } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

export default function WagerTypes(props) {
  //const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },
    center: {
      margin: "0 auto",
      border: "1px grey black",
      textAlign: "center",
      width: "75%",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 200,
      },
      "& .MuiFormGroup-row": {
        justifyContent: "center",
      },
      "& .MuiFormControl-root": {
        justifyContent: "center",
      },
    },
  }));
  const classes = useStyles();
  const [wagerTypes, setWagerTypes] = React.useState([]);
  const [selectedWagerType, setSelectedWagerType] = React.useState(["1"]);

  useEffect(() => {
    // Change for server api address
    console.log("PROPS======>", props);
    console.log("PROPS======>", selectedWagerType);
    const url = process.env.REACT_APP_API_URL + "/WAGERTYPE";
    axios({
      method: "get",
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log("PROPS====== REST THEN>", response.data.records);
      setWagerTypes(response.data.records);
    });
  }, []);
  const handleChange = (event) => {
    props.setIdWagerTypeOnMem(event.target.value);
    setSelectedWagerType(event.target.value);
  };
  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          wagerType
        </InputLabel>

        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          //value={wagerTypes[0] && wagerTypes[0]['IdwagerType']}
          value={selectedWagerType}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
        >
          <MenuItem value={"1"}>Parlay</MenuItem>
          <MenuItem value={"2"}>Teaser</MenuItem>
        </Select>

        <FormHelperText>Select wagerType</FormHelperText>
      </FormControl>
    </React.Fragment>
  );
}
