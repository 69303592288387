import React, { useState, useEffect, useContext } from 'react';
import { forwardRef,} from 'react';
import { useHistory } from 'react-router-dom';
import LastChanges from './playerComponents/LastChanges';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@mui/material/Button';
import { UserContext } from '../../contexts/UserContext';
import PlayerInfo from './playerComponents/PlayerInfo';
import PlayerTabs from './playerComponents/PlayerTabs';
import PlayerStatistics from './playerComponents/PlayerStatistics';

import './Player.css';

import { getPlayerStats } from './../../tools/index';

const Player = forwardRef((props) => {
  const user = useContext(UserContext);
  const [player, setPlayer] = React.useState(0);
  const [playerStats, setPlayerStats] = React.useState();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [statsNeedsReload, setStatsNeedsReload] = useState(false);
  const setStatisticsToReload = () => {
    console.log('getStatistics setStatisticsToReload');
    setTimeout(() => {
      statsNeedsReload ? setStatsNeedsReload(false) : setStatsNeedsReload(true);
    }, 2500);
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      useState,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [isLastChanges, setIsLastChanges] = useState(false);
  

  const toggleDrawer = () => {   
    setIsOpen(!isOpen);
  };
  
  const toggleLastCHanges = () => {   
    setIsLastChanges(!isLastChanges);
  };

  const handleResetChanges = () => {
    if(window.confirm("Are you sure you want to reset the Player Statistics")){
      resetChanges();
    }
  }

  const resetChanges = () => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/PlayerResetStats.php',
      data: {
        idPlayer : player.IdPlayer,
        idUser : userInfo.UserId,
        outResult : 0
      },
    }).then((response) => {
      printMessage(response.data.data)
    });
  };

  const printMessage = (response) => {
    switch (response){
      case 1:
        alert("Can't reset Player Statistics, Player has open wagers.");
      break;
      case 2:
        alert("Can't reset Player Statistics, Player has available free amount.");
      break;
      case 3:
        alert("Can't reset Player Statistics, Player balance must be zero.");
      break;
      default:
        alert("Player Statistics have been reset");
    }
  }

  const classes = useStyles();
  let history = useHistory();

  useEffect(() => {

    getPlayerStats(props.match.params.id, setPlayerStats);

    const url =
      process.env.REACT_APP_API_URL +
      '/PLAYER?filter=IdPlayer,eq,' +
      props.match.params.id;
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setPlayer(data.records[0]);
      });
  }, []);

  function Updateplayer(row) {
    let RequestDTO = {
      IdPlayer: row.IdPlayer,
      IdLineType: row.IdLineType,
      IdOffice: row.IdOffice,
      IdAgent: row.IdAgent,
      IdCurrency: row.IdCurrency,
      IdGrouping: row.IdGrouping,
      IdSource: row.IdSource,
      IdProfile: row.IdProfile,
      IdProfileLimits: row.IdProfileLimits,
      IdPlayerRate: row.IdPlayerRate,
      IdBook: row.IdBook,
      IdTimeZone: row.IdTimeZone,
      IdLanguage: row.IdLanguage,
      ScheduleStyle: row.ScheduleStyle,
      Player1: row.Player,
      Password: row.Password,
      Name: row.Name,
      LastName: row.LastName,
      LastName2: row.LastName2,
      Address1: row.Address1,
      Address2: row.Address2,
      City: row.City,
      State: row.State,
      Country: row.Country,
      Zip: row.Zip,
      Phone: row.Phone,
      Fax: row.Fax,
      Email: row.Email,
      Status: row.Status,
      AutoPay: row.AutoPay,
      BalanceChecked: row.BalanceChecked,
      CreditLimit: row.CreditLimit,
      TempCredit: row.TempCredit,
      SoftLimitPercent: row.SoftLimitPercent,
      TempCreditExpire: row.TempCreditExpire,
      OnlineAccess: row.OnlineAccess,
      OnlinePassword: row.OnlinePassword,
      OnlineMessage: row.OnlineMessage,
      OnlineMaxWager: row.OnlineMaxWager,
      OnlineMinWager: row.OnlineMinWager,
      MaxWager: row.MaxWager,
      MinWager: row.MinWager,
      ChartPercent: row.ChartPercent,
      MasterChart: row.MasterChart,
      Master: row.Master,
      IdFlagMessageType: row.IdFlagMessageType,
      MaxActionPoints: row.MaxActionPoints,
      BonusPointsStatus: row.BonusPointsStatus,
      BonusPointsExpire: row.BonusPointsExpire,
      BonusPointsStart: row.BonusPointsStart,
      LineStyle: row.LineStyle,
      NHLLine: row.NHLLine,
      MLBLine: row.MLBLine,
      PitcherDefault: row.PitcherDefault,
      DuplicatedBets: row.DuplicatedBets,
      DuplicatedBetsOnline: row.DuplicatedBetsOnline,
      ScheduleFB: row.ScheduleFB,
      ScheduleBB: row.ScheduleBB,
      ScheduleHK: row.ScheduleHK,
      ScheduleBS: row.ScheduleBS,
      SettledFigure: row.SettledFigure,
      ShowInTicker: row.ShowInTicker,
      EPOSPlayer: row.EPOSPlayer,
      EnableSports: row.EnableSports,
      EnableCasino: row.EnableCasino,
      EnableHorses: row.EnableHorses,
      DateOfBirth: row.DateOfBirth,
      SecQuestion: row.SecQuestion,
      SecAnswer: row.SecAnswer,
      SignUpIP: '192.168.3.20',
      HoldBets: row.HoldBets,
      HoldDelay: row.HoldDelay,
      IdAffiliate: row.IdAffiliate,
      AllowNegTrans: row.AllowNegTrans,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/player/player_Update?IdUser=' +
        userInfo.UserId,
      //url: 'https://localhost:44337/api/player/player_Update?IdUser=' + userInfo.UserId,
      data: RequestDTO,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
        setStatisticsToReload();
      }
      console.log('response', response);
    });
  }

  const setCurrentplayer = (player) => {
    console.log('in player props1', props);
    console.log('in player props2', player);
    setPlayer(player);
    let anothervar = player.Idplayer.toString();
    //let anothervar = 'player.Idplayer';
    history.push(anothervar);
    console.log('in player props after anothervar', anothervar);
  };

  return (
    <React.Fragment>
      {
        <div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="players-right-div">
                  <div className="players-right-div">
                    <Paper className={classes.root}>
                      <div
                        id="inputs"
                        style={{ display: 'flex', maxHeight: 45 }}
                      >
                        {true ? (
                          <PlayerInfo
                            Player={player}
                            Updateplayer={Updateplayer}
                          ></PlayerInfo>
                        ) : (
                          <PlayerInfo
                            Player={player}
                            Updateplayer={Updateplayer}
                          ></PlayerInfo>
                        )}
                      </div>
                      <div style={{justifyContent:'space-between',display:'flex', padding:'2px', justifyContent:'right'}}> 
                        <Button 
                          onClick={toggleDrawer}
                          sx={{marginRight:'1px'}}
                          variant='contained'
                        >
                          See Player Stats
                        </Button>
                        <Button 
                          onClick={toggleLastCHanges}
                          sx={{marginRight:'1px'}}
                          color='primary'
                          variant='contained'
                        >
                          Last Changes
                        </Button>
                        <Button 
                          onClick={handleResetChanges}
                          color='primary'
                          variant='contained'
                        >
                          Reset Changes
                        </Button>
                      </div>
                      
                      
                      <PlayerTabs
                        AgentInfo={props.location}
                        Player={player}
                        Updateplayer={Updateplayer}
                        showLoading={props.showLoading}
                        setStatisticsToReload={setStatisticsToReload}
                      ></PlayerTabs>                      
                    </Paper>
                  </div>
                </TableCell>
                <TableCell className="cell-top-content">
                  {player !== 0 && (
                    <PlayerStatistics
                      Player={player}
                      needsToReload={statsNeedsReload}
                      toggleDrawer={toggleDrawer}
                      isOpen={isOpen}
                    >
                      {' '}
                    </PlayerStatistics>
                  )}{' '}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      }
      {
        isLastChanges?(
          <LastChanges onClose={toggleLastCHanges} player={player} open={isLastChanges}></LastChanges>
        ):(
          ""
        )
      }
    </React.Fragment>
  );
});

export default Player;
