import React, {
  useState,
  setState,
  useEffect,
  useContext,
  createContext,
  Component
} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputSelect from '../components/common/InputSelect';

let user;
class DatabaseProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuth: false,
      token: '',
      user: null,
      decoded: null,
      contact: null,
      LineTypes: null,
      readOnlyMode: false,
      showLoading: false,
      loadingFouser: null
    };
    const [LineTypes, setLineTypes] = useState();
    this.setState({ contact: 'Contacto' });

    //Change for server api address
    console.log('corrio context');
    let varName = 'LineTypes';
    user[varName] = 'LineTypes Object';
    varName = 'Msg';
    user[varName] = 'Mensaje';
    return user;
  }
}

export const UserContext = createContext(user);
