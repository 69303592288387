import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


export default function AdjustmentTypes(props) {
  //const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  const [AdjustmentTypes, setAdjustmentTypes] = React.useState([]);
  const [selectedAdjustmentType, setSelectedAdjustmentType] = React.useState(1);
  useEffect(() => {
    // Change for server api address
    GetAdjustmentTypes();
    //GetSports();
  }, []);

  const GetAdjustmentTypes = () => {
    console.log('PROPS AdjustmentType ======>', props);
    let url = process.env.REACT_APP_API_URL + '/ADJUSTMENTTYPE';

  
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log('PROPS======AdjustmentType REST THEN>', response.data.records);
      setAdjustmentTypes(response.data.records);
    });
  };


  /*Select Functions*/

  const handleMethodChange =(event)=>{

    console.log('event',event);
    props.setIdAdjTypeMem(event.target.value)
    setSelectedAdjustmentType(event.target.value)
  }

  const selectRowProp = {
    mode: 'checkbox',
  };
  /*End Select Functions*/

  
    return (
      <React.Fragment>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            AdjustmentType
          </InputLabel>
          {console.log('in AdjustmentType RENDERING SELeCT')}
          {AdjustmentTypes && (
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={selectedAdjustmentType}
              onChange={handleMethodChange}
              displayEmpty
              className={classes.selectEmpty}
            >
              {/*

    <MenuItem value={10}>Ten</MenuItem>
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem>
    */}

              {AdjustmentTypes.map((AdjustmentType) => (
                <MenuItem
                  /*key={AdjustmentType.NumberOfAdjustmentType}*/ value={AdjustmentType.IdAdjustmentType}
                >
                  {AdjustmentType.AdjustmentType}
                </MenuItem>
              ))}
            </Select>
          )}

          <FormHelperText>Select AdjustmentType</FormHelperText>
        </FormControl>
      </React.Fragment>
    );
  
}
