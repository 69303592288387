import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

export default function PlayerNotes(props) {
  // const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  console.log('PROPS====== PlayerNotes url props', props);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );

  const [playerNotes, setPlayerNotes] = React.useState([]);

  useEffect(() => {
    let IdPlayer = props.Player.IdPlayer;

    const url =
      process.env.REACT_APP_API_URL +
      '/PLAYERMESSAGES?filter=IdPlayer,eq,' +
      IdPlayer;
    console.log('PROPS====== PlayerNotes urlGT', url);
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log('PROPS====== PlayerNotes THEN', response.data.records[0]);
      setPlayerNotes(response.data.records);
    });
  }, []);
  console.log('PROPS====== PlayerNotes after useEffect');
  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
    afterInsertRow: InsertPlayerMessage, // A hook for after insert rows
  };

  const onBeforeSaveCell = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){
      done(true);
    }else{
      done(false);
    }  
    return 1
  } 

  const cellEditPlayerNotesProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCellEditPlayerNotes,
    beforeSaveCell: onBeforeSaveCell,
  };

  function onAfterSaveCellEditPlayerNotes(row, cellName, cellValue) {
    UpdateEditPlayerNotes(row);
  }

  function InsertPlayerMessage(row) {
    let request = {
      IdPlayer: props.Player.IdPlayer,
      IdSport: row.IdSport,
      Message: row.Message,
      MessageType: row.MessageType,
      UseExpirationDate: row.UseExpirationDate,
      UseDisplayCounter: row.UseDisplayCounter,
      UseCloseOption: row.UseCloseOption,
      ExpirationDate: row.ExpirationDate,
      DisplayCounter: row.DisplayCounter,
      Closed: row.Closed,
      LastModificationUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Player/PlayerMessages_Insert',
      data: request,
    }).then((response) => {
      console.log('response', response);
    });
  }

  function UpdateEditPlayerNotes(row) {
    let request = {
      IdPlayerMessage: row.IdPlayerMessage,
      IdPlayer: props.Player.IdPlayer,
      IdSport: row.IdSport,
      Message: row.Message,
      MessageType: row.MessageType,
      UseExpirationDate: row.UseExpirationDate,
      UseDisplayCounter: row.UseDisplayCounter,
      UseCloseOption: row.UseCloseOption,
      ExpirationDate: row.ExpirationDate,
      DisplayCounter: row.DisplayCounter,
      LastModificationUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Player/PlayerMessages_Update',
      data: request,
    }).then((response) => {
      console.log('response', response);
    });
  }
  const selectRowProp = {
    mode: 'checkbox',

    //clickToSelect: false, // enable click to select

    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    //onSelect: onRowSelect,
  };
  return (
    <React.Fragment>
      <Table>
        <TableRow>
          <TableCell>
            <p>
              <b>Free Play Options</b>
            </p>

            <b>NFL</b>
            <BootstrapTable
              data={playerNotes}
              cellEdit={cellEditPlayerNotesProp}
              options={options}
              insertRow={true}
              selectRow={selectRowProp}
              deleteRow={true}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                hiddenOnInsert
                dataField="IdPlayerMessage"
              >
                IdPlayerMessage
              </TableHeaderColumn>
              <TableHeaderColumn dataField="Message">Message</TableHeaderColumn>
              <TableHeaderColumn
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
                dataField="MessageType"
              >
                Message Type
              </TableHeaderColumn>
              <TableHeaderColumn
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
                dataField="UseExpirationDate"
              >
                Use Expiration Date
              </TableHeaderColumn>
              <TableHeaderColumn
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
                dataField="UseDisplayCounter"
              >
                Use Display Counter
              </TableHeaderColumn>
              <TableHeaderColumn
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
                dataField="UseCloseOption"
              >
                Use Close Caption
              </TableHeaderColumn>
              <TableHeaderColumn dataField="ExpirationDate">
                Expiration Date
              </TableHeaderColumn>
              <TableHeaderColumn dataField="DisplayCounter">
                Display Counter
              </TableHeaderColumn>
              <TableHeaderColumn dataField="Closed">Closed</TableHeaderColumn>

              <TableHeaderColumn dataField="LastModification">
                Last Modification
              </TableHeaderColumn>
            </BootstrapTable>
          </TableCell>
        </TableRow>
      </Table>
    </React.Fragment>
  );
}
