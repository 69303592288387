import React, { useState, setState, useEffect, useContext } from 'react';
import axios, { Axios } from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { UserContext } from '../contexts/UserContext';
import { DelayInput } from 'react-delay-input';
import InputSelect from '../components/common/InputSelect';
import PitcherList from '../components/common/PitcherList';
import LinesManager from '../containers/LinesManager';
import SchedulePS from '../components/common/SchedulePS';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Skeleton from '@material-ui/lab/Skeleton'; 
import PitcherChangeConfirm from './games/gameComponents/PitcherChangeConfirm';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import Divider from '@material-ui/core/Divider';
import Loader from '../components/common/loader/Loader';
import { Grid, TableRow } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getTeamNamesBySport } from './common/apiHelper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { PublicTwoTone } from '@material-ui/icons';
import Leagues from '../components/common/Leagues';
import Teams from "./teams/Teams";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import { getChipUtilityClass } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

export default function GameModify(props) {
  const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        //width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  const [games, setGames] = React.useState();
  const [flag, setFlag] = useState(false);
  const [tntName, setTntName] = useState(' ');
  const [tntOdds, setTntOdds] = useState(' ');
  const [tntNumber, setTntNumber] = useState(' ');
  const [idAction, setIdAction] = useState(0);
  const [pitcherSide, setPitcherSide] = useState('');
  const [open, setOpen] = React.useState(true);
  const [pitchers, setPitchers] = React.useState([]);
  const [idSport, setIdSport] = React.useState('');
  const [idLeague, setIdLeague] = React.useState(0);
  const [visitorScore, setVisitorScore] = React.useState(0);
  const [homeScore, setHomeScore] = React.useState(0);
  const [visitorPitcher, setVisitorPitcher] = React.useState('');
  const [visitorPitcherOld, setVisitorPitcherOld] = React.useState('');
  const [homePitcherOld, setHomePitcherOld] = React.useState('');
  const [homePitcher, setHomePitcher] = React.useState('');
  const [gameStat, setGameStat] = React.useState('');
  const [graded, setGraded] = React.useState(false);
  const [gameType, setGameType] = React.useState(0);
  const [hookups, setHookUps] = React.useState(false);
  const [local, setLocal] = React.useState(false);
  const [online, setOnline] = React.useState(false);
  const [shortGame, setShortGame] = React.useState(false);
  const [eventDate, setEventDate] = React.useState('');
  const [dateChanged, setDateChanged] = React.useState(false);
  const [timeChanged, setTimeChanged] = React.useState(false);
  const [pitcherChanged, setPitcherChanged] = React.useState(0);
  const [period, setPeriod] = React.useState(0);
  const [selectedContender, setSelectedContender] = React.useState();
  const [gradedDate, setGradedDate] = React.useState('');
  const [numTeams, setNumTeams] = React.useState(0);
  const [idEvent, setIdEvent] = React.useState(0);
  const [hasChildren, setHasChildren] = React.useState(false);
  const [idTeamVisitor, setIdTeamVisitor] = React.useState(0);
  const [idTeamHome, setIdTeamHome] = React.useState(0);
  const [idBannerType, setIdBannerType] = React.useState(0);
  const [gameDateTime, setGameDateTime] = React.useState('');
  const [oldGameDateTime, setOldGameDateTime] = useState('')
  const [league, setLeague] = React.useState([]);
  const [leagueID, setLeagueID] = React.useState(0);
  const [typeID, setTypeID] = React.useState(0);
  const [type, setType] = React.useState([]);
  const [familyGame, setFamilyGame] = React.useState(0);
  const [parentGame, setParentGame] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [visitorNumber, setVisitorNumber] = React.useState(0);
  const [visitorTeam, setVisitorTeam] = React.useState('');
  const [homeNumber, setHomeNumber] = React.useState(0);
  const [homeTeam, setHomeTeam] = React.useState('');
  const [visitorSpread, setVisitorSpread] = React.useState(0);
  const [visitorSpreadOdds, setVisitorSpreadOdds] = React.useState(0);
  const [overTotal, setOverTotal] = React.useState(0);
  const [overOdds, setOverOdds] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [TNTContenders, setTNTContenders] = useState(false);
  const [gameData, setGameData] = useState();
  const [visitorMoneyline, setVisitorMoneyline] = React.useState(0);
  const [visitorMoneyLineOld, setVisitorMoneyLineOld] = React.useState(0);
  const [newMoneyline, setNewMoneyline] = React.useState(0);
  const [homeSpread, setHomeSpread] = React.useState(0);
  const [homeSpreadOdds, setHomeSpreadOdds] = React.useState(0);
  const [underTotal, setUnderTotal] = React.useState(0);
  const [underOdds, setUnderOdds] = React.useState(0);
  const [homeMoneyline, setHomeMoneyline] = React.useState(0);
  const [homeMoneyLineOld,setHomeMoneyLineOld] = React.useState(0);
  const [idGame, setIdGame] = React.useState(0);
  const [normalGame, setNormalGame] = React.useState(0);
  const [idGameType, setIdGameType] = React.useState(0);
  const [teamNames, setTeamNames] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [acceptAutoChanges, setAcceptAutoChanges] = React.useState(false);
  const [isChange, setIsChange] = React.useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );

  const [lineTypes,setLineTypes] = React.useState([]);
  const [childrenPeriods, setChildrenPeriods] = React.useState([])
  const [result, setResult] = React.useState(0);
  const [valueHasChanged, setValueHasChanged] = useState(1);
  const [visitorOdds, setVisitorOdds] = React.useState(null);
  const [homeOdds, setHomeOdds] = React.useState(null);
  const [auxVisitorPitcher,setAuxVisitorPitcher] = React.useState('')
  const [auxPitcherChanged,setAuxPitcherChanged] = React.useState(0)
  const [auxHomePitcher,setAuxHomePitcher] = React.useState('')
  const [isLoader,setLoader] = React.useState(true)
  const [checks, setChecks] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
  });
  const [valueLinearBar, setValueLinearBar] = React.useState(0)
  const [recentUpdatedGame, setRecentUpdatedGame] = useState("");
  

  const handleChangeGameDateTime = (event) => {
    setGameDateTime(event.target.value + ':00');
  };
  const handleChangeFamilyGame = (event) => {
    setFamilyGame(event.target.value);
  };

  const handleChangeParentGame = (event) => {
    setParentGame(event.target.value);
  };
  const handleFindParent = (event) => {
    //TODO
  };  

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangeVisitorNumber = (event) => {
    setVisitorNumber(event.target.value);
  };

  const handleChangeHomeNumber = (event) => {
    setHomeNumber(event.target.value);
  };

  const handleChangeVisitorSpread = (event) => {
    setVisitorSpread(event.target.value);
  };

  const handleChangeVisitorSpreadOdds = (event) => {
    setVisitorSpreadOdds(event.target.value);
  };

  const handleChangeHomeTeamSelect = (event, values) => { 
    setHomeTeam(values);
  };
  
  const handleChangeVisitorTeamSelect = (event, values) => { 
    
    setVisitorTeam(values);
  };
  const handleChangeVisitorTeam = (event,values) => {
    setVisitorTeam(event);
  };

  const handleChangeHomeTeam = (event,values) => {
    setHomeTeam(event);
  };
  const handleChangeOverTotal = (event) => {
    setOverTotal(event.target.value);
  };

  const handleChangeOverOdds = (event) => {
    setOverOdds(event.target.value);
  };

  const updateContender = () => {
    let data = [
      { Id: parseInt(tntNumber), TeamName: tntName, Odds: parseInt(tntOdds) },
    ];
    axios
      .post(
        `${process.env.REACT_APP_API2_URL}/Game/InsertGameValuesTNT?idGame=${props.IdGames[0]}`,
        data
      )
      .then((r) => getContenders());
  };
  const deleteContender = () => {
    console.log();
  };
  const handleChangeVisitorMoneyline = (event) => {
    setValueHasChanged(true);
    setVisitorMoneyline(event.target.value);
    setNewMoneyline(event.target.value);
  };

  const updateML = (
    newValue,
    line //linea
  ) => {
    if (valueHasChanged) {
      let sport = 'MLB';
      let lineType = typeID.value ? typeID.value : typeID;
      let flag = true;
      let z = newValue < 0 ? newValue * -1 : newValue;
      const url =
        process.env.REACT_APP_API_URL +
        '/ODDSBYSPORT?filter=IdLineType,eq,' +
        props.IdLinetype +
        '&filter=IdSport,eq,' +
        sport +
        '&filter=IdGameType,eq,' +
        lineType +
        '&filter=Line,eq,' +
        3 +
        '&filter=Range,ge,' +
        Math.round(z);

      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          //setoddBySport(data.records.odds);
          let moneyLineObject = {
            odds: newValue,
            newValue: newValue,
          };
          let storedOdd = -moneyLineObject.odds;

          if (newValue != '') {
            let oddToLower = 0;
            let found = false;
            data.records.forEach((item, index) => {
              let valCompare = parseInt(moneyLineObject.odds);
              valCompare = valCompare < 0 ? -valCompare : valCompare;
              if (valCompare < item.Range && !found) {
                if (index == 0) {
                  oddToLower = data.records[index].OddsValue;
                } else {
                  oddToLower = data.records[index - 1].OddsValue;
                }
                found = true;
              }
            });
            oddToLower = oddToLower === 0 ? 20 : oddToLower;
            if (
              storedOdd === 100 ||
              storedOdd === -100 ||
              oddToLower / 2 + 100 === storedOdd
            ) {
              oddToLower = 0;
            } else if (storedOdd > 0 && storedOdd - oddToLower < 100) {
              oddToLower = 100 + (100 + oddToLower - storedOdd) - storedOdd;
              flag = false;
            }
            if (storedOdd === 100 || storedOdd === -100) {
              storedOdd = null;
            } else if (oddToLower === 0 || !flag) {
              storedOdd = storedOdd + oddToLower;
              storedOdd = storedOdd * -1;
            } else {
              storedOdd += -oddToLower;
            }
            if (line == 0) {
              setHomeMoneyline(storedOdd);
            } else {
              setVisitorMoneyline(storedOdd);
            }
          } else {
            storedOdd = null;
            newValue = null;

            moneyLineObject.odds = null;
            moneyLineObject.newValue = null;
          }
        });
    }
    setValueHasChanged(true);
  };

  const KeyPress = (event, side) => {
    if (event.key == 'Enter' && side == 'V') {
      setNewMoneyline(event.target.value);
      updateML(event.target.value, 0);
      props.updateML(
        props.IdGames[0],
        0,
        props.IdLinetype,
        event.target.value,
        props.IdSport,
        visitorNumber,
        4,
        8,
        1,
        0,
        homeNumber,
        gameType,
        8,
        5
      );
    } else if (event.key == 'Enter' && side == 'H') {
      setNewMoneyline(event.target.value);
      updateML(event.target.value, 1);
      props.updateML(
        props.IdGames[0],
        0,
        props.IdLinetype,
        event.target.value,
        props.IdSport,
        visitorNumber,
        5,
        8,
        1,
        0,
        homeNumber,
        gameType,
        8,
        4
      );
    }
  };

  const handleChangeHomeSpread = (event) => {
    setHomeSpread(event.target.value);
  };

  const handleChangeHomeSpreadOdds = (event) => {
    setHomeSpreadOdds(event.target.value);
  };

  const handleChangeUnderTotal = (event) => {
    setUnderTotal(event.target.value);
  };

  const handleChangeUnderOdds = (event) => {
    setUnderOdds(event.target.value);
  };

  const handleChangeHomeMoneyline = (event) => {
    setValueHasChanged(true);
    setHomeMoneyline(event.target.value);
    setNewMoneyline(event.target.value);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };

  const getContenders = () => {
    axios
      .get(
        process.env.REACT_APP_API2_URL +
          `/Game/Games_GameDataTNT?idGame=${props.IdGames[0]}&idLineType=${props.IdLinetype}`
      )
      .then((response) => {
        if(response.data.length > 0){
          setGameData(response.data[0]);
          setGameDateTime(response.data[0].GameDateTime);
          setOldGameDateTime(response.data[0].GameDateTime);
          setFamilyGame(response.data[0].FamilyGame);
          setParentGame(response.data[0].ParentGame);
          setDescription(response.data[0].Description);
          setTNTContenders(response.data);  
        }        
        setLoading(false);
      });
  };

  const handleChangeChecks = (event) => {
    setChecks({ ...checks, [event.target.name]: event.target.checked });
  };

  const calculateCaseUpdate = (checks) => {         
      if (checks.checkedA && checks.checkedB && checks.checkedC && checks.checkedD) {
        return 1;
      }
      if (checks.checkedA && checks.checkedB && checks.checkedC && !checks.checkedD) {
        return 2;
      }
      if (checks.checkedA && checks.checkedB && !checks.checkedC && !checks.checkedD) {
        return 3;
      }
      if (checks.checkedA && !checks.checkedB && !checks.checkedC && !checks.checkedD) {
        return 4;
      }
      if (!checks.checkedA && checks.checkedB && !checks.checkedC && !checks.checkedD) {
        return 5;
      }
      if (!checks.checkedA && !checks.checkedB && checks.checkedC && !checks.checkedD) {
        return 6;
      }
      if (!checks.checkedA && !checks.checkedB && !checks.checkedC && checks.checkedD) {
        return 7;
      }
      if (!checks.checkedA && checks.checkedB && checks.checkedC && checks.checkedD) {
        return 8;
      }      
      if (!checks.checkedA && !checks.checkedB && checks.checkedC && checks.checkedD) {        
        return 9;
      }
      if (checks.checkedA && !checks.checkedB && checks.checkedC && !checks.checkedD) {
        return 10;
      }
      if (!checks.checkedA && checks.checkedB && !checks.checkedC && checks.checkedD) {
        return 11;
      }
      if (checks.checkedA && !checks.checkedB && !checks.checkedC && checks.checkedD) {
        return 12;
      }
      if (!checks.checkedA && checks.checkedB && checks.checkedC && !checks.checkedD) {
        return 13;
      }
      if (checks.checkedA == false && checks.checkedB == false && checks.checkedC == false && checks.checkedD == false) {
        return 14;
      }
  }

  const didTheTimeChanged = () => {
    if(oldGameDateTime.substr(11, oldGameDateTime.length) == gameDateTime.substr(11, oldGameDateTime.length))
      return false
    else
      return true
  }

  const didTheDateChanged = () => {
    if(oldGameDateTime.substr(0, 10) == gameDateTime.substr(0, 10))
      return false
    else
      return true
  }


  const handleOk = async () => {   
    let updatedGames = 0;
    let switchValue = calculateCaseUpdate(checks);    
    
    if (gameStat != 'B') {
      let aux = true; 
      if(props.IdGames.length == 1){
        if (flag) {          
          setFlag(false);
          if (props.MLVisitorAction != "" || props.MLVisitorCount != "" || props.MLHomeCount != "" || props.MLHomeAction != ""){
            window.alert("You can't update the pitcher values because this game has Money, please go to the Grading Tool to perform the Pitcher Change")             
            aux=false; 
            handleClose()           
          }          
        }
        if(aux){
          let objTsend = {
            IdGame: idGame,
            IdSport: props.IdSport,
            IdLeague: leagueID.value ? leagueID.value : leagueID,
            IdGameType: typeID.value ? typeID.value : typeID,
            GameDateTime: gameDateTime.date
              ? gameDateTime.date.slice(0, -4)
              : gameDateTime,
            VisitorNumber: visitorNumber,
            HomeNumber: homeNumber,
            VisitorTeam: visitorTeam.toUpperCase(),
            HomeTeam: homeTeam.toUpperCase(),
            VisitorScore: visitorScore,
            HomeScore: homeScore,
            VisitorPitcher: visitorPitcher,
            HomePitcher: homePitcher,
            NormalGame: normalGame,
            GameStat: gameStat,
            Graded: graded,
            Hookups: hookups,
            Local: local,
            Online: online,
            ShortGame: shortGame,
            EventDate: eventDate.date
              ? eventDate.date.slice(0, -4)
              : eventDate.slice(0, -4),
            DateChanged: didTheDateChanged() ? true : dateChanged,
            TimeChanged: didTheTimeChanged() ? true : timeChanged,
            PitcherChanged: pitcherChanged,
            Period: period,
            ParentGame: parentGame,
            GradedDate: gradedDate
              ? gradedDate.date
                ? gradedDate.date.slice(0, -4)
                : gradedDate.slice(0, -4)
              : null,
            NumTeams: numTeams,
            IdEvent: idEvent,
            FamilyGame: familyGame,
            HasChildren: hasChildren,
            IdTeamVisitor: idTeamVisitor,
            IdTeamHome: idTeamHome,
            IdBannerType: idBannerType,
            Description: description,
            AcceptAutoChanges: acceptAutoChanges,
            IdUser: 498,
            Result: result,
          };
          console.log('OBJECT SEND===>', objTsend);
          await axios({
            method: 'post',
            url: process.env.REACT_APP_API2_URL + '/Game/GameUpdate',
            data: objTsend,
          }).then((response) => {
            props.searchGameValues();
            setIsLoading(false);
            handleClose();
          });
          if(props.IdSport == 'MLB' && childrenPeriods.records.length > 0){
            updateChildrenPeriods()
          }

          for(let i=0;i<lineTypes.length;i++){
            if(visitorOdds != visitorMoneyline && lineTypes.length>0){
              props.updateML(
                props.IdGames[0],
                0,
                lineTypes[i].IdLineType,
                visitorMoneyline,
                props.IdSport,
                visitorNumber,
                4,
                8,
                1,
                0,
                homeNumber,
                gameType,
                8,
                5,
                valueHasChanged,
                setRecentUpdatedGame,
                setValueHasChanged,
                props
              )
            }
            if(homeOdds != homeMoneyline){
              if (props.IdSport != "PROP") {
                props.updateML(            
                  props.IdGames[0],
                  0,
                  lineTypes[i].IdLineType,
                  homeMoneyline,
                  props.IdSport,
                  visitorNumber,
                  5,
                  8,
                  1,
                  0,
                  homeNumber,
                  gameType,
                  8,
                  4,
                  valueHasChanged,
                  setRecentUpdatedGame,
                  setValueHasChanged,
                  props
                )
              } else {
                props.updatePROPTNT(
                  lineTypes[i].IdLineType,
                  props.IdLinetype,
                  homeMoneyline,
                  visitorNumber,
                  valueHasChanged,
                  setValueHasChanged
                );
              }
            }         
          }   
        }             
      }else{
        switch(switchValue){
          case 1:{
            if(gameDateTime=='' || leagueID == 0 || typeID == 0 || (visitorPitcher == "" && homePitcher == "")){
              alert("Please provide the required fields")
            }else{
              setIsLoading(true);
              let objMultisend;
              if(homePitcher == ""){
                objMultisend = {          
                GameDateTime: gameDateTime.date ? gameDateTime.date.slice(0, -4) : gameDateTime,
                IdLeague: leagueID.value ? leagueID.value : leagueID,
                IdGameType: typeID.value ? typeID.value : typeID ,
                VisitorPitcher: visitorPitcher,
                }  
              }
      
              if(visitorPitcher==""){
                objMultisend = {          
                GameDateTime: gameDateTime.date ? gameDateTime.date.slice(0, -4) : gameDateTime,
                IdLeague: leagueID.value ? leagueID.value : leagueID,
                IdGameType: typeID.value ? typeID.value : typeID ,
                HomePitcher: homePitcher,
                }  
              }
      
              if(visitorPitcher!="" && homePitcher != ""){
                objMultisend = {          
                  GameDateTime: gameDateTime.date ? gameDateTime.date.slice(0, -4) : gameDateTime,
                  IdLeague: leagueID.value ? leagueID.value : leagueID,
                  IdGameType: typeID.value ? typeID.value : typeID ,
                  VisitorPitcher: visitorPitcher,
                  HomePitcher: homePitcher,
                } 
              }      
              
              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend
                  ,
                }
                ).then((response) => {
                  console.log('objMultisend response', response);
                  updatedGames++
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }

              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }           
            break;
          }
          case 2:{  
            if(gameDateTime=='' || leagueID == 0 || typeID == 0){
              alert("Please provide the required fields")
            }else{
              setIsLoading(true);
              let objMultisend = {
                GameDateTime: gameDateTime.date ? gameDateTime.date.slice(0, -4) : gameDateTime,
                IdLeague: leagueID.value ? leagueID.value : leagueID,
                IdGameType: typeID.value ? typeID.value : typeID ,
              } 
              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],               
                  data: objMultisend,
                }
                ).then((response) => {
                  console.log('objMultisend response', response);
                  updatedGames++
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }
            
            break;
          }
          case 3:{
            if(gameDateTime=='' || leagueID == 0){
              alert("Please provide the required fields")
            }else{
              let objMultisend = {
                GameDateTime: gameDateTime.date ? gameDateTime.date.slice(0, -4) : gameDateTime,
                IdLeague: leagueID.value ? leagueID.value : leagueID,
              } 
              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend,
                }
                ).then((response) => {
                  console.log('objMultisend response', response); 
                  updatedGames++
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)           
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }            
            break;
          }
          case 4:{
            if(gameDateTime==''){
              alert("Please provide the required fields")
            }else{
              let objMultisend = {
                GameDateTime: gameDateTime.date ? gameDateTime.date.slice(0, -4) : gameDateTime,
              } 
              for (let i = 0; i < props.IdGames.length; i++) {   
                console.log("objMultisend",objMultisend)       
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend,            
                  }
                ).then((response) => {
                  console.log('objMultisend response', response);
                  updatedGames++
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }            
            break;
          }
          case 5:{
            if(leagueID==0){
              alert("Please provide the required fields")
            }else{
              let objMultisend = {
                IdLeague: leagueID.value ? leagueID.value : leagueID,
              } 
              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend,             
                }
                ).then((response) => {
                  console.log('objMultisend response', response);
                  updatedGames++
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }            
            break;
          }   
          case 6:{
            if(typeID==0){
              alert("Please provide the required fields")
            }else{
              let objMultisend = {
                IdGameType: typeID.value ? typeID.value : typeID ,
              } 
              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend
                  
                }
                ).then((response) => {
                  console.log('objMultisend response', response);
                  updatedGames++
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }            
            break;
          }
          case 7:{
            if(homePitcher == '' && visitorPitcher == ''){
              alert("Please provide the required fields")
            }else{
              let objMultisend;        
              if(homePitcher==""){
                objMultisend = {            
                  VisitorPitcher: visitorPitcher,
                }
              }

              if(visitorPitcher==""){
                objMultisend = { 
                  HomePitcher: homePitcher,
                }  
              }

              if(visitorPitcher!="" && homePitcher != ""){
                objMultisend = { 
                  VisitorPitcher: visitorPitcher,
                  HomePitcher: homePitcher,
                } 
              }
              
              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend            
                }
                ).then((response) => {
                  console.log('objMultisend response', response);   
                  updatedGames++ 
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)        
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }
            
            break;
          }
          case 8: {
            if(leagueID == 0 || typeID == 0 || (homePitcher == '' && visitorPitcher == '')){
              alert("Please provide the required fields")
            }else{
              let objMultisend;
              if(homePitcher==""){
                objMultisend = {            
                  IdLeague: leagueID.value ? leagueID.value : leagueID,
                  IdGameType: typeID.value ? typeID.value : typeID ,
                  VisitorPitcher: visitorPitcher,
                }
              }

              if(visitorPitcher==""){
                objMultisend = { 
                  IdLeague: leagueID.value ? leagueID.value : leagueID,
                  IdGameType: typeID.value ? typeID.value : typeID ,
                  HomePitcher: homePitcher,
                }  
              }

              if(visitorPitcher!="" && homePitcher != ""){
                objMultisend = { 
                  IdLeague: leagueID.value ? leagueID.value : leagueID,
                  IdGameType: typeID.value ? typeID.value : typeID ,
                  VisitorPitcher: visitorPitcher,
                  HomePitcher: homePitcher,
                } 
              }

              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend,
                }
                ).then((response) => {
                  console.log('objMultisend response', response);    
                  updatedGames++  
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)      
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }            
            break;
          }
          case 9: {
            if(typeID == 0 || (homePitcher == '' && visitorPitcher == '')){
              alert("Please provide the required fields")
            }else{
              let objMultisend;

              if(homePitcher==""){
                objMultisend = {            
                  IdGameType: typeID.value ? typeID.value : typeID ,            
                  VisitorPitcher: visitorPitcher, 
                }
              }

              if(visitorPitcher==""){
                objMultisend = { 
                  IdGameType: typeID.value ? typeID.value : typeID ,
                  HomePitcher: homePitcher,          
                }  
              }

              if(visitorPitcher!="" && homePitcher != ""){
                objMultisend = { 
                  IdGameType: typeID.value ? typeID.value : typeID ,
                  VisitorPitcher: visitorPitcher,
                  HomePitcher: homePitcher,
                } 
              }
              
              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend,              
                }
                ).then((response) => {
                  console.log('objMultisend response', response);
                  updatedGames++
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }            
            break;
          }
          case 10: {
            if(gameDateTime == '' || typeID == 0){
              alert("Please provide the required fields")
            }else{
              let objMultisend = {
                GameDateTime: gameDateTime.date ? gameDateTime.date.slice(0, -4) : gameDateTime,
                IdGameType: typeID.value ? typeID.value : typeID ,
              } 
              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend,
                }
                ).then((response) => {
                  console.log('objMultisend response', response);  
                  updatedGames++ 
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)         
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }            
            break;
          }
          case 11: {
            if(leagueID == 0 || (homePitcher == '' && visitorPitcher == '')){
              alert("Please provide the required fields")
            }else{
              let objMultisend;

              if(homePitcher==""){
                objMultisend = {            
                  IdLeague: leagueID.value ? leagueID.value : leagueID,            
                  VisitorPitcher: visitorPitcher,
                }
              }

              if(visitorPitcher==""){
                objMultisend = { 
                  IdLeague: leagueID.value ? leagueID.value : leagueID,
                  HomePitcher: homePitcher,                  
                }  
              }

              if(visitorPitcher!="" && homePitcher != ""){
                objMultisend = { 
                  IdLeague: leagueID.value ? leagueID.value : leagueID,
                  VisitorPitcher: visitorPitcher,
                  HomePitcher: homePitcher,
                } 
              }

              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend,
                }
                ).then((response) => {
                  console.log('objMultisend response', response);
                  updatedGames++
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose() 
            }            
            break;
          }
          case 12: {
            if(gameDateTime == '' || (homePitcher == '' && visitorPitcher == '')){
              alert("Please provide the required fields")
            }else{
              let objMultisend;

              if(homePitcher==""){
                objMultisend = {            
                  GameDateTime: gameDateTime.date ? gameDateTime.date.slice(0, -4) : gameDateTime,            
                  VisitorPitcher: visitorPitcher, 
                }
              }

              if(visitorPitcher==""){
                objMultisend = { 
                  GameDateTime: gameDateTime.date ? gameDateTime.date.slice(0, -4) : gameDateTime,            
                  HomePitcher: homePitcher,                  
                }  
              } 

              if(visitorPitcher!="" && homePitcher != ""){
                objMultisend = { 
                  GameDateTime: gameDateTime.date ? gameDateTime.date.slice(0, -4) : gameDateTime,
                  VisitorPitcher: visitorPitcher,
                  HomePitcher: homePitcher,
                } 
              }
              
              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend,
                }
                ).then((response) => {
                  console.log('objMultisend response', response);
                  updatedGames++
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }            
            break;
          }
          case 13: {
            if(leagueID == 0 || typeID == 0){
              alert("Please provide the required fields")
            }else{
              let objMultisend = {
                IdLeague: leagueID.value ? leagueID.value : leagueID,
                IdGameType: typeID.value ? typeID.value : typeID ,
              } 
              for (let i = 0; i < props.IdGames.length; i++) {
                await axios({
                  method: 'put',
                  url: process.env.REACT_APP_API_URL + '/GAME/' + props.IdGames[i],
                  data: objMultisend,
                }
                ).then((response) => {
                  console.log('objMultisend response', response);  
                  updatedGames++  
                  setValueLinearBar((updatedGames/props.IdGames.length)*100)        
                }).catch( (e) => {
                  console.log('objMultisend error', e);
                });
              }
              setIsLoading(false)
              props.searchGameValues()
              handleClose()
            }            
            break;
          }
          case 14: {
            alert("Please check the items you want to modify");
            setIsLoading(false);            
            break;
          } 
        }          
      }      
    }   
  };  

  const getChildrenPeriods = (idGame) =>{
    const url =
        process.env.REACT_APP_API_URL +
        "/GAME?filter=FamilyGame,eq," +
        idGame +
        "&filter=Period,eq," +
        1;
      axios
        .get(url)
        .then((response) => {
          setChildrenPeriods(response.data);
        })
  }

  const updateChildrenPeriods = async () =>{  
    let objMultisend = {    
      VisitorPitcher: visitorPitcher,
      HomePitcher: homePitcher,     
    }   
    await axios({
      method: 'put',
      url: process.env.REACT_APP_API_URL + '/GAME/' + childrenPeriods.records[0].IdGame,
      data: objMultisend
      
    }).then((response) => {
      console.log('objMultisend response', response);
      props.searchGameValues();
      setIsLoading(false);
      handleClose();
    }).catch( (e) => {
      console.log('objMultisend error', e);
    });
  }  
  
  useEffect(() => {       
    props.IdSport && getTeamNamesBySport(props.IdSport, setTeamNames);
    if(props.IdSport == 'TNT')
      getContenders() 
    axios
        .get(
          process.env.REACT_APP_API2_URL +
            `/Game/Games_GameData?idGame=${props.IdGames[0]}&idLineType=${props.IdLinetype}`
        )
        .then((response) => {
          console.log('responsegamedata', response);
          response.data.forEach((x) => {
            setIdGame(x['IdGame']);
            if(props.IdSport === 'MLB'){
              getChildrenPeriods(x['IdGame'])
            }              
            setVisitorTeam(x['VisitorTeam']);
            setHomeTeam(x['HomeTeam']);
            setIdSport(x['IdSport']);
            setIdLeague(x['IdLeague']);
            setIdGameType(x['IdGameType']);
            props.IdGames.length == 1 ? setGameDateTime(x['GameDateTime']):setGameData('')
            props.IdGames.length == 1 ? setOldGameDateTime(x['GameDateTime']):setGameData('')
            setVisitorNumber(x['VisitorNumber']);
            setHomeNumber(x['HomeNumber']);
            setNormalGame(x['NormalGame']);
            setGameStat(x['GameStat']);
            setGraded(x['Graded']);
            setHookUps(x['HookUps']);
            setOnline(x['Online']);
            setLocal(x['OnLocal']);
            setShortGame(x['ShortGame']);
            setEventDate(x['EventDate']);
            setPeriod(x['Period']);
            setDateChanged(x['DateChanged']);
            setTimeChanged(x['TimeChanged']);
            setParentGame(x['ParentGame']);
            setVisitorScore(x['VisitorScore']);
            setHomeScore(x['HomeScore']);
            props.IdGames.length == 1 ? setVisitorPitcher(x['VisitorPitcher']) : setVisitorPitcher('')
            setAuxVisitorPitcher(x['VisitorPitcher']);
            setVisitorPitcherOld(x['VisitorPitcher']);
            setAuxPitcherChanged(x['PitcherChanged']);
            props.IdGames.length == 1 ? setHomePitcher(x['HomePitcher']) : setHomePitcher('')
            setHomePitcherOld(x['HomePitcher']);
            setAuxHomePitcher(x['HomePitcher']);
            setGradedDate(x['GradedDate']);
            setNumTeams(x['NumTeams']);
            setPitcherChanged(x['PitcherChanged']);
            setIdEvent(x['IdEvent']);
            setFamilyGame(x['FamilyGame']);
            setHasChildren(x['HasChildren']);
            setIdTeamVisitor(x['IdTeamVisitor']);
            setIdTeamHome(x['IdTeamHome']);
            setIdBannerType(x['IdBannerType']);
            setDescription(x['GameDescription']);
            props.IdGames.length == 1 ? setTypeID(x['IdGameType']):setTypeID(0)
            props.IdGames.length == 1 ? setLeagueID(x['IdLeague']):setLeagueID(0)
            setVisitorSpread(x['VisitorSpread']);
            setVisitorSpreadOdds(x['VisitorSpreadOdds']);
            setOverTotal(x['OverTotal']);
            setOverOdds(x['OverOdds']);
            setVisitorMoneyline(x['VisitorOdds']);
            setVisitorMoneyLineOld(x['VisitorOdds']);
            setHomeSpread(x['HomeSpread']);
            setHomeSpreadOdds(x['HomeSpreadOdds']);
            setUnderTotal(x['UnderTotal']);
            setUnderOdds(x['UnderOdds']);
            setHomeMoneyline(x['HomeOdds']);
            setHomeMoneyLineOld(x['HomeOdds']);
            setVisitorOdds(x['VisitorOdds']);
            setHomeOdds(x['HomeOdds']);
          });            
        });   
      getLineTypes()       
      }, []);

  useEffect(() => {
    console.log("props",props)
  }, []);
  
  useEffect(() => {
    //for multiple games
    if (props.IdGames.length > 0) {
      axios({
        method: 'post',
        url: process.env.REACT_APP_API2_URL + '/Game/GameUpdate',
        /*  REPLACE WITH SELECT DATA */
        data: {
          idgame: props.IdGames[0],
          idlinetype: props.IdLinetype,
          idSport: props.IdSport,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          data != 0 &&
            data.data &&
            data.data.forEach((x) => {
              setIdGame(x['IdGame']);
              setVisitorTeam(x['VisitorTeam']);
              setHomeTeam(x['HomeTeam']);
              setIdSport(x['IdSport']);
              setIdLeague(x['IdLeague']);
              setIdGameType(x['IdGameType']);
              setGameDateTime(x['GameDateTime']);
              setOldGameDateTime(x['GameDateTime']);
              setVisitorNumber(x['VisitorNumber']);
              setHomeNumber(x['HomeNumber']);
              setNormalGame(x['NormalGame']);
              setGameStat(x['GameStat']);
              setGraded(x['Graded']);
              setHookUps(x['HookUps']);
              setOnline(x['Online']);
              setLocal(x['OnLocal']);
              setShortGame(x['ShortGame']);
              setEventDate(x['EventDate']);
              setPeriod(x['Period']);
              setDateChanged(x['DateChanged']);
              setTimeChanged(x['TimeChanged']);
              setParentGame(x['ParentGame']);
              setVisitorScore(x['VisitorScore']);
              setHomeScore(x['HomeScore']);
              props.IdGames.length == 1 ? setVisitorPitcher(x['VisitorPitcher']) : setVisitorPitcher('')
              setVisitorPitcherOld(x['VisitorPitcher']);
              setAuxVisitorPitcher(x['VisitorPitcher']);
              setAuxPitcherChanged(x['PitcherChanged']);
              setAuxHomePitcher(x['HomePitcher']);
              props.IdGames.length == 1 ? setHomePitcher(x['HomePitcher']) : setHomePitcher('')
              setHomePitcherOld(x['HomePitcher']);
              setGradedDate(x['GradedDate']);
              setNumTeams(x['NumTeams']);
              setPitcherChanged(x['PitcherChanged']);
              setIdEvent(x['IdEvent']);
              setFamilyGame(x['FamilyGame']);
              setHasChildren(x['HasChildren']);
              setIdTeamVisitor(x['IdTeamVisitor']);
              setIdTeamHome(x['IdTeamHome']);
              setIdBannerType(x['IdBannerType']);
              setDescription(x['GameDescription']);
              props.IdGames.length == 1 ? setTypeID(x['IdGameType']):setTypeID(0)
              props.IdGames.length == 1 ? setLeagueID(x['IdLeague']):setLeagueID(0)
              setVisitorSpread(x['VisitorSpread']);
              setVisitorSpreadOdds(x['VisitorSpreadOdds']);
              setOverTotal(x['OverTotal']);
              setOverOdds(x['OverOdds']);
              setVisitorMoneyline(x['VisitorOdds']);
              setVisitorMoneyLineOld(x['VisitorOdds']);
              setHomeSpread(x['HomeSpread']);
              setHomeSpreadOdds(x['HomeSpreadOdds']);
              setUnderTotal(x['UnderTotal']);
              setUnderOdds(x['UnderOdds']);
              setHomeMoneyline(x['HomeOdds']);
              setHomeMoneyLineOld(x['HomeOdds']);
            });
        })
        .then(() => {
          const url = process.env.REACT_APP_API_URL + '/GAMETYPE';
          axios
            .get(url)
            .then((response) => response.data)
            .then((data) => {
              setType(data.records);
            });
        })
        .then(() => {
          const url =
            process.env.REACT_APP_API_URL +
            '/LEAGUE?filter=IdSport,eq,' +
            props.IdSport;
          axios
            .get(url)
            .then((response) => response.data)
            .then((data) => {
              setLeague(data.records);
            });
        })
        .then(() => {
          const url = process.env.REACT_APP_API_URL + '/PITCHER';
          axios
            .get(url)
            .then((response) => response.data)
            .then((data) => {
              let PitchersInfo = data.records.map((v) => {
                v.IdSport = v.PitcherName;
                return v;
              });
              console.log('pitchers info=========>', PitchersInfo);
              setPitchers(PitchersInfo);
            })
            .then(setLoading(false));
        });
    }
  }, [props.IdGames, props.IdLinetype, props.IdSport]);

  const doesPitcherExist = (pitcherName) => {
    let doesExist = false;
    pitchers.forEach((x) => {
      if (x.PitcherName === pitcherName) {
        doesExist = true;
      }
    });
    return doesExist;
  }

  const doesTeamExist = (teamName) => {
    let doesExist = false;
    teamNames.forEach((x) => {
      if (x === teamName) {
        doesExist = true;
      }
    });
    return doesExist;
  }

  const getLineTypes = async () => {
    const url = process.env.REACT_APP_API_URL + "/LINETYPE";
    await axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setLineTypes(data.records);
      });
  }

  function changeLeagueID(value) {
    setLeagueID(value);
    GetGames(value, typeID);
  }

  const GetGames = (IdLeague, IdType) => {
    if (IdLeague != 0 && IdType != 0) {
      const url =
        process.env.REACT_APP_API_URL +
        "/GAME?filter=IdLeague,eq," +
        IdLeague +
        "&filter=IdGameType,eq," +
        IdType;
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setGames(data.records.filter((g) => g.GameStat != "B"));
        });
    }
  }

  const calculateVisitorPitcherChanged = (pitcher) =>{
    if(pitcher !== auxVisitorPitcher && pitcherChanged === 0){
      return 1
    }else
      if(pitcher !== auxVisitorPitcher && pitcherChanged === 2){
        return 3
      }
    return pitcherChanged   
  }
  
  const calculateHomePitcherChanged = (pitcher) =>{ 
    if(pitcher !== auxHomePitcher && pitcherChanged === 0){
      return 2
    }else
      if(pitcher !== auxHomePitcher && pitcherChanged === 1){
        return 3
      }   
    return pitcherChanged 
  }

  const setVisitorPitcherOnMem = (VisitorPitcher) => { 
    setVisitorPitcher(VisitorPitcher);
    setFlag(true);
    setIdAction(1);
    setPitcherSide('V');
    auxVisitorPitcher === '' || auxVisitorPitcher === 'UNDECIDED' || (auxVisitorPitcher === null && auxPitcherChanged === 0) ? setPitcherChanged(0) : setPitcherChanged(calculateVisitorPitcherChanged(VisitorPitcher)) 
  }

  const setHomePitcherOnMem = (HomePitcher) => {
    setHomePitcher(HomePitcher);
    setFlag(true);
    setIdAction(2);
    setPitcherSide('H');
    auxHomePitcher === '' || auxHomePitcher === 'UNDECIDED' || (auxHomePitcher === null && auxPitcherChanged === 0) ? setPitcherChanged(0) : setPitcherChanged(calculateHomePitcherChanged(HomePitcher))
  };

  function changeTypeID(value) {
    setTypeID({ value });
  }

  const handleCloseIsChange = function () {
    setIsChange(false);
  }

  return (
    <React.Fragment>            
      <Dialog open={open} onClose={handleClose}>        
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Game Modify
            </Typography>
          </Toolbar>
        </AppBar>
        {loading ? (
          <CircularProgress style={{ margin: '0 auto' }} />
        ) : (
          <React.Fragment>
            <br />
            <form className={classes.center}>
              <FormGroup row>
                <FormControlLabel
                control={<Checkbox checked={checks.checkedA} onChange={handleChangeChecks} name="checkedA"  />}
                />
                <TextField
                  variant="outlined"
                  id="gameDateTime"
                  label="Game Date and Time"
                  type="datetime-local"
                  value={
                    gameDateTime.date
                      ? gameDateTime.date.replace(' ', 'T').split('.')[0]
                      : gameDateTime
                  }
                  onChange={handleChangeGameDateTime}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  
                />
              </FormGroup>                           
              <Divider />
              {props.IdGames.length == 1 ? (
                <React.Fragment>
                  <FormGroup row>
                    <FormControl className={classes.formControl}>
                      {leagueID != 0 ? (
                        <Leagues
                          setLeagueOnMem={
                          changeLeagueID
                          }
                          filterBy={idSport}
                          key={idSport}                        
                          showDataTable={false}
                          isModify={true}                        
                          league={leagueID}                                              
                        />
                      ) : (
                        ''
                      )}
                    </FormControl>
                    <FormControl className={classes.formControl}>
                    {typeID != 0 ? (
                      <InputSelect                        
                        variant="outlined"
                        name="name"
                        data={type}
                        placeholder="Type"
                        setSelectValue={changeTypeID}
                        startValue={typeID}
                      />
                    ) : (
                      ''
                    )}
                    </FormControl>
                  </FormGroup>
                  <Divider />
                  <FormGroup row>
                    <TextField
                      type="number"
                      label="Family Game"
                      value={familyGame}
                      multiline={true}
                      onChange={handleChangeFamilyGame}
                      variant="outlined"
                    />

                    <Button
                      className={classes.button}
                      disabled
                      variant="contained"
                      onClick={handleFindParent}
                      startIcon={<SearchIcon />}
                    >
                      Find
                    </Button>
                  </FormGroup>
                  <Divider />
                  <FormGroup row>
                    <TextField
                      type="number"
                      label="Parent Game"
                      value={parentGame}
                      multiline={true}
                      onChange={handleChangeParentGame}
                      variant="outlined"
                    />
                    <Button
                      disabled
                      className={classes.button}
                      variant="contained"
                      onClick={handleFindParent}
                      startIcon={<SearchIcon />}
                    >
                      Find
                    </Button>
                  </FormGroup>
                  <Divider />
                  <FormGroup row>
                    <TextField
                      style={{ width: '400px' }}
                      type="text"
                      label="Description"
                      value={description}
                      multiline={true}
                      rows="4"
                      onChange={handleChangeDescription}
                      variant="outlined"
                    />
                  </FormGroup>
                  <Divider />
                  {props.IdSport != 'TNT' ? (
                    <React.Fragment>                      
                          <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                            <FormGroup row>
                              <Table>
                                <TableRow style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                                  <TableCell>
                                    <TextField
                                      type="number"
                                      label="Visitor Team #"
                                      value={visitorNumber}
                                      multiline={true}
                                      onChange={handleChangeVisitorNumber}
                                      variant="outlined"
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell  colSpan={4}>                                    
                                      <Teams 
                                        callBackFn={handleChangeVisitorTeam}
                                        teamName={visitorTeam}                        
                                        isModify={true}
                                        idSport={props.IdSport?props.IdSport:idSport}
                                        Key={props.IdSport?props.IdSport:idSport}
                                        filterByTeamName={false}
                                        label={"Visitor Teams"}
                                        doesTeamExist= {teamNames.length > 0 ? !doesTeamExist(visitorTeam):false}
                                      ></Teams>
                                      <Teams 
                                        callBackFn={handleChangeHomeTeam}
                                        teamName={homeTeam}                        
                                        isModify={true}
                                        idSport={props.IdSport?props.IdSport:idSport}
                                        Key={props.IdSport?props.IdSport:idSport}
                                        filterByTeamName={false}
                                        label={"Home Teams"}
                                        doesTeamExist= {teamNames.length > 0 ? !doesTeamExist(homeTeam):false} 
                                      ></Teams>                                      
                                  </TableCell>
                                </TableRow>
                                <TableRow style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                                  <TableCell>
                                    <TextField
                                      variant="outlined"
                                      type="number"
                                      label="Home Team #"
                                      value={homeNumber}
                                      multiline={true}
                                      onChange={handleChangeHomeNumber}
                                    />
                                  </TableCell>                                
                                </TableRow>
                              </Table> 
                            </FormGroup>                     
                          </div>                                                  
                    </React.Fragment>
                  ) : (
                    <>
                      <Grid container>
                        <Grid item xs={4}>
                          <TextField
                            variant="outlined"
                            type="number"
                            label="#"
                            value={tntNumber}
                            onChange={(e) => setTntNumber(e.target.value)}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            variant="outlined"
                            type="text"
                            label="Name"
                            value={tntName}
                            onChange={(e) => setTntName(e.target.value)}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            variant="outlined"
                            type="number"
                            label="Odds"
                            value={tntOdds}
                            onChange={(e) => setTntOdds(e.target.value)}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <Button
                            onClick={() => updateContender()}
                            color="primary"
                            variant="outlined"
                          >
                            Add
                          </Button>
                          <Button
                            onClick={() => updateContender()}
                            disabled={!selectedContender}
                            variant="outlined"
                          >
                            Update
                          </Button>
                          <Button
                            onClick={() => deleteContender()}
                            disabled={!selectedContender}
                            color="secondary"
                            variant="outlined"
                          >
                            Delete
                          </Button>
                        </Grid>
                        <Grid xs={12}>
                          <Typography variant="h5">Contenders</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            {TNTContenders ? (
                              TNTContenders.map((contender) => (
                                <>
                                  <Grid xs={1}>
                                    {selectedContender == contender && (
                                      <CheckCircleIcon />
                                    )}
                                  </Grid>
                                  <Grid xs={3}>
                                    <Typography variant="h6">
                                      {contender.TeamNumber}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={4}>
                                    <Typography variant="h6">
                                      {contender.TeamName}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={3}>
                                    <Typography variant="h6">
                                      {contender.Odds}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={1}>
                                    <Button
                                      onClick={() => {
                                        setTntName(contender.TeamName);
                                        setTntOdds(contender.Odds);
                                        setTntNumber(contender.TeamNumber);
                                        setSelectedContender(contender);
                                      }}
                                      variant="contained"
                                      color="primary"
                                    >
                                      Select
                                    </Button>
                                  </Grid>
                                </>
                              ))
                            ) : (
                              <Skeleton variant="rect" height={200} />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {props.IdSport == 'MLB' ? (
                    <FormGroup row>
                      <FormControlLabel
                      control={<Checkbox checked={checks.checkedD} onChange={handleChangeChecks} name="checkedD" />}
                      />
                      <FormControl className={classes.formControl}>
                        <PitcherList
                          key={visitorPitcher}
                          isInput={true}
                          initialValue={visitorPitcher}
                          setPitcherOnMem={setVisitorPitcherOnMem}
                          doesPitcherExist= {visitorPitcher !== '' && visitorPitcher !== null ? !doesPitcherExist(visitorPitcher):false}
                        ></PitcherList>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <PitcherList
                          key={homePitcher}
                          isInput={true}
                          initialValue={homePitcher}
                          setPitcherOnMem={setHomePitcherOnMem}
                          doesPitcherExist= {homePitcher !== '' && homePitcher !== null ? !doesPitcherExist(homePitcher):false} 
                        ></PitcherList>
                      </FormControl>
                    </FormGroup>
                  ) : (
                    ''
                  )}                  
                </React.Fragment>
              ) : (
                <React.Fragment>            
                  <FormGroup row>
                    <FormControlLabel
                    control={<Checkbox checked={checks.checkedB} onChange={handleChangeChecks} name="checkedB"  />}
                    />
                    <Leagues
                          setLeagueOnMem={
                          changeLeagueID
                          }
                          filterBy={idSport}
                          key={idSport}                        
                          showDataTable={false}
                          isModify={false}                        
                          league={leagueID}                                              
                    />
                  </FormGroup>                           
                  <Divider />
                  <FormGroup row>
                    <FormControlLabel
                    control={<Checkbox checked={checks.checkedC} onChange={handleChangeChecks} name="checkedC"  />}
                    />
                    <InputSelect                        
                        variant="outlined"
                        name="name"
                        data={type}
                        placeholder="Type"
                        setSelectValue={changeTypeID}
                        startValue={typeID}
                      />
                  </FormGroup> 
                  {props.IdSport == 'MLB' ? (
                    <FormGroup row>
                      <FormControlLabel
                      control={<Checkbox checked={checks.checkedD} onChange={handleChangeChecks} name="checkedD" />}
                      />
                      <FormControl className={classes.formControl}>
                        <PitcherList
                          key={visitorPitcher}
                          isInput={true}
                          initialValue={visitorPitcher}
                          setPitcherOnMem={setVisitorPitcherOnMem}
                          doesPitcherExist= {visitorPitcher !== '' && visitorPitcher !== null ? !doesPitcherExist(visitorPitcher):false}
                        ></PitcherList>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <PitcherList
                          key={homePitcher}
                          isInput={true}
                          initialValue={homePitcher}
                          setPitcherOnMem={setHomePitcherOnMem}
                          doesPitcherExist= {homePitcher !== '' && homePitcher !== null ? !doesPitcherExist(homePitcher):false} 
                        ></PitcherList>
                      </FormControl>
                    </FormGroup>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              )}
            </form>
          </React.Fragment>
        )}
        <DialogActions>
          {isLoading ? (
            <Box sx={{ flexGrow: 1 }}>              
              <BorderLinearProgress variant="determinate" value={valueLinearBar} />
            </Box>
          ) : (
            <div>
              <Button
                color="inherit"
                startIcon={<CancelIcon />}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                startIcon={<SaveIcon />}
                color="inherit"
                onClick={handleOk}
              >
                Ok
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
