import openSocket from "socket.io-client";
const socket = openSocket(process.env.REACT_APP_NOTIFICATION_SVR_URL, {
  transports: ["websocket"],
});

function subscribeToTimer(cb) {
  const socket = openSocket(process.env.REACT_APP_NOTIFICATION_SVR_URL, {
    transports: ["websocket"],
  });
  console.log("subscribeToChanges subscribeToTimer");
  console.log("subscribeToChanges subscribeToTimer", socket);
  socket.on("timer", (timestamp) => cb(null, timestamp));
  socket.emit("subscribeToTimer", 10000);
}

function subscribeToChanges(cb) {
  console.log("subscribeToChanges subscribeToChanges");
  socket.on("change", (change) => cb(null, change));
  socket.emit("subscribeToChanges", 0);
}

function subscribeToAnnounceChange(cb) {
  console.log("subscribeToChanges subscribeToAnnounceChange", cb);
  socket.on("change", (change) => cb(null, change));
  socket.emit("anounceChange", cb);
}

export { subscribeToTimer, subscribeToChanges, subscribeToAnnounceChange };
