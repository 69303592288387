import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as moment from 'moment';
import InputSelect from '../../common/InputSelect';
/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './ChangesByGame.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FinalStatsByAgent(props) {
  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  const classes = useStyles();
  const [stats, setStats] = React.useState([]);
  const [groupingID, setGroupingID] = useState();
  const [grouping, setGrouping] = useState();
  const [lineTypeID, setLineTypeID] = useState();
  const [lineType, setLineType] = useState();
  const [officeID, setOfficeID] = useState();
  const [office, setOffice] = useState();
  const [agentFrom, setAgentFrom] = useState('');
  const [agentTo, setAgentTo] = useState('');
  const [distributor, setDistributor] = useState('');
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [bookID, setBookID] = useState();
  const [playerType, setPlayerType] = useState(0);
  const [orderBy, setOrderBy] = useState(0);
  const [startDate, setStartDate] = React.useState(week[0]);
  const [endDate, setEndDate] = React.useState(week[0]);
  const [book, setBooks] = useState();

  const [groupByAgent, setGroupByAgent] = useState(false);
  // let IdPeriod = '0';

  const handleShow = () => {};

  useEffect(() => {
    console.log('PROPS AGENT INFO=====>', props);
    //Change for server api address
    const url = process.env.REACT_APP_API_URL + '/BOOK';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let bookInfo = data.records.map((v) => {
          v.IdLineType = v.Description;
          v.Description = v.Description;
          return v;
        });
        setBooks(bookInfo);
      });
  }, [setBooks]);

  useEffect(() => {
    //Change for server api address
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/OFFICE';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let officeInfo = data.records.map((v) => {
          v.IdLineType = v.OfficeName;
          v.Description = v.OfficeName;
          return v;
        });
        setOffice(officeInfo);
        setIsLoading(false);
      });
  }, [setOffice]);

  useEffect(() => {
    //Change for server api address
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/GROUPING';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let officeInfo = data.records.map((v) => {
          v.IdLineType = v.GroupName;
          v.Description = v.GroupName;
          return v;
        });
        setGrouping(officeInfo);
        setIsLoading(false);
      });
  }, [setGrouping]);
  useEffect(() => {
    //Change for server api address
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/LINETYPE';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let lineTypeInfo = data.records.map((v) => {
          v.IdLineType = v.Description;
          v.Description = v.Description;
          return v;
        });
        setLineType(lineTypeInfo);
        setIsLoading(false);
      });
  }, [setLineType]);

  const HistoryValues = () => {
    setIsLoading(true);
    let request = {
      LogIdUser: userInfo.UserId,
      StartDate: startDate,
      EndDate: endDate,
      Office: officeID ? officeID : '',
      Book: bookID ? bookID : '',
      Group: groupingID ? groupingID : '',
      Distributor: distributor,
      AgentFrom: agentFrom,
      AgentTo: agentTo,
      LineType: lineTypeID ? lineTypeID : '',
      PlayerType: playerType,
      GroupbyAgent: groupByAgent ? 1 : 0,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Reports/Report_Action_by_Player',
      data: request,
    }).then((response) => {
      console.log('response', response.data);
      setStats(response.data);
      setIsLoading(false);
    });
  };
  // const [selectedIdGame, setselectedIdGame] = useState();

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };
  const handleChangeAgentFrom = (event) => {
    setAgentFrom(event.target.value);
  };
  const handleChangeAgentTo = (event) => {
    setAgentTo(event.target.value);
  };
  const handleChangeDistributor = (event) => {
    setDistributor(event.target.value);
  };
  const handlePlayerTypeChange = (event) => {
    setPlayerType(event.target.value);
  };

  const handleChangeGroupByAgent = () => {
    setGroupByAgent(!groupByAgent);
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/

  function changeBookID(value) {
    setBookID({ value });
  }

  function changeLineTypeID(value) {
    setLineTypeID({ value });
  }
  function changeGroupingID(value) {
    setGroupingID({ value });
  }

  function changeOfficeID(value) {
    setOfficeID({ value });
  }

  function rowClassNameFormat(row, rowIdx) {
    // row is whole row object
    // rowIdx is index of row
    return rowIdx % 2 === 0
      ? 'tr-function-example'
      : 'td-column-function-odd-example';
  }
  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <div>
          <form className={classes.container} noValidate>
            <Table>
              <TableRow>
                <TableCell>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    defaultValue={startDate}
                    className={classes.textField}
                    onChange={handleChangeStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    defaultValue={endDate}
                    className={classes.textField}
                    onChange={handleChangeEndDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={book}
                    placeholder="Book"
                    setSelectValue={changeBookID}
                    startValue={bookID}
                  />
                </TableCell>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={office}
                    placeholder="Office"
                    setSelectValue={changeOfficeID}
                    startValue={officeID}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={grouping}
                    placeholder="Group"
                    setSelectValue={changeGroupingID}
                    startValue={groupingID}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    label="Distributor"
                    value={distributor}
                    multiline={true}
                    onChange={handleChangeDistributor}
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextField
                    type="text"
                    label="Player"
                    value={agentFrom}
                    multiline={true}
                    onChange={handleChangeAgentFrom}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    label="Player"
                    value={agentTo}
                    multiline={true}
                    onChange={handleChangeAgentTo}
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={lineType}
                    placeholder="LineType"
                    setSelectValue={changeLineTypeID}
                    startValue={lineTypeID}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <h6>Player Type</h6>
                  <RadioGroup row>
                    <FormControlLabel
                      // value="None"
                      control={
                        <Radio
                          checked={playerType == '0'}
                          onChange={handlePlayerTypeChange}
                          value="0"
                          label="Both"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'None' }}
                          //disabled
                        />
                      }
                      label="Both"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={playerType == '1'}
                          onChange={handlePlayerTypeChange}
                          value="1"
                          label="Cash"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Cash"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={playerType == '2'}
                          onChange={handlePlayerTypeChange}
                          value="1"
                          label="Credit"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Credit"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <h6>Order By</h6>
                  <RadioGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={groupByAgent}
                          onChange={handleChangeGroupByAgent}
                          color="primary"
                        />
                      }
                      label="Time Changed"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button color="inherit" onClick={HistoryValues}>
                    Show
                  </Button>
                </TableCell>
              </TableRow>
            </Table>
          </form>
          <BootstrapTable
            data={stats}
            //   cellEdit={cellEditProp}
            trClassName={rowClassNameFormat}
            pagination
          >
            <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
              IdGame
            </TableHeaderColumn>
            <TableHeaderColumn dataField="Player">Player</TableHeaderColumn>
            <TableHeaderColumn dataField="Name">Name</TableHeaderColumn>
            <TableHeaderColumn dataField="Straight">Straight</TableHeaderColumn>
            <TableHeaderColumn dataField="Parlay">Parlay</TableHeaderColumn>
            <TableHeaderColumn dataField="Teaser">Teaser</TableHeaderColumn>
            <TableHeaderColumn dataField="Reverses">Reverse</TableHeaderColumn>
            <TableHeaderColumn dataField="Casino">Casino</TableHeaderColumn>
            <TableHeaderColumn dataField="Horses">Horses</TableHeaderColumn>
            <TableHeaderColumn dataField="Others">Others</TableHeaderColumn>
            <TableHeaderColumn dataField="Others">Others Adj</TableHeaderColumn>
            <TableHeaderColumn dataField="Others">Horses Adj</TableHeaderColumn>
            <TableHeaderColumn dataField="Total">Total</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    </React.Fragment>
  );
}
