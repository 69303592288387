import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import './GamePeriod.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import InputSelect from "../../common/InputSelect";
import { DataGrid } from '@material-ui/data-grid'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function GameModify(props) {
  const useStyles = makeStyles((theme) => ({
    root2: {
      flexGrow: 1,
      borderBlockColor: "grey"
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: "50px",
      width: "max-content"
    },
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));

  let curr = new Date();
  let week = [];
  let counter = 0;

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  const classes = useStyles();
  const [holdBets, setHoldBets] = React.useState();
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [periods, setPeriods] = React.useState([]);
  const [periodsSelected, setPeriodsSelected] = React.useState([]);
  const [startDate, setStartDate] = React.useState(week[0]);
  const [endDate, setEndDate] = React.useState(week[6]);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  
  const [leagues, setLeagues] = useState([]);
  const [leagueId, setLeagueId] = useState(-1);
  const [gameTypeId, setGameTypeId] = useState(-1);
  const [row, setRow] = useState([]);
  const [gameTypes, setGameTypes] = useState([]);


  useEffect(() => {
    PeriodsValues();
    getLeagues(props.IdSport);
    getGameTypes();    
  }, []);


  const handleClose = () => {
    if (props.onClose) {
      props.onClose(props.IdSport);
    }
    setOpen(false);
  };

  const handleShow = () => {
    if(periodsSelected.length===0){
      alert("Please select at least one period to continue")
    }else{
      for(let i=0;i<periodsSelected.length;i++){
        if(row.find(x => x.id === periodsSelected[i]).league === "Select League" ||
          row.find(x => x.id === periodsSelected[i]).gameType === "Select Game Type"){
          return alert("Please select the league and game type for the period(s) you want to create")  
        }
      }
      PeriodsInsert();
    }    
  };
  
  
  const handleRowSelection = (row) => {
    setPeriodsSelected(row)    
  }     

  const PeriodsValues = () => {
    const url =
      process.env.REACT_APP_API_URL +
      '/PERIOD?filter=IdSport,eq,' +
      props.IdSport +
      '&order= NumberOfPeriod';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setPeriods(data.records.filter((x) => x.NumberOfPeriod != 0));
        feedRow(data.records.filter((x) => x.NumberOfPeriod != 0))
      });
    setLoading(false);
  };

  const postPeriods = async (gameParent, rotationInicial, currentPeriod,leagueId,SelectedGameTypeId) => {

    let prefix = ''
    switch (currentPeriod) {
      case 1:
        prefix = "1H "
        break;
      case 2:
        prefix = "2H "
        break;
      case 3:
        prefix = "1Q "
        break;
      case 4:
        prefix = "2Q "
        break;
      case 5:
        prefix = "3Q "
        break;
      case 6:
        prefix = "4Q "
        break;
      case 7:
        prefix = "1P "
        break;
      case 8:
        prefix = "2P "
        break;
      case 9:
        prefix = "3P "
        break;
      default:
        counter += 1
        prefix = counter + "X "
    }
    let gameRequest = {
      IdSport: gameParent.IdSport,
      IdLeague: leagueId,
      GameDateTime: gameParent.GameDateTime,
      VisitorNumber: rotationInicial,
      HomeNumber: rotationInicial + 1,
      VisitorTeam: prefix + gameParent.VisitorTeam,
      HomeTeam: prefix + gameParent.HomeTeam,
      VisitorScore: gameParent.VisitorScore === null ? -1 : gameParent.VisitorScore,
      HomeScore: gameParent.HomeScore === null ? -1 : gameParent.HomeScore,
      VisitorPitcher: gameParent.VisitorPitcher === null ? '' : gameParent.VisitorPitcher,
      HomePitcher: gameParent.HomePitcher === null ? '' : gameParent.HomePitcher,
      NormalGame: gameParent.NormalGame,
      GameStat: "D",
      Graded: gameParent.Graded,
      IdGameType: SelectedGameTypeId,
      Hookups: gameParent.HookUps,
      Local: gameParent.OnLocal,
      Online: gameParent.Online,
      ShortGame: gameParent.ShortGame,
      EventDate: gameParent.EventDate,
      DateChanged: gameParent.DateChanged,
      TimeChanged: gameParent.TimeChanged,
      PitcherChanged: gameParent.PitcherChanged,
      Period: currentPeriod,
      ParentGame: gameParent.IdGame,
      GradedDate: gameParent.GradedDate === null ? -1 : gameParent.GradedDate,
      NumTeams: gameParent.NumTeams,
      IdEvent: gameParent.IdEvent === null ? -1 : gameParent.IdEvent,
      FamilyGame: gameParent.IdGame,
      HasChildren: gameParent.HasChildren,
      IdTeamVisitor: gameParent.IdTeamVisitor === null ? -1 : gameParent.IdTeamVisitor,
      IdTeamHome: gameParent.IdTeamHome === null ? -1 : gameParent.IdTeamHome,
      IdBannerType: gameParent.IdBannerType === null ? -1 : gameParent.IdBannerType,
      Description: gameParent.Description === null ? '' : gameParent.Description,
      AcceptAutoChanges: gameParent.AcceptAutoChanges,
      IdUser: userInfo.UserId,
      Result: 0
    }
    await axios({
      method: "post",
      url: process.env.REACT_APP_API2_URL + "/Game/InsertGame",
      data: gameRequest,
    }).then((r) => {
      console.log("axios", r);
      if (r.data) {
        setHoldBets(r.data)
      }
    })
      .catch((e) => console.log("axios error", e));
  }

  const getPeriodRotationNumber = (periodId, gameParent) => {
    let rotationNumber
    switch (periodId) {
      case 1:
        rotationNumber = gameParent.VisitorNumber + 1000
        break;
      case 2:
        rotationNumber = gameParent.VisitorNumber + 2000
        break;
      case 3:
        rotationNumber = gameParent.VisitorNumber + 3000
        break;
      case 4:
        rotationNumber = gameParent.VisitorNumber + 4000
        break;
      case 5:
        rotationNumber = gameParent.VisitorNumber + 5000
        break;
      case 6:
        rotationNumber = gameParent.VisitorNumber + 6000
        break;
      case 7:
        rotationNumber = gameParent.VisitorNumber + 1000
        break;
      case 8:
        rotationNumber = gameParent.VisitorNumber + 2000
        break;
      case 9:
        rotationNumber = gameParent.VisitorNumber + 3000
        break;
      default:
        rotationNumber = gameParent.VisitorNumber
    }
    return rotationNumber
  }


  const PeriodsInsert = async () => {

    let periods = {
      periodsSelected,
    };
    
    console.log("row", row)
    for (let i = 0; i < props.IdGames.length; i++) {      
      let rotationInicial = 0;
      for (let i2 = 0; i2 < periods.periodsSelected.length; i2++) {
        const currentPeriod = periods.periodsSelected[i2];        
        const leagueId = leagues.find(x => x.Description === row.find(x => x.id === periods.periodsSelected[i2]).league).IdLeague
        const SelectedGameTypeId = gameTypes.find(x => x.Description === row.find(x => x.id === periods.periodsSelected[i2]).gameType).IdGameType            
        axios.get(process.env.REACT_APP_API_URL + `/GAME/${props.IdGames}`).then(r => {
          const gamesSelected = r.data
          const gameParent = props.IdGames.length === 1 ? gamesSelected : gamesSelected[i];
          rotationInicial = getPeriodRotationNumber(currentPeriod, gameParent)

          postPeriods(gameParent, rotationInicial, currentPeriod,leagueId,SelectedGameTypeId)
          props.clearArray();
          props.searchGameValues();
          handleClose();

          console.log("gameParent", r.data)

        }).catch(e => console.log(' error', e))
      }
    }
  };

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });      
    },
  };

  const getLeagues = async (idSport) => {
    await axios.get(process.env.REACT_APP_API_URL + "/LEAGUE?filter=IdSport,eq," + idSport)
      .then(r => {
        setLeagues(r.data.records);
      })
  }

  const getGameTypes = async () =>{
    await axios.get(process.env.REACT_APP_API_URL + "/GAMETYPE")
      .then(r => {
        setGameTypes(r.data.records);
      })
  }


  function renderInputSelectLeague (props) {
    return (
      <Autocomplete        
        style={{ width: '100%', margin: '10px' }}
        disablePortal
        id="League"
        options={leagues}
        getOptionLabel={(option) => option.Description ? option.Description : ""}
        onChange={(event, newValue) => {
          if (newValue === null) {
            setLeagueId(-1)
          } else {
            let valueToReturn = "";
            setLeagueId(newValue.IdLeague)                       
            let periodsCopy = [...periods]

            for (let i = 0; i < periodsCopy.length; i++) {
              let per = periodsCopy[i];
              if (per.NumberOfPeriod == props.row.id) {
                valueToReturn = newValue.Description
                periodsCopy[i].leagueDesc = valueToReturn;
              }
            }            
            let rowtemp = []
            for (let i = 0; i < periodsCopy.length; i++) {
              rowtemp.push({
                id: periodsCopy[i].NumberOfPeriod,
                period: periodsCopy[i].PeriodDescription,
                league: periodsCopy[i].leagueDesc ? periodsCopy[i].leagueDesc : "Select League",
                gameType: row.find(x => x.id === periodsCopy[i].NumberOfPeriod).gameType
              })
            }            
            setRow(rowtemp)            
            props.api.setEditCellValue({
              id: props.id,
              field: props.field,
              value: valueToReturn
            })
          }
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            fullWidth
          />}
      />
    )
  }

  function renderInputSelectGameType (props) {
    return (
      <Autocomplete        
        style={{ width: '100%', margin: '10px' }}
        disablePortal
        id="gameType"
        options={gameTypes}
        getOptionLabel={(option) => option.Description ? option.Description : ""}
        onChange={(event, newValue) => {
          if (newValue === null) {
            setGameTypeId(-1)
          } else {
            let valueToReturn = "";
            setGameTypeId(newValue.IdGameType)   
            console.log("gameTypeId",gameTypeId)                    
            let periodsCopy = [...periods]

            for (let i = 0; i < periodsCopy.length; i++) {
              let per = periodsCopy[i];
              if (per.NumberOfPeriod == props.row.id) {
                valueToReturn = newValue.Description
                periodsCopy[i].gameTypeDescription = valueToReturn;
              }
            }            
            let rowtemp = []
            for (let i = 0; i < periodsCopy.length; i++) {
              rowtemp.push({
                id: periodsCopy[i].NumberOfPeriod,
                period: periodsCopy[i].PeriodDescription,
                league: row.find(x => x.id === periodsCopy[i].NumberOfPeriod).league,
                gameType: periodsCopy[i].gameTypeDescription ? periodsCopy[i].gameTypeDescription : "Select Game Type"
              })
            }            
            setRow(rowtemp)            
            props.api.setEditCellValue({
              id: props.id,
              field: props.field,
              value: valueToReturn
            })
          }
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            fullWidth
          />}
      />
    )
  }

  const columns = [
    { field: 'period', headerName: 'Period', width: 150, editable: false, sortable: false, filterable: false },
    { field: 'league', headerName: 'League', width: 250, editable: true, sortable: false, filterable: false, renderEditCell: renderInputSelectLeague},
    { field: 'gameType', headerName: 'Game Type', width: 250, editable: true, sortable: false, filterable: false, renderEditCell: renderInputSelectGameType }
  ]

  const feedRow = (periods) => {
    let rowtemp = []
    for (let i = 0; i < periods.length; i++) {
      rowtemp.push({
        id: periods[i].NumberOfPeriod,
        period: periods[i].PeriodDescription,
        league: periods[i].leagueDesc ? periods[i].leagueDesc : "Select League",
        gameType: periods[i].gameTypeDescription ? periods[i].gameTypeDescription : "Select Game Type"
      })
    }
    setRow(rowtemp)    
  }

  return (
    <React.Fragment>
      <Dialog style={{ maxWidth: '75%' }} open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add Game Period
            </Typography>
          </Toolbar>
        </AppBar>

        {loading ? (
          <CircularProgress style={{ margin: '0 auto' }} />
        ) : (
          <React.Fragment>
            <br />
            {periods && (
              <div style={{ height: 500, width: '100%' }}>
                <DataGrid
                  rows={row}
                  columns={columns}
                  pageSize={periods.length}
                  rowsPerPageOptions={[5]}                  
                  checkboxSelection
                  disableColumnSelector
                  disableColumnMenu
                  onSelectionModelChange={handleRowSelection}    
                  disableSelectionOnClick                                         
                />
              </div>
            )}
          </React.Fragment>
        )}
        <DialogActions>
          <Button
            color="inherit"
            startIcon={<SaveIcon />}
            onClick={handleShow}            
          >
            Create Periods
          </Button>
          <Button
            color="inherit"
            startIcon={<CancelIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

}
