import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from '../../common/InputSelect';
import Drawer from '@mui/material/Drawer';


/*Other Components*/
import Sports from '../../common/Sports';
import Periods from '../../common/Periods';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import ReactLoading from 'react-loading';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './PlayerStatistics.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export default function PlayerStatistics(props) {
  const classes = useStyles();
  const [gameRelatedOptions, setGameRelatedOptions] = React.useState([]);
  const [idSport, setIdSport] = React.useState(['CBB']);
  const [playerStatistic, setPlayerStatistic] = React.useState([]);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [getStatistics, setNeedsToReload] = React.useState(props.needsToReload);
  const [realAvailableBalance, setRealAvailableBalance] = useState(0)
  let idPlayer = props.Player.IdPlayer;

  let statsItems2 = [];
  const [statsItems, setStatsItems] = React.useState([]);

  useEffect(() => {
    getPlayerBalance(idPlayer);
    getPlayerStatatistics();
  }, [props.needsToReload,realAvailableBalance]);

  useEffect(() => {
    console.log("playerStatistic",playerStatistic)
  }, [playerStatistic]);
 

  const getPlayerBalance = (idPlayer) => {
    axios({
      method: "post",
      url: process.env.REACT_APP_DEF_PHP_URL + "/GetPlayerBalance.php",
      data: {
        idPlayer: idPlayer
      },
    })
      .then((response) => response.data)
      .then((data) => {
        setRealAvailableBalance(Math.round(data.data[0][7]*100)/100)
      })
  }

  const getPlayerStatatistics = () => {
    const url =
      process.env.REACT_APP_API_URL +
      '/PLAYERSTATISTIC' +
      '?filter=IdPlayer,eq,' +
      idPlayer;
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      response.data.records[0].AvailBalance = realAvailableBalance.toString();
      setPlayerStatistic(response.data.records[0]);             

      console.log("response.data.records[0]",response.data.records[0])
      for (let key in response.data.records[0]) {
        console.log("array",response.data.records[0])
        statsItems.push(<li key={key}>{response.data.records[0][key]}</li>);
        statsItems2[key] = response.data.records[0][key];
      }
    });

    setIsLoading(false);
  };
  function onAfterSaveCell(row, cellName, cellValue) {
    props.UpdatePlayer(row);
  }
  function onAfterSaveCellRelated(row, cellName, cellValue) {
    UpdateRelatedGame(row);
  }
  function UpdateRelatedGame(row) {
    let gameRelatedOptionsRequest = {
      IdPlayer: row.IdPlayer,
      IdSport: row.IdSport,
      IdPeriod: row.IdPeriod,
      MLFav_Over: row.MLFav_Over,
      MLFav_Under: row.MLFav_Under,
      MLDog_Over: row.MLDog_Over,
      MLDog_Under: row.MLDog_Under,
      SpreadFav_Over: row.SprFav_Over,
      SpreadFav_Under: row.SprFav_Under,
      SpreadDog_Over: row.SprDog_Over,
      SpreadDog_Under: row.SprDog_Under,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Game/GameRelatedOptions_Update',
      // url: 'https://localhost:44337/api/Game/GameRelatedOptions_Update',
      data: gameRelatedOptionsRequest,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
    });
  }

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  const cellEditRelatedGameProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCellRelated, // a hook for after saving cell
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/
  

  return (
    <React.Fragment>
      <Drawer
            anchor={'right'}
            open={props.isOpen}            
            onClose={props.toggleDrawer}
      >
        <div class="" style={{ minWidth: '350px' }}>
          <Table>
            <TableRow>
              <TableCell>
                <Table>
                  <TableCell>
                    <h2>Player Stats</h2>
                    {Object.keys(playerStatistic).map(function (key) {
                      if (key !== 'IdPlayer') {
                        if (isNaN(playerStatistic[key])) {
                          return (
                            <React.Fragment>
                              <TableRow>
                                <TableCell>
                                  <b>{key}:</b>
                                </TableCell>
                                <TableCell>{playerStatistic[key]} </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment>
                              <TableRow>
                                <TableCell>
                                  <b>{key}:</b>
                                </TableCell>
                                <TableCell>
                                  {new Intl.NumberFormat().format(
                                    playerStatistic[key]
                                  )}{' '}
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        }
                      }
                    })}{' '}
                  </TableCell>
                </Table>
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </Drawer>
    </React.Fragment>
  );
}
