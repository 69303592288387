import React, { useState, setState, useEffect, useContext } from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import InputSelect from "../../components/common/InputSelect";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import Loader from "../../components/common/loader/Loader";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import { DescriptionSharp } from '@material-ui/icons';

function ModifyLeagueMapping (props) {

    const useStyles = makeStyles((theme) => ({        
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },
        center: {
            margin: "0 auto",
            border: "1px grey black",
            textAlign: "center",
            width: "600px",
            height: "600px",
            "& .MuiTextField-root": {
              margin: theme.spacing(1),
              width: 500,
            },
            "& .MuiFormGroup-row": {
              justifyContent: "center",
            },
            "& .MuiFormControl-root": {
              justifyContent: "center",
            },
            "& .MuiSelect-select": {
                width: 220,
                spacing: theme.spacing(1),
            }
        },        
    }));
    
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [IdSport, setIdSport] = useState(props.rowSelected[0].idDGSidSport.trim());   
    const [lineTypeID, setlineTypeID] = useState(props.rowSelected[0].idDGSLineType);
    const [leagues, setLeagues] = useState();
    const [leagueID, setLeagueID] = useState(props.rowSelected[0].idDGSidLeague);
    const [gameTypeID, setGameTypeId] = useState(props.rowSelected[0].idDGSidGameType);
    const [dbLeague, setDbLeague] = useState(props.rowSelected[0].nameDonBestLeague.trim());
    const [dbLeagueId, setDbLeagueId] = useState(props.rowSelected[0].idDonBestLeague);
    const [isLoading, setIsLoading] = useState(false);  
    const [isRed, setIsRed] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [descriptions, setDescriptions] = useState([]);
    const [value, setValue] = useState(props.leagues.filter(v => v.IdLeague === props.rowSelected[0].idDGSidLeague)[0].Description);

    useEffect(() => {
        getLeagues(IdSport) 
        getDescriptions(props.leagues)    
    }, []);

    

    function changeSportID(value) {
        setIdSport(value);       
        getLeagues(value);           
    }

    function changeGameTypeId(value) {        
        setGameTypeId(value);             
    }

    function changeLineTypeID(value) {        
        setlineTypeID(value)      
    }

    const handleClose = () => {
        if (props.onClose) {
          props.onClose();
        }
        setOpen(false);
    };
    const getLeagues = (idSport) => {        
        const url =
          process.env.REACT_APP_API_URL + "/LEAGUE?filter=IdSport,eq," + idSport;
        Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            let leagueInfo = data.records.map((v) => {
              v.IdLineType = v.IdLeague;
              v.Description = v.Description;
              return v;
            });
            setLeagues(leagueInfo);
          });
      };

    const handleChangeDbLeague = (event) => {
        setDbLeague(event.target.value);
    };
    const handleChangeDbLeagueId = (event) => {
        if(event.target.value.match(/^[0-9]+$/)!=null || event.target.value===""){
            setDbLeagueId(event.target.value);    
        }       
    };    
    const handleOk = () => {        
        if(IdSport===0|| leagueID===-1 ||gameTypeID===0||lineTypeID===0||dbLeagueId===""||dbLeague===""){
            alert("Please provide the required fields");     
            setIsRed(true);                        
        }else{
            updateLeagueMapping();            
        }
    } 

    const getDescriptions = (leagues) => {
        let descriptions = [];
        leagues.map((v) => {             
            if(v.IdSport.trim()==props.rowSelected[0].idDGSidSport.trim())             
            descriptions.push(v.Description);
        });
        console.log(descriptions);
        setDescriptions(descriptions);               
    }

    const updateLeagueMapping = () => {
        let leagueMappingRequest = {
            idDonBestLeague: dbLeagueId,
            nameDonBestLeague: dbLeague,
            idDGSidSport: IdSport,
            idDGSidLeague: leagueID===-1?props.rowSelected[0].idDGSidLeague:leagueID,           
            idDGSidGameType: gameTypeID,
            idDGSLineType: lineTypeID,
            idASIidStore: null,
            idASIidSport: null,
            idASIidSportSubType: null,
            idDBHouse: null,
            time: null,
            pointSpd: null,
            moneyline: null,
            offsetTotals: null,
            minRangePointSpd: null,
            minRangeMoneyline: null,
            minRangeTotals: null,
            maxRangePointSpd: null,
            maxRangeMoneyline: null,
            maxRangeTotals: null,
            roundUp: null,
        };
        setIsLoading(true); 
        console.log("league mapping request",leagueMappingRequest);
        Axios({
            method: 'put',
            url: process.env.REACT_APP_API_URL + '/AddOns_ALMLeagueReference/'+props.rowSelected[0].idReference,
            data: leagueMappingRequest,
        }).then((response) => {
            console.log(response)
            setIsModified(true);
            alert("The League mapping was successfully updated Reference ID: "+props.rowSelected[0].idReference)            
        })
        setIsLoading(false) 
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <AppBar className={classes.appBar}>            
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                        Modify Mapping
                        </Typography>
                    </Toolbar>
                </AppBar>  
                    <form className={classes.center}>
                        <Divider />
                            <Typography variant="h6" className={classes.title}>
                                Don Best Information
                            </Typography>
                            <FormGroup row>                                
                                <TextField                                
                                    variant="outlined"                            
                                    label="League Name"
                                    type="text"
                                    value={dbLeague}
                                    onChange={handleChangeDbLeague}
                                    fullWidth   
                                    {...isRed&&dbLeague===""?(
                                        {error:true}
                                    ):("")}                                                
                                />
                                <TextField                                
                                    variant="outlined"                            
                                    label="Id League"
                                    type="text"                                
                                    value={dbLeagueId}
                                    onChange={handleChangeDbLeagueId}
                                    fullWidth    
                                    {...isRed&&dbLeagueId===""?(
                                        {error:true}
                                    ):("")}                                              
                                />                                
                            </FormGroup>
                        <Divider />
                            <Typography variant="h6" className={classes.title}>
                                DGS Information
                            </Typography>
                            <FormGroup row>
                                <InputSelect
                                    variant="outlined"
                                    name="name"
                                    data={props.sports}
                                    placeholder="Sports"
                                    setSelectValue={changeSportID}
                                    startValue={IdSport}
                                    {...isRed&&IdSport===0?(
                                        {isError:true}
                                    ):("")} 
                                />
                                <InputSelect
                                    variant="outlined"
                                    name="name"
                                    data={props.lineTypes}
                                    placeholder="Line Type"
                                    setSelectValue={changeLineTypeID}
                                    startValue={lineTypeID}
                                    {...isRed&&lineTypeID===0?(
                                        {isError:true}
                                    ):("")} 
                                />                                
                            </FormGroup>
                        <Divider/> 
                            <FormGroup row>
                                <Autocomplete                                    
                                    disablePortal
                                    id="League"
                                    options={descriptions}                                   
                                    value={value}                                                                   
                                    onChange={(event, newValue) => {                                        
                                        if(newValue===null){
                                            setLeagueID(-1)  
                                            setValue("")  
                                            console.log("league",leagueID)                                                                                                                    
                                        }else{
                                            for(let i=0;i<leagues.length;i++){
                                                if(leagues[i].Description===newValue){
                                                    setLeagueID(leagues[i].IdLeague)                                                    
                                                }                                        
                                            }  
                                            console.log("league",leagueID)                                                                                                                              
                                            setValue(newValue); 
                                        }                                           
                                    }}                        
                                    renderInput={(params) => 
                                        <TextField 
                                        {...isRed&&leagueID===-1?(
                                            {error:true}
                                        ):("")} 
                                        {...params} 
                                        label="League Name" 
                                        fullWidth 
                                        />}
                                    />
                            </FormGroup> 
                            <Divider/> 
                            <FormGroup row>
                                <InputSelect                                    
                                    variant="outlined"
                                    name="name"
                                    data={props.gameTypes}
                                    placeholder="Type"
                                    setSelectValue={changeGameTypeId}
                                    startValue={gameTypeID}  
                                    {...isRed&&gameTypeID===0?(
                                        {isError:true}
                                    ):("")}                                   
                                />
                            </FormGroup>                                              
                    </form>
                    <React.Fragment>
                        <DialogActions>
                            {isModified ? (                                
                                <FormControl className={classes.formControl}>
                                    <InputBase
                                    style={{
                                        color: "red",
                                        fontSize: 35,
                                        fontWeight: "bold",
                                    }}
                                    className={classes.margin}
                                    defaultValue="Done!"
                                    inputProps={{ "aria-label": "naked" }}
                                    />
                                </FormControl>                                
                            ) : (
                                ""
                            )}
                            {isLoading ? (
                            <Loader height={100} width={100}></Loader>
                            ) : (
                            <div>                                
                                <Button
                                startIcon={<SaveIcon />}
                                color="inherit"
                                onClick={handleOk}
                                >
                                Ok
                                </Button>
                            </div>
                            )}                            
                        </DialogActions>                                            
                    </React.Fragment>   
            </Dialog>
        </React.Fragment>
    );
}
export default ModifyLeagueMapping;