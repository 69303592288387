import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';


/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from '../../../components/common/InputSelect';

/*Other Components*/
import Sports from '../../../components/common/Sports';
import Periods from '../../../components/common/Periods';


/*Loader */
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import ReactLoading from "react-loading";

/*animation*/
import * as legoData from "../../common/animations/legoLoader.json"
/*Inputs*/
/*Styles*/
import './ProfileSettings.css';



const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const priceFormatter = (cell, row) => {
  let isSelected = '';
  let isSelected0 = '';
  let isSelected1 = '';
  let isSelected2 = '';
  let isSelected3 = '';
  let isSelected4 = '';
  console.log('Formatting cell', cell);
  switch (cell) {
    case 0:
      isSelected0 = 'checked';
      break;
    case 1:
      isSelected1 = 'checked';
      break;
    case 2:
      isSelected2 = 'checked';
      break;
    case 3:
      isSelected3 = 'checked';
      break;
    case 4:
      isSelected4 = 'checked';
      break;
    default:
      isSelected = 'checked';
  }
  console.log('Formatting cell isSelected0', isSelected0);
  return `
    <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
    <label for="isSelected0">Keep Price</label><br>
    <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
    <label for="isSelected1">Credit use line, Cash Adjust win amount</label><br>
    <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
    <label for="isSelected2">Adjust Risk Amount</label><br>
    <input type="radio" id="isSelected3" name="age" value="3" ${isSelected3}>
    <label for="isSelected3">Adjust Win Amount</label><br>
    <input type="radio" id="isSelected4" name="age" value="4" ${isSelected4}>
    <label for="isSelected4">UseLine</label><br>

 `;
};
export default function ProfileSettings(props) {
  const classes = useStyles();
  const [gameRelatedOptions, setGameRelatedOptions] = React.useState([]);
  const [idSport, setIdSport] = React.useState(['CBB']);
  const [period, setPeriod] = React.useState(['0']);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  let idProfile = props.Profile.IdProfile;

  //let IdPeriod = '0';
  useEffect(() => {    
    getGameRelatedOptions();
  }, [idSport, period, idProfile]);
  // const [selectedIdGame, setselectedIdGame] = useState();

  const getGameRelatedOptions = async () => {
    const url =
    process.env.REACT_APP_API_URL +
    '/GAMERELATEDOPTIONS' +
    '?filter=IdProfile,eq,' +
    idProfile +
    '&filter=IdSport,eq,' +
    idSport +
    '&filter=IdPeriod,eq,' +
    period;
    await axios({
      method: 'get',
      url: url,
    }).then((response) => {
      console.log('PROPS====== REST THEN>', response.data.records[0]);
      setGameRelatedOptions(response.data.records[0]);
    });

    setIsLoading(false);
  } 

  function UpdateRelatedGame(row) {
    let gameRelatedOptionsRequest = {
      IdProfile: row.IdProfile,
      IdSport: row.IdSport,
      IdPeriod: row.IdPeriod,
      MLFav_Over: row.MLFav_Over,
      MLFav_Under: row.MLFav_Under,
      MLDog_Over: row.MLDog_Over,
      MLDog_Under: row.MLDog_Under,
      SpreadFav_Over: row.SprFav_Over,
      SpreadFav_Under: row.SprFav_Under,
      SpreadDog_Over: row.SprDog_Over,
      SpreadDog_Under: row.SprDog_Under,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL+'/Game/GameRelatedOptions_Update',
      //url: 'https://localhost:44337/api/Game/GameRelatedOptions_Update',
      data: gameRelatedOptionsRequest,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  }

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const onBeforeSaveCell = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){
      
      done(true);
    }else{
      done(false);
    }  
    return 1
  } 

  const onAfterSaveCell = (row, cellName, cellValue) => {
    props.UpdateProfile(row);  
  }

  const onAfterSaveCellRelated = (row, cellName, cellValue) => {
    UpdateRelatedGame(row); 
  }

  const onBeforeSaveCellRelated = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){       
      done(true);
    }else{
      done(false);
    }
    return 1  
  } 

  const cellEditProp = {
    mode: 'click',   
    beforeSaveCell: onBeforeSaveCell,
    afterSaveCell: onAfterSaveCell,
    blurToSave: true,
  };

  const cellEditRelatedGameProp = {
    mode: 'click',
    beforeSaveCell: onBeforeSaveCellRelated,  
    afterSaveCell: onAfterSaveCellRelated,
    blurToSave: true,
  };

  const setIdSportOnMem = (IdSport) => {
    setIdSport(IdSport);
  };

  const setPeriodOnMem = (period) => {
    setPeriod(period);
    console.log('PROPS====== REST setPeriodOnMem>', period);
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
    }
  }
  /**/

  
  console.log('PROPS====== Settings setPeriodOnMem>', isLoading);
  return (
    <React.Fragment>
    <div class="main-div">
    
    { isLoading ? (
      <FadeIn>
  <div class="d-flex justify-content-center align-items-center">
    
    <Lottie options={defaultOptions} height={360} width={360} />                      
  </div>
</FadeIn>
    ): ''}
    <Table>
    <TableRow>
            <TableCell colSpan="2">
              <b>Profile Info</b>
              <br></br>

              <BootstrapTable
                data={[props.Profile]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>

               

                <TableHeaderColumn dataField="ProfileName">
                  ProfileName
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="AlternateEnable"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  AlternateEnable
                </TableHeaderColumn>
                <TableHeaderColumn dataField="AlternateProfile">
                AlternateProfile
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Notes">
                  Notes
                </TableHeaderColumn>
             
              
              </BootstrapTable>
            </TableCell>
          </TableRow>

   
      <TableRow>
        <TableCell colSpan="3">
          <Table>
            <TableCell>
              {' '}
              <b>  Pitcher Change Type</b>
              {/**/}
              <BootstrapTable data={[props.Profile]}>
                <TableHeaderColumn
                  isKey={true}
                  dataField="PC_PitcherChangeType"
                  dataFormat={priceFormatter}
                >
                
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
            <TableCell>
              {' '}
              {/* */}
              <TableHeaderColumn>MLB Pitcher Change</TableHeaderColumn>
              <BootstrapTable
                data={[props.Profile]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="PC_SkipSide"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  PC_SkipSide
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="PC_SkipTotal"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  PC_SkipTotal
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="PC_SkipRunLine"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  PC_SkipRunLine
                </TableHeaderColumn>
              </BootstrapTable>
              <TableHeaderColumn>MLB Pitcher Change</TableHeaderColumn>
              <BootstrapTable
                data={[props.Profile]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="SG_CancelSide"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  SG_CancelSide
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="SG_CancelTotal"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  SG_CancelTotal
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="SG_CancelRunLine"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  SG_CancelRunLine
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </Table>
        </TableCell>
      </TableRow>

      <TableRow style={{ backgroundColor: 'white' }}>
        <TableCell>
          <div class="select-input-general">
            <b>Related Options By Game</b>
          </div>
          <div class="select-input-general">
            {}

            <Sports
              /* showDataTable={true}*/ setIdSportOnMem={setIdSportOnMem}
            ></Sports>
          </div>
          <div class="select-input-general">
            {}

            <Periods
              /* showDataTable={true}*/ setPeriodOnMem={setPeriodOnMem}
              filterBy={idSport}
            ></Periods>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
        {gameRelatedOptions && (
          <div>
          <BootstrapTable
            data={[gameRelatedOptions]}
            cellEdit={cellEditRelatedGameProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="MLFav_Under"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Fav Money Line / Total Under
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="MLFav_Over"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Fav Money Line / Total Over
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="MLDog_Over"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Dog Money Line / Total Over
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="MLDog_Under"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Dog Money Line / Total Under
            </TableHeaderColumn>
          </BootstrapTable>

          <BootstrapTable
            data={[gameRelatedOptions]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="SprFav_Over"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Fav Spread / Total Over
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="SprFav_Under"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Fav Spread / Total Under
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="SprDog_Over"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Dog Spread / Total Over
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="SprDog_Under"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Dog Spread / Total Under
            </TableHeaderColumn>
          </BootstrapTable>
          </div>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan="2">
          <hr></hr>
          <b>Duplicated Bets Options</b>

          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="DuplicateBetsCheckLineChange"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              LineChange
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="DuplicateBetsCheckParlays"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Parlays
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="DuplicateBetsCheckTeasers"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Teasers
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="DuplicateBetsCheckReverses"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Reverses
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="DuplicateBetsCheckIfbets"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              If Bets
            </TableHeaderColumn>
          </BootstrapTable>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Parlays</b>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="DuplicateBetsCheckParlaysSB"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Check Parlay with SB
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="DuplicateBetsCheckSameParlay"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Check Parlay with the same Details
            </TableHeaderColumn>
          </BootstrapTable>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Teasers</b>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="DuplicateBetsCheckTeasersSB"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Check Teasers with SB
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="DuplicateBetsCheckSameTeaser"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Check Teaser with the same Details
            </TableHeaderColumn>
          </BootstrapTable>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Others</b>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="DuplicateBetsCheckIfSBRev"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Check If Bets & Reverses with Startight
            </TableHeaderColumn>
          </BootstrapTable>

          <TableRow>
            <TableCell colSpan="2">
              <b>Free Plays</b>
              <br></br>

              <BootstrapTable
                data={[props.Profile]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>

                <TableHeaderColumn dataField="FPMaxPayout">
                  FPMaxPayout
                </TableHeaderColumn>

                <TableHeaderColumn dataField="FPOddsLimit">
                  FPOddsLimit
                </TableHeaderColumn>

                <TableHeaderColumn dataField="FPMaxTeams">
                  FPMaxTeams
                </TableHeaderColumn>

                <TableHeaderColumn dataField="FPMaxFav">
                  FPMaxFav
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="FPAllowDuplicatedBets"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  FPAllowDuplicatedBets
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="FPAllowBothSides"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  FPAllowBothSides
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="FPCheckOfficeFilters"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  FPCheckOfficeFilters
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan="2">
          <b>Card Limits</b>
          <br></br>

          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="CLMaxWager">
              Max Wager
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="Soc_Hookups"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Allow Hookups in Related Soccer Games
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="Rev_AllowOpen"
              editable={{
                type: 'checkbox',
                options: {
                  values: 'true:false',
                },
              }}
            >
              Allow Open Sports in Reverses
            </TableHeaderColumn>
          </BootstrapTable>
        </TableCell>
      </TableRow>
    </Table>
    
    </div>
    </React.Fragment>
  );
}
