import React, { useState, setState, useEffect, useContext } from 'react';
import Loader from "../../components/common/loader/Loader";
import Axios from 'axios';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputSelect from "../../components/common/InputSelect";
import { DataGrid, esES } from '@material-ui/data-grid'
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from "@material-ui/icons/AddBox";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";

function OddsBySport (props) {

    const [lineTypes,setLineTypes] = useState([]);
    const [gameTypes, setGameTypes] = useState([]);
    const [sports, setSports] = useState([]);
    const [isLoading,setIsLoading] = useState(false)
    const [idSport,setIdSport] = useState('CBB');
    const [lineTypeID, setlineTypeID] = useState(55);
    const [gameTypeId, setGameTypeId] = useState(1);
    const [lineTypeIndex, setLineTypeIndex] = useState(13);
    const [lineId,setLineId] = useState(2)
    const [row, setRow] = useState([]);
    const [selectedRow,setSelectedRow] = useState([]);
    const [oddsBySport,setOddsBySport] = useState([]); 
    const [range,setRange] = useState(0)
    const [juice,setJuice] = useState(0)
    const [open, setOpen] = useState(false);
    const [isRed, setIsRed] = useState(false);
    const [isInserted, setIsInserted] = useState(false);
    const [lines, setLines] = useState([])
    
    const columns = [
      { field: 'id', headerName: 'Id', width: 0, editable: false, sortable: false, filterable: false, hide:true },  
      { field: 'range', headerName: 'Range', width: 150, editable: false, sortable: false, filterable: false },
      { field: 'juice', headerName: 'Juice', width: 150, editable: true, sortable: false, filterable: false}
    ]
    const [userInfo, setUserInfo] = useState(
      JSON.parse(sessionStorage.getItem("userInfo"))
    );
    
    const useStyles = makeStyles((theme) => ({
        root: {
          width: '100%',
          overflowX: 'auto',
        },
        appBar: {
          position: "relative",
        },
        center: {
          marginTop:"10px",
          display:"flex",
          margin: "0 auto",
          border: "1px grey black",
          textAlign: "center",
          height: "300px",
          "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: 200,
          },
          "& .MuiFormGroup-row": {
            justifyContent: "center",
          },
          "& .MuiFormControl-root": {
            justifyContent: "center",
          }
        }
    }));
    const classes = useStyles();
    
  
    const getLineTypes = async () => {
        const url = process.env.REACT_APP_API_URL + "/LINETYPE";
        await Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setLineTypes(data.records);
          });
    };

    const getGameTypes = async () => {
        const url = process.env.REACT_APP_API_URL + "/GAMETYPE";
        await Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setGameTypes(data.records);
          });
    };

    const getSports = async () => {
        const url = process.env.REACT_APP_API_URL + "/SPORT";
        await Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setSports(data.records);
          });
    };
    
    const getOddsBySport = async () => {
      const url = process.env.REACT_APP_API_URL +
      '/ODDSBYSPORT?filter=IdLineType,eq,' +
      lineTypeID +
      '&filter=IdSport,eq,' +
      idSport +
      '&filter=IdGameType,eq,' +
      gameTypeId +
      '&filter=Line,eq,' +
      (lineId + 1)
      await Axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setOddsBySport(data.records);
          feedRow(data.records)
          setIsLoading(false)
        });
    }
    
    const calculateLines = () => {
      let aux = [];
      if(idSport === 'CBB' || idSport === 'CFB' || idSport === 'NBA' || idSport === 'NFL' ){
        aux.push(
          {id:1,Description:'Spread'},
          {id:2,Description:'Total'},
          {id:3,Description:'Money Line'}
        )
      }else 
        if(idSport === 'TNT' || idSport === 'ESOC' ||  idSport === 'PROP'){
          aux.push(
            {id:1,Description:'Money Line'}
          )
        }else 
          if(idSport === 'MLB'){
            aux.push(
              {id:1,Description:'Money Line'},
              {id:2,Description:'Total'},
              {id:3,Description:'Run Line'}
            )
          }else 
            if(idSport === 'MU'){
              aux.push(
                {id:1,Description:'Money Line'},
                {id:2,Description:'Total'},
                {id:3,Description:'Spread'}
              )
            }else 
              if(idSport === 'NHL'){
                aux.push(
                  {id:1,Description:'Money Line'},
                  {id:2,Description:'Total'},
                  {id:3,Description:'Canadian Lines'},
                  {id:4,Description:'American Lines'}
                )
              }else 
                if(idSport === 'NHL'){
                  aux.push(
                    {id:1,Description:'Money Line'},
                    {id:2,Description:'Total'},
                    {id:3,Description:'Goals'}
                  )
                }  
      setLines(aux);
    }  

    const updateOddsBySport = async (e) => {
      setIsLoading(true)

      let oddsBySport={
        idSport: idSport,
        idLineType: lineTypeID,
        line: (lineId + 1),
        idGameType: gameTypeId,
        range:range,
        oddsValue: e.value,
        idUser: userInfo.UserId
      }     

      console.log("oddsBySport",oddsBySport)
      
      const url = process.env.REACT_APP_DEF_PHP_URL + '/OddsBySportUpdate.php'
      Axios({
        method: 'post',
        url: url,
        data: oddsBySport,
      }).then((response) => {
          setIsLoading(false)
          console.log("odd response",response)
        });
    }

    const insertOddsBySport = async () => {
      let oddsBySport={
        idSport: idSport,
        idLineType: lineTypeID,
        line: (lineId + 1),
        idGameType: gameTypeId,
        range:range,
        oddsValue: juice,
        idUser: userInfo.UserId
      }     

      console.log("oddsBySport",oddsBySport)
      
      const url = process.env.REACT_APP_DEF_PHP_URL + '/OddsBySportInsert.php'
      Axios({
        method: 'post',
        url: url,
        data: oddsBySport,
      }).then((response) => {
          setIsLoading(false)
          setIsInserted(true)
          console.log("odd response",response)
          getOddsBySport()
          setRange(0)
          setJuice(0)
        });
    }

    const deleteOddsBySport = async () => {
      let oddsBySport={
        idSport: idSport,
        idLineType: lineTypeID,
        line: (lineId + 1),
        idGameType: gameTypeId,
        range:range,
        idUser: userInfo.UserId
      }     

      console.log("oddsBySport",oddsBySport)

      const url = process.env.REACT_APP_DEF_PHP_URL + '/OddsBySportDelete.php'
      Axios({
        method: 'post',
        url: url,
        data: oddsBySport,
      }).then((response) => {
          setIsLoading(false)
          setIsInserted(true)
          console.log("odd response",response)
          getOddsBySport();
        });
    }

    useEffect (() => { 
        getLineTypes();
        getGameTypes();
        getSports();
        calculateLines();  
        getOddsBySport();   
              
    },[])

    useEffect(() => {
        setLineId(idSport === 'CBB' || idSport === 'CFB' || idSport === 'NBA' || idSport === 'NFL' ? 2 : 0)
    }, [idSport]);

    useEffect(() => {
        setLoader();
        getOddsBySport();
        calculateLines();
    }, [idSport, lineTypeID, gameTypeId,lineId,range]);

    const setLoader = () => {
        if(sports.length === 0 || lineTypes.length === 0 || gameTypes.length === 0) {
            setIsLoading(true);
        }else{
            setIsLoading(false);
        }      
    }
    
    const feedRow = (oddsBySport) => {            
      let rowtemp = []
      for (let i = 0; i < oddsBySport.length; i++) {
        rowtemp.push({
          id:i,
          range: oddsBySport[i].Range,
          juice: oddsBySport[i].OddsValue
        })
      }
      setRow(rowtemp);    
    }
    
    const handleRowSelection = (row) => {
      setSelectedRow(row)                  
    }

    function changeSportID(value) {
        setIdSport(value);        
    }

    function changegameTypeId(value) {
        setGameTypeId(gameTypes[value].IdGameType);
    }

    function changeLineTypeID(value) {    
        let linetype = lineTypes[value];
        setLineTypeIndex(value);    
        setlineTypeID(linetype.IdLineType);
    }

    function changeLineId(value){
      setLineId(value)
    }

    function handleCell (e){
      console.log(e)
      updateOddsBySport(e)
    }    
    
    function handleRow (e){
      console.log(e)
      setRange(e.row.range)
    }

    function handleDelete () {
      if(selectedRow.length==0){
        alert("Please select a row to edit");
      }else{
        if (window.confirm("Are you sure you want to delete the selected item?")){
          deleteOddsBySport();  
        }        
      }
    }
    function handleNew () {
      setOpen(true)
    }

    function handleClose () {
      setOpen(false);
      setIsRed(false);
      setRange(0);
      setJuice(0);
    }

    const handleChangeRange = (event) => {
      setRange(event.target.value);
    }

    const handleChangeJuice = (event) => {
      setJuice(event.target.value);
    }

    const handleOk = () => {
      if(range === 0 || juice === 0){
          setIsRed(true);
          alert("Please provide the required fields");
      }else{
        insertOddsBySport();            
      }
    }   


    const newOddBySport = () => {
      return(
        <React.Fragment>
            <Dialog  style={{width: "50%", height: "38%", margin: "0 auto", marginTop: "100px"}} open={open} onClose={handleClose}>
                <AppBar className={classes.appBar}>            
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          New Odd By Sport
                        </Typography>
                    </Toolbar>
                </AppBar>  
                <form className={classes.center}>
                  <FormGroup row>                                
                    <TextField                                
                      variant="outlined"                            
                      label="Range"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      value={range}
                      {...isRed && range === 0?(
                          {error:true}
                      ):("")}
                      onChange={handleChangeRange}
                      fullWidth                                                                       
                    />                        
                  </FormGroup>
                  <FormGroup row>                                
                    <TextField                                
                      variant="outlined"                            
                      label="Juice"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      value={juice}
                      {...isRed && juice === 0?(
                          {error:true}
                      ):("")}
                      onChange={handleChangeJuice}
                      fullWidth                                                                       
                    />                        
                  </FormGroup>
                </form>
                <React.Fragment>
                  <DialogActions>
                      {isInserted ? (                                
                          <FormControl className={classes.formControl}>
                              <InputBase
                                style={{
                                    color: "red",
                                    fontSize: 35,
                                    fontWeight: "bold",
                                }}
                                className={classes.margin}
                                defaultValue="Done!"
                                inputProps={{ "aria-label": "naked" }}
                              />
                          </FormControl>                                
                      ) : (
                          ""
                      )}
                      {isLoading ? (
                      <Loader height={100} width={100}></Loader>
                      ) : (
                      <div>                                
                          <Button
                          startIcon={<SaveIcon />}
                          color="inherit"
                          onClick={handleOk}
                          >
                          Ok
                          </Button>
                      </div>
                      )}                            
                  </DialogActions> 
                </React.Fragment>
            </Dialog>
          </React.Fragment>
      )
    }

    return(
        <React.Fragment>
            {isLoading ? (                  
            <Loader height={100} width={100}></Loader>
            ):("")}
            <Paper className={classes.root}>
              <br />
              <br /> 
              <h2>Odds By Sport</h2>
            </Paper>
            {open ? (                  
              newOddBySport()
            ):("")}
            <div style={{display:"flex", gap:"100px"}}>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '50ch' },
                        borderRight:"1px solid"
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <InputSelect                             
                        variant="outlined"
                        name="name"
                        data={lineTypes}
                        placeholder="LineTypes"
                        setSelectValue={changeLineTypeID}                        
                        startValue={lineTypeID}
                        idLineType={lineTypeIndex}  
                        fixedWidth={true}                      
                    />
                    <InputSelect
                        variant="outlined"
                        name="name"
                        data={gameTypes}
                        placeholder="Game Type"
                        setSelectValue={changegameTypeId}
                        startValue={gameTypes.find(id => id.IdGameType == gameTypeId)}
                        fixedWidth={true} 
                    />
                    <InputSelect
                        variant="outlined"
                        name="name"
                        data={sports}
                        placeholder="Sports"
                        setSelectValue={changeSportID}
                        startValue={idSport}
                        fixedWidth={true} 
                    />
                    <InputSelect
                        variant="outlined"
                        name="name"
                        data={lines}
                        placeholder="Line"
                        setSelectValue={changeLineId}
                        startValue={lineId}
                        fixedWidth={true} 
                        key={lineId}
                    />
                </Box>
                <Box sx={{ height: 500, width: '350px', marginBottom:"10px",marginTop:"20px"}}>
                  <DataGrid                  
                    rows={row}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[5]}            
                    disableColumnSelector
                    disableColumnMenu
                    onSelectionModelChange={handleRowSelection}                                    
                    disableMultipleSelection={true}    
                    onCellEditCommit={handleCell}   
                    onRowClick={handleRow}                              
                  />                
                </Box>
                <div style={{marginTop:"20px"}}>
                  <label style={{padding:"8px",fontSize:"150%"}}>
                    <DeleteIcon                 
                      aria-label="Delete"
                      size="small"                  
                      style={{ cursor: "pointer" }}
                      onClick={handleDelete}
                    />{" "}
                    Delete
                  </label>  
                  <br />
                  <label style={{padding:"8px",fontSize:"150%"}}>
                    <AddBoxIcon                 
                      aria-label="newGame"
                      size="small"
                      className={classes.fab}
                      style={{ cursor: "pointer" }}
                      onClick={handleNew}
                    />{" "}
                    Add New
                  </label> 
                </div>
            </div>   
            <Divider></Divider>  
        </React.Fragment>
             
    )
}
export default OddsBySport