import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Loader from "../../components/common/loader/Loader";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from '@material-ui/data-grid'
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditIcon from '@material-ui/icons/Edit';
import CreateSport from '../../components/common/CreateSport';

export default function Sports(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [sports, setSports] = React.useState([]);
  const [selectedSport, setSelectedSport] = React.useState(['CBB  ']);
  const [row, setRow] = useState([]);
  const [sportSelected, setSportSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isNew, setIsNew] = useState(false);
  
  const columns = [
    { field: 'IdSport', headerName: 'Id Sport', width: 100, editable: false, sortable: false, filterable: false },
    { field: 'SportName', headerName: 'Sport Name', width: 250, editable: false, sortable: false, filterable: false},
    { field: 'SportOrder', headerName: 'Sport Order', width: 250, editable: false, sortable: false, filterable: false},
    { field: 'OnlineStatus', headerName: 'Online Status', width: 250, editable: false, sortable: false, filterable: false},
    { field: 'LocalStatus', headerName: 'Local Status', width: 250, editable: false, sortable: false, filterable: false},
  ]
  
  
  const handleRowSelection = (row) => {
    setSportSelected(row)                  
  } 

  const builtRows = (sports) => {
      let row = [];
      sports.forEach(sport => {
        row.push({
          IdSport: sport.IdSport,
          SportName: sport.SportName,
          SportOrder: sport.SportOrder,
          OnlineStatus: sport.OnlineStatus,
          LocalStatus: sport.LocalStatus
        });
      });
      setRow(row);
  }

  const handleCloseCreate = function () {
    setIsNew(false);  
    setIsEdit(false);  
  };

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + '/SPORT';
    axios({
      method: 'get',
      url: url,
    }).then((response) => {
      console.log('PROPS====== REST THEN>', response.data.records);
      setSports(response.data.records);
      builtRows(response.data.records);         
    });
  }, [isEdit,isNew]);

  function selectedValues(row) {
    console.log(`You click row id: ${row.IdProfile}`);

    // setRedirect(row);
  }

  /*Select Functions*/

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
    props.setIdSportOnMem(event.target.value);
    setSelectedSport(event.target.value);
    console.log('in Sports  event.target', event.target.value);
  };
  /*End Select Functions*/

  if (props.showDataTable) {
    return (      
      <React.Fragment>        
          {isLoading ? (                  
            <Loader height={100} width={100}></Loader>
          ):("")}
          <Paper className={classes.root}>
              <br />
              <br /> <h2>Sports</h2>
              <label style={{padding:"8px",fontSize:"150%"}}>
                <AddBoxIcon                 
                  aria-label="newGame"
                  size="small"
                  className={classes.fab}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsNew(true);                      
                  }}
                />{" "}
                Add New
              </label>  
              <label style={{padding:"8px",fontSize:"150%"}}>
                <EditIcon                 
                  aria-label="editGame"
                  size="small"
                  className={classes.fab}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if(sportSelected.length > 0){
                      setIsEdit(true)
                    }else{
                      alert("Please select a row to edit")
                    }                     
                  }}
                />{" "}
                Edit
              </label>               
              <div style={{ height: 500, width: '100%' }}>
                  <DataGrid
                    rows={row}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[5]}            
                    disableColumnSelector
                    disableColumnMenu
                    onSelectionModelChange={handleRowSelection}                                    
                    disableMultipleSelection={true}    
                    getRowId={(row)=> row.IdSport}                                     
                  />
              </div>
          </Paper>
          {isNew || isEdit?(        
            <CreateSport
              onClose={handleCloseCreate}   
              title={isNew ? "Create New Sport" : "Edit Sport"}  
              isEdit={isEdit}    
              sportSelected={sports.filter(sport => sport.IdSport == sportSelected)}  
              sports={sports}
            />          
          ):(
            "" 
          )}             
        </React.Fragment>        
    );
  } else if (props.showMinimalSelect) {
    return (
      <React.Fragment>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Sport
          </InputLabel>

          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            //value={sports[0] && sports[0]['IdSport']}
            value={selectedSport}
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            <MenuItem value={'NFL  '}>NFL</MenuItem>
            <MenuItem value={'CFB  '}>CFB</MenuItem>
            <MenuItem value={'NBA  '}>NBA</MenuItem>
            <MenuItem value={'CBB  '}>CBB</MenuItem>
          </Select>

          <FormHelperText>Select Sport</FormHelperText>
        </FormControl>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Sport
          </InputLabel>
          {sports[0] && (
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              //value={sports[0] && sports[0]['IdSport']}
              value={selectedSport}
              onChange={handleChange}
              displayEmpty
              className={classes.selectEmpty}
            >
              {sports.map((sport) => (
                <MenuItem /*key={sport.IdSport}*/ value={sport.IdSport}>
                  {sport.SportName}
                </MenuItem>
              ))}
            </Select>
          )}

          <FormHelperText>Select Sport</FormHelperText>
        </FormControl>
      </React.Fragment>
    );
  }
}
