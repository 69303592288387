import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import InputSelect from '../../../components/common/InputSelect';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Loader from '../../../components/common/loader/Loader';

export default function GameModify(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [hideGame, setHideGame] = useState(false);
  const [hideSpread, setHideSpread] = useState(false);
  const [hideTotal, setHideTotal] = useState(false);
  const [hideMoneyline, setHideMoneyline] = useState(false);
  const [idAgent, setIdAgent] = useState(0);
  const [agentList, setAgentList] = useState();
  const [gameValues, setGameValues] = useState();
  const [loading, setLoading] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [result, setResult] = React.useState(0);
  const [valueHasChanged, setValueHasChanged] = useState(1);

  const handleChangeHideGame = () => {
    setHideGame(!hideGame);
  };
  const handleChangeHideSpread = () => {
    setHideSpread(!hideSpread);
  };
  const handleChangeHideTotal = () => {
    setHideTotal(!hideTotal);
  };
  const handleChangeHideML = () => {
    setHideMoneyline(!hideMoneyline);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };

  useEffect(() => {
    if (props.IdGame.length > 0) {
      const url = process.env.REACT_APP_API_URL + '/AGENT';
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          let agentInfo = data.records.map((v) => {
            v.IdLineType = v.IdAgent;
            v.Description = v.Agent;
            return v;
          });
          setAgentList(agentInfo);
        })

        .then(() => {
          const url =
            process.env.REACT_APP_API2_URL +
            '/Game/GameValuesByAgent?idGame=' +
            props.IdGame[0] +
            '&IdAgent=' +
            idAgent;
          axios
            .get(url)
            .then((response) => response.data)
            .then((data) => {
              console.log('data==========>', data);
              data.data.forEach((x) => {
                setHideGame(x.hideGame);
                setHideSpread(x.hideSpread);
                setHideTotal(x.hideTotal);
                setHideMoneyline(x.hideMoneyline);
              });
            })
            .then(setLoading(false));
        });
    }
  }, []);

  function changeAgentID(value) {
    console.log('changeLeagueID', value);
    setIdAgent(value);
  }

  const HideValues = () => {
    let request = {
      HideGame: hideGame,
      HideSpread: hideSpread,
      HideTotal: hideTotal,
      HideML: hideMoneyline,
      IdGame: props.IdGame[0],
      IdAgent: idAgent,
      IdSport: props.IdSport,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      //url: process.env.REACT_APP_API2_URL + '/Game/HideGameValues',
      url: 'https://localhost:44337/api/Game/HideGameValues',

      data: request,
    }).then((response) => {
      console.log('response', response);
      // props.searchGameValues();
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Hide Game Values per Agent
            </Typography>
          </Toolbar>
        </AppBar>
        {loading ? (
          <CircularProgress style={{ margin: '0 auto' }} />
        ) : (
          <React.Fragment>
            {agentList && (
              <React.Fragment>
                <br />
                <form className={classes.center}>
                  <React.Fragment>
                    <FormGroup row>
                      <FormControl className={classes.formControl}>
                        <InputSelect
                          variant="outlined"
                          name="name"
                          data={agentList}
                          placeholder="Agents"
                          setSelectValue={changeAgentID}
                          startValue={idAgent}
                        />
                      </FormControl>
                    </FormGroup>
                    <Divider />
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={hideGame}
                            onChange={handleChangeHideGame}
                            color="primary"
                          />
                        }
                        label="Hide Game"
                      />

                      {props.IdSport != 'TNT' ? (
                        <React.Fragment>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={hideSpread}
                                onChange={handleChangeHideSpread}
                                color="primary"
                              />
                            }
                            label="Hide Spread"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={hideTotal}
                                onChange={handleChangeHideTotal}
                                color="primary"
                              />
                            }
                            label="Hide Total"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={hideMoneyline}
                                onChange={handleChangeHideML}
                                color="primary"
                              />
                            }
                            label="Hide Moneyline"
                          />
                        </React.Fragment>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                    <Divider />
                  </React.Fragment>
                </form>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <DialogActions>
          {isLoading ? (
            <Loader height={100} width={100}></Loader>
          ) : (
            <div>
              <Button
                color="inherit"
                startIcon={<CancelIcon />}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                startIcon={<SaveIcon />}
                color="inherit"
                onClick={HideValues}
              >
                Ok
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
