import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { UserContext } from '../contexts/UserContext';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'; 
import TableHead from '@material-ui/core/TableHead'; 
import Time from 'react-time';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledTableCell, StyledTableRow } from './common/Table';
import { TablePagination } from '@material-ui/core';
import ActionDetail from './common/ActionDetail';

export default function WagersInfo(props) {
  const user = useContext(UserContext);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [seeTeams, setSeeTeams] = useState(false);
  const [ticketNumber, setTicketNumber] = useState(0);
  const [actionDetail, setActionDetail] = useState([]);
  const [actionHeader, setActionHeader] = useState([]);

  function handleClick(event, newPage) { 
    setCurrentPage(newPage);
  }
  
  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleCloseActionDetails = function () {
    setSeeTeams(false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },
    headerCell: {
      borderLeft:'2px solid white',
      borderRight:'2px solid white',
    },
    Cell: {
      borderLeft:'2px solid white',
      borderRight:'2px solid white',
      textAlign:'center !important'
    },
    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const actionDetail_GetHeader = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_DEF_PHP_URL + "/ActionDetailGetHeader.php",
      data: {
        idWager: ticketNumber
      },
    })
      .then((response) => response.data)
      .then((data) => {
        setActionHeader(data.data)
      })
  }

  const actionDetail_GetDetail = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_DEF_PHP_URL + "/ActionDetail_GetDetail.php",
      data: {
        idWager: ticketNumber
      },
    })
      .then((response) => response.data)
      .then((data) => {
        setActionDetail(data.data)
      })
  }

  useEffect(() => {
    if(ticketNumber != 0){
      actionDetail_GetHeader(ticketNumber)
      actionDetail_GetDetail(ticketNumber)
    }    
  }, [ticketNumber]);

  useEffect(() => {
    if (actionDetail.length > 0 && actionHeader.length > 0) {
      setSeeTeams(true);
    }  
  }, [actionDetail,actionHeader]);

  const handleViewActionDetail = (row) => {
    setTicketNumber(row[0])
    //setSeeTeams(true)
  }

  return (
    <React.Fragment>
      {seeTeams?
        (
          <ActionDetail 
            onClose={handleCloseActionDetails}
            actionDetail = {actionDetail}
            actionHeader = {actionHeader}
          >
          </ActionDetail>
        ):
        (
          ""
        )
      }
      <br />
      <form className={classes.center}>
        <FormGroup>
          <FormControl className={classes.formControl}>
            <Table>
              <TableHead className={classes.tableHead}>
                <StyledTableRow>
                  <StyledTableCell className={classes.headerCell}>
                    <strong>User</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <strong>Placed</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <strong>Player</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <strong>Description</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <strong>Points</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <strong>Odds</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <strong>Risk</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <strong>Win</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.headerCell}>
                    <strong>Station</strong>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {props.WagersValues &&
                  props.WagersValues.slice(
                    currentPage * rowsPerPage,
                    currentPage * rowsPerPage + rowsPerPage
                  ).map((row) => (
                    <StyledTableRow 
                      className={classes.tableRow} 
                      onClick={() => handleViewActionDetail(row)}
                      style={{cursor:"pointer"}}
                    >
                      <StyledTableCell className={classes.Cell}>
                        <strong>{row[2]}</strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.Cell}>
                        <strong>
                          <Time value={row[3].date} format="DD/MM/YYYY" />
                        </strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.Cell}>
                        <strong>{row[4]}</strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.Cell}>
                        <strong>{row[5]}</strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.Cell}>
                        <strong>{row[11]}</strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.Cell}>
                        <strong>{row[6]}</strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.Cell}>
                        <strong>{row[7]}</strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.Cell}>
                        <strong>{row[8]}</strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.Cell}>
                        <strong>{row[1]}</strong>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={props.WagersValues && props.WagersValues.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleClick}
              onChangeRowsPerPage={handleChange}
            />
          </FormControl>
        </FormGroup>
      </form>
    </React.Fragment>
  );
}
