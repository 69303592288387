import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { UserContext } from '../contexts/UserContext';
import { DelayInput } from 'react-delay-input';
import InputSelect from '../components/common/InputSelect';
import PitcherList from '../components/common/PitcherList';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PitcherChangeConfirm from './games/gameComponents/PitcherChangeConfirm';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

export default function ModifyBanner(props) {
  console.log("LACA props:", props)
  const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [flag, setFlag] = useState(false);
  const [idAction, setIdAction] = useState(0);
  const [pitcherSide, setPitcherSide] = useState('');
  const [open, setOpen] = React.useState(true);
  const [pitchers, setPitchers] = React.useState([]);
  const [idSport, setIdSport] = React.useState('');
  const [idLeague, setIdLeague] = React.useState(0);
  const [visitorScore, setVisitorScore] = React.useState(0);
  const [homeScore, setHomeScore] = React.useState(0);
  const [visitorPitcher, setVisitorPitcher] = React.useState('');
  const [visitorPitcherOld, setVisitorPitcherOld] = React.useState('');
  const [homePitcherOld, setHomePitcherOld] = React.useState('');
  const [homePitcher, setHomePitcher] = React.useState('');
  const [gameStat, setGameStat] = React.useState('');
  const [graded, setGraded] = React.useState(false);
  const [gameType, setGameType] = React.useState(0);
  const [hookups, setHookUps] = React.useState(false);
  const [local, setLocal] = React.useState(false);
  const [online, setOnline] = React.useState(false);
  const [shortGame, setShortGame] = React.useState(false);
  const [eventDate, setEventDate] = React.useState('');
  const [dateChanged, setDateChanged] = React.useState(false);
  const [timeChanged, setTimeChanged] = React.useState(false);
  const [pitcherChanged, setPitcherChanged] = React.useState(0);
  const [period, setPeriod] = React.useState(0);
  const [gradedDate, setGradedDate] = React.useState('');
  const [numTeams, setNumTeams] = React.useState(0);
  const [idEvent, setIdEvent] = React.useState(0);
  const [hasChildren, setHasChildren] = React.useState(false);
  const [idTeamVisitor, setIdTeamVisitor] = React.useState(0);
  const [idTeamHome, setIdTeamHome] = React.useState(0);
  const [idBannerType, setIdBannerType] = React.useState(0);
  const [gameDateTime, setGameDateTime] = React.useState('');
  const [league, setLeague] = React.useState([]);
  const [leagueID, setLeagueID] = React.useState(0);
  const [typeID, setTypeID] = React.useState(0);
  const [type, setType] = React.useState([]);
  const [familyGame, setFamilyGame] = React.useState(0);
  const [parentGame, setParentGame] = React.useState(0);
  const [description, setDescription] = React.useState(0);
  const [visitorNumber, setVisitorNumber] = React.useState(0);
  const [visitorTeam, setVisitorTeam] = React.useState('');
  const [homeNumber, setHomeNumber] = React.useState(0);
  const [homeTeam, setHomeTeam] = React.useState('');
  const [visitorSpread, setVisitorSpread] = React.useState(0);
  const [visitorSpreadOdds, setVisitorSpreadOdds] = React.useState(0);
  const [overTotal, setOverTotal] = React.useState(0);
  const [overOdds, setOverOdds] = React.useState(0);
  const [visitorMoneyline, setVisitorMoneyline] = React.useState(0);
  const [newMoneyline, setNewMoneyline] = React.useState(0);
  const [homeSpread, setHomeSpread] = React.useState(0);
  const [homeSpreadOdds, setHomeSpreadOdds] = React.useState(0);
  const [underTotal, setUnderTotal] = React.useState(0);
  const [underOdds, setUnderOdds] = React.useState(0);
  const [homeMoneyline, setHomeMoneyline] = React.useState(0);
  const [idGame, setIdGame] = React.useState(0);
  const [sportType, setSportType] = React.useState(0);
  const [normalGame, setNormalGame] = React.useState(0);
  const [idGameType, setIdGameType] = React.useState(0);
  const [gameStatus, setGameStatus] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [acceptAutoChanges, setAcceptAutoChanges] = React.useState(false);
  const [isChange, setIsChange] = React.useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [result, setResult] = React.useState(0);
  const [valueHasChanged, setValueHasChanged] = useState(1);  

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangeVisitorTeam = (event) => {
    setVisitorTeam(event.target.value);
  };

  const handleChangeHomeTeam = (event) => {
    setHomeTeam(event.target.value);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };

  const handleOk = () => {
    let objTsend = {
      idGame: idGame,
      idSport: props.IdSport,
      idLeague: leagueID.value ? leagueID.value : leagueID,
      idGameType: typeID.value ? typeID.value : typeID,
      gameDateTime: gameDateTime.date
        ? gameDateTime.date.slice(0, -4)
        : gameDateTime,
      visitorNumber: visitorNumber,
      homeNumber: homeNumber,
      visitorTeam: visitorTeam,
      homeTeam: homeTeam,
      visitorScore: visitorScore,
      homeScore: homeScore,
      visitorPitcher: visitorPitcher,
      homePitcher: homePitcher,
      normalGame: normalGame,
      gameStat: gameStat,
      graded: graded,
      hookups: hookups,
      local: local,
      online: online,
      shortGame: shortGame,
      eventDate: eventDate.date.slice(0, -4),
      dateChanged: dateChanged,
      timeChanged: timeChanged,
      pitcherChanged: pitcherChanged,
      period: period,
      parentGame: parentGame,
      gradedDate: gradedDate.date.slice(0, -4),
      numTeams: numTeams,
      idEvent: idEvent,
      familyGame: familyGame,
      hasChildren: hasChildren,
      idTeamVisitor: idTeamVisitor,
      idTeamHome: idTeamHome,
      idBannerType: idBannerType,
      description: description,
      acceptAutoChanges: acceptAutoChanges,
      idUser: userInfo.UserId,
      result: result,
    };
    console.log('objTsend', objTsend)
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/GameUpdate.php',
      data: objTsend,
    }).then((response) => {
      console.log('response', response);
      props.searchGameValues();
      handleClose();
    }).catch( (e) => {
      console.log('objTsend error', e)
    });
  }
  
  useEffect(() => {
    console.log('props IDGAME ==', props.IdGame, props.IdLinetype, props.IdSport)
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/GamesGameData.php',
      /*  REPLACE WITH SELECT DATA */
      data: {
        idgame: props.IdGame,
        idlinetype: props.IdLinetype,
        idsport: props.IdSport,
      },
    })
      .then(response => response.data)
      .then(data => {
        console.log('data==========>', data);
        data.data.forEach((x) => {
          setIdGame(x[0]);
          setVisitorTeam(x[1]);
          setHomeTeam(x[2]);
          setIdSport(x[3]);
          setIdLeague(x[4]);
          setIdGameType(x[5]);
          setGameDateTime(x[6]);
          setVisitorNumber(x[7]);
          setHomeNumber(x[8]);
          setNormalGame(x[9]);
          setGameStat(x[10]);
          setGraded(x[11]);
          setHookUps(x[12]);
          setOnline(x[13]);
          setLocal(x[14]);
          setShortGame(x[15]);
          setEventDate(x[16]);
          setPeriod(x[17]);
          setDateChanged(x[18]);
          setTimeChanged(x[19]);
          setParentGame(x[20]);
          setVisitorScore(x[21]);
          setHomeScore(x[22]);
          setVisitorPitcher(x[23]);
          setVisitorPitcherOld(x[23]);
          setHomePitcher(x[24]);
          setHomePitcherOld(x[24]);
          setGradedDate(x[25]);
          setNumTeams(x[26]);
          setPitcherChanged(x[27]);
          setIdEvent(x[28]);
          setFamilyGame(x[29]);
          setHasChildren(x[30]);
          setIdTeamVisitor(x[31]);
          setIdTeamHome(x[32]);
          setIdBannerType(x[33]);
          setDescription(x[61]);
          setTypeID(x[5]);
          setLeagueID(x[4]);

          setVisitorSpread(x[44]);
          setVisitorSpreadOdds(x[45]);
          setOverTotal(x[40]);
          setOverOdds(x[41]);
          setVisitorMoneyline(x[38]);
          setHomeSpread(x[46]);
          setHomeSpreadOdds(x[47]);
          setUnderTotal(x[42]);
          setUnderOdds(x[43]);
          setHomeMoneyline(x[39]);
        });
      })
      .then(() => {
        const url = process.env.REACT_APP_API_URL + '/GAMETYPE';
        axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setType(data.records);
          });
      })
      .then(() => {
        const url =
          process.env.REACT_APP_API_URL +
          '/LEAGUE?filter=IdSport,eq,' +
          props.IdSport;
        axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setLeague(data.records);
          });
      });
  }, [props.IdGame, props.IdLinetype, props.IdSport]);

  console.count("Modify Banner Entrando a Ventana")

  const handleCloseIsChange = function () {
    setIsChange(false);
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Banner Modify
            </Typography>
          </Toolbar>
        </AppBar>
        {loading ? (
          <CircularProgress style={{ margin: '0 auto' }} />
        ) : (
          <React.Fragment>
            <br />
            <form className={classes.center}>
              <FormGroup row>
                <TextField
                  style={{ width: '400px' }}
                  type="text"
                  label="Description"
                  value={description}
                  multiline={true}
                  rows="4"
                  onChange={handleChangeDescription}
                  variant="outlined"
                />
              </FormGroup>
              <Divider />
              <FormGroup row>
                <FormControl className={classes.formControl}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Visitor Team"
                    value={visitorTeam}
                    multiline={true}
                    onChange={handleChangeVisitorTeam}
                  />
                </FormControl>
              </FormGroup>
              <FormGroup row>
                <FormControl className={classes.formControl}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Home Team"
                    value={homeTeam}
                    multiline={true}
                    onChange={handleChangeHomeTeam}
                  />
                </FormControl>
              </FormGroup>
              <FormGroup row>
                <FormControlLabel
                  label="Permanent"
                  control={
                    <Checkbox
                      checked={normalGame === 2 ? true : false}
                      onChange={() => {
                        setNormalGame(normalGame === 2 ? 0 : 2);
                      }}
                      name="permanent"
                    />
                  }
                />
              </FormGroup>
            </form>
          </React.Fragment>
        )}
        <DialogActions>
          <Button
            color="inherit"
            startIcon={<CancelIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button startIcon={<SaveIcon />} color="inherit" onClick={handleOk}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
