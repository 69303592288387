import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as moment from 'moment';

/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LineTypeList from '../../lineTypes/lineTypesComponents/LineTypeList';
import AgentList from '../../agents/agentComponents/AgentList';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

import OfficeList from '../../offices/officeComponents/OfficeList';
import BookList from '../../books/bookComponents/BookList';
import WagerLimitsList from '../../wagerLimits/wagerLimitsComponents/WagerLimitsList';
import ProfileList from '../../profiles/profileComponents/ProfileList';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './PlayerHistory.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const linetypeFormatter = (cell, row) => {
  console.log('Settings cell', cell);
  console.log('Setting row', row);
  return <LineTypeList isInput={true} initialValue={cell}></LineTypeList>;
};
const agentFormatter = (cell, row) => {
  console.log('Settings  cell', cell);
  console.log('Setting row', row);
  return <AgentList isInput={true} initialValue={cell}></AgentList>;
};
const officeFormatter = (cell, row) => {
  return <OfficeList isInput={true} initialValue={cell}></OfficeList>;
};
const bookFormatter = (cell, row) => {
  return <BookList isInput={true} initialValue={cell}></BookList>;
};
const profileLimitFormatter = (cell, row) => {
  return <WagerLimitsList isInput={true} initialValue={cell}></WagerLimitsList>;
};
const profileFormatter = (cell, row) => {
  return <ProfileList isInput={true} initialValue={cell}></ProfileList>;
};
const numberFormatter = (cell, row) => {
  return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(
    cell
  );
};
const linetypeFormatter2 = (cell, row) => {
  let isSelected = '';
  let isSelected0 = '';
  let isSelected1 = '';
  let isSelected2 = '';
  let isSelected3 = '';
  let isSelected4 = '';
  console.log('Formatting cell', cell);
  switch (cell) {
    case 0:
      isSelected0 = 'checked';
      break;
    case 1:
      isSelected1 = 'checked';
      break;
    case 2:
      isSelected2 = 'checked';
      break;
    case 3:
      isSelected3 = 'checked';
      break;
    case 4:
      isSelected4 = 'checked';
      break;
    default:
      isSelected = 'checked';
  }
  console.log('Formatting cell isSelected0', isSelected0);
  return `
    <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
    <label for="isSelected0">Keep Price</label><br>
    <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
    <label for="isSelected1">Credit use line, Cash Adjust win amount</label><br>
    <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
    <label for="isSelected2">Adjust Risk Amount</label><br>
    <input type="radio" id="isSelected3" name="age" value="3" ${isSelected3}>
    <label for="isSelected3">Adjust Win Amount</label><br>
    <input type="radio" id="isSelected4" name="age" value="4" ${isSelected4}>
    <label for="isSelected4">UseLine</label><br>`;
};
export default function PlayerHistory(props) {
  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  const classes = useStyles();
  const [playerHistory, setPlayerHistory] = React.useState([]);
  const [idSport, setIdSport] = React.useState(['CBB']);
  const [period, setPeriod] = React.useState(['0']);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);

  const [startDate, setStartDate] = React.useState(week[0]);
  const [endDate, setEndDate] = React.useState(week[6]);
  let idPlayer = props.Player.IdPlayer;

  // let IdPeriod = '0';
  useEffect(() => {
    // Change for server api address
    HistoryValues();
  }, []);

  const handleShow = () => {
    HistoryValues();
  };

  const HistoryValues = () => {
    let idPlayer = props.Player.IdPlayer;

    /*const url =
      process.env.REACT_APP_API2_URL +
      '/Player/GetPlayerHistoryWagers?idPlayer=' +
      idPlayer +
      '&startDate=' +
      startDate +
      '&endDate=' +
      endDate;*/

    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/Player/GetPlayerHistoryWagers?idPlayer=' +
        idPlayer +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate,
      // data: scheduleRequest,
    }).then((response) => {
      console.log('response', response.data);
      setPlayerHistory(response.data);
      setIsLoading(false);
      let lastIdWager = 0;
      let openBetList = [];
      let openBet = {};
      response.data.map(function (keyMain) {
        console.log('response1 key', keyMain);
        console.log('response1 playerHistorykey', response.data[keyMain]);
        if (keyMain['IdWager'] != lastIdWager) {
          console.log('response1 key IdWager', keyMain['IdWager']);
          keyMain.Leg = [];
          openBetList.push(keyMain);
          lastIdWager = keyMain['IdWager'];
          console.log('response1 key openBetList', openBetList);
        } else {
          let tempGame = {};
          tempGame = openBetList[openBetList.length - 1];
          lastIdWager = keyMain['IdWager'];
          console.log('response1 key tempGame', tempGame);

          tempGame.Leg.push(keyMain);
          console.log('response1 key tempGame after add', tempGame);
          delete openBetList[openBetList.length - 1];
          console.log('response1 key openBetList', openBetList);
          openBetList.push(tempGame);
          console.log('response1 key openBetList after', openBetList);
        }
      });

      setPlayerHistory(openBetList);
    });
  };
  // const [selectedIdGame, setselectedIdGame] = useState();

  function onAfterSaveCell(row, cellName, cellValue) {
    props.UpdatePlayer(row);
  }
  function onAfterSaveCellRelated(row, cellName, cellValue) {
    UpdateRelatedGame(row);
  }
  function UpdateRelatedGame(row) {
    let playerHistoryRequest = {
      IdPlayer: row.IdPlayer,
      IdSport: row.IdSport,
      IdPeriod: row.IdPeriod,
      MLFav_Over: row.MLFav_Over,
      MLFav_Under: row.MLFav_Under,
      MLDog_Over: row.MLDog_Over,
      MLDog_Under: row.MLDog_Under,
      SpreadFav_Over: row.SprFav_Over,
      SpreadFav_Under: row.SprFav_Under,
      SpreadDog_Over: row.SprDog_Over,
      SpreadDog_Under: row.SprDog_Under,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Game/playerHistory_Update',
      // url: 'https://localhost:44337/api/Game/playerHistory_Update',
      data: playerHistoryRequest,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  }

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  const cellEditRelatedGameProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCellRelated, // a hook for after saving cell
  };

  const setIdSportOnMem = (IdSport) => {
    setIdSport(IdSport);
  };
  const setPeriodOnMem = (period) => {
    setPeriod(period);
    console.log('PROPS====== REST setPeriodOnMem>', period);
  };
  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/
  const isExpandableRow = (row) => {
    console.log('isExpandableRow ', row);
    return true;
  };

  class BSTable extends React.Component {
    render() {
      if (this.props.data) {
        console.log('response this.props.data', this.props.data);
        return (
          <Table className="table-open-bets">
            <TableRow>
              <TableCell>
                <p>
                  <b> {this.props.data.DetailDescription}</b>
                  <br />
                  {this.props.data.IdSport != '' ? this.props.data.IdWager : ''}
                  <br />
                  <small>
                    <b>Placed Date: {this.props.data.PlacedDate} </b>
                  </small>
                </p>
              </TableCell>
              <TableCell>
                <p>
                  <b> {this.props.data.LoginName} </b>
                  <br />
                  {this.props.data.IP ? this.props.data.IP : ''}
                  <br />
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan="2">
                <p>
                  <b>Ticket Description</b>
                </p>
                <li key={this.props.data.IdWager}>
                  {this.props.data.DetailDescription}{' '}
                </li>

                {this.props.data.Leg &&
                  this.props.data.Leg.map(function (item, i) {
                    console.log('response item', item);
                    console.log('response test');
                    return <li key={i}>{item.DetailDescription} </li>;
                  })}
                <br />
                <p style={{ textAlign: 'center' }}>
                  <b>Risk/Win : </b>
                  {this.props.data.RiskAmount}/ {this.props.data.WinAmount}{' '}
                </p>
              </TableCell>
            </TableRow>
          </Table>
        );
      } else {
        return <p>?</p>;
      }
    }
  }

  const expandComponent = (row) => {
    console.log('expandComponent ', row);
    return <BSTable data={row} />;
  };
  console.log('PROPS====== Settings setPeriodOnMem>', isLoading);
  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <div>
          <form className={classes.container} noValidate>
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              defaultValue={startDate}
              className={classes.textField}
              onChange={handleChangeStartDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              defaultValue={endDate}
              className={classes.textField}
              onChange={handleChangeEndDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button color="inherit" onClick={handleShow}>
              Show
            </Button>
          </form>
          <BootstrapTable
            data={playerHistory}
            //   cellEdit={cellEditProp}
            expandableRow={isExpandableRow}
            expandComponent={expandComponent}
            options={options}
          >
            <TableHeaderColumn isKey={true} hidden dataField="TicketNumber">
              TicketNumber
            </TableHeaderColumn>
            <TableHeaderColumn width="200" dataField="PlacedDate">
              PlacedDate
            </TableHeaderColumn>
            <TableHeaderColumn width="200" dataField="CompleteDescription">
              Type
            </TableHeaderColumn>
            <TableHeaderColumn dataField="DetailDescription">
              Description
            </TableHeaderColumn>
            <TableHeaderColumn width="150" dataField="Amount">
              Amount
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    </React.Fragment>
  );
}
