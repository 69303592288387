import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../contexts/UserContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from '../components/common/loader/Loader';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function GameStatus(props) {
  const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [idGame, setIdGame] = React.useState(0);
  const [sportType, setSportType] = React.useState(0);
  const [shortGame, setShortGame] = React.useState(false);
  const [gameStatus, setGameStatus] = React.useState('');
  const [contenderStatus, setContenderStatus] = React.useState(0);
  const [system, setSystem] = React.useState(0);
  const [local, setLocal] = React.useState(false);
  const [online, setOnline] = React.useState(false);
  const [noHookUps, setNoHookUps] = React.useState(false);
  const [dateChanged, setDateChanged] = React.useState(false);
  const [timeChanged, setTimeChanged] = React.useState(false);
  const [acceptAutoChanges, setAcceptAutoChanges] = React.useState(false);
  const [pitcherChanged, setPitcherChanged] = React.useState(false);
  const [completedGame, setCompletedGame] = React.useState('');
  const [homeNumber, setHomeNumber] = React.useState(0);
  const [homeTeam, setHomeTeam] = React.useState(0);
  const [homeScore, setHomeScore] = React.useState(0);
  const [visitorNumber, setVisitorNumber] = React.useState(0);
  const [visitorTeam, setVisitorTeam] = React.useState(0);
  const [visitorScore, setVisitorScore] = React.useState(0);
  const [idUser, setIdUser] = React.useState(0);
  const [result, setResult] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [selectedValueTime, setSelectedValueTime] = React.useState('None');
  const [gamesTNT, setGamesTNT] = React.useState('None');
  const [selectedIdProfile, setSelectedIdProfile] = useState(props.rotation);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValueStatus, setSelectedValueStatus] = React.useState(
    'Visitor'
  );
  const [flag, setFlag] = useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );

  const [
    selectedValueAutoChanges,
    setSelectedValueAutoChanges,
  ] = React.useState('Visitor');

  const handleChangeStatus = (event) => {
    setGameStatus(event.target.value);
  };
  const handleChangeContenderStatus = (event) => {
    setContenderStatus(event.target.value);
    if (event.target.value == '0') {
      setFlag(true);
    }
  };

  const handleChangeShortGame = () => {
    setShortGame(!shortGame);
  };

  const handleChangeVisitorNumber = (event) => {
    setVisitorNumber(event.target.value);
  };

  const handleChangeVisitorTeam = (event) => {
    setVisitorTeam(event.target.value);
  };

  const handleChangeVisitorScore = (event) => {
    setVisitorScore(event.target.value);
  };

  const handleChangeHomeNumber = (event) => {
    setHomeNumber(event.target.value);
  };

  const handleChangeHomeTeam = (event) => {
    setHomeTeam(event.target.value);
  };

  const handleChangeHomeScore = (event) => {
    setHomeScore(event.target.value);
  };
  const handleChangeCompletedGame = (event) => {
    setCompletedGame(event.target.value);
  };
  const handleChangeTime = (event) => {
    setSelectedValueTime(event.target.value);
  };
  const handleChangeAutoChangesRadio = (event) => {
    setSelectedValueAutoChanges(event.target.value);
  };

  const handleIdGame = () => {
    setIdGame(!idGame);
  };

  const handleChangeLocal = () => {
    setLocal(!local);
  };
  const handleChangeOnline = () => {
    setOnline(!online);
  };
  const handleChangeNoHookUps = () => {
    setNoHookUps(!noHookUps);
  };
  const handleChangeDateChanged = () => {
    setDateChanged(!dateChanged);
  };
  const handleChangeTimeChanged = () => {
    setTimeChanged(!timeChanged);
  };
  const handleChangeAcceptAutoChanges = () => {
    setAcceptAutoChanges(!acceptAutoChanges);
  };

  const handlePitcherChanged = (event) => {
    setPitcherChanged(event.target.value);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };

  const handleIdProfileChange = (event) => {
    setSelectedIdProfile(event.target.value);
  };

  const changeLoserContender = () => {
    if (props.IdSport == 'TNT') {
      axios({
        method: 'post',
        url:
          process.env.REACT_APP_API2_URL +
          '/Game/TNTLoseContender?idGame=' +
          props.IdGameTNT +
          '&teamNumber=' +
          selectedIdProfile +
          '&idUser=' +
          userInfo.UserId +
          '&value=' +
          contenderStatus,
      }).then((response) => {
        console.log('response', response);
      });
    } else if (props.IdSport == 'PROP') {
      axios({
        method: 'post',
        url:
          process.env.REACT_APP_API2_URL +
          '/Game/PROPLoseContender?idGame=' +
          props.IdGames[0] +
          '&teamNumber=' +
          selectedIdProfile +
          '&idUser=' +
          userInfo.UserId,
      }).then((response) => {
        console.log('response', response);
      });
    }
    props.searchGameValues();
    props.clearArray();
  }  

  const handleOk = () => {
    if (gameStatus != 'B') {
      setIsLoading(true);
      if (flag) {
        changeLoserContender();
      }
      for (let i = 0; i < props.IdGames.length; i++) {               
        axios({
          method: 'post',
          url: process.env.REACT_APP_DEF_PHP_URL + '/GameSetStatus.php',
          data: {
            idGame: props.IdGames[i],
            shortGame: shortGame,
            sportType: sportType,
            local: local,
            online: online,
            noHookUps: noHookUps,
            dateChanged: dateChanged,
            timeChanged: timeChanged,
            pitcherChanged: pitcherChanged,
            gameStatus: gameStatus,
            visitorScore: props.IdSport === 'PROP' ? contenderStatus : visitorScore,
            homeScore: props.IdSport === 'PROP' ? contenderStatus : homeScore,
            acceptAutoChanges: acceptAutoChanges,
            system: system,
            idUser: userInfo.UserId,
          },
        }).then((response) => {
          console.log('response', response);
          props.searchGameValues();
          setIsLoading(false);
          props.onClose();
        });
      }
    }
  }

  const calculateSportType = () => {
    if(props.IdSport === 'PROP'){
      setSportType(1)
    }else 
      if(props.IdSport === 'TNT'){
        setSportType(2)
      }
  }

  useEffect(() => {    
    axios.get(
      process.env.REACT_APP_API2_URL +
        `/Game/Games_GameData?idGame=${props.IdGames[0]}&idLineType=${props.IdLinetype}`
    )
    .then((response) => {
      console.log("response.data",response.data[0])
          response.data.forEach((x) => {
              setShortGame(x['ShortGame']);
              setIdGame(x['IdGame']);
              setLocal(x['OnLocal']);
              setOnline(x['Online']);
              setGameStatus(x['GameStat']);
              setNoHookUps(x['HookUps']);
              setDateChanged(x['DateChanged']);
              setTimeChanged(x['TimeChanged']);
              setPitcherChanged(x['PitcherChanged']);
              x['VisitorScore'] == null ? setVisitorScore(0) : setVisitorScore(x['VisitorScore']) 
              x['HomeScore'] == null ?  setHomeScore(0) : setHomeScore(x['HomeScore'])
              setAcceptAutoChanges(x['AcceptAutoChanges']);
              setVisitorNumber(x['VisitorNumber']);
              setVisitorTeam(x['VisitorTeam']);
              setHomeNumber(x['HomeNumber']);
              setHomeTeam(x['HomeTeam']); 
              props.IdSport === 'PROP' ? setContenderStatus(x['VisitorScore']) : setContenderStatus(0)
      })
    })
    setLoading(false);
  }, []);
  

  useEffect(() => {
    console.log("props", props);
    calculateSportType();
    const url =
      process.env.REACT_APP_API_URL +
      '/GAMETNT?filter=IdGame,eq,' +
      props.IdGameTNT;

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setGamesTNT(data.records);
      });
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Game Status
          </Typography>
        </Toolbar>
      </AppBar>
      {loading ? (
        <CircularProgress style={{ margin: '0 auto' }} />
      ) : (
        <React.Fragment>
          <br />
          <form className={classes.center}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={local}
                    onChange={handleChangeLocal}
                    color="primary"
                  />
                }
                label="Local"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={online}
                    onChange={handleChangeOnline}
                    color="primary"
                  />
                }
                label="Online"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noHookUps}
                    onChange={handleChangeNoHookUps}
                    color="primary"
                  />
                }
                label="No Hookups"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dateChanged}
                    onChange={handleChangeDateChanged}
                    color="primary"
                  />
                }
                label="Date Changed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={timeChanged}
                    onChange={handleChangeTimeChanged}
                    color="primary"
                  />
                }
                label="Time Changed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptAutoChanges}
                    onChange={handleChangeAcceptAutoChanges}
                    color="primary"
                  />
                }
                label="Accept Auto Changes"
              />
            </FormGroup>
            <Divider />
            <FormGroup row>
              <h5>Pitcher Changed</h5>
            </FormGroup>
            <FormGroup row>
              <RadioGroup row>
                <FormControlLabel
                  // value="None"
                  control={
                    <Radio
                      disabled = {props.IdSport === 'MLB'? false : true}
                      checked={pitcherChanged == '0'}
                      onChange={handlePitcherChanged}
                      value="0"
                      label="None"
                      name="radio-button-time"
                      inputProps={{ 'aria-label': 'None' }}
                      //disabled
                    />
                  }
                  label="None"
                  labelPlacement="end"
                />
                <FormControlLabel
                  //   value="Home"
                  control={
                    <Radio
                      disabled = {props.IdSport === 'MLB'? false : true}
                      checked={pitcherChanged == '2'}
                      onChange={handlePitcherChanged}
                      value="2"
                      label="Home"
                      name="radio-button-time"
                      inputProps={{ 'aria-label': 'Home' }}
                      // disabled
                    />
                  }
                  label="Home"
                  labelPlacement="end"
                />
                <FormControlLabel
                  //  value="Visitor"
                  control={
                    <Radio
                      disabled = {props.IdSport === 'MLB'? false : true}
                      checked={pitcherChanged == '1'}
                      onChange={handlePitcherChanged}
                      value="1"
                      label="Visitor"
                      name="radio-button-time"
                      inputProps={{ 'aria-label': 'Visitor' }}
                      // disabled
                    />
                  }
                  label="Visitor"
                  labelPlacement="end"
                />
                <FormControlLabel
                  //   value="Both"
                  control={
                    <Radio
                      disabled = {props.IdSport === 'MLB'? false : true}
                      checked={pitcherChanged == '3'}
                      onChange={handlePitcherChanged}
                      value="3"
                      label="Both"
                      name="radio-button-time"
                      inputProps={{ 'aria-label': 'Both' }}
                      // disabled
                    />
                  }
                  label="Both"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormGroup>
            <Divider />
            <FormGroup row>
              <span>&nbsp;&nbsp;</span>
            </FormGroup>
            <FormGroup row>
              <span>&nbsp;&nbsp;</span>
            </FormGroup>
            <FormGroup row>
              <span>&nbsp;&nbsp;</span>
            </FormGroup>
            <FormGroup row>
            <h5>Game Status</h5>
            </FormGroup>
            <FormGroup row>
              <RadioGroup row>
              <FormControlLabel
                  value="Open"
                  control={
                    <Radio
                      checked={gameStatus == 'O'}
                      onChange={handleChangeStatus}
                      value="O"
                      name="radio-button-time"
                      inputProps={{ 'aria-label': 'Open' }}
                    />
                  }
                  label="Open"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Off Board"
                  control={
                    <Radio
                      checked={gameStatus == 'D'}
                      //checked={gameStatus == 'B'}
                      onChange={handleChangeStatus}
                      //value="B"
                      value="D"
                      name="radio-button-time"
                      inputProps={{ 'aria-label': 'Off Board' }}
                    />
                  }
                  label="Off Board"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Cancel"
                  control={
                    <Radio
                      checked={gameStatus == 'C'}
                      onChange={handleChangeStatus}
                      value="C"
                      name="radio-button-time"
                      inputProps={{ 'aria-label': 'Cancel' }}
                    />
                  }
                  label="Cancel"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Final"
                  control={
                    <Radio
                      checked={gameStatus == 'F'}
                      onChange={handleChangeStatus}
                      value="F"
                      name="radio-button-time"
                      inputProps={{ 'aria-label': 'Final' }}
                    />
                  }
                  label="Final"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormGroup>
            <Divider />
            {props.IdGames.length == 1 ? (
              <React.Fragment>
                {props.IdSport != 'TNT' && props.IdSport != 'PROP' ? (
                  <React.Fragment>
                    <FormGroup row>
                      <h5>Completed Game</h5>
                    </FormGroup>
                    <FormGroup row>
                      <TextField
                        style={{ width: '400px' }}
                        type="text"
                        label="<no description>"
                        value={completedGame}
                        multiline={true}
                        rows="4"
                        onChange={handleChangeCompletedGame}
                        variant="outlined"
                      />
                    </FormGroup>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FormGroup row>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                      >
                        Contenders
                      </InputLabel>
                      {gamesTNT && gamesTNT.length > 0 && gamesTNT.map ? (
                        <Select
                          labelId="demo-simple-select-placeholder-label-label"
                          id="demo-simple-select-placeholder-label"
                          value={selectedIdProfile}
                          onChange={handleIdProfileChange}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          {gamesTNT &&
                            gamesTNT.length > 0 &&
                            gamesTNT.map((row) => (
                              <MenuItem value={row.TeamNumber}>
                                {row.TeamNumber + ' ' + row.TeamName}
                              </MenuItem>
                            ))}
                        </Select>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <RadioGroup row>
                        <FormControlLabel
                          value="Loser"
                          control={
                            <Radio
                              disabled={gameStatus === "F" ? false : true}
                              checked={contenderStatus == '0'}
                              onChange={handleChangeContenderStatus}
                              value="0"
                              name="radio-button-time"
                              inputProps={{ 'aria-label': 'Open' }}
                            />
                          }
                          label="Loser"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="Winner"
                          control={
                            <Radio
                              disabled={gameStatus === "F" ? false : true}
                              checked={contenderStatus == '1'}
                              onChange={handleChangeContenderStatus}
                              value="1"
                              //value="D"
                              name="radio-button-time"
                              inputProps={{ 'aria-label': 'Off Board' }}
                            />
                          }
                          label="Winner"
                          labelPlacement="end"
                        />
                        {props.IdSport === 'TNT' ? 
                          <FormControlLabel
                            value="Pend"
                            control={
                              <Radio
                                checked={gameStatus == '2'}
                                onChange={handleChangeContenderStatus}
                                value="2"
                                name="radio-button-time"
                                inputProps={{ 'aria-label': 'Cancel' }}
                              />
                            }
                            label="Pend"
                            labelPlacement="end"
                          />
                        :
                          ""
                        }
                        
                        <FormControlLabel
                          value="N/A"
                          control={
                            <Radio
                              disabled={gameStatus === "F" ? false : true}
                              checked={contenderStatus == '3'}
                              onChange={handleChangeContenderStatus}
                              value="3"
                              name="radio-button-time"
                              inputProps={{ 'aria-label': 'Final' }}
                            />
                          }
                          label="N/A"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormGroup>
                  </React.Fragment>
                )}
                <Divider />
                {props.IdSport != 'PROP' ? (
                  <React.Fragment>
                    <FormGroup row>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <h5>Score</h5>
                    </FormGroup>
                    <FormGroup row>
                      [{visitorNumber}]{visitorTeam}
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <TextField
                        style={{ width: '100px' }}
                        type="number"
                        value={visitorScore}
                        variant="outlined"
                        multiline={true}
                        onChange={handleChangeVisitorScore}
                      />
                    </FormGroup>
                    <FormGroup row>
                      [{homeNumber}]{homeTeam}
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <TextField
                        style={{ width: '100px' }}
                        type="number"
                        value={homeScore}
                        variant="outlined"
                        multiline={true}
                        onChange={handleChangeHomeScore}
                      />
                    </FormGroup>                    
                    {props.IdSport==='MLB' ? (
                      <FormGroup row style={{justifyContent:"end"}}>
                        <FormControlLabel
                          label="Short Game"
                          control={
                            <Checkbox
                              checked={shortGame}
                              onChange={handleChangeShortGame}
                              //value="3"
                              name="Short Game"
                              inputProps={{ 'aria-label': 'Short Game' }}
                            />
                          }
                          name="Short Game"
                          color="Secondary"
                        />
                      </FormGroup>
                    ):("")}
                  </React.Fragment>
                ) : (
                  ''
                )}
              </React.Fragment>
            ) : (
              ''
            )}
          </form>
        </React.Fragment>
      )}
      <DialogActions>
        {isLoading ? (
          <Loader height={100} width={100}></Loader>
        ) : (
          <div>
            <Button
              color="inherit"
              startIcon={<CancelIcon />}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button 
              startIcon={<SaveIcon />} 
              color="inherit" 
              onClick={handleOk}>
              Ok
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
