import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './LineTypeSettings.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export default function ProfileSettings(props) {
  const classes = useStyles();
  const [gameRelatedOptions, setGameRelatedOptions] = React.useState([]);
  const [idSport, setIdSport] = React.useState(['CBB']);
  const [period, setPeriod] = React.useState(['0']);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  
  useEffect(() => {
    const url =
      process.env.REACT_APP_API_URL +
      '/LINETYPE' +
      '?filter=IdLineType,eq,' +
      props.LineType.IdLineType;
    axios({
      method: 'get',
      url: url,
    }).then((response) => {
      setGameRelatedOptions(response.data.records[0]);
    });

    setIsLoading(false);
  }, [props.LineType.IdLineType]);
  
  function onAfterSaveCell(row, cellName, cellValue) {
    UpdateLineType(row);
  }
  function UpdateLineType(row) {
    let request = {
      IdLineType: row.IdLineType,
      Description: row.Description,
      MLBIncrease: row.MLBIncrease,
      NBAIncrease: row.NBAIncrease,
      NHLIncrease: row.NHLIncrease,
      FBBBSpreadSync: row.FBBBSpreadSync,
      FBBBSpreadOddsSync: row.FBBBSpreadOddsSync,
      FBBBTotalSync: row.FBBBTotalSync,
      FBBBTotalOddsSync: row.FBBBTotalOddsSync,
      FBBBMoneyLineSync: row.FBBBTotalOddsSync,
      MLBMoneyLineSync: row.MLBMoneyLineSync,
      MLBRunSync: row.MLBRunSync,
      MLBRunOddsSync: row.MLBRunOddsSync,
      MLBTotalSync: row.MLBTotalSync,
      MLBTotalOddsSync: row.MLBTotalOddsSync,
      MUSpreadSync: row.MUSpreadSync,
      MUSpreadOddsSync: row.MUSpreadOddsSync,
      MUTotalSync: row.MUTotalSync,
      MUTotalOddsSync: row.MUTotalOddsSync,
      MUMoneyLineSync: row.MUMoneyLineSync,
      NHLAmericanSync: row.NHLAmericanSync,
      NHLAmericanOddsSync: row.NHLAmericanOddsSync,
      NHLCanadianSync: row.NHLCanadianSync,
      NHLCanadianOddsSync: row.NHLCanadianOddsSync,
      NHLMoneyLineSync: row.NHLMoneyLineSync,
      NHLTotalSync: row.NHLTotalSync,
      NHLTotalOddsSync: row.NHLTotalOddsSync,
      SOCGoalsSync: row.SOCGoalsSync,
      SOCGoalsOddsSync: row.SOCGoalsOddsSync,
      SOCMoneyLineSync: row.SOCMoneyLineSync,
      SOCTotalSync: row.SOCTotalSync,
      SOCTotalOddsSync: row.SOCTotalOddsSync,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/api/LineType/LineType_Update',
      //url: 'https://localhost:44337/api/LineType/LineType_Update',
      data: request,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  }

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/

  console.log('PROPS====== Settings setPeriodOnMem>', isLoading);
  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <Table>
          <TableRow>
            <TableCell colSpan="3">
              <Table>
                <TableCell> {/**/}</TableCell>
                <TableCell>
                  {' '}
                  {/* */}
                  <TableHeaderColumn>MLB Pitcher Change</TableHeaderColumn>
                  <TableHeaderColumn>MLB Pitcher Change</TableHeaderColumn>
                  <BootstrapTable
                    data={[props.LineType]}
                    cellEdit={cellEditProp}
                    options={options}
                  >
                    <TableHeaderColumn
                      visible="none"
                      isKey={true}
                      hidden
                      dataField="IdLineType"
                    >
                      IdLineType
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="FBBBSpreadSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      FBBBSpreadSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="FBBBSpreadOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      FBBBSpreadOddsSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="FBBBTotalSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      FBBBTotalSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="FBBBTotalOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      FBBBTotalOddsSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="FBBBMoneyLineSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      FBBBMoneyLineSync
                    </TableHeaderColumn>
                  </BootstrapTable>
                  <BootstrapTable
                    data={[props.LineType]}
                    cellEdit={cellEditProp}
                    options={options}
                  >
                    <TableHeaderColumn
                      visible="none"
                      isKey={true}
                      hidden
                      dataField="IdLineType"
                    >
                      IdLineType
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MLBMoneyLineSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MLBMoneyLineSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MLBIncrease"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MLBIncrease
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MLBRunSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MLBRunSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MLBRunOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MLBRunOddsSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MLBTotalSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MLBTotalSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MLBTotalOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MLBTotalOddsSync
                    </TableHeaderColumn>
                  </BootstrapTable>
                  <BootstrapTable
                    data={[props.LineType]}
                    cellEdit={cellEditProp}
                    options={options}
                  >
                    <TableHeaderColumn
                      visible="none"
                      isKey={true}
                      hidden
                      dataField="IdLineType"
                    >
                      IdLineType
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="MUSpreadSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MUSpreadSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MUSpreadOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MUSpreadOddsSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MUTotalSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MUTotalSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MUTotalOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MUTotalOddsSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MUMoneyLineSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      MUMoneyLineSync
                    </TableHeaderColumn>
                  </BootstrapTable>
                  <BootstrapTable
                    data={[props.LineType]}
                    cellEdit={cellEditProp}
                    options={options}
                  >
                    <TableHeaderColumn
                      visible="none"
                      isKey={true}
                      hidden
                      dataField="IdLineType"
                    >
                      IdLineType
                    </TableHeaderColumn>

                    <TableHeaderColumn dataField="NBAIncrease">
                      NBAIncrease
                    </TableHeaderColumn>
                  </BootstrapTable>
                  <BootstrapTable
                    data={[props.LineType]}
                    cellEdit={cellEditProp}
                    options={options}
                  >
                    <TableHeaderColumn
                      visible="none"
                      isKey={true}
                      hidden
                      dataField="IdLineType"
                    >
                      IdLineType
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="NHLAmericanSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      NHLAmericanSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="NHLAmericanOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      NHLAmericanOddsSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="NHLCanadianSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      NHLCanadianSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="NHLCanadianOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      NHLCanadianOddsSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="NHLMoneyLineSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      NHLMoneyLineSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="NHLTotalSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      NHLTotalSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="NHLTotalOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      NHLTotalOddsSync
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="NHLIncrease">
                      NHLIncrease
                    </TableHeaderColumn>
                  </BootstrapTable>
                  <BootstrapTable
                    data={[props.LineType]}
                    cellEdit={cellEditProp}
                    options={options}
                  >
                    <TableHeaderColumn
                      visible="none"
                      isKey={true}
                      hidden
                      dataField="IdLineType"
                    >
                      IdLineType
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="SOCGoalsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      SOCGoalsSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="SOCGoalsOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      SOCGoalsOddsSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="SOCMoneyLineSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      SOCMoneyLineSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="SOCTotalSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      SOCTotalSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="SOCTotalOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      SOCTotalOddsSync
                    </TableHeaderColumn>
                  </BootstrapTable>
                </TableCell>
              </Table>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </React.Fragment>
  );
}
