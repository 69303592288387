import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid'
import AddBoxIcon from "@material-ui/icons/AddBox";
import CreateLeagueMapping from './CreateLeagueMapping';
import Loader from "../../components/common/loader/Loader";
import EditIcon from '@material-ui/icons/Edit';
import { set } from 'date-fns';
import ModifyLeagueMapping from './ModifyLeagueMapping';
import TextField from "@material-ui/core/TextField";

function LeagueMapping () {
  
    const useStyles = makeStyles({
      root: {
        width: '100%',
        overflowX: 'auto',
      },
    });

    const classes = useStyles();

    useEffect(() => {            
      getALMReference();
      getSports();  
      getLineTypes(); 
      getGameTypes(); 
      getLeagues();                   
    }, []);  

    const [leagueMapping, setLeagueMapping] = useState([]);
    const [row, setRow] = useState([]);
    const [leagueMappingSelected, setLeagueMappingSelected] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [isNew, setIsNew] = useState(false);
    const [sports, setSports] = useState([]);
    const [lineTypes, setLineTypes] = useState();
    const [gameTypes, setGameTypes] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [filter, setFilter] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    
    useEffect(() => {
      setLoader();
    }, [leagues, sports, lineTypes, gameTypes]);

    useEffect(() => {
      console.log("row",row)
    }, [row]);

    useEffect(() => {
      console.log("row",filter)
    }, [filter]);

    const columns = [
        { field: 'id', headerName: 'DGS ID', width: 100, editable: false, sortable: false, filterable: false },
        { field: 'dgsLeague', headerName: 'DGS League', width: 250, editable: false, sortable: false, filterable: false},
        { field: 'donBestIdLeague', headerName: 'DB ID', width: 100, editable: false, sortable: false, filterable: false},
        { field: 'donBestLeague', headerName: 'Don Best League', width: 250, editable: false, sortable: false, filterable: false}
    ]

    const setLoader = () => {
      if(leagues.length === 0 || sports.length === 0 || lineTypes.length === 0 || gameTypes.length === 0) {
        setIsLoading(true);
      }else{
        setIsLoading(false);
      }      
    }

    const handleRowSelection = (row) => {
        setLeagueMappingSelected(row)                  
    } 
      
    const getALMReference = async () => {             
        await axios.get(process.env.REACT_APP_API_URL + "/AddOns_ALMLeagueReference")
        .then(r => {
            setLeagueMapping(r.data.records);   
            axios.get(process.env.REACT_APP_API_URL + "/LEAGUE")
            .then(r2 => {         
              setLeagues(r2.data.records);
              feedRow(r.data.records,r2.data.records);
            })            
        })            
    }   

    const getSports = async () => {      
      const url = process.env.REACT_APP_API_URL + "/SPORT";
      await axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          let groupingInfo = data.records.map((v) => {
            v.IdSport = v.IdSport;
            v.SportName = v.SportName;
            return v;
          });
          setSports(groupingInfo);
        });      
    }  

    const getLineTypes = async () => {
      const url = process.env.REACT_APP_API_URL + "/LINETYPE";
      await axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setLineTypes(data.records);
        });
    }

    const getGameTypes = async () => {
      const url = process.env.REACT_APP_API_URL + "/GAMETYPE";
      await axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setGameTypes(data.records);
        });              
    }

    const getLeagues = async () => {
      const url = process.env.REACT_APP_API_URL + "/LEAGUE";
      await axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setLeagues(data.records);          
        });              
    }

    function compare( a, b ) {
      if ( a.dgsLeague < b.dgsLeague ){
        return -1;
      }
      if ( a.dgsLeague > b.dgsLeague ){
        return 1;
      }
      return 0;
    }

    const feedRow = (leagueMapping,leagues) => {            
        let rowtemp = []
        for (let i = 0; i < leagueMapping.length; i++) {
          rowtemp.push({
            id: leagueMapping[i].idDGSidLeague,
            dgsLeague: leagues.find(x => x.IdLeague === leagueMapping[i].idDGSidLeague).Description,
            donBestIdLeague: leagueMapping[i].idDonBestLeague,
            donBestLeague: leagueMapping[i].nameDonBestLeague
          })
        }
        setRow(rowtemp.sort(compare));    
    }

    const handleCloseCreate = function () {
      setIsNew(false);
      setIsEdit(false);
    };

    const handleChangeFilter = (event) => {
      setFilter(event.target.value);
    }

    return (        
        <React.Fragment>
          {isLoading ? (                  
            <Loader height={100} width={100}></Loader>
          ):("")}
          <Paper className={classes.root}>
              <br />
              <br /> <h2>League Mapping</h2>
              <div style={{display:"flex", flexDirection:"row", alignContent:"space-between", alignItems:"center"}}>
                <label style={{padding:"8px",fontSize:"150%"}}>
                  <AddBoxIcon                 
                    aria-label="newGame"
                    size="small"
                    className={classes.fab}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsNew(true);                      
                    }}
                  />{" "}
                  Add New
                </label>  
                <label style={{padding:"8px",fontSize:"150%"}}>
                  <EditIcon                 
                    aria-label="editGame"
                    size="small"
                    className={classes.fab}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if(leagueMappingSelected.length > 0){
                        setIsEdit(true)
                      }else{
                        alert("Please select a row to edit")
                      }                     
                    }}
                  />{" "}
                  Edit
                </label>    
                <TextField      
                  style={{padding:"8px"}}                          
                  variant="outlined"                            
                  label="Search"
                  type="text"
                  value={filter}
                  onChange={handleChangeFilter}                                                             
                />
              </div>            
              <div style={{ height: 500, width: '100%' }}>
                  <DataGrid
                    rows={filter != "" ? row.filter(x => x.dgsLeague.toUpperCase().includes(filter.toUpperCase())) : row}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[5]}            
                    disableColumnSelector
                    disableColumnMenu
                    onSelectionModelChange={handleRowSelection}                                    
                    disableMultipleSelection={true}                                         
                  />
              </div>                
              {isNew ? (                  
                <CreateLeagueMapping 
                  onClose={handleCloseCreate}
                  sports={sports}
                  lineTypes={lineTypes}
                  gameTypes={gameTypes}                  
                />
              ):("")}
              {isEdit ? (
                <ModifyLeagueMapping
                  onClose={handleCloseCreate}
                  sports={sports}
                  lineTypes={lineTypes}
                  gameTypes={gameTypes}                   
                  rowSelected={leagueMapping.filter(x => x.idDGSidLeague == leagueMappingSelected)}
                  leagues={leagues}
                />
              ):("")}
          </Paper>            
        </React.Fragment>
    );

}
export default LeagueMapping;