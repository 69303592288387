import React, { useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

export default function CreateGame(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
 
  
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [dateChanged, setDateChanged] = React.useState();
  const [counterChanged, setCounterChanged] = React.useState();
  const [expirationDateChanged, setExpirationDateChanged] = React.useState(
    false
  );
  const [typeMessage, setTypeMessage] = React.useState(1);
  const [displayCounterChanged, setDisplayCounterChanged] = React.useState(
    false
  );
  const [disabledMessageChanged, setDisabledMessageChanged] = React.useState(
    false
  );
  const [message, setMessage] = React.useState();

  const [loading, setLoading] = React.useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );

  const handleChangeDateTime = (event) => {
    setDateChanged(event.target.value);
  };
  const handleChangeCounter = (event) => {
    setCounterChanged(event.target.value);
  };
  const handleChangeDisplayCounter = (event) => {
    setDisplayCounterChanged(!displayCounterChanged);
  };
  const handleChangeDisabledMessage = (event) => {
    setDisabledMessageChanged(!disabledMessageChanged);
  };
  const handleTypeChanged = (event) => {
    setTypeMessage(event.target.value);
  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleExpirationDateChanged = () => {
    setExpirationDateChanged(!expirationDateChanged);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };
  const handleOk = () => {
    let request = {
      IdAgent: props.IdAgent,
      Message: message.value ? message.value : message,
      MessageType: typeMessage.value ? typeMessage.value : typeMessage,
      UseExpirationDate: expirationDateChanged.value
        ? expirationDateChanged.value
        : expirationDateChanged,
      UseDisplayCounter: displayCounterChanged.value
        ? displayCounterChanged.value
        : displayCounterChanged,
      UseCloseOption: disabledMessageChanged.value
        ? disabledMessageChanged.value
        : disabledMessageChanged,
      ExpirationDate: dateChanged
        ? dateChanged.replace(' ', 'T').split('.')[0]
        : '',
      DisplayCounter: counterChanged,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL+'/Agents/AgentPlayerMessage',
      data: request,
    }).then((response) => {
      console.log('response', response);
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              New Player Message
            </Typography>
          </Toolbar>
        </AppBar>
        {loading ? (
          <CircularProgress style={{ margin: '0 auto' }} />
        ) : (
          <React.Fragment>
            <br />
            <form className={classes.center}>
              <FormGroup row>
                <TextField
                  style={{ width: '400px' }}
                  type="text"
                  label="Message"
                  value={message}
                  multiline={true}
                  rows="4"
                  onChange={handleChangeMessage}
                  variant="outlined"
                />
              </FormGroup>
              <Divider />
              <FormGroup row>
                <RadioGroup row>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={typeMessage == '0'}
                        onChange={handleTypeChanged}
                        value="0"
                        label="Normal"
                        name="radio-button-time"
                        inputProps={{ 'aria-label': 'Home' }}
                        // disabled
                      />
                    }
                    label="Normal"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={typeMessage == '1'}
                        onChange={handleTypeChanged}
                        value="1"
                        label="Permanent"
                        name="radio-button-time"
                        inputProps={{ 'aria-label': 'Visitor' }}
                        // disabled
                      />
                    }
                    label="Permanent"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormGroup>
              <Divider />
              {typeMessage == 0 ? (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={expirationDateChanged}
                        onChange={handleExpirationDateChanged}
                        color="primary"
                      />
                    }
                    label="Expiration Date"
                  />
                  {expirationDateChanged ? (
                    <TextField
                      variant="outlined"
                      id="gameDateTime"
                      label="Game Date and Time"
                      type="datetime-local"
                      onChange={handleChangeDateTime}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </FormGroup>
              ) : (
                ''
              )}
              <Divider />
              {typeMessage == 0 ? (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={displayCounterChanged}
                        onChange={handleChangeDisplayCounter}
                        color="primary"
                      />
                    }
                    label="Display Counter"
                  />
                  {displayCounterChanged ? (
                    <TextField
                      id="outlined-number"
                      label="Counter"
                      type="number"
                      onChange={handleChangeCounter}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    ''
                  )}
                </FormGroup>
              ) : (
                ''
              )}
              <Divider />
              {typeMessage == 0 ? (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={disabledMessageChanged}
                        onChange={handleChangeDisabledMessage}
                        color="primary"
                      />
                    }
                    label="Player can disabled message"
                  />
                </FormGroup>
              ) : (
                ''
              )}
            </form>
          </React.Fragment>
        )}
        <DialogActions>
          <Button
            color="inherit"
            startIcon={<CancelIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button startIcon={<SaveIcon />} color="inherit" onClick={handleOk}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
