import Axios from "axios";

export const getTeamNamesBySport = (idSport, callback) => {
  Axios.get(process.env.REACT_APP_API_GET_TEAMS_PER_SPORT_URL + idSport).then(
    (response) => {
        if (response.data.records && response.data.records.length > 0) {
            let dataToSet = response.data.records.map((team) => team.TeamName);
            callback(dataToSet);
          }
      
    }
  );
};
