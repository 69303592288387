import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AgentSettings from './AgentSettings';
import AgentPersonalInfo from './AgentPersonalInfo';
import AgentNotes from './AgentNotes';
import AgentRights from './AgentRights';
import AgentHierarchy from './AgentHierarchy';
import PlayerList from '../../PlayerList';
import AgentsList from '../../AgentsList';

function TabPanel(props) {

  const { children, value, index, ...other } = props;  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AgentTabs(props) {

  const [agents, setAgents] = useState([]);
  const [books, setBooks] = useState([]);
  const [agentType, setAgentType] = useState([]);
  const [grouping, setGrouping] = useState([]);  
  const [lineType, setLineType] = useState([]);
  const [agentPerHead, setAgentPerHead] = useState([]);
  const [language, setLanguage] = useState([]);
  const [currency, setCurrencies] = useState([]);
  const [agentStatistics, setAgentStatistics] = useState([]);
  const [value, setValue] = React.useState(0);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };  

  useEffect(() => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/Agent_GetAgentStatistics.php',
      data: {
        idAgent: props.AgentInfo.IdAgent,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        setAgentStatistics(data.data);
        
      });
  }, [props.AgentInfo]);

  useEffect(() => {
    getBookInfo();
    getTypes();
    getGrouping();
    getCurrencies();
    getLineTypes();
    getAgentPerHead();
    getLanguages();
    loadAgents();
  }, []);

  const loadAgents = () => {
    const url =
      process.env.REACT_APP_API_URL +
      "/AGENT?" +
      "filter=IdBook,in," +
      userInfo.commaSeparatedBooks;
    const timeout = setTimeout(() => {
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setAgents(data.records);
        }, 2000);

      return () => clearTimeout(timeout);
    });
  }

  const getTypes = () => {
    const url = process.env.REACT_APP_API_URL + '/AGENTTYPE?order=AgentType,asc';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let agentTypeInfo = data.records.map((v) => {
          v.IdLineType = v.IdAgentType;
          v.Description = v.AgentType;
          return v;
        });
        console.log('AGENT TYPE INFO ====>', data);
        setAgentType(agentTypeInfo);
      });
  }

  const getLanguages = () => {
    const url = process.env.REACT_APP_API_URL + '/Language';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let languageInfo = data.records.map((v) => {
          v.IdLineType = v.IdLanguage;
          v.Description = v.LangDesc;
          return v;
        });
        setLanguage(languageInfo);
      });
  }

  const getBookInfo = () => {
    const url = process.env.REACT_APP_API_URL + "/BOOK?order=IdBook";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let bookInfoInfo = data.records.map((v) => {
          v.value = v.IdBook;
          v.text = v.Description;
          return v;
        });
        setBooks(bookInfoInfo);
      });
  };

  const getAgentPerHead = () => {
    const url = process.env.REACT_APP_API_URL + '/AGENTPERHEAD';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let agentPerHeadInfo = data.records.map((v) => {
          v.IdLineType = v.IdAgentPerHead;
          v.Description = v.Description;
          return v;
        });
        setAgentPerHead(agentPerHeadInfo);
      });
  }

  const getGrouping = () => {
    const url = process.env.REACT_APP_API_URL + '/GROUPING?order=GroupName,asc';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let groupingInfo = data.records.map((v) => {
          v.IdLineType = v.IdGrouping;
          v.Description = v.GroupName;
          return v;
        });
        setGrouping(groupingInfo);
      });
  }

  const getCurrencies = () => {
    const url = process.env.REACT_APP_API_URL + '/CURRENCY?order=AgentType,asc';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let currencyInfo = data.records.map((v) => {
          v.IdLineType = v.IdCurrency;
          v.Description = v.Description;
          return v;
        });
        setCurrencies(currencyInfo);
      });
  }

  const getLineTypes = () => {
    const url = process.env.REACT_APP_API_URL + '/LINETYPE?order=Description,asc';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setLineType(data.records);
      });

      console.log('props', props)
  }


  return (
    <React.Fragment>
      <br />
      {props.AgentInfo && (
        <form>
          <Tabs value={value} onChange={handleChange} className="tabs-class">
            <Tab label="Settings" {...a11yProps(0)} />
            <Tab label="Players" {...a11yProps(1)} />
            <Tab label="Agents" {...a11yProps(2)} />
            <Tab label="Personal Information" {...a11yProps(3)} />
            <Tab to="/" label="Notes" {...a11yProps(4)} />
            <Tab to="/" label="Rights" {...a11yProps(5)} />
            <Tab to="/" label="Hierarchy" {...a11yProps(6)} />
          </Tabs>
          {
            books.length > 0 && agentType.length > 0  &&
            currency.length > 0 && language.length > 0 &&
            agentPerHead.length > 0 && lineType.length > 0 &&
            agentStatistics.length > 0 && agents.length > 0?
          (
            <TabPanel className="tab-content" value={value} index={0}>
              <AgentSettings 
                AgentInfo={props.AgentInfo} 
                books={books}
                agentType={agentType} 
                grouping={grouping} 
                currency={currency}
                language={language}
                agentPerHead={agentPerHead}
                lineType={lineType}
                agentStatistics={agentStatistics}
                agents={agents}
                setTrigger={props.setTrigger}
                trigger={props.trigger}
              ></AgentSettings>
            </TabPanel>
          ) : (
            ""
          )}
          
          <TabPanel className="tab-content" value={value} index={1}>
            <PlayerList
              IdAgent={props.AgentInfo.IdAgent}
              IdCurrency={props.AgentInfo.IdCurrency}
            />
          </TabPanel>
          <TabPanel className="tab-content" value={value} index={2}>
            <AgentsList IdAgent={props.AgentInfo.IdAgent} />
          </TabPanel>
          <TabPanel className="tab-content" value={value} index={3}>
            <AgentPersonalInfo AgentInfo={props.AgentInfo}></AgentPersonalInfo>
          </TabPanel>
          <TabPanel className="tab-content" value={value} index={4}>
            <AgentNotes IdAgent={props.AgentInfo.IdAgent}></AgentNotes>
          </TabPanel>
          <TabPanel className="tab-content" value={value} index={5}>
            <AgentRights IdAgent={props.AgentInfo.IdAgent}  />
          </TabPanel>
          <TabPanel className="tab-content" value={value} index={6}>
            <AgentHierarchy></AgentHierarchy>
          </TabPanel>
        </form>
      )}
    </React.Fragment>
  );
}
