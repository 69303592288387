import React, {useState} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import AgentHistory from './AgentHistory';
import PlayerOnlineMessage from './PlayerOnlineMessage';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import ContactsIcon from '@material-ui/icons/Contacts';

export default function GameModify(props) { // const user = useContext(UserContext);
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%'
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1
        },
        button: {
            margin: theme.spacing(1)
        },

        center: {
            margin: '0 auto',
            border: '1px grey black',
            textAlign: 'center',
            width: '75%',
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: 200
            },
            '& .MuiFormGroup-row': {
                justifyContent: 'center'
            },
            '& .MuiFormControl-root': {
                justifyContent: 'center'
            }
        }
    }));

    const [isAgentHistory, setIsAgentHistory] = useState(false);
    const [isNewMessage, setIsNewMessage] = useState(false);
    const [name, setName] = useState(props.AgentInfo.Name);
    const [password, setPassword] = useState(props.AgentInfo.Password);
    const [userInfo, setUserInfo] = useState(
        JSON.parse(sessionStorage.getItem("userInfo"))
      );

    const classes = useStyles();
    const handleCloseCharting = function () {
        setIsAgentHistory(false);
    };

    const handleChangeName = (event) => {
        setName(event.target.value)
    }

    const handleChangePassword = (event) => {
        setPassword(event.target.value)
    }

    
    
    const AgentUpdate = () => {
        let AgentRequest = {
          IdAgent: props.AgentInfo.IdAgent,
          Enable: props.AgentInfo.Enable,
          DontXferPlayerActivity: props.AgentInfo.DontXferPlayerActivity,
          IsDistributor: props.AgentInfo.IdDistributor,
          Distributor: props.AgentInfo.Distributor,
          CommSports: props.AgentInfo.CommSports,
          CommCasino: props.AgentInfo.CommCasino,
          CommHorses: props.AgentInfo.CommHorses,
          IdAgentType: props.AgentInfo.IdAgentType,
          IdCurrency: props.AgentInfo.IdCurrency,
          IdBook: props.AgentInfo.IdBook,
          IdGrouping: props.AgentInfo.IdGrouping,
          Agent1: props.AgentInfo.Agent,
          Name: name,
          Password: password,
          City: props.AgentInfo.City,
          State: props.AgentInfo.State,
          Country: props.AgentInfo.Country,
          Address1: props.AgentInfo.Address1,
          Address2: props.AgentInfo.Address2,
          Email: props.AgentInfo.Email,
          Phone: props.AgentInfo.Phone,
          Fax: props.AgentInfo.Fax,
          Zip: props.AgentInfo.Zip,
          OnlineAccess: props.AgentInfo.OnlineAccess,
          OnlineMessage: props.AgentInfo.OnlineMessage,
          IdLineType: props.AgentInfo.IdLineType,
          IdAgentPerHead: props.AgentInfo.IdAgentPerHead,
          IdLanguage: props.AgentInfo.IdLanguage,
          LastModificationUser: userInfo.UserId,
        };
        axios({
          method: 'post',
          url: process.env.REACT_APP_API2_URL + '/Agent/Agent_Update',
          data: AgentRequest,
        }).then((response) => {
          if (response.data === '-1') {
            alert('Data Successfully Updated');
          }
          console.log('response', response);
        });
      };

    return (
        <React.Fragment>
            <div id="agentInfoSection">
                <br/> {
                isNewMessage ? (
                    <PlayerOnlineMessage IdAgent={
                            props.AgentInfo.IdAgent
                        }
                        onClose={handleCloseCharting}/>
                ) : ('')
            }

                {
                isAgentHistory ? (
                    <AgentHistory IdAgent={
                            props.AgentInfo.IdAgent
                        }
                        StartDate={'2020-05-01 00:00:00'}
                        EndDate={'2020-05-07 00:00:00'}
                        Filter={0}
                        onClose={handleCloseCharting}/>
                ) : ('')
            }
                {
                props.AgentInfo && (
                    <form className={
                        classes.center
                    }>

                        <FormGroup row>
                            <FormControl className={
                                classes.margin
                            }>
                                <InputLabel htmlFor="input-with-icon-adornment">Agent</InputLabel>
                                <Input id="input-with-icon-adornment"
                                    value={
                                        props.AgentInfo.Agent
                                    }
                                    startAdornment={
                                        <InputAdornment
                                    position="start"><AccountCircle/></InputAdornment>                                    
                                    }                                    
                                    disabled
                                    />
                                    
                            </FormControl>
                            <FormControl className={
                                classes.margin
                            }>
                                <InputLabel htmlFor="input-with-icon-adornment">Name</InputLabel>
                                <Input id="input-with-icon-adornment"
                                    value={
                                        name
                                    }
                                    onChange={handleChangeName}
                                    onBlur={AgentUpdate}
                                    startAdornment={
                                        <InputAdornment
                                    position="start"><ContactsIcon/></InputAdornment>
                                    }
                                    />
                            </FormControl>
                            <FormControl className={
                                classes.margin
                            }>
                                <InputLabel htmlFor="input-with-icon-adornment">Password</InputLabel>
                                <Input id="input-with-icon-adornment"
                                    value={
                                        password
                                    }
                                    onChange={handleChangePassword}
                                    onBlur={AgentUpdate}
                                    startAdornment={
                                        <InputAdornment
                                    position="start"><LockIcon/></InputAdornment>
                                    }
                                    />
                            </FormControl>
                            <Button className={
                                    classes.button
                                }
                                variant="contained"
                                onClick={
                                    () => {
                                        setIsAgentHistory(true);
                                    }
                                }
                                //startIcon={<SearchIcon />}
                            >
                                History
                            </Button>

                            <Button className={
                                    classes.button
                                }
                                variant="contained"
                                onClick={
                                    () => {
                                        setIsNewMessage(true);
                                    }
                                }
                                //startIcon={<SearchIcon />}
                            >
                                New Online Msg
                            </Button>
                        </FormGroup>
                    </form>
                )
            } </div>
        </React.Fragment>
    );
}
