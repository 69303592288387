import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid' 
import Box from '@material-ui/core/Box';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import WebConfiguratorList from './WebConfiguratorList';
import Lottie from 'react-lottie';
import * as legoData from '../../common/animations/legoLoader.json';
import './WebConfiguratorRow.css';
import WebConfiguratorColumnsNew from './WebConfiguratorColumnsNew';

export default function WebConfiguratorRow(props) {
  
  const [webConfiguratorRow, setWebConfiguratorRow] = React.useState([]);
  const [idWebRow, setIdWebRow] = React.useState(0);
  const [order, setOrder] = React.useState();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);  
  const [isNewWebColumnDetail, setIsNewWebColumnDetail] = useState(false);
  let IdWebColumn = props.WebColumn.IdWebColumn;
  const [selectedWebDetailColumn,setSelectedWebDetailColumn] = useState(false);
  
  useEffect(() => {
    GetColumnDetail();
  }, [IdWebColumn]);
  
  const GetColumnDetail = () => {
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/WebConfigurator/WebColumnDetail_GetListInfo?idColumn=' +
        IdWebColumn,
    }).then((response) => {
      setWebConfiguratorRow(response.data);
      console.log('response', response);
    });
    setIsLoading(false);
  };  

  function deleteColumnDetail() {     
    if(window.confirm("Are you sure you want to delete the selected item? ("+selectedWebDetailColumn.IdWebColumn+")")){      
      let RequestDTO = {
        idWebRow: selectedWebDetailColumn.IdWebRow,
        idWebColumn: selectedWebDetailColumn.IdWebColumn,
        idUser: userInfo.UserId,
      };
      console.log("RequestDTO",RequestDTO)
      axios({
        method: 'post',
        url:
          process.env.REACT_APP_API2_URL + '/WebConfigurator/WebColumnDetail_Delete',
        data: RequestDTO,
      }).then((response) => {                    
        GetColumnDetail();
        alert('Data Successfully Deleted');  
        setSelectedWebDetailColumn(false);     
      });      
    }
  };
  
  const setWebOnMem = (IdWebRow) => {
    setIdWebRow(IdWebRow);
  };

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/

  function InsertWebDetailColumn(row) {
    let RequestDTO = {
      idWebColumn: props.WebColumn.IdWebColumn,
      idWebRow: idWebRow,
      rowOrder: order,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/WebConfigurator/WebColumnDetail_Insert',
      data: RequestDTO,
    }).then((response) => {
      GetColumnDetail();
      alert('Data Successfully Inserted');      
      console.log('response', response);
    });
  }

  function onRowSelect(row, isSelected, e) {
    setSelectedWebDetailColumn(webConfiguratorRow.filter(x => x.IdWebRow == row[0])[0])
  }

  function UpdateWebColumn(row, field, newValue) {
    let WebConfiguratorColumnRequest = {
      idWebColumn: row.IdWebColumn ,
      idWebRow: field === "IdWebRow" ? newValue : row.IdWebRow ,
      columnOrder: field === "ColumnOrder" ? newValue : row.ColumnOrder,
      idUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + "/webColumnDetailUpdate.php",
      data: WebConfiguratorColumnRequest,
    }).then((response) => {
        alert('Data Successfully Updated');
        console.log('response', response);
    });
  }

  const handleStopEditingCell = (params,event) => {
    if(window.confirm("Are you sure you want to update the selected data?")){
      UpdateWebColumn(params.row,params.field,event.target.value)
    }
  }

  const handleCloseCreate = function () {
    setIsNewWebColumnDetail(false); 
  };
 
  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <Fade in={true}>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </Fade>
        ) : (
          ''
        )}

        <Table>
          <TableRow style={{ backgroundColor: 'white' }}>
            <TableCell>
              <div class="select-input-general">
                <b>Related Options By Game</b>
              </div>
              <div class="select-input-general">               
                <div></div>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <div>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Order"
                  value={order}
                  multiline={false}
                  onChange={handleChangeOrder}
                />
              </div>
            </TableCell>
            <TableCell>
              <div style={{display:"flex"}}>
                {selectedWebDetailColumn ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={deleteColumnDetail}
                  >
                    Delete
                  </Button>
                  ) : (
                  ""
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={()=>{setIsNewWebColumnDetail(true)}}
                >
                  Add
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </Table>

        <Table>
          <TableRow>
            <TableCell>
              
              <br></br>
              <Box sx={{ width: '750px' , paddingLeft: '5%'}}>
              <b>Profile Info</b>
                <DataGrid
                  rows={webConfiguratorRow}
                  getRowId={(row) => Object.values(row)[2]}
                  columns={[                                         
                    {field: 'WebRowName' , headerName: 'Web Row Name', width: 350, editable: false, sortable: false, filterable: false},
                    {field: 'ColumnOrder' , headerName: 'Column Order', width: 350, editable: true, sortable: false, filterable: false},
                    
                  ]}
                  pageSize={100}
                  rowsPerPageOptions={[5]}            
                  disableColumnSelector
                  disableColumnMenu
                  disableMultipleSelection={true} 
                  autoHeight      
                  style={{backgroundColor:'white'}}    
                  onCellEditStop={handleStopEditingCell}  
                  onSelectionModelChange={onRowSelect}                  
                />
              </Box> 
            </TableCell>
          </TableRow>
        </Table>
      </div>
      {isNewWebColumnDetail?(        
            <WebConfiguratorColumnsNew
              onClose={handleCloseCreate}
              GetColumnDetail={GetColumnDetail}
              IdWebColumn={props.WebColumn.IdWebColumn}
              webConfiguratorRow={webConfiguratorRow}
            />          
        ):(
          "" 
        )}
    </React.Fragment>
  );
}
