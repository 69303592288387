import React, {
  useState,
  useRef,
  setState,
  useEffect,
  useCallback,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./Schedule.css";
import Time from "react-time";
import { DelayInput } from "react-delay-input";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import AddBoxIcon from "@material-ui/icons/AddBox";
import axios from "axios";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import GameModify from "../GameModify";
import ModifyBanner from "../ModifyBanner";
import GameStatus from "../GameStatus";
import CreateGame from "../CreateGame";
import CreateBanner from "../CreateBanner";
import DeleteGame from "../DeleteGame";
import ChartingInfo from "../ChartingInfo";
import GamePeriods from "../games/gameComponents/GamePeriod";
import HideGames from "../games/gameComponents/HideGames";
import CreateEvents from "../events/createEvents";
import GameSettingsByLt from "../games/gameComponents/GameSettingsByLt";
import DescriptionIcon from "@material-ui/icons/Description";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardEventHandler from "react-keyboard-event-handler";
import clsx from "clsx";
import { updateMLxp } from "./ScheduleHelper"; 
import {
  updateML,
  updateTO,
  updatePROPTNT,
  updateDraw,
  updateSP,
} from "../../api/SportsBookUtils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const useStylesCheck = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 24,
    height: 24,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "white",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "1px auto rgba(19,124,189,.6)",
      outlineOffset: 1,
    },
    "input:hover ~ &": {
      backgroundColor: "#137cbd",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 24,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

export default function Schedule(props) {
  const classes = useStyles();
  const classCheck = useStylesCheck();

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;
  const lineManagerGames = useSelector((state) => state.lineManagerGames);
  const [selectedIdGame, setselectedIdGame] = useState();
  const [gameList, setGameList] = useState();
  const [rotation, setRotation] = useState();
  const [selectedPlay, setselectedPlay] = useState();
  const [selectedIdLeague, setselectedIdLeague] = useState();
  const [isModify, setIsModify] = useState(false);
  const [isModifyBanner, setIsModifyBanner] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isPeriod, setIsPeriod] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isCharting, setIsCharting] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isNewEvent, setIsNewEvent] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [newBanner, setNewBanner] = useState(false);
  const [parentGame, setParentGame] = useState();
  const [homeNumber, setHomeNumber] = useState();
  const [visitorNumber, setVisitorNumber] = useState();
  const [homeTeam, setHomeTeam] = useState();
  const [visitorTeam, setVisitorTeam] = useState();
  const [gameDateTime, setGameDateTime] = useState();
  const [recentUpdatedGame, setRecentUpdatedGame] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(75);
  const [currentPage, setCurrentPage] = useState(0);
  const [valueHasChanged, setValueHasChanged] = useState(1);
  const [isChecked, setisChecked] = useState(false);

  const [coords, setCoords] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setGameList(lineManagerGames);
  }, [lineManagerGames]);

  const setEventKey = function (key, e) {
    if (key === "alt+d") {
      if (isChecked) {
        setIsDelete(true);
      } else {
        alert("You must select a game");
      }
    } else if (key === "alt+i") {
      if (isChecked) {
        setIsPeriod(true);
      } else {
        alert("You must select a game");
      }
    } else if (key === "alt+m") {
      if (isChecked) {
        setIsModify(true);
      } else {
        alert("You must select a game");
      }
    } else if (key === "alt+b") {
      if (isChecked) {
        setNewBanner(true);
      } else {
        alert("You must select a game");
      }
    } else if (key === "alt+t") {
      if (isChecked) {
        setIsSettings(true);
      } else {
        alert("You must select a game");
      }
    } else if (key === "alt+n") {
      setIsNew(true);
    } else if (key === "alt+s") {
      if (isChecked) {
        setIsStatus(true);
      } else {
        alert("You must select a game");
      }
    }
  };

  const updateML = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay
  ) => {
    updateMLxp(
      idGame,
      odds,
      idLinetype,
      newValue, //linea
      idSport,
      teamNumber,
      play,
      column,
      changeLine,
      changeOdds,
      otherTeam,
      IdGameType,
      otherColumn,
      otherPlay,
      valueHasChanged,
      setRecentUpdatedGame,
      setValueHasChanged,
      props
    );
  };

  const updateDraw = (idGame, odds, idLinetype) => {
    if (valueHasChanged) {
      //setoddBySport(data.records.odds);
      let drawObject = {
        idGame: idGame,
        odds: odds,
        idLinetype: idLinetype,
      };
      axios({
        method: "post",
        url: process.env.REACT_APP_DEF_PHP_URL + "/updateDraw.php",
        data: drawObject,
      }).then((response) => {});
    }
    setValueHasChanged(true);
  };

  const updatePROPTNT = (idGame, idLinetype, odds, teamNumber) => {
    if (valueHasChanged) {
      //setoddBySport(data.records.odds);
      let drawObject = {
        idGame: idGame,
        odds: odds,
        teamNumber: teamNumber,
        idLinetype: idLinetype,
      };
      axios({
        method: "post",
        url: process.env.REACT_APP_DEF_PHP_URL + "/changeTNTPROPValues.php",
        data: drawObject,
      }).then((response) => {});
    }
    setValueHasChanged(true);
  };

  const updateSP = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay,
    isOdd // 1  is just the spread, 2 is spread odd
  ) => {
    let queryString = "";
    if (isOdd == 1) {
      queryString = "&filter=SpreadSync,eq,1";
    } else {
      queryString = "&filter=SpreadOddsSync,eq,1";
    }

    if (valueHasChanged) {
      let flag = true;
      let line = idSport != "MLB" ? 1 : 3;
      let z = newValue < 0 ? newValue * -1 : newValue;
      setRecentUpdatedGame(idGame);
      props.setLastChangedGamedID(idGame);
      const url =
        process.env.REACT_APP_API_URL +
        "/ODDSBYSPORT?filter=IdLineType,eq," +
        idLinetype +
        "&filter=IdSport,eq," +
        idSport +
        "&filter=IdGameType,eq," +
        IdGameType +
        "&filter=Line,eq," +
        line +
        "&filter=Range,ge," +
        Math.round(z);
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          //setoddBySport(data.records.odds);
          let spreadObject = {
            idGame: idGame,
            odds: odds,
            idLinetype: idLinetype,
            idSport: idSport,
            newValue: parseFloat(newValue),
            play: play,
            teamNumber: teamNumber,
            column: column,
            changeLine: changeLine,
            changeOdds: changeOdds,
          };
          let storedOdd = -spreadObject.odds;
          let storedLine = -spreadObject.newValue;
          if (newValue != "" && odds != "") {
            let oddToLower = 0;
            let found = false;
            data.records.forEach((item, index) => {
              let valCompare = parseInt(spreadObject.odds);
              valCompare = valCompare < 0 ? -valCompare : valCompare;
              if (valCompare < item.Range && !found) {
                if (index == 0) {
                  oddToLower = data.records[index].OddsValue;
                } else {
                  oddToLower = data.records[index - 1].OddsValue;
                }
                found = true;
              }
            });
            oddToLower = oddToLower === 0 ? 20 : oddToLower;
            if (
              storedOdd === 100 ||
              storedOdd === -100 ||
              oddToLower / 2 + 100 === storedOdd
            ) {
              oddToLower = 0;
            } else if (storedOdd > 0 && storedOdd - oddToLower < 100) {
              oddToLower = 100 + (100 + oddToLower - storedOdd) - storedOdd;
              flag = false;
            }

            if (storedOdd === 100 || storedOdd === -100) {
              storedOdd = null;
            } else if (oddToLower === 0 || !flag) {
              storedOdd = storedOdd + oddToLower;
              storedOdd = storedOdd * -1;
            } else {
              storedOdd += -oddToLower;
            }
          } else {
            storedOdd = null;
            newValue = null;
            odds = null;
            spreadObject.odds = null;
            spreadObject.newValue = null;
          }

          axios({
            method: "post",
            url: process.env.REACT_APP_DEF_PHP_URL + "/updateSpread.php",
            data: spreadObject,
          }).then((response) => {
            axios({
              method: "post",
              url: process.env.REACT_APP_DEF_PHP_URL + "/updateSpread.php",
              data: {
                idGame: idGame,
                odds: storedOdd,
                idLinetype: idLinetype,
                idSport: idSport,
                newValue: storedLine,
                play: otherPlay,
                teamNumber: otherTeam,
                column: otherColumn,
                changeLine: changeLine,
                changeOdds: changeOdds,
              },
            }).then((response) => {
              const url =
                process.env.REACT_APP_API_URL +
                "/LINETYPELINKS?filter=IdLineTypeIn,eq," +
                idLinetype +
                "&filter=IdSport,eq," +
                idSport +
                queryString;
              axios
                .get(url)
                .then((response) => response.data)
                .then((data) => {
                  if (data.records !== null && data.records.length > 0) {
                    data.records.forEach(async (x) => {
                      let idLT = x.IdLineTypeOut;

                      await axios({
                        method: "post",
                        url:
                          process.env.REACT_APP_DEF_PHP_URL +
                          "/updateSpread.php",
                        data: {
                          idGame: idGame,
                          odds: odds,
                          idLinetype: idLT,
                          idSport: idSport,
                          newValue: parseFloat(newValue),
                          play: play,
                          teamNumber: teamNumber,
                          column: column,
                          changeLine: changeLine,
                          changeOdds: changeOdds,
                        },
                      }).then((response) => {
                        axios({
                          method: "post",
                          url:
                            process.env.REACT_APP_DEF_PHP_URL +
                            "/updateSpread.php",
                          data: {
                            idGame: idGame,
                            odds: storedOdd,
                            idLinetype: idLT,
                            idSport: idSport,
                            newValue: storedLine,
                            play: otherPlay,
                            teamNumber: otherTeam,
                            column: otherColumn,
                            changeLine: changeLine,
                            changeOdds: changeOdds,
                          },
                        }).then((response) => {});
                      });
                    });
                  }
                });

              props.searchGameValues();
            });
          });
        });
    }
    setValueHasChanged(true);
  };

  const updateTO = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay,
    isOdd
  ) => {
    let queryString = "";
    if ((isOdd = 1)) {
      queryString = "&filter=TotalSync,eq,1";
    } else {
      queryString = "&filter=TotalOddsSync,eq,1";
    }
    if (valueHasChanged) {
      let flag = true;
      let z = newValue < 0 ? newValue * -1 : newValue;
      setRecentUpdatedGame(idGame);
      props.setLastChangedGamedID(idGame);
      const url =
        process.env.REACT_APP_API_URL +
        "/ODDSBYSPORT?filter=IdLineType,eq," +
        idLinetype +
        "&filter=IdSport,eq," +
        idSport +
        "&filter=IdGameType,eq," +
        IdGameType +
        "&filter=Line,eq," +
        2 +
        "&filter=Range,ge," +
        Math.round(z);
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          // setoddBySport(data.records.odds);

          let totalObject = {
            idGame: idGame,
            odds: odds,
            idLinetype: idLinetype,
            idSport: idSport,
            newValue: newValue,
            play: play,
            teamNumber: teamNumber,
            column: column,
            changeLine: changeLine,
            changeOdds: changeOdds,
          };
          //if (totalObject.flag == true) {
          let storedOdd = -totalObject.odds;
          if (newValue != "" && odds != "") {
            let oddToLower = 0;
            let found = false;
            data.records.forEach((item, index) => {
              let valCompare = parseInt(totalObject.odds);
              valCompare = valCompare < 0 ? -valCompare : valCompare;
              if (valCompare <= item.Range && !found) {
                if (index == 0) {
                  oddToLower = data.records[index].OddsValue;
                } else {
                  oddToLower = data.records[index - 1].OddsValue;
                }
                found = true;
              }
            });
            oddToLower = oddToLower === 0 ? 20 : oddToLower;
            if (
              storedOdd === 100 ||
              storedOdd === -100 ||
              oddToLower / 2 + 100 === storedOdd
            ) {
              oddToLower = 0;
            } else if (storedOdd > 0 && storedOdd - oddToLower < 100) {
              oddToLower = 100 + (100 + oddToLower - storedOdd) - storedOdd;
              flag = false;
            }

            if (storedOdd === 100 || storedOdd === -100) {
              storedOdd = null;
            } else if (oddToLower === 0 || !flag) {
              storedOdd = storedOdd + oddToLower;
              storedOdd = storedOdd * -1;
            } else {
              storedOdd += -oddToLower;
            }
          } else {
            storedOdd = null;
            newValue = null;
            odds = null;
            totalObject.odds = null;
            totalObject.newValue = null;
          }

          //}
          axios({
            method: "post",
            url: process.env.REACT_APP_DEF_PHP_URL + "/updateTotal.php",
            data: totalObject,
          }).then((response) => {
            axios({
              method: "post",
              url: process.env.REACT_APP_DEF_PHP_URL + "/updateTotal.php",
              data: {
                idGame: idGame,
                odds: storedOdd,
                idLinetype: idLinetype,
                idSport: idSport,
                newValue: newValue,
                play: otherPlay,
                teamNumber: otherTeam,
                column: otherColumn,
                changeLine: changeLine,
                changeOdds: changeOdds,
              },
            }).then((response) => {
              const url =
                process.env.REACT_APP_API_URL +
                "/LINETYPELINKS?filter=IdLineTypeIn,eq," +
                idLinetype +
                "&filter=IdSport,eq," +
                idSport +
                queryString;
              axios
                .get(url)
                .then((response) => response.data)
                .then((data) => {
                  if (data.records !== null && data.records.length > 0) {
                    data.records.forEach(async (x) => {
                      let idLT = x.IdLineTypeOut;

                      await axios({
                        method: "post",
                        url:
                          process.env.REACT_APP_DEF_PHP_URL +
                          "/updateTotal.php",
                        data: {
                          idGame: idGame,
                          odds: odds,
                          idLinetype: idLT,
                          idSport: idSport,
                          newValue: newValue,
                          play: play,
                          teamNumber: teamNumber,
                          column: column,
                          changeLine: changeLine,
                          changeOdds: changeOdds,
                        },
                      }).then((response) => {
                        axios({
                          method: "post",
                          url:
                            process.env.REACT_APP_DEF_PHP_URL +
                            "/updateTotal.php",
                          data: {
                            idGame: idGame,
                            odds: storedOdd,
                            idLinetype: idLT,
                            idSport: idSport,
                            newValue: newValue,
                            play: otherPlay,
                            teamNumber: otherTeam,
                            column: otherColumn,
                            changeLine: changeLine,
                            changeOdds: changeOdds,
                          },
                        }).then((response) => {});
                      });
                    });
                  }
                });

              props.searchGameValues();
            });
          });
        });
    }
    setValueHasChanged(true);
  };

  const handleCloseModify = function () {
    setIsModify(false);
  };
  const handleCloseBannerModify = function () {
    setIsModifyBanner(false);
  };
  const handleCloseCharting = function () {
    setIsCharting(false);
  };
  const handleCloseHideGames = function () {
    setIsHidden(false);
  };
  const handleCloseIsNewEvent = function () {
    isNewEvent(false);
  };
  const handleCloseStatus = function () {
    setIsStatus(false);
  };
  const handleCloseCreate = function () {
    setIsNew(false);
  };
  const handleDelete = function () {
    setIsDelete(false);
  };
  const handlePeriods = function () {
    setIsPeriod(false);
  };
  const handleSettings = function () {
    setIsSettings(false);
  };
  const handleCloseNewBanner = function () {
    setNewBanner(false);
  };
  const pageNumbers = [];
  if (lineManagerGames) {
    for (
      let i = 1;
      i <= Math.ceil(lineManagerGames.length / rowsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
  }
  function handleClick(event, newPage) {
    console.log("handleClick", event.target.id);
    setCurrentPage(newPage);
  }
  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const getStyle = () => {
    return false;
  };

  const getLastGameId = () => {
    let lastSystemChangedLinesId = JSON.parse(
      localStorage.getItem("lastSystemChangedLinesId")
    );
    return lastSystemChangedLinesId;
  };

  const handleClickCheckEvent = (
    event,
    name,
    idLeagueSelected,
    parentGame,
    homeNumber,
    visitorNumber,
    visitorOdds
  ) => {
    props.handleClickCheck(name, visitorOdds);

    setselectedIdLeague(idLeagueSelected);
    setParentGame(parentGame);
    setHomeNumber(homeNumber);
    setVisitorNumber(visitorNumber);
    setRotation(visitorOdds);
  };

  const isSelected = (name, name2) =>
    props.IdSport != "TNT"
      ? props.selectedGame.indexOf(name) !== -1
      : props.selectedGame.indexOf(name2) !== -1;

  const handleContextMenu = useCallback((e) => {
    // e.preventDefault();
    console.log("right1 click");
  });

  document.addEventListener("contextmenu", handleContextMenu);
  //document.addEventListener('contextmenu', useEventListener);
  const useEventListener1 = (eventName, handler, element = window) => {
    // Create a ref that stores handler
    const savedHandler = useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);

    useEffect(
      () => {
        // Make sure element supports addEventListener
        // On
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        // Create event listener that calls handler function stored in ref
        const eventListener = (event) => savedHandler.current(event);

        // Add event listener
        element.addEventListener(eventName, eventListener);

        // Remove event listener on cleanup
        return () => {
          element.removeEventListener(eventName, eventListener);
        };
      },
      [eventName, element] // Re-run if eventName or element changes
    );
  };
  function StyledCheckbox(props) {
    const classesCk = useStylesCheck();

    return (
      <Checkbox
        className={classesCk.root}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(classesCk.icon, classesCk.checkedIcon)} />
        }
        icon={<span className={classesCk.icon} />}
        inputProps={{ "aria-label": "decorative checkbox" }}
        {...props}
      />
    );
  }

  // useEventListener1('mousemove', handler);
  return (
    <React.Fragment>
      <KeyboardEventHandler
        handleKeys={[
          "alt+d",
          "alt+i",
          "alt+m",
          "alt+b",
          "alt+t",
          "alt+s",
          "alt+n",
        ]}
        onKeyEvent={(key, e) => setEventKey(key, e)}
      />
      <div className="schedule" style={{ width: "80%" }}>
        {isNew ? (
          <CreateGame
            IdLinetype={props.IdLinetype.value}
            IdSport={props.IdSport}
            onClose={handleCloseCreate}
            searchGameValues={props.searchGameValues}
          />
        ) : (
          ""
        )}
        <AddBoxIcon
          aria-label="newGame"
          size="small"
          className={classes.fab}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsNew(true);
          }}
        />{" "}
        Add Games
        {isChecked ? (
          <div>
            <Tooltip title="Delete Game" aria-label="Delete Game">
              <DeleteIcon
                aria-label="edit"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  //setselectedIdGame(row.IdGame);
                  setIsDelete(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Game Periods" aria-label="Game Periods">
              <AssignmentIcon
                aria-label="edit"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  setIsPeriod(true);
                }}
              />
            </Tooltip>{" "}
            <Tooltip title="Add Banner" aria-label="Add Banner">
              <AddBoxIcon
                aria-label="newGame"
                size="small"
                // className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  setNewBanner(true);
                }}
              />
            </Tooltip>{" "}
            <Tooltip title="Game Status" aria-label="Game Status">
              <DescriptionIcon
                aria-label="description"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  setIsStatus(true);
                }}
              />
            </Tooltip>{" "}
            <Tooltip title="Modify Game" aria-label="Modify Game">
              <EditIcon
                aria-label="edit"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  setIsModify(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Settings Game" aria-label="Settings Game">
              <SettingsIcon
                aria-label="edit"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  //setselectedIdGame(row.IdGame);
                  setIsSettings(true);
                }}
              />
            </Tooltip>
            {props.selectedGame.length === 1 ? (
              <React.Fragment>
                <Tooltip title="Settings Game" aria-label="Settings Game">
                  <VisibilityOffIcon
                    aria-label="edit"
                    size="small"
                    className={classes.fab}
                    style={{
                      cursor: "pointer",
                      width: "18px",
                      height: "18px",
                    }}
                    onClick={() => {
                      //setselectedIdGame(row.IdGame);
                      setIsHidden(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Add Event" aria-label="Add Event">
                  <VisibilityOffIcon
                    aria-label="edit"
                    size="small"
                    className={classes.fab}
                    style={{
                      cursor: "pointer",
                      width: "18px",
                      height: "18px",
                    }}
                    onClick={() => {
                      //setselectedIdGame(row.IdGame);
                      setIsNewEvent(true);
                    }}
                  />
                </Tooltip>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div>
          <Table
            className={classes.table}
            component={Paper}
            aria-label="a dense table"
          >
            {newBanner ? (
              <CreateBanner
                IdGames={props.selectedGame}
                IdSport={props.IdSport}
                IdLeague={selectedIdLeague}
                GameDateTime={gameDateTime}
                HomeNumber={homeNumber}
                VisitorNumber={visitorNumber}
                onClose={handleCloseNewBanner}
                searchGameValues={props.searchGameValues}
                clearArray={props.ClearArrar}
              />
            ) : (
              ""
            )}
            {isModify ? (
              <GameModify
                IdGames={props.selectedGame}
                IdLinetype={props.IdLinetype.value}
                IdSport={props.IdSport}
                onClose={handleCloseModify}
                searchGameValues={props.searchGameValues}
                updateML={updateML}
                clearArray={props.ClearArrar}
              />
            ) : (
              ""
            )}
            {isModifyBanner ? (
              <ModifyBanner
                IdGames={selectedIdGame}
                IdLinetype={props.IdLinetype.value}
                IdSport={props.IdSport}
                onClose={handleCloseBannerModify}
                searchGameValues={props.searchGameValues}
              />
            ) : (
              ""
            )}
            {isStatus ? (
              <GameStatus
                IdGames={props.selectedGame}
                IdLinetype={props.IdLinetype.value}
                IdSport={props.IdSport}
                rotation={props.selectedGame[0]}
                onClose={handleCloseStatus}
                searchGameValues={props.searchGameValues}
                clearArray={props.ClearArrar}
                IdGameTNT={props.IdGameTNT}
              />
            ) : (
              ""
            )}

            {isDelete ? (
              <DeleteGame
                IdGames={props.selectedGame}
                clearArray={props.ClearArrar}
                searchGameValues={props.searchGameValues}
                onClose={handleDelete}
              />
            ) : (
              ""
            )}

            {isPeriod ? (
              <GamePeriods
                IdSport={props.IdSport}
                IdGames={props.selectedGame}
                clearArray={props.ClearArrar}
                onClose={handlePeriods}
                searchGameValues={props.searchGameValues}
              />
            ) : (
              ""
            )}

            {isSettings ? (
              <GameSettingsByLt
                IdGame={props.selectedGame}
                IdLinetype={props.IdLinetype.value}
                clearArray={props.ClearArrar}
                onClose={handleSettings}
                searchGameValues={props.searchGameValues}
              />
            ) : (
              ""
            )}

            {isCharting ? (
              <ChartingInfo
                IdGame={selectedIdGame}
                IdLinetype={props.IdLinetype.value}
                IdSport={props.IdSport}
                HomeTeam={homeTeam}
                VisitorTeam={visitorTeam}
                ActionType={0}
                Play={selectedPlay}
                onClose={handleCloseCharting}
              />
            ) : (
              ""
            )}
            {isHidden ? (
              <HideGames
                IdGame={props.selectedGame}
                onClose={handleCloseHideGames}
                IdSport={props.IdSport}
              />
            ) : (
              ""
            )}
            {isNewEvent ? (
              <CreateEvents
                IdGame={props.selectedGame}
                onClose={handleCloseIsNewEvent}
                IdSport={props.IdSport}
                IdLeague={selectedIdLeague}
                GameDateTime={gameDateTime}
                HomeTeam={homeTeam}
                VisitorTeam={visitorTeam}
                HomeNumber={homeNumber}
                VisitorNumber={visitorNumber}
              />
            ) : (
              ""
            )}

            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell order={2} className={classes.headerCell}>
                  <strong>Rotation</strong>
                </TableCell>
                <TableCell order={1} className={classes.headerCell}>
                  <strong>Match</strong>
                </TableCell>

                <TableCell className={classes.headerCell}></TableCell>

                <TableCell className={classes.headerCell}>
                  <strong>Spread</strong>
                </TableCell>

                <TableCell className={classes.headerCell}>
                  <strong>Action</strong>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <strong>Sum</strong>
                </TableCell>

                <TableCell className={classes.headerCell}>
                  <strong>Totals</strong>
                </TableCell>

                <TableCell className={classes.headerCell}>
                  <strong>Action</strong>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <strong>Sum</strong>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <strong>ML</strong>
                </TableCell>

                <TableCell className={classes.headerCell}>
                  <strong>Action</strong>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <strong>Sum</strong>
                </TableCell>

                <TableCell className={classes.headerCell}>
                  <strong>Special</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gameList &&
                lineManagerGames
                  .filter(
                    (e) =>
                      e.HomeTeam.toLowerCase().includes(
                        props.filterStr.toLowerCase()
                      ) ||
                      e.VisitorTeam.toLowerCase().includes(
                        props.filterStr.toLowerCase()
                      ) ||
                      e.HomeNumber.toString()
                        .toLowerCase()
                        .includes(props.filterStr.toLowerCase()) ||
                      e.VisitorNumber.toString().includes(
                        props.filterStr.toLowerCase()
                      ) ||
                      (e.HomeOdds &&
                        e.HomeOdds.toString().includes(
                          props.filterStr.toLowerCase()
                        )) ||
                      (e.VisitorOdds &&
                        e.VisitorOdds.toString()
                          .toLowerCase()
                          .includes(props.filterStr.toLowerCase()))
                  )
                  .slice(
                    currentPage * rowsPerPage,
                    currentPage * rowsPerPage + rowsPerPage
                  )
                  .map((row) => (
                    <React.Fragment>
                      {console.log("DATE ======>", row.GameDateTime)}
                      {row.Printable === 1 ? (
                        <TableRow>
                          <TableCell
                            colSpan="14"
                            className={` ${" schedule-banner classes.tableRow"} `}
                          >
                            <Time
                              value={row.GameDateTime}
                              format="DD/MM/YYYY"
                            />                             
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}

                      {row.Description !== null && row.Description !== "" ? (
                        <TableRow
                          className={` ${
                            row.GameStat === "B"
                              ? " schedule-banner classes.tableRow"
                              : "classes.tableRow"
                          } ${
                            row.IdGame === props.lastChangedGamedID
                              ? "row-recent"
                              : ""
                          }`}
                        >
                          <TableCell
                            colSpan="14"
                            className={` ${
                              row.GameStat === "B"
                                ? " schedule-banner classes.tableRow"
                                : "classes.tableRow"
                            } `}
                          >
                            {row.GameStat == "B" ? (
                              <Tooltip
                                title="Modify Banner"
                                aria-label="Modify Banner"
                              >
                                <EditIcon
                                  aria-label="edit"
                                  size="small"
                                  className={classes.fab}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setselectedIdGame(row.IdGame);
                                    setIsModifyBanner(true);
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                            {row.Description}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      <TableRow
                        style={{
                          backgroundColor:
                            "#" + row.GameType.BkColor.toString(16),
                          color: "#" + row.GameType.FkColor.toString(16),
                        }}
                        className={`${row.isHighlight ? "green" : ""} ${
                          row.GameStat === "B"
                            ? " schedule-banner classes.tableRow"
                            : getStyle(row.GameStat, row.GameDateTime)
                        } ${
                          row.IdGame === props.lastChangedGamedID
                            ? "row-recent"
                            : ""
                        }`}
                        key={row.IdGame}
                        onClick={(event) =>
                          handleClickCheckEvent(
                            event,
                            row.IdGame,
                            row.IdLeague,
                            row.ParentGame,
                            row.HomeNumber,
                            row.VisitorNumber,
                            row.VisitorOdds
                          )
                        }
                        checked={isSelected(row.IdGame, row.VisitorOdds)}
                      >
                        {row.GameStat !== "B" ? (
                          <React.Fragment>
                            <TableCell>
                              <label>
                                {/* 
                                  <Checkbox
                                checked={isSelected(
                                  row.IdGame,
                                  row.VisitorOdds
                                )}
                                color="primary"
                              />
                               */}
                                <StyledCheckbox
                                  checked={isSelected(
                                    row.IdGame,
                                    row.VisitorOdds
                                  )}
                                />
                                <span
                                  style={{
                                    color:
                                      "#" + row.GameType.FkColor.toString(16),
                                  }}
                                >
                                  <Time
                                    value={row.GameDateTime}
                                    format="DD/MM/YY HH:mm"
                                  />
                                </span>
                              </label>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <span
                                style={{
                                  color:
                                    "#" + row.GameType.FkColor.toString(16),
                                }}
                              >
                                {props.IdSport == "TNT" ||
                                props.IdSport == "PROP"
                                  ? ""
                                  : " " + row.VisitorNumber}
                                <br></br>

                                {props.IdSport == "TNT"
                                  ? " " + row.VisitorOdds
                                  : " " + row.HomeNumber}
                              </span>
                            </TableCell>
                          </React.Fragment>
                        ) : (
                          " "
                        )}
                        {row.GameStat !== "B" ? (
                          <TableCell>
                            <span
                              style={{
                                color: "#" + row.GameType.FkColor.toString(16),
                              }}
                            >
                              {props.IdSport == "TNT" ? "" : row.VisitorTeam}
                              {props.IdSport == "MLB"
                                ? " " + row.VisitorPitcher
                                : ""}
                              <br></br>
                              {props.IdSport == "TNT"
                                ? row.HomeOdds
                                : row.HomeTeam}
                              {props.IdSport == "MLB"
                                ? " " + row.HomePitcher
                                : ""}
                              <br></br>
                              {props.IdSport == "SOC" ? "Draw " : ""}
                            </span>
                          </TableCell>
                        ) : (
                          <TableCell
                            colSpan="14"
                            className={` ${
                              row.GameStat === "B"
                                ? " schedule-banner classes.tableRow"
                                : "classes.tableRow"
                            } `}
                            component="th"
                            scope="row"
                          >
                            <span
                              style={{
                                color: "#" + row.GameType.FkColor.toString(16),
                              }}
                            >
                              {row.VisitorTeam} {row.HomeTeam}
                            </span>
                          </TableCell>
                        )}

                        {row.GameStat !== "B" &&
                        props.IdSport != "TNT" &&
                        props.IdSport != "PROP" ? (
                          <TableCell className={classes.headerCell}>
                            <DelayInput
                              style={{
                                color: "#" + row.GameType.FkColor.toString(16),
                              }}
                              minLength={1}
                              type="number"
                              delayTimeout={2000}
                              className="schedule-text-box-input"
                              value={
                                row.VisitorSpread == 0 ||
                                row.VisitorSpread == null
                                  ? ""
                                  : row.VisitorSpread
                              }
                              onKeyDown={(event) => {
                                if (event.key == "Enter") {
                                  updateSP(
                                    row.IdGame,
                                    row.OverOdds,
                                    props.IdLinetype.value,
                                    event.target.value,
                                    props.IdSport,
                                    row.VisitorNumber,
                                    0,
                                    8,
                                    1,
                                    0,
                                    row.HomeNumber,
                                    row.IdGameType,
                                    9,
                                    1,
                                    1,
                                    valueHasChanged,
                                    setValueHasChanged,
                                    setRecentUpdatedGame,
                                    props
                                  );
                                  props.announce(
                                    row.IdGame,
                                    props.IdLinetype.value
                                  );
                                }
                              }}
                              onChange={() => {
                                setValueHasChanged(true);
                              }}
                            />
                            <DelayInput
                              style={{
                                color: "#" + row.GameType.FkColor.toString(16),
                              }}
                              minLength={1}
                              delayTimeout={2000}
                              type="number"
                              className="schedule-text-box-input"
                              value={
                                row.VisitorSpreadOdds == 0 ||
                                row.VisitorSpreadOdds == null
                                  ? ""
                                  : row.VisitorSpreadOdds
                              }
                              onKeyDown={(event) => {
                                if (event.key == "Enter") {
                                  updateSP(
                                    row.IdGame,
                                    event.target.value,
                                    props.IdLinetype.value,
                                    row.OverOdds,
                                    props.IdSport,
                                    row.VisitorNumber,
                                    0,
                                    8,
                                    0,
                                    1,
                                    row.HomeNumber,
                                    row.IdGameType,
                                    9,
                                    1,
                                    2,
                                    valueHasChanged,
                                    setValueHasChanged,
                                    setRecentUpdatedGame,
                                    props
                                  );

                                  props.announce(
                                    row.IdGame,
                                    props.IdLinetype.value
                                  );
                                }
                              }}
                              onChange={() => {
                                setValueHasChanged(true);
                              }}
                            />
                            <br />
                            <DelayInput
                              style={{
                                color: "#" + row.GameType.FkColor.toString(16),
                              }}
                              minLength={1}
                              delayTimeout={2000}
                              type="number"
                              className="schedule-text-box-input"
                              value={
                                row.HomeSpread == 0 || row.HomeSpread == null
                                  ? ""
                                  : row.HomeSpread
                              }
                              onKeyDown={(event) => {
                                if (event.key == "Enter") {
                                  updateSP(
                                    row.IdGame,
                                    row.UnderOdds,
                                    props.IdLinetype.value,
                                    event.target.value,
                                    props.IdSport,
                                    row.HomeNumber,
                                    1,
                                    9,
                                    1,
                                    0,
                                    row.VisitorNumber,
                                    row.IdGameType,
                                    8,
                                    0,
                                    1,
                                    valueHasChanged,
                                    setValueHasChanged,
                                    setRecentUpdatedGame,
                                    props
                                  );
                                  props.announce(
                                    row.IdGame,
                                    props.IdLinetype.value
                                  );
                                  //props.searchGameValues();
                                }
                              }}
                              onChange={() => {
                                setValueHasChanged(true);
                              }}
                            />
                            <DelayInput
                              style={{
                                color: "#" + row.GameType.FkColor.toString(16),
                              }}
                              minLength={1}
                              delayTimeout={2000}
                              type="number"
                              className="schedule-text-box-input"
                              value={
                                row.HomeSpreadOdds == 0 ||
                                row.HomeSpreadOdds == null
                                  ? ""
                                  : row.HomeSpreadOdds
                              }
                              onKeyDown={(event) => {
                                if (event.key == "Enter") {
                                  updateSP(
                                    row.IdGame,
                                    event.target.value,
                                    props.IdLinetype.value,
                                    event.target.value,
                                    props.IdSport,
                                    row.HomeNumber,
                                    1,
                                    9,
                                    0,
                                    1,
                                    row.VisitorNumber,
                                    row.IdGameType,
                                    8,
                                    0,
                                    2,
                                    valueHasChanged,
                                    setValueHasChanged,
                                    setRecentUpdatedGame,
                                    props
                                  );
                                  props.announce(
                                    row.IdGame,
                                    props.IdLinetype.value
                                  );
                                }
                              }}
                              onChange={() => {
                                setValueHasChanged(true);
                              }}
                            />
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {row.GameStat !== "B" ? (
                          <TableCell className={classes.headerCell}>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setHomeTeam(row.HomeTeam);
                                setVisitorTeam(row.VisitorTeam);
                                setselectedIdGame(row.IdGame);
                                setselectedPlay(4);
                                setIsCharting(true);
                              }}
                            >
                              {row.gameAction &&
                                (parseInt(row.gameAction.VSWin) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.VSCount) || "")}
                            </Link>

                            <br></br>
                            <br></br>

                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setHomeTeam(row.HomeTeam);
                                setVisitorTeam(row.VisitorTeam);
                                setselectedIdGame(row.IdGame);
                                setselectedPlay(5);
                                setIsCharting(true);
                              }}
                            >
                              {row.gameAction &&
                                (parseInt(row.gameAction.HSWin) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.HSCount) || "")}
                            </Link>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {row.GameStat !== "B" &&
                        props.IdSport != "TNT" &&
                        props.IdSport != "PROP" ? (
                          <TableCell className={classes.headerCell}>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setHomeTeam(row.HomeTeam);
                                setVisitorTeam(row.VisitorTeam);
                                setselectedIdGame(row.IdGame);
                                setIsCharting(true);
                              }}
                            >
                              {row.gameAction &&
                                (parseInt(row.gameAction.SVSRisk) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.SVSCount) || "")}
                            </Link>
                            <br></br>
                            <br></br>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setHomeTeam(row.HomeTeam);
                                setVisitorTeam(row.VisitorTeam);
                                setselectedIdGame(row.IdGame);
                                setIsCharting(true);
                              }}
                            >
                              {row.gameAction &&
                                (parseInt(row.gameAction.SHSRisk) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.SHSCount) || "")}
                            </Link>
                          </TableCell>
                        ) : (
                          ""
                        )}

                        {row.GameStat !== "B" &&
                        props.IdSport != "TNT" &&
                        props.IdSport != "PROP" ? (
                          <TableCell className={classes.headerCell}>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setHomeTeam(row.HomeTeam);
                                setVisitorTeam(row.VisitorTeam);
                                setselectedIdGame(row.IdGame);
                                setselectedPlay(0);
                                setIsCharting(true);
                              }}
                            >
                              {row.gameAction &&
                                (parseInt(row.gameAction.TOWin) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.TOCount) || "")}
                            </Link>
                            <br></br>
                            <br></br>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setHomeTeam(row.HomeTeam);
                                setVisitorTeam(row.VisitorTeam);
                                setselectedIdGame(row.IdGame);
                                setselectedPlay(0);
                                setIsCharting(true);
                              }}
                            >
                              {row.gameAction &&
                                (parseInt(row.gameAction.TUWin) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.TUCount) || "")}
                            </Link>
                          </TableCell>
                        ) : (
                          ""
                        )}

                        {row.GameStat !== "B" ? (
                          <TableCell className={classes.headerCell}>
                            <span
                              style={{
                                color: "#" + row.GameType.FkColor.toString(16),
                              }}
                            >
                              {props.IdSport != "TNT" && props.IdSport != "PROP"
                                ? "O "
                                : ""}
                            </span>
                            {props.IdSport != "TNT" &&
                            props.IdSport != "PROP" ? (
                              <DelayInput
                                style={{
                                  color:
                                    "#" + row.GameType.FkColor.toString(16),
                                }}
                                minLength={1}
                                type="number"
                                delayTimeout={2000}
                                className="schedule-text-box-input"
                                value={
                                  row.TotalOver == 0 || row.TotalOver == null
                                    ? ""
                                    : row.TotalOver
                                }
                                onBlur={(event) => {
                                  updateTO(
                                    row.IdGame,
                                    row.OverOdds,
                                    props.IdLinetype.value,
                                    event.target.value,
                                    props.IdSport,
                                    row.VisitorNumber,
                                    2,
                                    8,
                                    1,
                                    0,
                                    row.HomeNumber,
                                    row.IdGameType,
                                    9,
                                    3,
                                    1,
                                    valueHasChanged,
                                    setValueHasChanged,
                                    setRecentUpdatedGame,
                                    props
                                  );
                                  props.announce(
                                    row.IdGame,
                                    props.IdLinetype.value
                                  );
                                  //props.searchGameValues();
                                }}
                                onChange={() => {
                                  setValueHasChanged(true);
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {props.IdSport != "TNT" &&
                            props.IdSport != "PROP" ? (
                              <DelayInput
                                style={{
                                  color:
                                    "#" + row.GameType.FkColor.toString(16),
                                }}
                                minLength={1}
                                type="number"
                                delayTimeout={2000}
                                className="schedule-text-box-input"
                                value={
                                  row.OverOdds == 0 || row.OverOdds == null
                                    ? ""
                                    : row.OverOdds
                                }
                                onBlur={(event) => {
                                  updateTO(
                                    row.IdGame,
                                    event.target.value,
                                    props.IdLinetype.value,
                                    row.OverOdds,
                                    props.IdSport,
                                    row.VisitorNumber,
                                    2,
                                    9,
                                    0,
                                    1,
                                    row.HomeNumber,
                                    row.IdGameType,
                                    9,
                                    3,
                                    2,
                                    valueHasChanged,
                                    setValueHasChanged,
                                    setRecentUpdatedGame,
                                    props
                                  );
                                  props.announce(
                                    row.IdGame,
                                    props.IdLinetype.value
                                  );
                                }}
                                onChange={() => {
                                  setValueHasChanged(true);
                                }}
                              />
                            ) : (
                              ""
                            )}
                            <br></br>
                            <span
                              style={{
                                color: "#" + row.GameType.FkColor.toString(16),
                              }}
                            >
                              {props.IdSport != "TNT" && props.IdSport != "PROP"
                                ? "U "
                                : ""}
                            </span>

                            {props.IdSport != "PROP" ? (
                              <DelayInput
                                style={{
                                  color:
                                    "#" + row.GameType.FkColor.toString(16),
                                }}
                                minLength={1}
                                type="number"
                                delayTimeout={2000}
                                className={` ${
                                  props.IdSport == "TNT"
                                    ? "schedule-text-box-input-tnt"
                                    : " schedule-text-box-input"
                                } `}
                                value={
                                  row.TotalUnder == 0 || row.TotalUnder == null
                                    ? ""
                                    : row.TotalUnder
                                }
                                onBlur={(event) => {
                                  {
                                    props.IdSport != "TNT" &&
                                    props.IdSport != "PROP"
                                      ? updateTO(
                                          row.IdGame,
                                          row.UnderOdds,
                                          props.IdLinetype.value,
                                          event.target.value,
                                          props.IdSport,
                                          row.HomeNumber,
                                          3,
                                          8,
                                          1,
                                          0,
                                          row.VisitorNumber,
                                          row.IdGameType,
                                          9,
                                          2,
                                          1,
                                          valueHasChanged,
                                          setValueHasChanged,
                                          setRecentUpdatedGame,
                                          props
                                        )
                                      : updatePROPTNT(
                                          row.IdGame,
                                          props.IdLinetype.value,

                                          event.target.value,
                                          row.VisitorOdds,
                                          valueHasChanged,
                                          setValueHasChanged
                                        );
                                  }

                                  props.announce(
                                    row.IdGame,
                                    props.IdLinetype.value
                                  );
                                }}
                                onChange={() => {
                                  setValueHasChanged(true);
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {props.IdSport != "TNT" &&
                            props.IdSport != "PROP" ? (
                              <DelayInput
                                style={{
                                  color:
                                    "#" + row.GameType.FkColor.toString(16),
                                }}
                                minLength={1}
                                type="number"
                                delayTimeout={2000}
                                className="schedule-text-box-input"
                                value={
                                  row.UnderOdds == 0 || row.UnderOdds == null
                                    ? ""
                                    : row.UnderOdds
                                }
                                onBlur={(event) => {
                                  updateTO(
                                    row.IdGame,
                                    event.target.value,
                                    props.IdLinetype.value,
                                    event.target.value,
                                    props.IdSport,
                                    row.HomeNumber,
                                    3,
                                    9,
                                    0,
                                    1,
                                    row.VisitorNumber,

                                    row.IdGameType,
                                    9,
                                    2,
                                    2,
                                    valueHasChanged,
                                    setValueHasChanged,
                                    setRecentUpdatedGame,
                                    props
                                  );
                                  props.announce(
                                    row.IdGame,
                                    props.IdLinetype.value
                                  );
                                }}
                                onChange={() => {
                                  setValueHasChanged(true);
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        ) : (
                          ""
                        )}

                        {row.GameStat !== "B" &&
                        props.IdSport != "TNT" &&
                        props.IdSport != "PROP" ? (
                          <TableCell>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setHomeTeam(row.HomeTeam);
                                setVisitorTeam(row.VisitorTeam);
                                setselectedIdGame(row.IdGame);
                                setIsCharting(true);
                              }}
                            >
                              {row.gameAction &&
                                (parseInt(row.gameAction.STORisk) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.STOCount) || "")}
                            </Link>
                            <br></br>
                            <br></br>
                            {/*totals */}
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setHomeTeam(row.HomeTeam);
                                setVisitorTeam(row.VisitorTeam);
                                setselectedIdGame(row.IdGame);
                                setIsCharting(true);
                              }}
                            >
                              {row.gameAction &&
                                (parseInt(row.gameAction.STUWin) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.STUCount) || "")}
                            </Link>
                          </TableCell>
                        ) : (
                          ""
                        )}

                        {row.GameStat !== "B" && props.IdSport != "TNT" ? (
                          <TableCell className={classes.headerCell}>
                            {props.IdSport != "PROP" ? (
                              <DelayInput
                                style={{
                                  color:
                                    "#" + row.GameType.FkColor.toString(16),
                                }}
                                minLength={1}
                                type="number"
                                delayTimeout={2000}
                                className={` ${
                                  row.GameStat != "TNT" &&
                                  row.GameStat != "PROP"
                                    ? " schedule-text-box-input"
                                    : "schedule-text-box-input-tnt"
                                } `}
                                value={
                                  row.VisitorOdds == 0 ||
                                  row.VisitorOdds == null
                                    ? ""
                                    : row.VisitorOdds
                                }
                                onKeyDown={(event) => {
                                  if (event.key == "Enter") {
                                    updateML(
                                      row.IdGame,
                                      row.OverOdds,
                                      props.IdLinetype.value,
                                      event.target.value,
                                      props.IdSport,
                                      row.VisitorNumber,
                                      4,
                                      8,
                                      1,
                                      0,
                                      row.HomeNumber,
                                      row.IdGameType,
                                      8,
                                      5,
                                      valueHasChanged,
                                      setRecentUpdatedGame,
                                      setValueHasChanged,
                                      props
                                    );
                                    props.announce(
                                      row.IdGame,
                                      props.IdLinetype.value
                                    );
                                  }
                                }}
                                /*onBlur={(event) => {
                              updateML(
                                row.IdGame,
                                row.OverOdds,
                                props.IdLinetype.value,
                                event.target.value,
                                props.IdSport,
                                row.VisitorNumber,
                                4,
                                8,
                                1,
                                0,
                                row.HomeNumber,
                                row.IdGameType,
                                8,
                                5
                              );
                              props.announce(row.IdGame, props.IdLinetype.value);
                            }}*/
                                onChange={() => {
                                  setValueHasChanged(true);
                                }}
                              />
                            ) : (
                              ""
                            )}
                            <br></br>
                            <DelayInput
                              style={{
                                color: "#" + row.GameType.FkColor.toString(16),
                              }}
                              minLength={1}
                              type="number"
                              delayTimeout={2000}
                              className={` ${
                                props.IdSport == "TNT" ||
                                props.IdSport == "PROP"
                                  ? "schedule-text-box-input-tnt"
                                  : " schedule-text-box-input"
                              } `}
                              value={
                                row.HomeOdds == 0 || row.HomeOdds == null
                                  ? ""
                                  : row.HomeOdds
                              }
                              onKeyDown={(event) => {
                                if (event.key == "Enter") {
                                  if (props.IdSport != "PROP") {
                                    updateML(
                                      row.IdGame,
                                      row.OverOdds,
                                      props.IdLinetype.value,
                                      event.target.value,
                                      props.IdSport,
                                      row.VisitorNumber,
                                      5,
                                      8,
                                      1,
                                      0,
                                      row.HomeNumber,
                                      row.IdGameType,
                                      8,
                                      4,
                                      valueHasChanged,
                                      setRecentUpdatedGame,
                                      setValueHasChanged,
                                      props
                                    );
                                  } else {
                                    updatePROPTNT(
                                      row.IdGame,
                                      props.IdLinetype.value,
                                      event.target.value,
                                      row.VisitorNumber,
                                      valueHasChanged,
                                      setValueHasChanged
                                    );
                                  }

                                  props.announce(
                                    row.IdGame,
                                    props.IdLinetype.value
                                  );
                                }
                              }}
                              onChange={() => {
                                setValueHasChanged(true);
                              }}
                            />
                            <br></br>
                            {props.IdSport == "SOC" ? (
                              <DelayInput
                                style={{
                                  color:
                                    "#" + row.GameType.FkColor.toString(16),
                                }}
                                minLength={1}
                                type="number"
                                delayTimeout={2000}
                                className="schedule-text-box-input"
                                value={
                                  row.VisitorSpecialOdds == 0 ||
                                  row.VisitorSpecialOdds == null
                                    ? ""
                                    : row.VisitorSpecialOdds
                                }
                                onKeyDown={(event) => {
                                  if (event.key == "Enter") {
                                    updateDraw(
                                      row.IdGame,
                                      event.target.value,
                                      props.IdLinetype.value,
                                      valueHasChanged,
                                      setValueHasChanged
                                    );
                                    props.announce(
                                      row.IdGame,
                                      props.IdLinetype.value
                                    );
                                  }
                                }}
                                onChange={() => {
                                  setValueHasChanged(true);
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        ) : (
                          ""
                        )}

                        {row.GameStat !== "B" ? (
                          <TableCell className={classes.headerCell}>
                            <span
                              style={{
                                color: "#" + row.GameType.FkColor.toString(16),
                              }}
                            >
                              {" "}
                              {row.gameAction &&
                                (parseInt(row.gameAction.VWin) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.VCount) || "")}
                              <br></br>
                              <br></br>
                              {row.gameAction &&
                                (parseInt(row.gameAction.HRisk) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.HCount) || "")}
                            </span>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {row.GameStat !== "B" ? (
                          <TableCell className={classes.headerCell}>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setHomeTeam(row.HomeTeam);
                                setVisitorTeam(row.VisitorTeam);
                                setselectedIdGame(row.IdGame);
                                setIsCharting(true);
                              }}
                            >
                              {row.gameAction &&
                                (parseInt(row.gameAction.SVRisk) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.SVCount) || "")}
                            </Link>

                            <br></br>

                            <br></br>

                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                setHomeTeam(row.HomeTeam);
                                setVisitorTeam(row.VisitorTeam);
                                setselectedIdGame(row.IdGame);
                                setIsCharting(true);
                              }}
                            >
                              {row.gameAction &&
                                (parseInt(row.gameAction.SHRisk) || "") +
                                  "-" +
                                  (parseInt(row.gameAction.SHCount) || "")}
                            </Link>
                          </TableCell>
                        ) : (
                          ""
                        )}

                        {/*      <TableCell className={classes.headerCell}>
                    {row.VisitorSpecial}
                    {row.VisitorSpecialOdds}
                    <br></br>
                    {row.HomeSpecial} {row.HomeSpecialOdds}
                  </TableCell>
                      */}
                      </TableRow>
                    </React.Fragment>
                  ))}
            </TableBody>
          </Table>
        </div>
        {props && gameList && (
          <div>
            <TablePagination
              rowsPerPageOptions={[50, 75, 125, 175]}
              component="div"
              count={lineManagerGames.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleClick}
              onChangeRowsPerPage={handleChange}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
