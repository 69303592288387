import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Table, TableRow, TableCell } from '@material-ui/core/';

import WebConfiguratorList from './WebConfiguratorList';
import WebConfigurationColumnHead from './WebConfigurationColumnHead';
import WebConfiguratorRow from './WebConfiguratorRow';
import WebConfiguratorColumns from './WebConfiguratorColumns';
import './WebConfiguratorTabs.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function WebConfiguratorTabs(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [webRow, setWebRow] = React.useState(false);
  const [webColumn, setWebColumn] = React.useState(false);
  //const user = useContext(UserContext);
  console.log('In Tabs ===>', props);

  const loadWebRow = (row) => {
    console.log(`You click row id: ${row}`);

    setWebRow(row);
  };

  const loadWebColumn = (column) => {
    setWebColumn(column);
  };

  return (
    <React.Fragment>
      <br />
      <Tabs value={value} onChange={handleChange} className="tabs-class">
        <Tab label="Web Rows" {...a11yProps(0)} />
        <Tab label="Web Columns" {...a11yProps(1)} />
      </Tabs>
      <TabPanel className="tab-content" value={value} index={0}>
        <Table>
          <TableRow className="cell-top-align">
            <TableCell className="cell-top-align">
              <WebConfiguratorList
                loadWebColumn={loadWebColumn}
                loadWebRow={loadWebRow}
              >
                {' '}
              </WebConfiguratorList>
            </TableCell>
            <TableCell className="cell-top-align">
              {webRow && (
                <WebConfiguratorRow WebRow={webRow}> </WebConfiguratorRow>
              )}
            </TableCell>
          </TableRow>
        </Table>
      </TabPanel>
      <TabPanel className="tab-content" value={value} index={1}>
        <Table>
          <TableRow className="cell-top-align">
            <TableCell className="cell-top-align">
              <WebConfigurationColumnHead
                loadWebColumn={loadWebColumn}
                loadWebRow={loadWebRow}
              >
                {' '}
              </WebConfigurationColumnHead>
            </TableCell>
            <TableCell className="cell-top-align">
              {webColumn && (
                <WebConfiguratorColumns WebColumn={webColumn}>
                  {' '}
                </WebConfiguratorColumns>
              )}
            </TableCell>
          </TableRow>
        </Table>
      </TabPanel>
    </React.Fragment>
  );
}
