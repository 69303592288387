import React, { useState, setState, useEffect, useContext } from "react";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { UserContext } from "../contexts/UserContext";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import CircularProgress from "@material-ui/core/CircularProgress";
import WagersInfo from "./WagersInfo";
import NumberFormat from "react-number-format";
import { StyledTableCell, StyledTableRow } from "./common/Table";
import { TablePagination } from "@material-ui/core";

export default function GameModify(props) {

  const user = useContext(UserContext);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);

  function handleClick(event, newPage) {
    setCurrentPage(newPage);
  }
  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    appBar: {
      position: "relative",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },
    table: {
      minWidth: "100%",
    },
    headerCell: {
      borderLeft:'2px solid white',
      borderRight:'2px solid white',
    },
    Cell: {
      borderLeft:'2px solid white',
      borderRight:'2px solid white',
      textAlign:'center !important'
    },
    center: {
      margin: "0 auto",
      border: "1px grey black",
      textAlign: "center",
      width: "75%",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 200,
      },
      "& .MuiFormGroup-row": {
        justifyContent: "center",
      },
      "& .MuiFormControl-root": {
        justifyContent: "center",
      },
    },
  }));

 

  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [valuesLine, setValuesLine] = React.useState();
  const [lineValue, setLineValue] = React.useState();
  const [actionTypeChanged, setActionTypeChanged] = React.useState(0);
  const [playerTypeChanged, setPlayerTypeChanged] = React.useState(2);
  const [lineChanged, setLineChanged] = React.useState(2);
  const [straightBetChanged, setStraightBetChanged] = React.useState(true);
  const [parlaysChanged, setParlaysChanged] = React.useState(false);
  const [teasersChanged, setTeasersChanged] = React.useState(false);
  const [othersBetChanged, setOthersBetChanged] = React.useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [playValue, setPlayValue] = React.useState(props.Play);
  const [wagersValues, setWagersValues] = React.useState();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );

  const handleActionTypeChanged = (event) => {
    setActionTypeChanged(event.target.value);
  };
  const handlePlayerTypeChanged = (event) => {
    setPlayerTypeChanged(event.target.value);
  };
  const handleLineChanged = (event) => {
    setLineChanged(event.target.value);
  };

  const handleStraightBetChanged = (straightBet) => {
    setStraightBetChanged(straightBet);
  };
  const handleParlaysChanged = (parlayBet) => {
    setParlaysChanged(parlayBet);
  };
  const handleTeasersChanged = (teaserBet) => {
    setTeasersChanged(teaserBet);
  };
  const handleOthersBetsChanged = (otherBet) => {
    setOthersBetChanged(otherBet);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };

  const VisitorValues = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_DEF_PHP_URL + "/WagersOnGameGetBothLines.php",
      data: {
        IdLineType: props.Action ? -1 : props.IdLinetype,
        IdGame: props.IdGame,
        IdSport: props.IdSport,
        Play: props.Play,
        ActionType: actionTypeChanged,
        PlayerType: playerTypeChanged,
        Line: lineChanged,
        ShowStraightBet: straightBetChanged,
        ShowParlay: parlaysChanged,
        ShowTeaser: teasersChanged,
        ShowOthers: othersBetChanged,
        NHLAmerican: 0,
        UserId: userInfo.UserId,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        setValuesLine(data.data);
      })
      .then(setLoading(false));
  };

  const GetWagersValues = (pointsValue, play) => {
    axios({
      method: "post",
      url: process.env.REACT_APP_DEF_PHP_URL + "/WagersOnGameGetWagers.php",
      data: {
        IdLineType: props.Action ? -1 : props.IdLinetype,
        IdGame: props.IdGame,
        IdSport: props.IdSport,
        Play: play,
        Points: pointsValue,
        PlayerType: playerTypeChanged,
        Line: lineChanged,
        ShowStraightBet: straightBetChanged,
        ShowParlay: parlaysChanged,
        ShowTeaser: teasersChanged,
        ShowOthers: othersBetChanged,
        NHLAmerican: 0,
        UserId: userInfo.UserId,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        setWagersValues(data.data);
      })
      .then(setLoading(false));
  };

  useEffect(() => {
    VisitorValues();
  }, [actionTypeChanged,playerTypeChanged,straightBetChanged, parlaysChanged, teasersChanged, othersBetChanged, lineChanged]);

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Dialog style={{ maxWidth: "100%" }} open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Action vs Line
            </Typography>
          </Toolbar>
        </AppBar>
        {loading ? (
          <CircularProgress style={{ margin: "0 auto" }} />
        ) : (
          <React.Fragment>
            <br />
            <form className={classes.center}>
              <FormGroup row>
                <h5>Action</h5>
              </FormGroup>
              <FormGroup row>
                <RadioGroup row>
                  <FormControlLabel
                    // value="None"
                    control={
                      <Radio
                        checked={actionTypeChanged == "0"}
                        onChange={handleActionTypeChanged}
                        value="0"
                        label="None"
                        name="radio-button-time"
                        inputProps={{ "aria-label": "None" }}
                        //disabled
                      />
                    }
                    label="Default"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    //   value="Home"
                    control={
                      <Radio
                        checked={actionTypeChanged == "1"}
                        onChange={handleActionTypeChanged}
                        value="1"
                        label="Home"
                        name="radio-button-time"
                        inputProps={{ "aria-label": "Home" }}
                        // disabled
                      />
                    }
                    label="Risk"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    //  value="Visitor"
                    control={
                      <Radio
                        checked={actionTypeChanged == "2"}
                        onChange={handleActionTypeChanged}
                        value="2"
                        label="Visitor"
                        name="radio-button-time"
                        inputProps={{ "aria-label": "Visitor" }}
                        // disabled
                      />
                    }
                    label="Win"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormGroup>
              <Divider />
              <FormGroup row>
                <h5>Wager Type</h5>
              </FormGroup>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={straightBetChanged}
                      onChange={(ev) =>
                        handleStraightBetChanged(ev.currentTarget.checked)
                      }
                      color="primary"
                    />
                  }
                  label="StraightBet"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={parlaysChanged}
                      onChange={(ev) =>
                        handleParlaysChanged(ev.currentTarget.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Parlays"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={teasersChanged}
                      onChange={(ev) =>
                        handleTeasersChanged(ev.currentTarget.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Teasers"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={othersBetChanged}
                      onChange={(ev) =>
                        handleOthersBetsChanged(ev.currentTarget.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Others"
                />
              </FormGroup>
              <Divider />
              <FormGroup row>
                <h5>Player Type</h5>
              </FormGroup>
              <FormGroup row>
                <RadioGroup row>
                  <FormControlLabel
                    // value="None"
                    control={
                      <Radio
                        checked={playerTypeChanged == "0"}
                        onChange={handlePlayerTypeChanged}
                        value="0"
                        label="None"
                        name="radio-button-time"
                        inputProps={{ "aria-label": "None" }}
                        //disabled
                      />
                    }
                    label="Single Player"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    //   value="Home"
                    control={
                      <Radio
                        checked={playerTypeChanged == "1"}
                        onChange={handlePlayerTypeChanged}
                        value="1"
                        label="Home"
                        name="radio-button-time"
                        inputProps={{ "aria-label": "Home" }}
                        // disabled
                      />
                    }
                    label="Master Player"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    //  value="Visitor"
                    control={
                      <Radio
                        checked={playerTypeChanged == "2"}
                        onChange={handlePlayerTypeChanged}
                        value="2"
                        label="Visitor"
                        name="radio-button-time"
                        inputProps={{ "aria-label": "Visitor" }}
                        // disabled
                      />
                    }
                    label="Both"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormGroup>
              <Divider />
              <FormGroup row>
                <h5>Line</h5>
              </FormGroup>
              <FormGroup row>
                <RadioGroup row>
                  <FormControlLabel
                    // value="None"
                    control={
                      <Radio
                        checked={lineChanged == "0"}
                        onChange={handleLineChanged}
                        value="0"
                        label="None"
                        name="radio-button-time"
                        inputProps={{ "aria-label": "None" }}
                        //disabled
                      />
                    }
                    label="Normal"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    //   value="Home"
                    control={
                      <Radio
                        checked={lineChanged == "1"}
                        onChange={handleLineChanged}
                        value="1"
                        label="Home"
                        name="radio-button-time"
                        inputProps={{ "aria-label": "Home" }}
                        // disabled
                      />
                    }
                    label="From Agent"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    //  value="Visitor"
                    control={
                      <Radio
                        checked={lineChanged == "2"}
                        onChange={handleLineChanged}
                        value="2"
                        label="Visitor"
                        name="radio-button-time"
                        inputProps={{ "aria-label": "Visitor" }}
                        // disabled
                      />
                    }
                    label="Both"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormGroup>
              <Divider />
              <FormGroup row>
                <FormControl className={classes.formControl}>
                  <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                      <StyledTableRow>
                        <StyledTableCell className={classes.headerCell}>
                          <strong>{props.VisitorTeam}</strong>
                        </StyledTableCell>
                        <StyledTableCell className={classes.headerCell}>
                          <strong>Amount</strong>
                        </StyledTableCell>
                        <StyledTableCell className={classes.headerCell}>
                          <strong>{props.HomeTeam}</strong>
                        </StyledTableCell>
                        <StyledTableCell className={classes.headerCell}>
                          <strong>Amount</strong>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {valuesLine &&
                        valuesLine
                          .slice(
                            currentPage * rowsPerPage,
                            currentPage * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <StyledTableRow className={classes.tableRow}>
                              <StyledTableCell
                                className={classes.Cell}
                              >
                                {row[2] === "V" ? (
                                  <Button
                                    onClick={() => {
                                      GetWagersValues(row[0], props.Play);
                                      setIsTrue(true);
                                    }}
                                  >
                                    <NumberFormat
                                      value={row[0]}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </Button>
                                ) : (
                                  " "
                                )}
                              </StyledTableCell>
                              <StyledTableCell 
                                className={classes.Cell}
                              >
                                {row[2] === "V" ? row[1] : ""}
                              </StyledTableCell>
                              <StyledTableCell
                                className={classes.Cell}
                              >
                                {row[2] === "H" ? (
                                  <Button
                                    onClick={() => {
                                      GetWagersValues(row[0], props.Play + 1);
                                      setIsTrue(true);
                                    }}
                                  >
                                    {row[0]}
                                  </Button>
                                ) : (
                                  " "
                                )}
                              </StyledTableCell>
                              <StyledTableCell 
                                className={classes.Cell}
                              >
                                {row[2] === "H" ? row[1] : ""}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    component="div"
                    count={valuesLine && valuesLine.length}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onChangePage={handleClick}
                    onChangeRowsPerPage={handleChange}
                  />
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormControl className={classes.formControl}>
                  {isTrue ? (
                    <WagersInfo
                      WagersValues={wagersValues}
                      GetWagersValues={GetWagersValues}
                    />
                  ) : (
                    ""
                  )}
                </FormControl>
              </FormGroup>
            </form>
          </React.Fragment>
        )}
        <DialogActions>
          <Button
            color="inherit"
            startIcon={<CancelIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
