import React, { useState, setState, useEffect, useContext } from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import Axios from "axios";
import FormGroup from "@material-ui/core/FormGroup";
import Divider from "@material-ui/core/Divider";
import Box from '@mui/material/Box';

import { DataGrid, esES } from '@material-ui/data-grid'

function LastChanges (props) {

    const useStyles = makeStyles((theme) => ({        
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },
        center: {
            margin: "0 auto",
            border: "1px grey black",
            textAlign: "center",
            //width: "600px",
            height: "600px",
            "& .MuiTextField-root": {
              margin: theme.spacing(1),
              //width: 500,
            },
            "& .MuiFormGroup-row": {
              justifyContent: "center",
            },
            "& .MuiFormControl-root": {
              justifyContent: "center",
            },
            "& .MuiSelect-select": {
                width: 220,
                spacing: theme.spacing(1),
            }
        },        
    }));

    const classes = useStyles();
    const [lastChanges, setLastChanges] = useState([]);
    const columns = [
        { field: 'id', headerName: 'Id', width: 0, editable: false, sortable: false, filterable: false, hide:true },  
        { field: 'FieldName', headerName: 'Field Name', width: 150, editable: false, sortable: false, filterable: false },
        { field: 'OldValue', headerName: 'Old Value', width: 150, editable: false, sortable: false, filterable: false},  
        { field: 'NewValue', headerName: 'New Value', width: 150, editable: false, sortable: false, filterable: false },
        { field: 'LoginName', headerName: 'Modify By', width: 150, editable: false, sortable: false, filterable: false},
        { field: 'PlacedDate', headerName: 'Last Modified', width: 250, editable: false, sortable: false, filterable: false,valueGetter: (params) => params.row.PlacedDate.date},  
        { field: 'PhoneLine', headerName: 'Phone', width: 150, editable: false, sortable: false, filterable: false },
        { field: 'IP', headerName: 'IP', width: 150, editable: false, sortable: false, filterable: false}
      ]

    useEffect(() => {
        getLastChanges();
      }, []);

    const getLastChanges = (idApp) => {
        Axios({
          method: 'post',
          url: process.env.REACT_APP_DEF_PHP_URL + '/GetLastChanges.php',
          data: {
            changeType: 1,
            idSport:'',
            param1:props.player.IdPlayer,
            param2:-1,
            param3:-1,
          },
        }).then((response) => {
          setLastChanges(response.data.data)
        });
      };

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
          }
    }


    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={handleClose}>
                <AppBar className={classes.appBar}>            
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                        Last Changes
                        </Typography>
                    </Toolbar>
                </AppBar>  
                    <Box sx={{ height:500, width: '100%', marginBottom:"10px",marginTop:"20px"}}>
                        <DataGrid                  
                            rows={lastChanges}
                            columns={columns}
                            pageSize={100}
                            rowsPerPageOptions={[5]}            
                            disableColumnSelector
                            disableColumnMenu
                            //onSelectionModelChange={handleRowSelection}                                    
                            disableMultipleSelection={true}    
                            //onCellEditCommit={handleCell}   
                            //onRowClick={handleRow}                              
                        />                
                    </Box>
                    <Divider /> 
                    <React.Fragment>
                        <DialogActions>                            
                            <div>   
                                <Button
                                    startIcon={<SaveIcon />}
                                    color="inherit"
                                    onClick={handleClose}
                                >
                                Cancel
                                </Button> 
                            </div>                                                       
                        </DialogActions>                                            
                    </React.Fragment>                     
            </Dialog>
        </React.Fragment>
    );
}
export default LastChanges;

