import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import Loader from "../../common/loader/Loader";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import InputSelect from "../../common/InputSelect"

function CreateAgent (props) {
    const useStyles = makeStyles((theme) => ({        
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },
        center: {
            margin: "0 auto",
            border: "1px grey black",
            textAlign: "center",
            width: "600px",
            height: "250px",
            "& .MuiTextField-root": {
              margin: theme.spacing(1),
              width: 500,
            },
            "& .MuiFormGroup-row": {
            },
            "& .MuiFormControl-root": {
              justifyContent: "center",
            },
            "& .MuiSelect-select": {
                width: 220,
                spacing: theme.spacing(1),
            }
        },        
    }));

    const classes = useStyles();

    const [open, setOpen] = useState(true);
    const [isInserted, setIsInserted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRed, setIsRed] = useState(false);
    const [agent, setAgent] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [currency, setCurrencies] = useState([]);
    const [book, setBooks] = useState([]);
    const [bookID, setBookID] = useState(43);  
    const [currencyID, setCurrencyID] = useState(1);

    var today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    const [userInfo, setUserInfo] = useState(
        JSON.parse(sessionStorage.getItem("userInfo"))
      ); 

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
        setOpen(false);
        props.loadAgents()
    }

    useEffect(() => {
        const url = process.env.REACT_APP_API_URL + '/BOOK?order=Description,asc';
        Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            let bookInfo = data.records.map((v) => {
              v.IdLineType = v.IdBook;
              v.Description = v.Description;
              return v;
            });
            setBooks(bookInfo);
          });
      }, [setBooks]);

      useEffect(() => {
        const url = process.env.REACT_APP_API_URL + '/CURRENCY?order=AgentType,asc';
        Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            let currencyInfo = data.records.map((v) => {
              v.IdLineType = v.IdCurrency;
              v.Description = v.Description;
              return v;
            });
            setCurrencies(currencyInfo);
          });
      }, [setCurrencies]);

    

    function changeBookID(value) {
        setBookID(book[value].IdBook);
    }

    function changeCurrencyID(value) {
        setCurrencyID(currency[value].IdCurrency);
    }

    const handleAgentChange = (event) => {
        setAgent(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const insertAgent = () => {
        let Request = {
            Enable : 0,
            DontXfer : 0,
            IsDistributor : 0,
            IdUser : userInfo.UserId,
            Distributor : null,
            Makeup: 0,
            CommSports : 0,
            CommCasino : 0,
            CommHorses : 0,
            IdAgentType : 1,
            IdCurrency : currencyID,
            IdBook : bookID,
            IdGrouping : null,
            Agent : agent,
            Name : name,
            Password: password,
            City : "",
            State : "",
            Country : "",
            Address1 : "",
            Address2 : "",
            Email : "",
            Phone : "",
            Fax : "",
            Zip : "",
            OnlineAccess : 0,
            OnlineMessage : "",
            IdLineType : null,
            IdAgentPerHead : null,
            IdLanguage : 0,
            OutIdAgent  : 0,
            OutResult  : 0,            
        };
    
        console.log("Request", Request)
        Axios({
          method: "post",
          url: process.env.REACT_APP_DEF_PHP_URL + '/AgentInsert.php',
          data: Request,
        }).then((response) => {      
          alert("The Agent has been succesfully created")
          setName("")
          setPassword("")
          setAgent("")
          setBookID(43)
          setCurrencyID(1)
        }).catch( (e) => {
          console.log('objMultisend error', e.response.data);
        });
      }

    const cloneAgent = () => {
        let Request = {
            Enable : props.agentSelected.Enable,
            DontXfer : props.agentSelected.DontXferPlayerActivity,
            IsDistributor : props.agentSelected.IsDistributor,
            IdUser : userInfo.UserId,
            Distributor : props.agentSelected.Distributor,
            Makeup: 0,
            CommSports : props.agentSelected.CommSports,
            CommCasino : props.agentSelected.CommCasino,
            CommHorses : props.agentSelected.CommHorses,
            IdAgentType : props.agentSelected.IdAgentType,
            IdCurrency : currencyID,
            IdBook : bookID,
            IdGrouping : props.agentSelected.IdGrouping,
            Agent : agent,
            Name : name,
            Password: password,
            City : props.agentSelected.City,
            State : props.agentSelected.State,
            Country : props.agentSelected.Country,
            Address1 : props.agentSelected.Address1,
            Address2 : props.agentSelected.Address2,
            Email : props.agentSelected.Email,
            Phone : props.agentSelected.Phone,
            Fax : props.agentSelected.Fax,
            Zip : props.agentSelected.Zip,
            OnlineAccess : props.agentSelected.OnlineAccess,
            OnlineMessage : props.agentSelected.OnlineMessage,
            IdLineType : props.agentSelected.IdLineType,
            IdAgentPerHead : props.agentSelected.IdAgentPerHead,
            IdLanguage : props.agentSelected.IdLanguage,
            OutIdAgent  : 0,
            OutResult  : 0,        
        };


        Axios({
            method: "post",
            url: process.env.REACT_APP_DEF_PHP_URL + '/AgentInsert.php',
            data: Request,
        }).then((response) => {      
            alert("The Agent has been succesfully cloned")
            props.loadAgents()
            props.onClose()
        })
    }
    

    const handleOk = () => {
        if(agent === "" || name === "" || password === ""){
            setIsRed(true);
            alert("Please provide the required fields");
        }else{
            props.isClone ? cloneAgent() : insertAgent();            
        }
    }

    return(        
        <React.Fragment>
            <Dialog 
                style={{width: "50%", height: "50%", margin: "0 auto", marginTop: "100px"}}
                open={open} 
                onClose={handleClose}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form className={classes.center}>
                    <Divider />
                    <FormGroup row>                                
                        <TextField                                
                        variant="outlined"                            
                        label="Agent"
                        type="text"
                        value={agent}
                        {...isRed && agent===''?(
                            {error:true}
                        ):("")}
                        onChange={handleAgentChange}
                        fullWidth                                                                       
                        />                        
                    </FormGroup>
                    <FormGroup row>                                
                        <TextField                                
                        variant="outlined"                            
                        label="Name"
                        type="text"
                        value={name}
                        {...isRed && name===''?(
                            {error:true}
                        ):("")}
                        onChange={handleNameChange}
                        fullWidth                                                                       
                        />                        
                    </FormGroup>
                    <FormGroup row>                                
                        <TextField                                
                            variant="outlined"                            
                            label="Agent"
                            type="text"
                            value={password}
                            {...isRed && password ===''?(
                                {error:true}
                            ):("")}
                            onChange={handlePasswordChange}
                            fullWidth                                                                       
                        />                        
                    </FormGroup>
                    <FormGroup row>
                        <InputSelect
                            variant="outlined"
                            name="name"
                            data={book}
                            placeholder="Line Type"
                            setSelectValue={changeBookID}
                            startValue={bookID}
                        />

                        <InputSelect
                            variant="outlined"
                            name="name"
                            data={currency}
                            placeholder="Currency"
                            setSelectValue={changeCurrencyID}
                            startValue={currencyID}
                        />            
                    </FormGroup>
                </form>
                <React.Fragment>
                    <DialogActions>
                        {isInserted ? (                                
                            <FormControl className={classes.formControl}>
                                <InputBase
                                style={{
                                    color: "red",
                                    fontSize: 35,
                                    fontWeight: "bold",
                                }}
                                className={classes.margin}
                                defaultValue="Done!"
                                inputProps={{ "aria-label": "naked" }}
                                />
                            </FormControl>                                
                        ) : (
                            ""
                        )}
                        {isLoading ? (
                        <Loader height={100} width={100}></Loader>
                        ) : (
                        <div>                                
                            <Button
                            startIcon={<SaveIcon />}
                            color="inherit"
                            onClick={handleOk}
                            >
                            Ok
                            </Button>
                        </div>
                        )}                            
                    </DialogActions>   
                                            
                </React.Fragment>
            </Dialog>
        </React.Fragment>
    );    
} 
export default CreateAgent;