import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import '../Data.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

function Data(props) {
  const [agentRights, setAgentRights] = useState();
  const [rights, setRights] = useState();
  const [showInfo, setshowInfo] = useState(true);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  

  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    },
  });

  const classes = useStyles();

  

  useEffect(() => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/AgentRights_GetList.php',
    })
      .then((response) => response.data)
      .then((data) => {
        console.log('RIGHTS LIST====>', data.data);
        setAgentRights(data.data);
      });
  }, [setAgentRights]);

  useEffect(() => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/AgentRights_GetRights.php',
      data: {
        idAgent: props.IdAgent,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        let mappedArr = data.data.map((x) => {
          return {
            Id: x['IdRight'],
          };
        });
        let array = [];
        mappedArr.forEach((element) => array.push(element.Id));
        console.log('MAPPED ARRAY =====>', array);
        setRights(array);
      });
  }, []);

  function onRowSelect(row, isSelected, e) {
    let rowStr = '';
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }
    console.log(e);
    alert(`is selected: ${isSelected}, ${rowStr}`);
  }

  const InsertRight = (row) => {
    let rightRequest = {
      IdAgent: props.IdAgent,
      IdForm: row.IdForm,
      IdRight: row.IdRight,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: 'http://192.168.3.22:4587/api/Agent/AgentRights_AddRight',
      data: rightRequest,
    }).then((response) => {
      if (response.data !== '0') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  };

  const DeleteRight = (row) => {
    let rightRequest = {
      IdAgent: props.IdAgent,
      IdForm: row.IdForm,
      IdRight: row.IdRight,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: 'http://192.168.3.22:4587/api/Agent/AgentRights_RemoveRight',
      data: rightRequest,
    }).then((response) => {
      if (response.data !== '0') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  };

  function selectedValues(row) {
    alert(`You click row id: ${row.IdAgent}`);
  }

  const options = {
    page: 2, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 0, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    onRowClick: selectedValues,
  };

  const selectRowProp = {
    mode: 'checkbox',

    //clickToSelect: false, // enable click to select
    selected: rights, // give a array which contain the row key you want to select.
    clickToSelect: true, // click to select, default is false
    onSelect: onRowSelect,
  };

  function onRowSelect(row, isSelected, e) {
    if (isSelected) {
      InsertRight(row);
    } else {
      DeleteRight(row);
    }
  }

  return (
    <Paper className={classes.root}>
      <br />
      <br />
      <Table className={classes.table} aria-label="simple table">
        <TableBody className={showInfo ? 'show-table' : 'hide-table'}>
          {agentRights && (
            <div>
              <BootstrapTable
                options={options}
                selectRow={selectRowProp} //Da problemas con el Expand
                //expandableRow={isExpandableRow}
                //expandComponent={expandComponent}
                //insertRow={true}
                /*expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: expandColumnComponent,
                  columnWidth: 50,
                }}*/
                version="4"
                data={agentRights}
                hover
              >
                <TableHeaderColumn dataField="IdRight" isKey={true} hidden>
                  Right ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  filter={{ type: 'TextFilter', delay: 1000 }}
                  dataField="Description"
                >
                  Right
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default Data;
