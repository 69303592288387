import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import './DepartmentsList.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { useHistory } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function BookList(props) {
  const [departments, setDepartments] = useState();

  const [record, setRecord] = useState();

  const [selectedPitcher, setSelectedPitcher] = useState(props.initialValue);
  const [isLoading, setIsLoading] = useState();

  const handleIdBookChange = (event, newValue) => {
    props.setIdDepartmentOnMem(event.target.value, props.row);

    setSelectedPitcher(newValue.props.value);
  };
  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 230,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    },
  });

  const classes = useStyles();

  useEffect(() => {
    //Change for server api address
    setIsLoading(true);
    //Profile Props',props )
    const url =
      process.env.REACT_APP_API_URL + '/DEPARTMENT?order=IdDepartment';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setDepartments(data.records);

        setIsLoading(false);
      });
  }, []);

  const printInputList = () => {
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Department
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={selectedPitcher}
            onChange={handleIdBookChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            {departments &&
              departments.map((row) => (
                <MenuItem value={row.IdDepartment}>{row.Description}</MenuItem>
              ))}
          </Select>
          <FormHelperText>Departments</FormHelperText>
        </FormControl>
      </div>
    );
  };

  if (props.isInput) {
    return printInputList();
  }
}

export default BookList;
