import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PlayerSettings from './PlayerSettings';
import PlayerNotes from './PlayerNotes';
import PlayerOpenBets from './PlayerOpenBets';
import PlayerHistory from './PlayerHistory';
import PlayerPayment from './PlayerPayment';

import PlayerStatistics from './PlayerStatistics';

import './PlayerTabs.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  const UpdatePlayer =()=>{
  
  }
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function PlayerTabs(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  
  //const user = useContext(UserContext);
  console.log('In Tabs ===>', props);
  return (
    <React.Fragment>
      <br />
      <Tabs value={value} onChange={handleChange} className="tabs-class">
        <Tab label="General" {...a11yProps(0)} />
        <Tab label="Notes" {...a11yProps(1)} />
        <Tab label="Open Bets" {...a11yProps(2)} />
        <Tab label="History" {...a11yProps(3)} />
        <Tab label="Payment" {...a11yProps(3)} />
      </Tabs>
      <TabPanel className="tab-content" value={value} index={0}>
        <PlayerSettings
          Player={props.Player}
          UpdatePlayer={props.Updateplayer}
          setStatisticsToReload={props.setStatisticsToReload}
        ></PlayerSettings>
      </TabPanel>
      <TabPanel className="tab-content" value={value} index={1}>
        <PlayerNotes Player={props.Player}></PlayerNotes>
      </TabPanel>
      <TabPanel className="tab-content" value={value} index={2}>
        <PlayerOpenBets Player={props.Player}></PlayerOpenBets>
      </TabPanel>
      <TabPanel className="tab-content" value={value} index={3}>
        <PlayerHistory Player={props.Player}></PlayerHistory>
      </TabPanel>
      <TabPanel className="tab-content" value={value} index={4}>
        <PlayerPayment setStatisticsToReload={props.setStatisticsToReload} Player={props.Player}></PlayerPayment>
      </TabPanel>
      
    </React.Fragment>
  );
}
