/* eslint max-len: 0 */
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const products = [];

function addProducts(quantity) {
  const startId = products.length;
  for (let i = 0; i < quantity; i++) {
    const id = startId + i;
    if (i < 3) {
      products.push({
        id: id,
        name: 'Item name ' + id,
        price: 2100 + i,
        expand: [
          {
            fieldA: 'test1',
            fieldB: (i + 1) * 99,
            fieldC: (i + 1) * Math.random() * 100,
            fieldD: '123eedd' + i,
          },
          {
            fieldA: 'test2',
            fieldB: i * 99,
            fieldC: i * Math.random() * 100,
            fieldD: '123eedd' + i,
          },
        ],
      });
    } else {
      products.push({
        id: id,
        name: 'Item name ' + id,
        price: 2100 + i,
      });
    }
  }
}
addProducts(5);

class BSTable extends React.Component {
  render() {
    if (this.props.data) {
      console.log('this.props.data', this.props.data);
      return <p>Hello !!</p>;
    } else {
      return <p>?</p>;
    }
  }
}

export default class ExpandRow extends React.Component {
  constructor(props) {
    super(props);
  }

  isExpandableRow(row) {
    console.log('isExpandableRow ', row);
    if (row.id < 3) {
      console.log('isExpandableRow true');
      return true;
    } else {
      console.log('isExpandableRow false');
      return false;
    }
  }

  expandComponent(row) {
    console.log('expandComponent ', row);
    return <BSTable data={row.expand} />;
  }

  render() {
    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
    };
    return (
      <BootstrapTable
        data={products}
        options={options}
        expandableRow={this.isExpandableRow}
        expandComponent={this.expandComponent}
        search
      >
        <TableHeaderColumn dataField="id" isKey={true}>
          Product ID
        </TableHeaderColumn>
        <TableHeaderColumn dataField="name">Product Name</TableHeaderColumn>
        <TableHeaderColumn dataField="price">Product Price</TableHeaderColumn>
      </BootstrapTable>
    );
  }
}
