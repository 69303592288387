import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './ScheduleTbl.css';
import AddBoxIcon from '@material-ui/icons/AddBox';
import axios from 'axios';
import GameModify from '../GameModify';
import GameStatus from '../GameStatus';
import CreateGame from '../CreateGame';
import CreateBanner from '../CreateBanner';
import DeleteGame from '../DeleteGame';
import ChartingInfo from '../ChartingInfo';
import Paper from '@material-ui/core/Paper';

/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function Schedule(props) {
  const classes = useStyles();

  const [selectedIdGame, setselectedIdGame] = useState();
  const [selectedPlay, setselectedPlay] = useState();
  const [selectedIdLeague, setselectedIdLeague] = useState();
  const [isModify, setIsModify] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isCharting, setIsCharting] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [newBanner, setNewBanner] = useState(false);
  const [parentGame, setParentGame] = useState();
  const [homeNumber, setHomeNumber] = useState();
  const [visitorNumber, setVisitorNumber] = useState();
  const [homeTeam, setHomeTeam] = useState();
  const [visitorTeam, setVisitorTeam] = useState();
  const [gameDateTime, setGameDateTime] = useState();
  

  useEffect(() => props.showOverlay(false));

  const handleCloseModify = function () {
    setIsModify(false);
  };
  const handleCloseCharting = function () {
    setIsCharting(false);
  };
  const handleCloseStatus = function () {
    setIsStatus(false);
  };
  const handleCloseCreate = function () {
    setIsNew(false);
  };

  useEffect(() => console.log('Schedule props',props));

  /*DataTable Functions*/
  const optionsBSDT ={

  }
  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 28, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 1, // the pagination bar size.
    prePage: '<', // Previous page button text
    nextPage: '>', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    withFirstAndLast: false, // > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    expandRowBgColor: '#d3e4e0',
  
    // afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    //handleConfirmDeleteRow: customConfirm,
    //onRowClick: selectedValues,
  };
  function rowClassNameFormat(row, rowIdx) {
    // row is whole row object
    // rowIdx is index of row
    
    if (row.GameStat === 'B' ) {
      console.log('isExpandableRow banner');
      return 'schedule-banner';
    } else {
      console.log('isExpandableRow game');
      //return true;
    }

  }
  const cellEditProp = {
    mode: 'click', //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    //afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }
  function expandComponent(row) {
    console.log('expandComponent ', row);
    return <BSTable data={row} />;
  }
  function isExpandableRow(row) {
    console.log('isExpandableRow ', row);
    if (row.GameStat === 'B' ) {
      console.log('isExpandableRow banner');
      return false;
    } else {
      console.log('isExpandableRow game');
      return true;
    }
  }
  class BSTable extends React.Component {
    render() {
      if (this.props.data) {
        console.log('this.props.data', this.props.data);
        return(
<div>
   
<BootstrapTable
  //pagination={true}
  options={optionsBSDT}
  cellEdit={cellEditProp} //DBClick daba problemas con el Expand
  version="4"
  data={[this.props.data]}
  // deleteRow={true}
  hover
>
<TableHeaderColumn
    isKey={true}
    dataField="HomeNumber"
  >
   HomeNumber
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="HomeOdds"   
  >
  HomeOdds
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="HomeSpread"   
  >
  HomeSpread
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="HomeSpreadOdds"   
  >
  HomeSpreadOdds
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="TotalOver"   
  >
  TotalOver
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="OverOdds"   
  >
  OverOdds
  </TableHeaderColumn>
  </BootstrapTable>
  {/* Visitor Table*/}
  <BootstrapTable
  //pagination={true}
  options={optionsBSDT}
  cellEdit={cellEditProp} //DBClick daba problemas con el Expand
  version="4"
  data={[this.props.data]}
  // deleteRow={true}
  hover
>
<TableHeaderColumn
    isKey={true}
    dataField="VisitorNumber"
    className="col-hidden"
  >
  VisitorNumber
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="VisitorOdds"  
    className="col-hidden" 
  >
  VisitorOdds
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="VisitorSpread"   
    className="col-hidden"
  >
 VisitorSpread
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="VisitorSpreadOdds"   
    className="col-hidden"
  >
  VisitorSpreadOdds
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="TotalUnder"   
    className="col-hidden"
  >
  TotalOver
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="UnderOdds" 
    className="col-hidden"  
  >
  OverOdds
  </TableHeaderColumn>
  </BootstrapTable>
</div>

        ) 
      } else {
        return <p>?</p>;
      }
    }
  }
  
  function dateFormatter(cell, row) {
    //return `${('0' + cell.getDate()).slice(-2)}/${('0' + (cell.getMonth() + 1)).slice(-2)}/${cell.getFullYear()}`;
    const date = new Date(cell.date);
    console.log('Date Foramtting cell', date)
    console.log('Date Foramtting row', row)
    var d = new Date(cell.date);
    console.log('Date Foramtting row', row)
    return cell.date

  }
  /* /DataTable Functions */
  

  return (
    <React.Fragment>

      <h1>Test! Localhost</h1>
      {isNew ? (
        <CreateGame
          IdLinetype={props.IdLinetype.value}
          IdSport={props.IdSport.value}
          onClose={handleCloseCreate}
          searchGameValues={props.searchGameValues}
        />
      ) : (
        ''
      )}
      <AddBoxIcon
        aria-label="newGame"
        size="small"
        className={classes.fab}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIsNew(true);
        }}
      />{' '}
      Add Games

{console.log('rendering ',props.data)}
     



      <Table
        className={classes.table}
        component={Paper}
        aria-label="a dense table"
      >
        {newBanner ? (
          <CreateBanner
            IdGame={selectedIdGame}
            IdSport={props.IdSport.value}
            IdLeague={selectedIdLeague}
            ParentGame={parentGame}
            GameDateTime={gameDateTime}
            HomeNumber={homeNumber}
            VisitorNumber={visitorNumber}
            onClose={handleCloseModify}
            searchGameValues={props.searchGameValues}
          />
        ) : (
          ''
        )}
        {isModify ? (
          <GameModify
            IdGame={selectedIdGame}
            IdLinetype={props.IdLinetype.value}
            IdSport={props.IdSport.value}
            onClose={handleCloseModify}
          />
        ) : (
          ''
        )}
        {isStatus ? (
          <GameStatus
            IdGame={selectedIdGame}
            IdLinetype={props.IdLinetype.value}
            onClose={handleCloseStatus}
          />
        ) : (
          ''
        )}

        {isDelete ? (
          <DeleteGame
            IdGame={selectedIdGame}
            searchGameValues={props.searchGameValues}
            onClose={handleCloseStatus}
          />
        ) : (
          ''
        )}

        {isCharting ? (
          <ChartingInfo
            IdGame={selectedIdGame}
            IdLinetype={props.IdLinetype.value}
            IdSport={props.IdSport}
            HomeTeam={homeTeam}
            VisitorTeam={visitorTeam}
            ActionType={0}
            Play={selectedPlay}
            onClose={handleCloseCharting}
          />
        ) : (
          ''
        )}

        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.headerCell}>
              <strong>Actions</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Rotation</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Match</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>ML</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Action</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Sum</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Spread</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Action</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Sum</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Totals</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Action</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Sum</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Special</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        
        </TableBody>
      </Table>
      {props && props.data && console.log('schedule rendering',props.data)}
      {props && props.data && console.log('schedule rendering',props.data[0])}
      {props && props.data && console.log('schedule rendering typeof',typeof props.data)}
      {props && props.data && console.log('schedule rendering typeof 0',typeof props.data[0])}
      {props && props.data && 
  
  <BootstrapTable
  pagination={true}
  options={options}
  cellEdit={cellEditProp} //DBClick daba problemas con el Expand
  trClassName={rowClassNameFormat}
  version="4"
  data={props.data}
  // deleteRow={true}
  expandableRow={isExpandableRow}
  expandComponent={expandComponent}
  hover
>
  <TableHeaderColumn
   
    hidden
    dataField="IdGame"
  >
    IdGame
  </TableHeaderColumn>
  <TableHeaderColumn
     isKey={true}
    dataField="GameDateTime"
    dataFormat={ dateFormatter }
  >
    GameDateTime
  </TableHeaderColumn>
  
  <TableHeaderColumn
  
    filter={{ type: 'TextFilter', delay: 1000 }}
    dataField="LeagueDescription"

    
  >
 LeagueDescription
  </TableHeaderColumn>
  <TableHeaderColumn
  
    filter={{ type: 'TextFilter', delay: 1000 }}
    dataField="VisitorTeam"

    
  >
 Visitor Team
  </TableHeaderColumn>
  <TableHeaderColumn
  
    filter={{ type: 'TextFilter', delay: 1000 }}
    dataField="HomeTeam"
 >
 Home Team
  </TableHeaderColumn>

</BootstrapTable>
} 
    </React.Fragment>
  );
}


