import React, { useState, setState, useEffect, useContext } from 'react';
import { forwardRef, useRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { UserContext } from '../../contexts/UserContext';
import Checkbox from '@material-ui/core/Checkbox';

import KeyboardEventHandler from 'react-keyboard-event-handler';
import LoadingOverlay from 'react-loading-overlay';
/*lineType Components*/
import LineTypeList from './lineTypesComponents/LineTypeList';
import LineTypeSettings from './lineTypesComponents/LineTypeSettings';
import LineTypeInfo from './lineTypesComponents/LineTypeInfo';
import LineTypeTabs from './lineTypesComponents/LineTypeTabs';
import './LineType.css';

const lineType = forwardRef((props) => {
  const user = useContext(UserContext);
  const [lineType, setlineType] = React.useState(0);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      useState,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  let history = useHistory();

  useEffect(() => {
    //Change for server api address
    const url =
      process.env.REACT_APP_API_URL +
      '/LINETYPE?filter=IdLineType,eq,' +
      props.match.params.id;
    const timeout = setTimeout(() => {
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setlineType(data.records[0]);

          console.log('data props', data);
        }, 500);

      return () => clearTimeout(timeout);
    });
  }, []);
  //subscribeToTimer((err, timestamp) => settimestamp(timestamp));

  function UpdatelineType(row) {
    let lineTypeRequest = {
      IdlineType: row.IdlineType,
      lineTypeName: row.lineTypeName,
      Notes: row.Notes,
      AlternatelineType: row.AlternatelineType,
      AlternateEnable: row.AlternateEnable,
      PromotionPoints: row.PromotionPoints,
      SL_IfBets: row.SL_IfBets,
      SL_AlwaysActionMLBTotals: row.SL_AlwaysActionMLBTotals,
      PL_MaxGames: row.PL_MaxGames,
      PL_AllowProps: row.PL_AllowProps,
      PL_AllowGolf: row.PL_AllowGolf,
      PL_AllowMatchUps: row.PL_AllowMatchUps,
      PL_NoListedPitchers: row.PL_NoListedPitchers,
      PL_Juice2TeamParlay: row.PL_Juice2TeamParlay,
      PL_TrueOddsOption: row.PL_TrueOddsOption,
      PL_MaxOdds: row.PL_MaxOdds,
      PL_MaxPayout: row.PL_MaxPayout,
      PL_MaxTeamBuyPoints: row.PL_MaxTeamBuyPoints,
      PL_MaxBuyPoints: row.PL_MaxBuyPoints,
      PL_AlwaysAction: row.PL_AlwaysAction,
      PL_AllowRunLineTotal: row.PL_AllowRunLineTotal,
      PL_AllowSpreadTotalHK: row.PL_AllowSpreadTotalHK,
      PL_AllowSpreadTotalSOC: row.PL_AllowSpreadTotalSOC,
      PL_TieLoses: row.PL_TieLoses,
      PL_ParlayFormula: row.PL_ParlayFormula,
      PL_AllowOpenPlays: row.PL_AllowOpenPlays,
      PL_OddsDefault: row.PL_OddsDefault,
      PL_LowerUseDefault: row.PL_LowerUseDefault,
      PL_MaxDogsSide: row.PL_MaxDogsSide,
      PL_MaxDogsTotal: row.PL_MaxDogsTotal,
      PL_MaxDogsMoney: row.PL_MaxDogsMoney,
      PL_UseMaxSumOdds: row.PL_UseMaxSumOdds,
      PL_MaxSumOdds: row.PL_MaxSumOdds,
      IL_MaxParlays: row.IL_MaxParlays,
      IL_MaxLevelParlays: row.IL_MaxLevelParlays,
      IL_MaxTeasers: row.IL_MaxTeasers,
      IL_MaxLevelTeasers: row.IL_MaxLevelTeasers,
      IL_MaxNumberSB: row.IL_MaxNumberSB,
      IL_AskAmount: row.IL_AskAmount,
      IL_AllowChildHigher: row.IL_AllowChildHigher,
      ILOddslimit: row.ILOddslimit,
      PC_PitcherChangeType: row.PC_PitcherChangeType,
      TL_OpenLose: row.TL_OpenLose,
      TL_TeaserCancel: row.TL_TeaserCancel,
      SG_CancelSide: row.SG_CancelSide,
      SG_CancelTotal: row.SG_CancelTotal,
      SG_CancelRunLine: row.SG_CancelRunLine,
      PC_SkipSide: row.PC_SkipSide,
      PC_SkipTotal: row.PC_SkipTotal,
      PC_SkipRunLine: row.PC_SkipRunLine,
      Soc_Hookups: row.Soc_Hookups,
      Rev_AllowOpen: row.Rev_AllowOpen,
      DuplicateBetsCheckLineChange: row.DuplicateBetsCheckLineChange,
      DuplicateBetsCheckParlays: row.DuplicateBetsCheckParlays,
      DuplicateBetsCheckTeasers: row.DuplicateBetsCheckTeasers,
      DuplicateBetsCheckReverses: row.DuplicateBetsCheckReverses,
      DuplicateBetsCheckIfbets: row.DuplicateBetsCheckIfbets,
      DuplicateBetsCheckIfSBRev: row.DuplicateBetsCheckIfSBRev,
      DuplicateBetsCheckParlaysSB: row.DuplicateBetsCheckParlaysSB,
      DuplicateBetsCheckTeasersSB: row.DuplicateBetsCheckTeasersSB,
      DuplicateBetsCheckSameParlay: row.DuplicateBetsCheckSameParlay,
      DuplicateBetsCheckSameTeaser: row.DuplicateBetsCheckSameTeaser,
      FPAllowBothSides: row.FPAllowBothSides,
      FPAllowDuplicatedBets: row.FPAllowDuplicatedBets,
      FPCheckOfficeFilters: row.FPCheckOfficeFilters,
      FPMaxFav: row.FPMaxFav,
      FPMaxPayout: row.FPMaxPayout,
      FPMaxTeams: row.FPMaxTeams,
      FPOddsLimit: row.FPOddsLimit,
      CLMaxWager: row.CLMaxWager,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/lineType/PlayerlineType_Update',
      //url: 'https://localhost:44337/api/lineType/PlayerlineType_Update',
      data: lineTypeRequest,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  }

  const setCurrentlineType = (lineType) => {
    console.log('in lineType props', props);
    console.log('in lineType props', lineType);
    setlineType(lineType);
    let anothervar = lineType.IdLineType.toString();
    //let anothervar = 'lineType.IdlineType';
    history.push(anothervar);
    console.log('in lineType props after anothervar', anothervar);
  };
  return (
    <React.Fragment>
      {console.log('in lineType props', props)}
      {lineType && (
        <div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="lineType-list" id="lineTypesCell">
                  <div className="lineTypes-left-div">
                    <LineTypeList
                      showLoading={props.showLoading}
                      setLineType={setCurrentlineType}
                    ></LineTypeList>
                  </div>
                </TableCell>
                <TableCell className="lineTypes-right-div cell-top-content">
                  <div className="lineTypes-right-div">
                    <Paper className={classes.root}>
                      <div
                        id="inputs"
                        style={{ display: 'flex', maxHeight: 45 }}
                      >
                        {true ? (
                          <LineTypeInfo
                            LineType={lineType}
                            UpdatelineType={UpdatelineType}
                          ></LineTypeInfo>
                        ) : (
                          <LineTypeInfo
                            LineType={lineType}
                            UpdatelineType={UpdatelineType}
                          ></LineTypeInfo>
                        )}
                      </div>

                      <LineTypeTabs
                        AgentInfo={props.location}
                        LineType={lineType}
                        UpdatelineType={UpdatelineType}
                        showLoading={props.showLoading}
                      ></LineTypeTabs>
                    </Paper>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
});

export default lineType;
