import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from '../../../components/common/loader/Loader';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { gridColumnsTotalWidthSelector } from '@material-ui/data-grid';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@mui/material/Tooltip';
import { Description } from '@material-ui/icons';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputSelect from "../../common/InputSelect";
import { InputAdornment } from '@material-ui/core';

export default function GameStatus(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [idGame, setIdGame] = React.useState(0);
  const [sportType, setSportType] = React.useState(0);
  const [shortGame, setShortGame] = React.useState(0);
  const [gameStatus, setGameStatus] = React.useState('');
  const [contenderStatus, setContenderStatus] = React.useState(0);
  const [system, setSystem] = React.useState(0);
  const [sendToLS, setSendToLS] = React.useState(false);
  const [hideGame, setHideGame] = React.useState(false);
  const [noHookUps, setNoHookUps] = React.useState(false);
  const [boldSpread, setBoldSpread] = React.useState(false);
  const [boldTotal, setBoldTotal] = React.useState(false);
  const [boldML, setBoldML] = React.useState(false);
  const [syncSpread, setSyncSpread] = React.useState(false);
  const [syncTotal, setSyncTotal] = React.useState(false);
  const [syncML, setSyncML] = React.useState(false);
  const [completedGame, setCompletedGame] = React.useState('');
  const [homeNumber, setHomeNumber] = React.useState(0);
  const [homeTeam, setHomeTeam] = React.useState(0);
  const [homeScore, setHomeScore] = React.useState(0);
  const [visitorNumber, setVisitorNumber] = React.useState(0);
  const [visitorTeam, setVisitorTeam] = React.useState(0);
  const [visitorScore, setVisitorScore] = React.useState(0);
  const [idUser, setIdUser] = React.useState(0);
  const [result, setResult] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [selectedValueTime, setSelectedValueTime] = React.useState('None');
  const [gamesTNT, setGamesTNT] = React.useState('None');
  const [selectedIdProfile, setSelectedIdProfile] = useState(props.rotation);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLineTypes,setSelectedLineTypes] = useState([props.IdLinetype]);
  const [selectedValueStatus, setSelectedValueStatus] = React.useState(
    'Visitor'
  );
  const [flag, setFlag] = useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );    
  const [updateSendToLS, setUpdateSendToLS] = useState(false);
  const [sendToLSBulk,setSendToLSBulk] = useState(true);
  const [updateHideGame, setUpdateHideGame] = useState(false);
  const [hideGameBulk, setHideGameBulk] = useState(true);
  const [updateBoldSpread, setUpdateBoldSpread] = useState(false);
  const [boldSpreadBulk, setBoldSpreadBulk] = useState(true);
  const [updateBoldTotal, setUpdateBoldTotal] = useState(false);
  const [boldTotalBulk, setBoldTotalBulk] = useState(true);
  const [updateBoldML, setUpdateBoldML] = useState(false);
  const [boldMLBulk, setBoldMLBulk] = useState(true);
  const [updateSyncSpread, setUpdateSyncSpread] = useState(false);
  const [syncSpreadBulk, setSyncSpreadBulk] = useState(true);
  const [updateSyncTotal, setUpdateSyncTotal] = useState(false);
  const [syncTotalBulk, setSyncTotalBulk] = useState(true);
  const [updateSyncML, setUpdateSyncML] = useState(false);
  const [syncMLBulk, setSyncMLBulk] = useState(true);
  const [gameValues, setGameValues] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const [selectAll,setSelectAll] = useState(false)
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [
    selectedValueAutoChanges,
    setSelectedValueAutoChanges,
  ] = React.useState('Visitor');

  const handleChangeSendToLS = (e) => {
    if(e.nativeEvent.ctrlKey){
      setUpdateSendToLS(!updateSendToLS)
    }
    if(updateSendToLS){
      setSendToLSBulk(!sendToLSBulk)
    }
    setSendToLS(!sendToLS);
  };

  const handleChangeHideGame = (e) => {
    if(e.nativeEvent.ctrlKey){
      setUpdateHideGame(!updateHideGame)
    }
    if(updateHideGame){
      setHideGameBulk(!hideGameBulk)
    }
    setHideGame(!hideGame);
  };

  const handleChangeBoldSpreadChanged = (e) => {
    if(e.nativeEvent.ctrlKey){
      setUpdateBoldSpread(!updateBoldSpread)
    }
    if(updateBoldSpread){
      setBoldSpreadBulk(!boldSpreadBulk)
    }
    setBoldSpread(!boldSpread);
  };

  const handleChangeBoldTotalChanged = (e) => {
    if(e.nativeEvent.ctrlKey){
      setUpdateBoldTotal(!updateBoldTotal)
    }
    if(updateBoldTotal){
      setBoldTotalBulk(!boldTotalBulk)
    }
    setBoldTotal(!boldTotal);
  };
  const handleChangeBoldMLChanges = (e) => {
    if(e.nativeEvent.ctrlKey){
      setUpdateBoldML(!updateBoldML)
    }
    if(updateBoldML){
      setBoldMLBulk(!boldMLBulk)
    }
    setBoldML(!boldML);
  };

  const handleChangeAllowSpreadChanges = (e) => {
    if(e.nativeEvent.ctrlKey){
      setUpdateSyncSpread(!updateSyncSpread)
    }
    if(updateSyncSpread){
      setSyncSpreadBulk(!syncSpreadBulk)
    }
    setSyncSpread(!syncSpread);
  };

  const handleChangeAllowTotalChanges = (e) => {
    if(e.nativeEvent.ctrlKey){
      setUpdateSyncTotal(!updateSyncTotal)
    }
    if(updateSyncTotal){
      setSyncTotalBulk(!syncTotalBulk)
    }
    setSyncTotal(!syncSpread);
  };

  const handleChangeAllowMLChanges = (e) => {
    if(e.nativeEvent.ctrlKey){
      setUpdateSyncML(!updateSyncML)
    }
    if(updateSyncML){
      setSyncMLBulk(!syncMLBulk)
    }
    setSyncML(!syncSpread);
  };
 
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };

  const handleInfo = () => {

  };

  const handleChangeSelectAll = () => {
    const aux = [props.IdLinetype];
    if(selectedLineTypes.length===1){
      props.lineTypes.map(
        (x) => {
          if(x.IdLineType != props.IdLinetype)
            aux.push(x.IdLineType)
        }
      )
    }
    console.log("aux",aux)
    setSelectedLineTypes(aux)
    setSelectAll(!selectAll)
  };

  const handleRemoveLineType = (e) => {
    console.log("e",e)
    let aux = [];
    
    selectedLineTypes.map((x) => {
      aux.push(x)
    }) 
    if(e !== props.IdLinetype){
      for(let i = 0 ; i < selectedLineTypes.length ; i++){
        if(selectedLineTypes[i] === e)
          aux.splice(i,1)
      }
    }else{
      alert("You can't remove the default lineType")
    } 
    setSelectedLineTypes(aux)   
  };

  const getCurrentValue = (lineType,idGame) => {
    return gameValues.filter(gameValue => gameValue.IdLineType === lineType && gameValue.IdGame === idGame)[0]
  }

  const handleOk = async () => {
    setIsLoading(true);
    if(selectedLineTypes.length === 1 && props.IdGame.length === 1){
      for (let i = 0; i < props.IdGame.length; i++) {
        let request = {
          IdLineType: props.IdLinetype,
          IdGame: props.IdGame[i],
          SendToLS: sendToLS,
          HideGame: hideGame,
          SyncSpread: syncSpread,
          SyncTotal: syncTotal,
          SyncML: syncML,
          BoldSpread: boldSpread,
          BoldTotal: boldTotal,
          BoldML: boldML,
          SystemID: 'N',
          IdUser: userInfo.UserId,
        };
        await axios({
          method: 'post',
          url: process.env.REACT_APP_API2_URL + '/Game/GameValues_ChangeSettings',
          data: request,
        }).then((response) => {
          console.log('response', response);
          props.searchGameValues();
          props.clearArray();
          setIsLoading(false);
          props.onClose();
        });
      }
    }else{
      for (let i = 0; i < props.IdGame.length; i++) {
        for(let j = 0; j < selectedLineTypes.length; j++){
          let request= {
            IdLineType: selectedLineTypes[j],
            IdGame: props.IdGame[i],
            SendToLS: updateSendToLS ? sendToLSBulk : getCurrentValue(selectedLineTypes[j],props.IdGame[i]).SendToLS,
            HideGame: updateHideGame ? hideGameBulk : getCurrentValue(selectedLineTypes[j],props.IdGame[i]).HideGame,
            SyncSpread: updateSyncSpread ? syncSpreadBulk : getCurrentValue(selectedLineTypes[j],props.IdGame[i]).SyncSpread,
            SyncTotal: updateSyncTotal ? syncTotalBulk : getCurrentValue(selectedLineTypes[j],props.IdGame[i]).SyncTotal,
            SyncML: updateSyncML ? syncMLBulk : getCurrentValue(selectedLineTypes[j],props.IdGame[i]).SyncML,
            BoldSpread: updateBoldSpread ? boldSpreadBulk : getCurrentValue(selectedLineTypes[j],props.IdGame[i]).BoldSpread,
            BoldTotal: updateBoldTotal ? boldTotalBulk : getCurrentValue(selectedLineTypes[j],props.IdGame[i]).BoldTotal,
            BoldML: updateBoldML ? boldMLBulk : getCurrentValue(selectedLineTypes[j],props.IdGame[i]).BoldML,
            SystemID: 'N',
            IdUser: userInfo.UserId,
          }
          
          await axios({
            method: 'post',
            url: process.env.REACT_APP_API2_URL + '/Game/GameValues_ChangeSettings',
            data: request,
          }).then((response) => {
            console.log('response', response);
            props.searchGameValues();
            props.clearArray();
            setIsLoading(false);
            props.onClose();
          });
        }
      }
    }
    
  };

  const getGamesValues = async () =>{
    let filters = "filter1=IdGame,eq,"
    for(let i = 0 ; i < props.IdGame.length ; i++){
      if(i===0){
        filters = filters.concat(props.IdGame[i])
      }else{
        filters = filters.concat('&filter'+(i+1)+'=IdGame,eq,' + props.IdGame[i])
      }      
    }
    const url =
      process.env.REACT_APP_API_URL +
      '/GAMEVALUES?' + filters
    await axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setGameValues(data.records)
      });
  }

  useEffect(() => {
    const url =
      process.env.REACT_APP_API_URL +
      '/GAMEVALUES?filter=IdLineType,eq,' +
      props.IdLinetype +
      '&filter=IdGame,eq,' +
      props.IdGame[0];

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setSendToLS(data.records[0].SendToLS);
        setHideGame(data.records[0].HideGame);
        setBoldSpread(data.records[0].BoldSpread);
        setBoldTotal(data.records[0].BoldTotal);
        setBoldML(data.records[0].BoldML);
        setSyncSpread(data.records[0].SyncSpread);
        setSyncTotal(data.records[0].SyncTotal);
        setSyncML(data.records[0].SyncML);

        setLoading(false);
      });
    getGamesValues();
  }, []);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeCheckBox = (event) => {
    let lineTypeId = props.lineTypes[event].IdLineType
    let aux = [];
    
    selectedLineTypes.map((x) => {
      aux.push(x)
    })  
    if(selectedLineTypes.includes(lineTypeId)){
      for(let i = 0 ; i < selectedLineTypes.length ; i++){
        if(selectedLineTypes[i] === lineTypeId)
          aux.splice(i,1)
      }
    }else{
      aux.push(lineTypeId)
    }
     
    setSelectedLineTypes(aux)
  }

  const calculateLableColor = (field) => {
    if(field){
      return "blue"
    }else{
      return "lightgrey"
    }
  }

  const calculateCheckBoxColor = (field) => {
    if(field){
      return "primary"
    }else{
      return "default"
    }
  }

  const calculateCheckBoxValue = (field,type) => {
    let returnValue = false
    if(field){
      switch (type){
        case "sendToLS" :
          returnValue = sendToLSBulk
        break
        case "hideGame" :
          returnValue = hideGameBulk
        break
        case "boldSpread" :
          returnValue = boldSpreadBulk
        break
        case "boldTotal" : 
          returnValue = boldTotalBulk
        break
        case "boldML" : 
          returnValue = boldMLBulk
        break
        case "syncSpread" :
          returnValue = syncSpreadBulk
        break
        case "syncTotal" : 
          returnValue = syncTotalBulk
        break
        case "syncML" :
          returnValue = syncMLBulk
        break
      }        
      return returnValue
    }else{
      return true
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Game Settings by Line Type
          </Typography>
          <Tooltip
            title="When Multiple Games and/or Line Types are selected, you must press and hold the ctrl key and click over the values you want to update" 
            placement="right-start"
          >
            <IconButton 
              edge="start"
              color="inherit"
              onClick={handleInfo}
              aria-label="info"
            >
              <InfoIcon/>
            </IconButton>
          </Tooltip>              
        </Toolbar>
      </AppBar>
      {loading ? (
        <CircularProgress style={{ margin: '0 auto' }} />
      ) : (
        <React.Fragment>
          <br />
          <form className={classes.center}>
            <FormGroup row>
              <FormControlLabel                
                control={
                  <Checkbox
                    checked={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? sendToLS : calculateCheckBoxValue(updateSendToLS,"sendToLS")}
                    onChange={handleChangeSendToLS}
                    color={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "primary" : calculateCheckBoxColor(updateSendToLS)}              
                  />
                }
                label="Send Line Changes to line service"
                style={{color:selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "" : calculateLableColor(updateSendToLS)}}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? hideGame : calculateCheckBoxValue(updateHideGame,"hideGame")}
                    onChange={handleChangeHideGame}
                    color={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "primary" : calculateCheckBoxColor(updateHideGame)}
                  />
                }
                label="Hide Game in this line type "
                style={{color:selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "" : calculateLableColor(updateHideGame)}}
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? boldSpread : calculateCheckBoxValue(updateBoldSpread, "boldSpread")}
                    onChange={handleChangeBoldSpreadChanged}
                    color={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "primary" : calculateCheckBoxColor(updateBoldSpread)}
                  />
                }
                label="Bold Spread"
                style={{color:selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "" : calculateLableColor(updateBoldSpread)}}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? boldTotal : calculateCheckBoxValue(updateBoldTotal,"boldTotal")}
                    onChange={handleChangeBoldTotalChanged}
                    color={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "primary" : calculateCheckBoxColor(updateBoldTotal)}
                  />
                }
                label="Bold Total"
                style={{color:selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "" : calculateLableColor(updateBoldTotal)}}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? boldML : calculateCheckBoxValue(updateBoldML,"boldML")}
                    onChange={handleChangeBoldMLChanges}
                    color={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "primary" : calculateCheckBoxColor(updateBoldML)}
                  />
                }
                label="Bold Money Line"                
                style={{color:selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "" : calculateLableColor(updateBoldML)}}
              />
            </FormGroup>            
            <Divider />
            <FormGroup style={{display:"flow-root"}} row>
              <Typography variant="h6" className={classes.title}>
                INCOMING Line Changes (from other line types)  
              </Typography>
              <br></br>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? syncSpread : calculateCheckBoxValue(updateSyncSpread,"syncSpread")}
                    onChange={handleChangeAllowSpreadChanges}
                    color={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "primary" : calculateCheckBoxColor(updateSyncSpread)}
                  />
                }
                label="Allow Point-Spread Changes"
                style={{color:selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "" : calculateLableColor(updateSyncSpread)}}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? syncTotal : calculateCheckBoxValue(updateSyncTotal,"syncTotal")}
                    onChange={handleChangeAllowTotalChanges}
                    color={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "primary" : calculateCheckBoxColor(updateSyncTotal)}
                  />
                }
                label="Allow Total Changes"
                style={{color:selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "" : calculateLableColor(updateSyncTotal)}}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? syncML : calculateCheckBoxValue(updateSyncML,"syncML")}
                    onChange={handleChangeAllowMLChanges}
                    color={selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "primary" : calculateCheckBoxColor(updateSyncML)}
                  />
                }
                label="Allow Money Line Changes"
                style={{color:selectedLineTypes.length === 1 && props.IdGame.length === 1 ? "" : calculateLableColor(updateSyncML)}}
              />
            </FormGroup>
            <Divider></Divider>
            <Typography variant="h6" className={classes.title}>
                Update Multiple Line Types
              </Typography>
            <FormGroup style={{border:"1px solid grey",borderRadius:"6px", margin:"5px"}}>    
              <div style={{display:"flow-root"}}>        
                <InputSelect                             
                  variant="outlined"
                  name="name"
                  data={props.lineTypes}
                  placeholder="LineTypes"
                  setSelectValue={handleChangeCheckBox}  
                  idLineType={props.lineTypeId}                         
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={handleChangeSelectAll}
                      color="primary"
                    />
                  }
                  label="Select All lineTypes"
                />
              </div>
              <div style={{display:"flow-root"}}>
              {
                selectedLineTypes.map(
                  (x) =>{
                    return(
                      <TextField
                        value={props.lineTypes.filter(y => y.IdLineType === x)[0].Description}
                        disabled
                        variant='outlined'
                        InputProps={{
                          startAdornment: 
                          <InputAdornment
                            position="end"
                          >
                            <IconButton
                              edge="start"
                              color="inherit"
                              onClick={() => {handleRemoveLineType(x)}}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>,
                        }}
                      >                        
                      </TextField>
                    )                    
                  }
                )
              }
              </div>
            </FormGroup>
            
              {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Update Multiple Line Types
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>                  
                  {props.lineTypes.map((x) =>{
                    return(
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedLineTypes.includes(x.IdLineType) ? true : false}
                            disabled={x.IdLineType === selectedLineTypes[0] ? true : false}
                            onChange={handleChangeCheckBox}
                            color="primary"
                            id={x.IdLineType}
                          />
                        }
                        label={x.Description}
                      />
                    )                  
                  })}
                </AccordionDetails>
              </Accordion> */}
            <FormGroup row></FormGroup>
          </form>
        </React.Fragment>
      )}
      <DialogActions>
        {isLoading ? (
          <Loader height={100} width={100}></Loader>
        ) : (
          <div>
            <Button
              color="inherit"
              startIcon={<CancelIcon />}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button startIcon={<SaveIcon />} color="inherit" onClick={handleOk}>
              Ok
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
