import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as moment from 'moment';
import InputSelect from '../../common/InputSelect';
/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './ProfileStraight.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FinalStatsByAgent(props) {
  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  const classes = useStyles();
  const [negatives, setNegatives] = React.useState([]);
  const [positives, setPositives] = React.useState([]);
  const [gameTypeID, setGameTypeID] = useState(1);
  const [gameType, setGameType] = useState();
  const [lineTypeID, setLineTypeID] = useState(1);
  const [lineType, setLineType] = useState();
  const [sportID, setSportID] = useState('CBB');
  const [sport, setSport] = useState();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [playerType, setPlayerType] = useState(0);
  const [line, setLine] = useState(1);
  const [type, setType] = useState(0);
  const [range, setRange] = useState(0);
  const [juiceRebate, setJuiceRebate] = useState(0);

  const [groupByAgent, setGroupByAgent] = useState(false);
  // let IdPeriod = '0';

  const handleShow = () => {};

  useEffect(() => {
    console.log('PROPS AGENT INFO=====>', props);
    //Change for server api address
    const url = process.env.REACT_APP_API_URL + '/LINETYPE';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let bookInfo = data.records.map((v) => {
          v.IdLineType = v.IdLineType;
          v.Description = v.Description;
          return v;
        });
        setLineType(bookInfo);
      });
  }, [setLineType]);

  useEffect(() => {
    //Change for server api address
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/SPORT';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let officeInfo = data.records.map((v) => {
          v.IdLineType = v.IdSport;
          v.Description = v.IdSport;
          return v;
        });
        setSport(officeInfo);
        setIsLoading(false);
      });
  }, [setSport]);

  useEffect(() => {
    //Change for server api address
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/GAMETYPE?order=Description';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let officeInfo = data.records.map((v) => {
          v.IdLineType = v.IdGameType;
          v.Description = v.Description;
          return v;
        });
        setGameType(officeInfo);
        setIsLoading(false);
      });
  }, [setGameType]);

  useEffect(() => {
    PositiveValues(lineTypeID, gameTypeID, sportID, line, type);
    NegativeValues(lineTypeID, gameTypeID, sportID, line, type);
  }, []);

  const PositiveValues = (
    lineTypeOnMem,
    gameTypeOnMem,
    sportOnMem,
    lineOnMem,
    typeOnMem
  ) => {
    setIsLoading(true);
    let request = {
      IdLineType: lineTypeOnMem,
      IdGameType: gameTypeOnMem,
      IdSport: sportOnMem,
      Line: lineOnMem,
      Online: typeOnMem,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL + '/Profile/JuiceRebate_GetListPositive',
      data: request,
    }).then((response) => {
      console.log('response', response.data);
      setPositives(response.data);
      setIsLoading(false);
    });
  };

  const NegativeValues = (
    lineTypeOnMem,
    gameTypeOnMem,
    sportOnMem,
    lineOnMem,
    typeOnMem
  ) => {
    setIsLoading(true);
    let request = {
      IdLineType: lineTypeOnMem,
      IdGameType: gameTypeOnMem,
      IdSport: sportOnMem,
      Line: lineOnMem,
      Online: typeOnMem,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL + '/Profile/JuiceRebate_GetListNegative',
      data: request,
    }).then((response) => {
      console.log('response', response.data);
      setNegatives(response.data);
      setIsLoading(false);
    });
  };
  // const [selectedIdGame, setselectedIdGame] = useState();

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
  };

  function onAfterDeleteRow(rowKeys) {
    let request = {
      IdLineType: lineTypeID,
      IdGameType: gameTypeID,
      IdSport: sportID,
      Line: line,
      Online: type,
      IdUser: userInfo.UserId,
      Range: range,
      JuiceRebate: juiceRebate,
    };
    axios({
      method: 'post',
      //url: process.env.REACT_APP_API2_URL + '/Profile/JuiceRebate_Delete',
      url: 'https://localhost:44337/api/Profile/JuiceRebate_Delete',
      data: request,
    }).then((response) => {
      if (response.data === 0) {
        PositiveValues(lineTypeID, gameTypeID, sportID, line, type);
        NegativeValues(lineTypeID, gameTypeID, sportID, line, type);
        alert('Data Successfully Deleted');
      }
    });
    //alert('The rowkey you drop: ' + rowKeys);
  }

  const handleTypeChange = (event) => {
    setType(event.target.value);
    PositiveValues(lineTypeID, gameTypeID, sportID, line, event.target.value);
    NegativeValues(lineTypeID, gameTypeID, sportID, line, event.target.value);
  };
  const handleLineChange = (event) => {
    setLine(event.target.value);
    PositiveValues(lineTypeID, gameTypeID, sportID, event.target.value, type);
    NegativeValues(lineTypeID, gameTypeID, sportID, event.target.value, type);
  };

  const onBeforeSaveCell = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){
      done(true);
    }else{
      done(false);
    }  
    return 1
  } 

  const cellEditProp = {
    mode: 'click', //DBClick daba problemas con el Expand se cambio a click
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
    beforeSaveCell: onBeforeSaveCell,
  };

  function onAfterSaveCell(row, cellName, cellValue) {
    let request = {
      IdLineType: lineTypeID,
      IdGameType: gameTypeID,
      IdSport: sportID,
      Line: line,
      Online: type,
      IdUser: userInfo.UserId,
      Range: row.Range,
      JuiceRebate: row.JuiceRebate,
    };
    axios({
      method: 'post',
      //url: process.env.REACT_APP_API2_URL + '/Profile/JuiceRebate_Delete',
      url: 'https://localhost:44337/api/Profile/JuiceRebate_Update',
      data: request,
    }).then((response) => {
      if (response.data === 0) {
        //PositiveValues(lineTypeID, gameTypeID, sportID, line, type);
        //NegativeValues(lineTypeID, gameTypeID, sportID, line, type);
        alert('Data Successfully Updated');
      }
    });
    //alert('The rowkey you drop: ' + rowKeys);
  }

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/

  function changeSportID(value) {
    setSportID(value);
    PositiveValues(lineTypeID, gameTypeID, value, line, type);
    NegativeValues(lineTypeID, gameTypeID, value, line, type);
  }

  function changeLineTypeID(value) {
    setLineTypeID(value);
    PositiveValues(value, gameTypeID, sportID, line, type);
    NegativeValues({ value }, gameTypeID, sportID, line, type);
  }
  function changeGameTypeID(value) {
    setGameTypeID(value);
    PositiveValues(lineTypeID, value, sportID, line, type);
    NegativeValues(lineTypeID, value, sportID, line, type);
  }

  function rowClassNameFormat(row, rowIdx) {
    // row is whole row object
    // rowIdx is index of row
    return rowIdx % 2 === 0
      ? 'tr-function-example'
      : 'td-column-function-odd-example';
  }
  function onRowSelect(row, isSelected, e) {
    setJuiceRebate(row.JuiceRebate);
    setRange(row.Range);
  }

  const selectRowPropPositive = {
    mode: 'checkbox',

    clickToSelect: true,
    onSelect: onRowSelect,
  };
  const selectRowPropNegative = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: onRowSelect,
  };
  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <div>
          <form className={classes.container} noValidate>
            <Table>
              <TableRow>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={lineType}
                    placeholder="Line Type"
                    setSelectValue={changeLineTypeID}
                    startValue={lineTypeID}
                  />
                </TableCell>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={gameType}
                    placeholder="Game Type"
                    setSelectValue={changeGameTypeID}
                    startValue={gameTypeID}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={sport}
                    placeholder="Sports"
                    setSelectValue={changeSportID}
                    startValue={sportID}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <h6>Type</h6>
                  <RadioGroup row>
                    <FormControlLabel
                      // value="None"
                      control={
                        <Radio
                          checked={type == '0'}
                          onChange={handleTypeChange}
                          value="0"
                          label="Local"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'None' }}
                          //disabled
                        />
                      }
                      label="Local"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={type == '1'}
                          onChange={handleTypeChange}
                          value="1"
                          label="Online"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Online"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <h6>Line</h6>
                  <RadioGroup row>
                    <FormControlLabel
                      // value="None"
                      control={
                        <Radio
                          checked={line == '1'}
                          onChange={handleLineChange}
                          value="1"
                          label="Spread"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'None' }}
                          //disabled
                        />
                      }
                      label="Spread"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={line == '2'}
                          onChange={handleLineChange}
                          value="2"
                          label="Total"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Total"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={line == '3'}
                          onChange={handleLineChange}
                          value="3"
                          label="MoneyLine"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Money Line"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
            </Table>
          </form>
          <Table>
            <TableRow>
              <TableCell>
                <BootstrapTable
                  data={positives}
                  cellEdit={cellEditProp}
                  deleteRow={true}
                  options={options}
                  insertRow={true}
                  selectRow={selectRowPropPositive}
                  trClassName={rowClassNameFormat}
                >
                  <TableHeaderColumn hidden dataField="IdLineType" isKey>
                    Line Type
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="IdGameType">
                    Game Type
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="IdSport">
                    Sport
                  </TableHeaderColumn>
                  <TableHeaderColumn editable={false} dataField="Range">
                    Range
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="JuiceRebate">
                    Juice Rebate
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="Line">
                    Line
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="Online">
                    Online
                  </TableHeaderColumn>
                </BootstrapTable>
              </TableCell>

              <TableCell>
                <BootstrapTable
                  data={negatives}
                  cellEdit={cellEditProp}
                  deleteRow={true}
                  options={options}
                  selectRow={selectRowPropNegative}
                  trClassName={rowClassNameFormat}
                >
                  <TableHeaderColumn isKey={true} hidden dataField="IdLineType">
                    Line Type
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="IdGameType">
                    Game Type
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="IdSport">
                    Sport
                  </TableHeaderColumn>
                  <TableHeaderColumn editable={false} dataField="Range">
                    Range
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="JuiceRebate">
                    Juice Rebate
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="Line">
                    Line
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="Online">
                    Online
                  </TableHeaderColumn>
                </BootstrapTable>
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </div>
    </React.Fragment>
  );
}
