import React, { useState, setState, useEffect, useContext } from 'react';
import { forwardRef, useRef, useImperativeHandle } from 'react';

import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import ScheduleTbl from '../components/common/ScheduleTbl';
import { UserContext } from '../contexts/UserContext';
import Checkbox from '@material-ui/core/Checkbox';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';

import KeyboardEventHandler from 'react-keyboard-event-handler';
import LoadingOverlay from 'react-loading-overlay';
import Loader from '../components/common/loader/Loader';
/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from '../components/common/InputSelect';
import { TextField } from '@material-ui/core';

const LManager = forwardRef((props, ref) => {
  const [games, setGames] = useState();
  const [gamesRaw, setGamesRaw] = useState([]);
  const [IdSport, setIdSport] = useState(0);
  const [SportChange, setSportChange] = useState(true);
  const [lineTypeID, setlineTypeID] = useState(0);
  const [lineTypes, setLineTypes] = useState();
  const [sports, setSports] = useState();
  const [isActive, setisActive] = useState(false);
  const [timestamp, settimestamp] = useState();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recentUpdatedGame, setRecentUpdatedGame] = useState('');
  let lastChangeId = 0;
  const setEventKey = function (key) {
    if (key === 'f4') {
      changeSportID('NFL');
    }
    if (key === 'f6') {
      changeSportID('CFB');
    }
    if (key === 'f7') {
      changeSportID('NBA');
    }
    if (key === 'f8') {
      changeSportID('CBB');
    }
    if (key === 'f9') {
      changeSportID('MLB');
    }
    if (key === 'f10') {
      changeSportID('NHL');
    }
    if (key === 'f11') {
      changeSportID('MU');
    }
    if (key === 'ctrl+f9') {
      changeSportID('ESOC');
    }
    if (key === 'ctrl+f10') {
      changeSportID('SOC');
    }
    if (key === 'ctrl+f11') {
      changeSportID('TNT');
    }
    if (key === 'ctrl+f12') {
      changeSportID('PROP');
    }
  };

  const user = useContext(UserContext);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      useState,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  useImperativeHandle(ref, () => ({
    searchGameValuesChild(gameInfo) {
      if (lastChangeId !== gameInfo.idGame) {
        searchGameValues(gameInfo.idGame, gameInfo.idLinetype);
        lastChangeId = gameInfo.idGame;
      }
    },
  }));

  const url =
    process.env.REACT_APP_API_URL + '/PLAYERPROFILE?order=ProfileName';

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 28, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 1, // the pagination bar size.
    prePage: '<', // Previous page button text
    nextPage: '>', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    withFirstAndLast: false, // > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    // afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    //handleConfirmDeleteRow: customConfirm,
    //onRowClick: selectedValues,
  };

  const cellEditProp = {
    mode: 'click', //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    //afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  const selectRowProp = {
    mode: 'checkbox',

    //clickToSelect: false, // enable click to select

    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    //onSelect: onRowSelect,
  };

  function onRowSelect(row, isSelected, e) {
    let rowStr = '';
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }
    console.log(e);
    alert(`is selected: ${isSelected}, ${rowStr}`);
  }

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }

  useEffect(() => {
    //Change for server api address
    // exec Games_GetSchedule 'NFL',8,-1,2,'A',1,498

    const url = process.env.REACT_APP_API2_URL + '/Game/Games_GetSchedule';
    let scheduleRequest = {
      IdSport: 'NFL',
      IdLineType: 8,
      IdLeague: -1,
      OrderType: 2,
      DisplayPeriod: 'A',
      MoveCloseGames: 1,
      IdUser: 498,
    };
    axios({
      method: 'post',
      url: url,
      data: scheduleRequest,
    }).then((response) => {
      console.log('response', response.data);
      setGames(response.data);
    });

    /*
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
    
        setGames(data.records);
      });
*/
  }, []);

  const searchGameValues = (idGame, idLinetype) => {
    //console.log('searching values',isLoading);
    setIsLoading(true);
    //console.log('searching values',isLoading);
    setisActive(true);
    console.log('searching values', idGame);
    //console.log('searching recentUpdatedGame',recentUpdatedGame);
    if (
      idGame &&
      idLinetype &&
      idGame !== recentUpdatedGame &&
      lineTypeID == !idLinetype
    ) {
      console.log('searching values', idGame);
      console.log('searching recentUpdatedGame', recentUpdatedGame);
      axios({
        method: 'post',
        url: process.env.REACT_APP_DEF_PHP_URL + '/GamesGameData.php',
        /*  REPLACE WITH SELECT DATA */
        data: {
          idgame: idGame,
          idlinetype: idLinetype,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          let mappedArr = data.data.map((x) => {
            return {
              VisitorOdds: x[38],
              HomeOdds: x[39],
              TotalOver: x[40],
              OverOdds: x[41],
              TotalUnder: x[42],
              UnderOdds: x[43],
              VisitorSpread: x[44],
              VisitorSpreadOdds: x[45],
              HomeSpread: x[46],
              HomeSpreadOdds: x[47],
              VisitorSpecial: x[48],
              VisitorSpecialOdds: x[49],
              HomeSpecial: x[50],
              HomeSpecialOdds: x[51],
              IdGameType: x[5],
              GameStat: x[11],
              Description: x[36],
              ParentGame: x[21],
              GameDateTime: x[7],
              Printable: x[62],
            };

            ////ACA mappedArr = mappedArr.Filter (filtro)
          });

          if (games) {
            let thisGame = games.filter((g) => g.IdGame == idGame)[0];
            if (thisGame) {
              thisGame.VisitorOdds = mappedArr[0].VisitorOdds;
              thisGame.HomeOdds = mappedArr[0].HomeOdds;
              thisGame.TotalOver = mappedArr[0].TotalOver;
              thisGame.OverOdds = mappedArr[0].OverOdds;
              thisGame.TotalUnder = mappedArr[0].TotalUnder;
              thisGame.UnderOdds = mappedArr[0].UnderOdds;
              thisGame.VisitorSpread = mappedArr[0].VisitorSpread;
              thisGame.VisitorSpreadOdds = mappedArr[0].VisitorSpreadOdds;
              thisGame.HomeSpread = mappedArr[0].HomeSpread;
              thisGame.HomeSpreadOdds = mappedArr[0].HomeSpreadOdds;
              thisGame.VisitorSpecial = mappedArr[0].VisitorSpecial;
              thisGame.VisitorSpecialOdds = mappedArr[0].VisitorSpecialOdds;
              thisGame.HomeSpecial = mappedArr[0].HomeSpecial;
              thisGame.HomeSpecialOdds = mappedArr[0].HomeSpecialOdds;
            }

            // let gamesCop = games.filter(g => g.IdGame != idGame);
            let gamesCop = games;
            //gamesCop.push(thisGame);
            //console.log('updatign game ', gamesCop);
            Promise.resolve().then(() => {
              setGames(gamesCop);
              setIsLoading(false);
            });
          }
        });
    } else {
      console.log('searching values else', idGame);
      console.log('searching values else', recentUpdatedGame);
      console.log('searching values else', lineTypeID.value);
      console.log('searching values else', idLinetype);
      //  if(idGame !== recentUpdatedGame && lineTypeID ==! idLinetype){
      if (1 >> 0) {
        console.log('searching values else inside if');

        axios({
          method: 'post',
          url: process.env.REACT_APP_DEF_PHP_URL + '/getGames.php',
          /*  REPLACE WITH SELECT DATA */
          data: { idsport: IdSport, idlinetype: lineTypeID['value'] },
        })
          .then((response) => response.data)
          .then((data) => {
            //console.log('DATA GAMES VALUES ======>', data);
            let mappedArr = data.data.map((x) => {
              return {
                IdGame: x[0],
                VisitorTeam: x[1],
                HomeTeam: x[2],
                IdLeague: x[4],
                IdGameType: x[5],
                VisitorNumber: x[9],
                HomeNumber: x[10],
                GameStat: x[11],
                VisitorScore: x[24],
                HomeScore: x[25],
                VisitorOdds: x[40],
                HomeOdds: x[41],
                TotalOver: x[42],
                OverOdds: x[43],
                TotalUnder: x[44],
                UnderOdds: x[45],
                VisitorSpread: x[46],
                VisitorSpreadOdds: x[47],
                HomeSpread: x[48],
                HomeSpreadOdds: x[49],
                VisitorSpecial: x[50],
                VisitorSpecialOdds: x[51],
                HomeSpecial: x[52],
                HomeSpecialOdds: x[53],
                GameStat: x[11],
                Description: x[36],
                ParentGame: x[21],
                GameDateTime: x[7],
                Printable: x[62],
                LeagueDescription: x[63],
                SpreadVisitorCount: x[64],
                SpreadHomeCount: x[65],
                TotalVisitorCount: x[66],
                TotalHomeCount: x[67],
                MLVisitorCount: x[68],
                MLHomeCount: x[69],
                SpreadVisitorAction: x[70],
                SpreadHomeAction: x[71],
                TotalVisitorAction: x[72],
                TotalHomeAction: x[73],
                MLVisitorAction: x[74],
                MLHomeAction: x[75],
              };
            });
            if (open == true) {
              mappedArr = mappedArr.filter((g) => g.GameStat == 'O');
            }
            //console.log('MAPPED ARRAY=====>', mappedArr);
            setGames(mappedArr);
            setIsLoading(false);
            //console.log('searching values',isLoading);
          });
      }
    }
    //
  };
  const setLastChangedGamedID = (idgame) => {
    console.log('setLastChangedGamedID searching values', idgame);
    setRecentUpdatedGame(idgame);
  };
  useEffect(() => {
    //Change for server api address
    const url = process.env.REACT_APP_API_URL + '/LINETYPE';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setLineTypes(data.records);
      });
  }, [setLineTypes]);

  useEffect(() => {
    //Change for server api address
    const url = process.env.REACT_APP_API_URL + '/SPORT';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let groupingInfo = data.records.map((v) => {
          v.IdSport = v.IdSport;
          v.SportName = v.SportName;
          return v;
        });
        setSports(groupingInfo);
      });
  }, []);

  useEffect(() => {
    //IdSport && lineTypeID && setEventKey();
    IdSport && lineTypeID && searchGameValues();
  }, [IdSport]);

  useEffect(() => {
    IdSport && searchGameValues();
  }, [setSports]);

  function getGameValues() {
    searchGameValues();
  }
  function changeSportID(value) {
    setIdSport(value);
    setSportChange(false);
    setSportChange(true);
  }

  function changeLineTypeID(value) {
    setlineTypeID({ value });
    IdSport && lineTypeID && searchGameValues();
  }

  function showOverlay(value) {
    value && setisActive(true);
    !value && setisActive(false);
  }

  function runChange() {
    props.announce();
  }

  //subscribeToTimer((err, timestamp) => settimestamp(timestamp));

  return (
    <Paper className={classes.root}>
      {/*<Fab
        color="primary"
        aria-label="add"
        size="small"
        className={classes.fab}
      >
        <AddIcon onClick={() => runChange()} />
      </Fab>
      Current Time: {timestamp}*/}

      <KeyboardEventHandler
        handleKeys={['function', 'ctrl+f9', 'ctrl+f10', 'ctrl+f11', 'ctrl+f12']}
        onKeyEvent={(key) => setEventKey(key)}
      />

      <Table>
        <TableRow>
          <TableCell>
            {SportChange === true ? (
              <div style={{ float: 'left' }}>
                <InputSelect
                  variant="outlined"
                  name="name"
                  data={sports}
                  placeholder="Sports"
                  setSelectValue={changeSportID}
                  startValue={IdSport}
                />
              </div>
            ) : (
              ''
            )}{' '}
            <div style={{ float: 'left' }}>
              <InputSelect
                variant="outlined"
                name="name"
                data={lineTypes}
                placeholder="LineTypes"
                setSelectValue={changeLineTypeID}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={searchGameValues}
            >
              Search
            </Button>
            <label>
              <Checkbox
                checked={open}
                onChange={(ev) => setOpen(ev.currentTarget.checked)}
                color="primary"
              />
              Open Games Only
            </label>
          </TableCell>

          <TableCell>
            {isLoading ? <Loader height={80} width={80}></Loader> : ''}
          </TableCell>
        </TableRow>
      </Table>

      <TextField>Filter</TextField>
      {games && console.log('response games', games)}
      <ScheduleTbl
        IdLinetype={lineTypeID}
        IdSport={IdSport}
        data={games}
        showOverlay={showOverlay}
        announce={props.announce}
        searchGameValues={searchGameValues}
        setLastChangedGamedID={setLastChangedGamedID}
      ></ScheduleTbl>
    </Paper>
  );
});

export default LManager;
