import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import './PitcherList.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import TextField from "@mui/material/TextField";
import Paper from "@material-ui/core/Paper";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditIcon from '@material-ui/icons/Edit';
import { DataGrid } from '@material-ui/data-grid'
import CreatePitcher from './CreatePitcher';
import Loader from "../../components/common/loader/Loader";
import { FormControl, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { InputAdornment } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function BookList(props) {
  const [pitchers, setPitchers] = useState([]);

  const [record, setRecord] = useState();


  const [selectedPitcher, setSelectedPitcher] = useState(props.initialValue);
  const [isLoading, setIsLoading] = useState();
  const [isNew, setIsNew] = useState(false);  
  const [row, setRow] = useState([]);
  const [isModify, setIsModify] = useState(false);
  const [pitcherSelected, setPitcherSelected] = useState([]);
  const [search, setSearch] = useState('');

  const handleRowSelection = (row) => {
    setPitcherSelected(row);
  };

  const handleChange = (event, values) => {   
    console.log('values', values);
    if(values!==null && values!=="" && values!== undefined){                
      props.setPitcherOnMem(values);  
      setSelectedPitcher(values);        
    }else{
      props.setPitcherOnMem("");
      setSelectedPitcher("");
    } 
  };
  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 230,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    }    
  });

  const classes = useStyles();
  
  const columns = [
    { field: 'id', headerName: 'Pitcher ID', width: 100, editable: false, sortable: false, filterable: false },
    { field: 'pitcherName', headerName: 'Pitcher Name', width: 250, editable: false, sortable: false, filterable: false}        
  ]
  
  const builtRows = (pitchers) => {
    let row = [];
    pitchers.forEach(pitcher => {
      row.push({
        id: pitcher.IdPitcher,
        pitcherName: pitcher.PitcherName,
        teamName: pitcher.TeamName
      });
    });
    setRow(row);
  }

  const getPitchers = () => {
    const url = process.env.REACT_APP_API_URL + '/PITCHER?order=IdBook';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {        
        setPitchers(data.records);
        setRecord(data.records[0]);
        search==='' ? builtRows(data.records) : builtRows(data.records.filter(pitcher => pitcher.PitcherName.toLowerCase().includes(search.toLowerCase())));
      });
  };  

  const handleCloseCreate = function () {
    setIsNew(false);  
    setIsModify(false);  
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  }

  useEffect(() => {    
    setIsLoading(true);
    getPitchers();
    setIsLoading(false);
  }, [isNew, isModify,search]);  

  useEffect(() => {
    setLoader();
  }, [pitchers]);

  useEffect(() => {
    console.log("props", props);
  }, []);

  const setLoader = () => {
    if(pitchers.length === 0) {
      setIsLoading(true);
    }else{
      setIsLoading(false);
    }      
  }

  useEffect(() => {
    console.log("selectedPitcher",selectedPitcher)
  }, [selectedPitcher]);

  return (
    <React.Fragment>
      {isLoading && props.table ? (                  
            <Loader height={100} width={100}></Loader>
          ):("")}
      {props.table?(                        
        <Paper className={classes.root}>
          <br />
          <br /> <h2>Pitcher Management</h2>
          <div style={{marginBottom:"10px"}}>
            <label style={{padding:"8px",fontSize:"150%"}}>
              <AddBoxIcon                 
                aria-label="newGame"
                size="small"
                className={classes.fab}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsNew(true);                      
                }}
              />{" "}
              Add New
            </label>  
            <label style={{padding:"8px",fontSize:"150%"}}>
              <EditIcon                 
                aria-label="editGame"
                size="small"
                className={classes.fab}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if(pitcherSelected.length > 0){
                    setIsModify(true);
                  }else{
                    alert("Please select a row to edit")
                  }                                 
                }}
              />{" "}
              Edit
            </label>  
            <FormControl>
              <TextField
                id="outlined-basic" 
                label="Search" 
                variant="outlined" 
                style={{height:"60%",width:"300px",marginLeft:"150px"}}
                onChange={handleSearch}
                value={search}
                InputProps={{
                  endAdornment: 
                  search !== '' ? (
                    (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSearch('')}
                        >
                          <CloseIcon/>
                        </IconButton>
                      </InputAdornment>
                    )
                  ) : ('')
                }}
              >            
              </TextField>
            </FormControl>
          </div>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
            rows={row}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[5]}            
            disableColumnSelector
            disableColumnMenu
            onSelectionModelChange={handleRowSelection}                                    
            disableMultipleSelection={true}                                         
            />
          </div>          
        </Paper>
      ):(
        <div>
          <Autocomplete
              color="secondary"
              onChange={handleChange}
              freeSolo
              autoSelect
              disablePortal
              fullWidth
              id="combo-box-demo"
              options={pitchers && pitchers.map((option) => option.PitcherName)}  
              sx={{ width: 400 }}                      
              renderInput={(params) => 
                <TextField {...params}   
                  helperText={props.doesPitcherExist ? "the input Pitcher will be created" : ""}             
                  label= "Select Pitcher"    
                  style={{ width: "200px" }}    
                  variant="outlined"                
                  color={props.doesPitcherExist ? 'success' : 'primary'}
                  focused={props.doesPitcherExist}                                                   
                />
              }
              value={selectedPitcher?selectedPitcher.toUpperCase():selectedPitcher}
            /> 
        </div>         
      )}
      {isNew || isModify?(        
          <CreatePitcher
            onClose={handleCloseCreate}
            isModify={isModify}
            title={isModify?"Edit Pitcher":"New Pitcher"}
            pitcherSelected={pitchers.filter(x => x.IdPitcher == pitcherSelected)}
          />          
      ):(
        "" 
      )}      
    </React.Fragment>      
  ); 
} 

export default BookList;

