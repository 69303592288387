import React, { useState, setState, useEffect, useContext } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@material-ui/core/FormControl";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

/**
 * Displays a list of leagues in a data table or Dropdown, depending on the
 * prop.showDataTable
 */
export default function Leagues(props) {
  //const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: "0 auto",
      border: "1px grey black",
      textAlign: "center",
      width: "75%",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 200,
      },
      "& .MuiFormGroup-row": {
        justifyContent: "center",
      },
      "& .MuiFormControl-root": {
        justifyContent: "center",
      },
    },
  }));
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );
  const [Leagues, setLeagues] = React.useState([]);
  const [sports, setSports] = React.useState([]);
  const [optionsAutoComplete, setOptionsAutoComplete] = React.useState([]);

  const [selectedLeague, setSelectedLeague] = React.useState([1]);
  useEffect(() => {
    // Change for server api address
    GetLeagues();
    GetSports();  
  }, []); 

  const GetLeagues = () => {
    let filterNecessaryFields = "";
    if (props.showDataTable) {
      filterNecessaryFields = "&include=Description,IdLeague";
    }
    let url = process.env.REACT_APP_API_URL + "/LEAGUE?order=Description";

    if (props.filterBy) {
      url =
        url +
        "?OrderBy=IdLeague&filter=IdSport,eq," +
        props.filterBy +
        filterNecessaryFields;
    }
    axios({
      method: "get",
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      let tmpOptionsAutoComplete = [];
           
      const obj = response.data.records.reduce((o, key) => {        
        tmpOptionsAutoComplete.push({
          label: key.Description,
          id: key.IdLeague,
        });
        return { label: key.Description, id: key.IdLeague };
      },undefined)      
      setOptionsAutoComplete(tmpOptionsAutoComplete);
      setLeagues(response.data.records);
    });
  };

  const GetSports = () => {    
    let url = process.env.REACT_APP_API_URL + "/SPORT?order=SportName";
    url = url;
    axios({
      method: "get",
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      let sportsInfo = response.data.records.map((v) => {
        v.value = v.IdSport;
        v.text = v.SportName;
        return v;
      });
      setSports(sportsInfo);
    });
  };

  /* se quito el ,[] que se puede para no hacer tantos renders*/
  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "15",
        value: 15,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "500",
        value: 500,
      },
      {
        text: "1000",
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 16, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: "top", // default is bottom, top and both is all available
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    handleConfirmDeleteRow: customConfirm,
    //onRowClick: selectedValues,
    afterInsertRow: onAfterInsertRow, // A hook for after insert rows
  };

  function selectedValues(row) {
    // setRedirect(row);
  }
  const cellEditProp = {
    mode: "click",
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  function onAfterInsertRow(row) {
    let RequestDTO = {
      IdSport: row.IdSport,
      IdLeagueRegion: 1,
      Description: row.Description,
      ShortDescription: row.ShortDescription,
      LeagueOrder: row.LeagueOrder,
      IdUser: userInfo.UserId,
    };
    axios({
      method: "post",
      url: process.env.REACT_APP_API2_URL + "/League/League_Insert",
      data: RequestDTO,
    }).then((response) => {
      if (response.data === "0") {
        alert("Data Successfully Inserted");
      }
      console.log("response", response);
    });
    GetLeagues();
  }
  function onAfterSaveCell(row) {
    let RequestDTO = {
      IdSport: row.IdSport,
      IdLeagueRegion: 1,
      Description: row.Description,
      ShortDescription: row.ShortDescription,
      LeagueOrder: row.LeagueOrder,
      IdUser: userInfo.UserId,
    };
    axios({
      method: "post",
      url:
        process.env.REACT_APP_API2_URL +
        "/League/League_Update?idLeague=" +
        row.IdLeague,
      data: RequestDTO,
    }).then((response) => {
      if (response.data === "0") {
        alert("Data Successfully Updated");
      }
      console.log("response", response);
    });
    GetLeagues();
  }

  function onAfterDeleteRow(rowKeys) {
    axios({
      method: "post",
      url:
        process.env.REACT_APP_API2_URL +
        "/League/League_Delete?idLeague=" +
        rowKeys +
        "&idUser=" +
        userInfo.UserId,
    }).then((response) => {
      console.log("response", response);
    });
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(",");
    if (
      window.confirm(
        `(It's a custom confirm)Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      next();
    }
  }

  const handleChange = (event) => {
    props.setLeagueOnMem(event.target.value);
    setSelectedLeague(event.target.value);
  };
  const onTagsChange = (event, values) => {
    values && values.id && props.setLeagueOnMem(values.id);
  };

  const selectRowProp = {
    mode: "checkbox",
  };  

  if (props.showDataTable) {
    return (
      <div>
        <BootstrapTable
          data={Leagues.sort((a, b) => {
            return a.LeagueOrder - b.LeagueOrder;
          })}
          cellEdit={cellEditProp}
          options={options}
          selectRow={selectRowProp}
          pagination={true}
          insertRow={true}
          version="4"
          deleteRow={true}
          hover    
        >
          <TableHeaderColumn
            visible="none"
            isKey={true}
            hidden
            hiddenOnInsert
            autoValue={true}
            dataField="IdLeague"
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            filter={{ type: "TextFilter" , defaultValue:"CBB"}}
            editable={{
              type: "select",
              readOnly: true,
              options: { values: sports },
            }}
            dataField="IdSport"
          >
            Id Sport
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="Description"
            filter={{ type: "TextFilter" }}
          >
            Description
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="ShortDescription"
            filter={{ type: "TextFilter" }}
          >
            Short Description
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField="LeagueOrder"
          >
            League Order
          </TableHeaderColumn>
        </BootstrapTable>        
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <FormControl className={classes.formControl}>
          {console.log("in League RENDERING SELeCT")}
          {Leagues[0] && (
            <>              
              <Autocomplete
                onChange={onTagsChange}
                disablePortal
                fullWidth
                id="combo-box-demo"
                options={optionsAutoComplete}               
                sx={{ width: 400 }}                
                renderInput={(params) => (
                  <TextField {...params} label="Leagues" />
                )}
                {...props.isModify?{value: Leagues.find((League) => League.IdLeague === props.league).Description}:null}                
              />
            </>
          )}
        </FormControl>
      </React.Fragment>
    );
  }
}
