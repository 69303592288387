import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

export default function Periods(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [periods, setPeriods] = React.useState([]);
  const [selectedPeriod, setSelectedPeriod] = React.useState([0]);
  useEffect(() => {
    // Change for server api address
    console.log('PROPS period ======>', props);
    let url = process.env.REACT_APP_API_URL + '/PERIOD';

    if (props.filterBy) {
      console.log('PROPS period INSIDE IF ======>', props);
      url = url + '?filter=IdSport,eq,' + props.filterBy;
    }
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log('PROPS======PERIOD REST THEN>', response.data.records);
      setPeriods(response.data.records);
    });
  }, [props]);
  /* se quito el ,[] que se puede para no hacer tantos renders*/
  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 16, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    //afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    //handleConfirmDeleteRow: customConfirm,
    onRowClick: selectedValues,
  };

  function selectedValues(row) {
    console.log(`You click row id: ${row.IdProfile}`);

    // setRedirect(row);
  }
  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
  };

  /*Select Functions*/

  const handleChange = (event) => {
    props.setPeriodOnMem(event.target.value);
    setSelectedPeriod(event.target.value);
    console.log('in Period  event.target', event.target.value);
  };
  /*End Select Functions*/
  console.log('in Periods props', props);
  console.log('in Periods typeof', typeof periods);
  console.log('in Periods props match', periods);

  if (props.showDataTable) {
    return (
      <React.Fragment>

        <br />

        <BootstrapTable
          data={periods}
          cellEdit={cellEditProp}
          options={options}
          pagination={true}
          version="4"
          hover
        >
          <TableHeaderColumn
            visible="none"
            isKey={true}
            hidden
            dataField="IdPeriod"
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="NumberOfPeriod">
            NumberOfPeriod
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="PeriodDescription"
            filter={{ type: 'TextFilter', delay: 1000 }}
          >
            PeriodDescription
          </TableHeaderColumn>
        </BootstrapTable>

        <select>
          {periods.map((period) => (
            <option
              key={period.NumberOfPeriod}
              value={period.PeriodDescription}
            >
              {period.IdSport + '-' + period.PeriodDescription}
            </option>
          ))}
        </select>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Period
          </InputLabel>
          {console.log('in Period RENDERING SELeCT')}
          {periods[0] && (
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={selectedPeriod}
              onChange={handleChange}
              displayEmpty
              className={classes.selectEmpty}
            >
              {periods.map((period) => (
                <MenuItem
                  /*key={period.NumberOfPeriod}*/ value={period.NumberOfPeriod}
                >
                  {period.IdSport + '-' + period.PeriodDescription}
                </MenuItem>
              ))}
            </Select>
          )}

          <FormHelperText>Select period</FormHelperText>
        </FormControl>
      </React.Fragment>
    );
  }
}
