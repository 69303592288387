import React, { useState, useEffect, Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import differenceBy from "lodash/differenceBy";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import "./agents/Data.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { useHistory } from "react-router-dom";
import { NavigateNextTwoTone } from "@material-ui/icons";

export default function Data(props) {
  const [renderShowsTotal, setrenderShowsTotal] = useState(false);
  const [showEditPlayerBtn, setShowEditPlayerBtn] = useState(false);
  const [tmpPlayer, setTmpPlayer] = useState();
  const history = useHistory();
  const [schema, setSchema] = useState();
  const [record, setRecord] = useState();
  const [showInfo, setshowInfo] = useState(true);
  const [value, setValue] = React.useState(0);
  const [players, setPlayers] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let temparray = [];

  const useStyles = makeStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: "#3f51b5",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "darkcyan !important",
      },
    },

    tableRow: {
      "&:hover": {
        backgroundColor: "lightgrey !important",
      },
    },
    headerCell: {
      color: "white",
    },
  });

  const classes = useStyles();

  function onAfterDeleteRow(rowKeys) {
    
    alert("The rowkey you drop: " + rowKeys);
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(",");
    if (
      window.confirm(
        `(It's a custom confirm)Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      // If the confirmation is true, call the function that
      // continues the deletion of the record.
      next();
    }
  }

  useEffect(() => {
    //Change for server api address
    axios({
      method: "post",
      url: process.env.REACT_APP_DEF_PHP_URL + "/Agent_GetPlayersList.php",
      /*  REPLACE WITH SELECT DATA */

      data: {
        idAgent: props.IdAgent,
        decimals: 0,
        trunc: 1,
        idCurrency: props.IdCurrency,
      },
    }).then((response) => {
      console.log("response.data.data", response.data);
      setPlayers([response.data.data]);
    });
  }, []);

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "500",
        value: 500,
      },
      {
        text: "1000",
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 35, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    //paginationPosition: 'top', // default is bottom, top and both is all available
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    handleConfirmDeleteRow: customConfirm,
  };

  const cellEditProp = {
    mode: "click", //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  function dateFormatter(cell) {
    if (cell) {
      var msec = Date.parse(cell.date);
      var d = new Date(msec);
      return ` <p>  ${d.toLocaleDateString()}</p>`;
    }
  }
  function actionFormatter(cell) {
    console.log("formatting", cell);
    return ` <a href="/player/${cell}" >Manage</a>  `;
  }
  function numberFormatter(cell) {
    console.log("formatting", cell);
    let tempBalance = parseInt(cell);
    if (isNaN(tempBalance)) {
      return ` <span>0</span>  `;
    } else {
      return ` <span>${tempBalance}</span>  `;
    }
  }

  const selectRowProp = {
    mode: "checkbox",

    //clickToSelect: false, // enable click to select

    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    onSelect: onRowSelect,
  };

  function NavigateTo(IdPlayer) {
    let path = "/player/" + IdPlayer;
    console.log("editing", IdPlayer);
    console.log("editing path", path);
    history.push(path);
  }

  function onRowSelect(row) {
    setShowEditPlayerBtn(true);
    setTmpPlayer(row.IdPlayer);
    console.log("row", row);
    //alert('selected',row)
  }
  function goToEdit(IdPlayer) {
    console.log("goToEdit");
    //NavigateTo(IdPlayer);
  }

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }
  function onAfterSaveCell(row, cellName, cellValue) {
    /*let AgentRequestDTO = {
          IdAgent: row.IdAgent,
          Enable: row.Enable,
          DontXferPlayerActivity: row.DontXferPlayerActivity,
          IsDistributor: row.IsDistributor,
          Distributor: row.Distributor,
          CommSports: row.CommSports,
          CommCasino: row.CommCasino,
          CommHorses: row.CommHorses,
          IdAgentType: row.IdAgentType,
          IdCurrency: row.IdCurrency,
          IdBook: row.IdBook,
          IdGrouping: row.IdGrouping,
          Agent1: row.Agent,
          Name: row.Name,
          Password: row.Password,
          City: row.City,
          State: row.State,
          Country: row.Country,
          Address1: row.Address1,
          Address2: row.Address2,
          Email: row.Email,
          Phone: row.Phone,
          Fax: row.Fax,
          Zip: row.Zip,
          OnlineAccess: row.OnlineAccess,
          OnlineMessage: row.OnlineMessage,
          IdLineType: row.IdLineType,
          IdAgentPerHead: row.IdAgentPerHead,
          IdLanguage: row.IdLanguage,
          LastModificationUser: userInfo.UserId,
        };
        axios({
          method: 'post',
          url:  process.env.REACT_APP_API2_URL + '/Agent/Agent_Update',
          data: AgentRequestDTO,
        }).then((response) => {
          if (response.data === '-1') {
            alert('Data Successfully Updated');
          }
          console.log('response', response);
        });*/
  }
  const toggleTable = () => {
    if (showInfo) {
      setshowInfo(false);
    } else {
      setshowInfo(true);
    }
  };
  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody className={showInfo ? "show-table" : "hide-table"}>
          {players && (
            <div>
              <BootstrapTable
                pagination={true}
                options={options}
                insertRow={true}
                // selectRow={selectRowProp} //Da problemas con el Expand
                version="4"
                data={players[0]}
                hover
              >
                <TableHeaderColumn
                  dataField="IdPlayer"
                  isKey={true}
                  dataFormat={actionFormatter}
                >
                  &nbsp;
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="Player"
                  filter={{ type: "TextFilter", delay: 1000 }}
                >
                  Player
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Name">Name</TableHeaderColumn>

                <TableHeaderColumn
                  dataField="CreditLimit"
                  dataFormat={numberFormatter}
                >
                  CreditLimit
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="CurrentBalance"
                  dataFormat={numberFormatter}
                >
                  Balance
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="LifeTimeNet"
                  dataFormat={numberFormatter}
                >
                  Life Time Net
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="LastWager"
                  dataFormat={dateFormatter}
                >
                  LastWager
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Status">Status</TableHeaderColumn>
                <TableHeaderColumn dataField="EnableSports">
                  Sports
                </TableHeaderColumn>
                <TableHeaderColumn dataField="EnableCasino">
                  Casino
                </TableHeaderColumn>
                <TableHeaderColumn dataField="EnableHorses">
                  Horses
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}
