
import axios from 'axios';



  export const updateMLxp = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay,
    valueHasChanged,
    setRecentUpdatedGame,
    setValueHasChanged,
    props,
  ) => {
    if (valueHasChanged) {
      let line = idSport != 'MLB' ? 3 : 1;
      let flag = true;
      let z = newValue < 0 ? newValue * -1 : newValue;
      setRecentUpdatedGame(idGame);
      props.setLastChangedGamedID(idGame);
      const url =
        process.env.REACT_APP_API_URL +
        '/ODDSBYSPORT?filter=IdLineType,eq,' +
        idLinetype +
        '&filter=IdSport,eq,' +
        idSport +
        '&filter=IdGameType,eq,' +
        IdGameType +
        '&filter=Line,eq,' +
        line +
        '&filter=Range,ge,' +
        Math.round(z);

      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          //setoddBySport(data.records.odds);
          let moneyLineObject = {
            idGame: idGame,
            odds: newValue,
            idLinetype: idLinetype,
            idSport: idSport,
            newValue: newValue,
            play: play,
            teamNumber: teamNumber,
            column: column,
            changeLine: changeLine,
            changeOdds: changeOdds,
          };
          let storedOdd = -moneyLineObject.odds;

          if (newValue != '') {
            let oddToLower = 0;
            let found = false;
            data.records.forEach((item, index) => {
              let valCompare = parseInt(moneyLineObject.odds);
              valCompare = valCompare < 0 ? -valCompare : valCompare;
              if (valCompare < item.Range && !found) {
                if (index == 0) {
                  oddToLower = data.records[index].OddsValue;
                } else {
                  oddToLower = data.records[index - 1].OddsValue;
                }
                found = true;
              }
            });
            oddToLower = oddToLower === 0 ? 20 : oddToLower;
            if (
              storedOdd === 100 ||
              storedOdd === -100 ||
              oddToLower / 2 + 100 === storedOdd
            ) {
              oddToLower = 0;
            } else if (storedOdd > 0 && storedOdd - oddToLower < 100) {
              oddToLower = 100 + (100 + oddToLower - storedOdd) - storedOdd;
              flag = false;
            }
            if (storedOdd === 100 || storedOdd === -100) {
              storedOdd = null;
            } else if (oddToLower === 0 || !flag) {
              storedOdd = storedOdd + oddToLower;
              storedOdd = storedOdd * -1;
            } else {
              storedOdd += -oddToLower;
            }
          } else {
            storedOdd = null;
            newValue = null;
            odds = null;
            moneyLineObject.odds = null;
            moneyLineObject.newValue = null;
          }

          axios({
            method: 'post',
            url: process.env.REACT_APP_DEF_PHP_URL + '/updateMoneyline.php',
            data: moneyLineObject,
          }).then((response) => {
            axios({
              method: 'post',
              url: process.env.REACT_APP_DEF_PHP_URL + '/updateMoneyline.php',
              data: {
                idGame: idGame,
                odds: storedOdd,
                idLinetype: idLinetype,
                idSport: idSport,
                newValue: newValue,
                play: otherPlay,
                teamNumber: otherTeam,
                column: otherColumn,
                changeLine: changeLine,
                changeOdds: changeOdds,
              },
            }).then((response) => {
              //props.announce(idGame, idLinetype);
              const url =
                process.env.REACT_APP_API_URL +
                '/LINETYPELINKS?filter=IdLineTypeIn,eq,' +
                idLinetype +
                '&filter=IdSport,eq,' +
                idSport +
                '&filter=MoneyLineSync,eq,' +
                1;
              axios
                .get(url)
                .then((response) => response.data)
                .then((data) => {
                  if (data.records !== null && data.records.length > 0) {
                    data.records.forEach(async (x) => {
                      let idLT = x.IdLineTypeOut;
                      await axios({
                        method: 'post',
                        url:
                          process.env.REACT_APP_DEF_PHP_URL +
                          '/updateMoneyline.php',
                        data: {
                          idGame: idGame,
                          odds: newValue,
                          idLinetype: idLT,
                          idSport: idSport,
                          newValue: newValue,
                          play: play,
                          teamNumber: teamNumber,
                          column: column,
                          changeLine: changeLine,
                          changeOdds: changeOdds,
                        },
                      }).then((response) => {
                        axios({
                          method: 'post',
                          url:
                            process.env.REACT_APP_DEF_PHP_URL +
                            '/updateMoneyline.php',
                          data: {
                            idGame: idGame,
                            odds: storedOdd,
                            idLinetype: idLT,
                            idSport: idSport,
                            newValue: newValue,
                            play: otherPlay,
                            teamNumber: otherTeam,
                            column: otherColumn,
                            changeLine: changeLine,
                            changeOdds: changeOdds,
                          },
                        }).then((response) => {});
                      });
                    });
                  }
                });

                props.searchGameValues();
            });
          });
        });
    }
    setValueHasChanged(true);
  };
