import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import EventsPerLeagues from '../events/eventsPerLeague';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputSelect from '../../components/common/InputSelect';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function Events(props) {
  //const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const [leagueID, setLeagueID] = React.useState(
    props.eventsLeaguesInfo.IdLeague
  );
  const [typeID, setTypeID] = React.useState(
    props.eventsLeaguesInfo.IdGameType
  );
  const [gameTypes, setGametypes] = React.useState();
  const [leagues, setLeagues] = React.useState([]);
  const [eventDescription, setEventDescription] = React.useState(
    props.eventsLeaguesInfo.Description
  );
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [eventGameDescription, setEventGameDescription] = React.useState(
    props.eventsLeaguesInfo.GameDescription
  );
  const [includePitcher, setIncludePitcher] = React.useState(
    props.eventsLeaguesInfo.IncludePitchers
  );

  useEffect(() => {
    // Change for server api address

    GetGameType();
    GetLeagues();
  }, []);

  const GetGameType = () => {
    const url = process.env.REACT_APP_API2_URL + '/GameType/GameType_Get';
    axios.get(url).then((response) => {
      let gameTypeInfo = response.data.map((v) => {
        v.IdLineType = v.IdGameType;
        v.Description = v.Description;
        return v;
      });
      setGametypes(gameTypeInfo);
    });
  };
  const GetLeagues = () => {
    let url = process.env.REACT_APP_API2_URL + '/League/League_GetAllLeagues';
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      let LeagueInfo = response.data.map((v) => {
        v.IdLineType = v.IdLeague;
        v.Description = v.Description;
        return v;
      });
      setLeagues(LeagueInfo);
    });
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };
  const handleChangeDescription = (event) => {
    setEventDescription(event.target.value);
  };
  const handleChangeGameDescription = (event) => {
    setEventDescription(event.target.value);
  };
  const handleChangeIncludePitcher = () => {
    setIncludePitcher(!includePitcher);
  };
  function changeLeagueID(value) {
    console.log('changeLeagueID', value);
    setLeagueID({ value });
  }

  function changeTypeID(value) {
    console.log('changeLeagueID', value);
    setTypeID({ value });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Game Modify
          </Typography>
        </Toolbar>
      </AppBar>
      <React.Fragment>
        <div>
          <br />
          <Table>
            <TableRow>
              <TableCell>
                {props.eventsLeaguesInfo && (
                  <Table>
                    <TableRow>
                      <TableCell>
                        <FormGroup row>
                          <FormControl className={classes.formControl}>
                            <TextField
                              type="text"
                              label="Description"
                              value={eventDescription}
                              multiline={true}
                              onChange={handleChangeDescription}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <InputSelect
                              variant="outlined"
                              name="name"
                              data={leagues}
                              placeholder="Target League"
                              setSelectValue={changeLeagueID}
                              startValue={leagueID}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <InputSelect
                              variant="outlined"
                              name="name"
                              data={gameTypes}
                              placeholder="GameType"
                              setSelectValue={changeTypeID}
                              startValue={typeID}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <TextField
                              type="text"
                              label="Game Description"
                              value={eventGameDescription}
                              multiline={true}
                              onChange={handleChangeGameDescription}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={includePitcher}
                                onChange={handleChangeIncludePitcher}
                                color="primary"
                              />
                            }
                            label="Copy original pitchers to new event"
                          />
                        </FormGroup>
                      </TableCell>
                    </TableRow>
                  </Table>
                )}
              </TableCell>
              <TableCell>
                <EventsPerLeagues />
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </React.Fragment>
    </Dialog>
  );
}

export default Events;
