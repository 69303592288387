import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import Loader from "../../common/loader/Loader";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import InputSelect from "../../common/InputSelect";
import Checkbox from "@material-ui/core/Checkbox";

function CreateUser (props) {
    const useStyles = makeStyles((theme) => ({        
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },
        center: {
            margin: "0 auto",
            border: "1px grey black",
            textAlign: "center",
            width: "600px",
            height: "250px",
            "& .MuiTextField-root": {
              margin: theme.spacing(1),
              width: 500,
            },
            "& .MuiFormGroup-row": {
            },
            "& .MuiFormControl-root": {
              justifyContent: "center",
            },
            "& .MuiSelect-select": {
                width: 220,
                spacing: theme.spacing(1),
            }
        },        
    }));

    const classes = useStyles();

    const [open, setOpen] = useState(true);
    const [isInserted, setIsInserted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRed, setIsRed] = useState(false);
    const [loginName, setLoginName] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [languages, setLanguage] = useState([]);
    const [languageId, setLanguageId] = useState(0)
    const [idUserProfile, setIdUserProfile] = useState(0);
    const [departmentId, setDepartmentId] = useState(0);
    const [profiles, setProfiles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [enabled, setEnabled] = useState(false)
    const [logons, setLogons] = useState({})

    var today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    const [userInfo, setUserInfo] = useState(
        JSON.parse(sessionStorage.getItem("userInfo"))
      ); 

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
        setOpen(false);
        props.loadUser()
    }

    useEffect(() => {
        getDepartments()
        getLanguages()
        getUserProfiles()
        getLogons()
    }, []);

    
    const getDepartments =  () => {
        setIsLoading(true);
        const url =
            process.env.REACT_APP_API_URL + '/DEPARTMENT?order=IdDepartment';

        Axios
            .get(url)
            .then((response) => response.data)
            .then((data) => {
            setDepartments(data.records);

            setIsLoading(false);
            });
    }

    const getLogons =  () => {
        const url = process.env.REACT_APP_DEF_PHP_URL + '/GetUserLogons.php'
        Axios({
            method: 'post',
            url: url,
            data: {
                idUser : userInfo.UserId
              },
        }).then((response) => {         
            setLogons(response.data.data[0]);
        });
    }

    const getUserProfiles = () => {
        setIsLoading(true);
        const url =
          process.env.REACT_APP_API_URL + '/USERPROFILE?order=IdUserProfile';
    
        Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setProfiles(data.records);    
            setIsLoading(false);
          });
    }

    const getLanguages = () => {
        const url = process.env.REACT_APP_API_URL + '/Language';
        Axios
            .get(url)
            .then((response) => response.data)
            .then((data) => {
            let languageInfo = data.records.map((v) => {
                v.IdLanguage = v.IdLanguage;
                v.Description = v.LangDesc;
                return v;
            });
            setLanguage(languageInfo);
            });
    }

    const handleEnabledChanged = (value) => {
        setEnabled(value);
    };
        
    function changelanguageId(value) {
        setLanguageId(languages[value].IdLanguage);
    }

    function changeIdUserProfile(value) {
        setIdUserProfile(profiles[value].IdUserProfile);
    }

    function changeDepartmentId(value) {
        setDepartmentId(departments[value].IdDepartment);
    }

    const handleLoginNameChange = (event) => {
        setLoginName(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const insertUser = () => {
        //console.log("languages.find(language => language.IdLanguage = languages[languageId].IdLanguage).IdLanguage",languages.find(language => language.IdLanguage = languages[languageId].IdLanguage).IdLanguage)
        //console.log("languages.find(language => language.IdLanguage = languages[languageId].IdLanguage)", languages.find(language => language.IdLanguage = languages[languageId].IdLanguage).IdLanguage)
        console.log("languages.find(language => language.IdLanguage = languages[languageId].IdLanguage)",languages.find(language => language.IdLanguage = languages[languageId]))
        console.log("languages",languages)
        let Request = {
            logonSessionID : logons.LogonSessionID,
            idUserProfile : profiles.find(profile => profile.IdUserProfile = profiles[idUserProfile].IdUserProfile).IdUserProfile,
            idDepartment : departments.find(department => department.IdDepartment = departments[departmentId].IdDepartment).IdDepartment, 
            loginName : loginName,
            name : name,
            password : password,
            status : enabled ? 1 : 0,
            idLanguage : languageId == 0 ? 0 : languages.find(language => language.IdLanguage = languages[languageId].IdLanguage).IdLanguage,
            idUser : userInfo.UserId,
                       
        };
    
        console.log("Request", Request)
        Axios({
          method: "post",
          url: process.env.REACT_APP_DEF_PHP_URL + '/UserInsert.php',
          data: Request,
        }).then((response) => {      
          alert("The User has been succesfully created")
          setName("")
          setPassword("")
          setLoginName("")
          setLanguageId(0)
          setDepartmentId(0)
          setIdUserProfile(0)
        }).catch( (e) => {
          console.log('objMultisend error', e.response.data);
        });
      }

    

    const handleOk = () => {
        if(loginName === "" || name === "" || password === ""){
            setIsRed(true);
            alert("Please provide the required fields");
        }else{
            insertUser();            
        }
    }

    return(        
        <React.Fragment>
            <Dialog 
                //style={{width: "100%", height: "100%", margin: "0 auto", marginTop: "10px"}}
                open={open} 
                onClose={handleClose}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form className={classes.center}>
                    <Divider />
                    <FormGroup row
                        style={{display:'flex', flexFlow:'row'}}
                    >                                
                        <TextField                                
                        variant="outlined"                            
                        label="Login Name"
                        type="text"
                        value={loginName}
                        {...isRed && loginName===''?(
                            {error:true}
                        ):("")}
                        onChange={handleLoginNameChange}
                        fullWidth                                                                       
                        />   
                        <InputSelect
                            variant="outlined"
                            name="name"
                            data={profiles}
                            placeholder="Profile"
                            setSelectValue={changeIdUserProfile}
                            startValue={idUserProfile}
                        />                     
                    </FormGroup>
                    <FormGroup row
                        style={{display:'flex', flexFlow:'row'}}
                    >           
                        <TextField                                
                            variant="outlined"                            
                            label="password"
                            type="text"
                            value={password}
                            {...isRed && password ===''?(
                                {error:true}
                            ):("")}
                            onChange={handlePasswordChange}
                            fullWidth                                                                       
                        />                           
                               
                        <InputSelect    
                            variant="outlined"
                            name="name"
                            data={departments}
                            placeholder="Department"
                            setSelectValue={changeDepartmentId}
                            startValue={departmentId}
                        />                 
                    </FormGroup>
                    <FormGroup row
                        style={{display:'flex', flexFlow:'row'}}
                    >                                 
                        <TextField                                
                        variant="outlined"                            
                        label="Name"
                        type="text"
                        value={name}
                        {...isRed && name===''?(
                            {error:true}
                        ):("")}
                        onChange={handleNameChange}
                        fullWidth                                                                       
                        />    
                        <InputSelect    
                            variant="outlined"
                            name="name"
                            data={languages}
                            placeholder="Language"
                            setSelectValue={changelanguageId}
                            startValue={languageId}
                        />                 
                    </FormGroup>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                checked={enabled}
                                onChange={(ev) =>
                                    handleEnabledChanged(ev.currentTarget.checked)
                                }
                                color="primary"
                                />
                            }
                            label="Enabled"
                        />
                    </FormGroup>  
                        
                    
                </form>
                <React.Fragment>
                <DialogActions>
                    {isInserted ? (                                
                        <FormControl className={classes.formControl}>
                            <InputBase
                            style={{
                                color: "red",
                                fontSize: 35,
                                fontWeight: "bold",
                            }}
                            className={classes.margin}
                            defaultValue="Done!"
                            inputProps={{ "aria-label": "naked" }}
                            />
                        </FormControl>                                
                    ) : (
                        ""
                    )}
                    {isLoading ? (
                    <Loader height={100} width={100}></Loader>
                    ) : (
                    <div>                                
                        <Button
                        startIcon={<SaveIcon />}
                        color="inherit"
                        onClick={handleOk}
                        >
                        Ok
                        </Button>
                    </div>
                    )}                            
                </DialogActions>
                </React.Fragment>        
            </Dialog>
            
        </React.Fragment>
    );    
} 
export default CreateUser;