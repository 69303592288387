import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import '../Data.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

export default function GameModify(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));

  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [agentHistory, setAgentHistory] = React.useState();
  const [startDate, setStartDate] = React.useState(week[0]);
  const [endDate, setEndDate] = React.useState(week[6]);
  const [filterChanged, setFilterChanged] = React.useState(false);

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };
  const handleShow = () => {
    VisitorValues();
  };
  const handleFilterChanged = (event) => {
    setFilterChanged(event.target.value);
  };
  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const VisitorValues = () => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/GetAgentHistory.php',
      data: {
        IdAgent: props.IdAgent,
        StartDate: startDate,
        EndDate: endDate,
        Filter: filterChanged,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        setAgentHistory(data.data);
      })
      .then(setLoading(false));
  };

  useEffect(() => {
    VisitorValues();
  }, []);

  const printList = () => {
    return (
      <React.Fragment>
        <Dialog style={{ maxWidth: '75%' }} open={open} onClose={handleClose}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Agent History
              </Typography>
            </Toolbar>
          </AppBar>
          {loading ? (
            <CircularProgress style={{ margin: '0 auto' }} />
          ) : (
            <React.Fragment>
              <br />
              {agentHistory && (
                <div>
                  <form className={classes.container} noValidate>
                    <TextField
                      id="startDate"
                      label="Start Date"
                      type="date"
                      defaultValue={startDate}
                      className={classes.textField}
                      onChange={handleChangeStartDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="endDate"
                      label="End Date"
                      type="date"
                      defaultValue={endDate}
                      className={classes.textField}
                      onChange={handleChangeEndDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <RadioGroup row>
                      <FormControlLabel
                        // value="None"
                        control={
                          <Radio
                            checked={filterChanged == '0'}
                            onChange={handleFilterChanged}
                            value="0"
                            label="None"
                            name="radio-button-time"
                            inputProps={{ 'aria-label': 'None' }}
                            //disabled
                          />
                        }
                        label="All"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        //   value="Home"
                        control={
                          <Radio
                            checked={filterChanged == '1'}
                            onChange={handleFilterChanged}
                            value="1"
                            label="Home"
                            name="radio-button-time"
                            inputProps={{ 'aria-label': 'Home' }}
                            // disabled
                          />
                        }
                        label="Payments"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        //  value="Visitor"
                        control={
                          <Radio
                            checked={filterChanged == '2'}
                            onChange={handleFilterChanged}
                            value="2"
                            label="Visitor"
                            name="radio-button-time"
                            inputProps={{ 'aria-label': 'Visitor' }}
                            // disabled
                          />
                        }
                        label="Adjustments"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </form>
                  <BootstrapTable
                    //options={options}
                    //cellEdit={cellEditProp} //DBClick daba problemas con el Expand
                    //selectRow={selectRowProp} //Da problemas con el Expand
                    //expandableRow={isExpandableRow}
                    //expandComponent={expandComponent}
                    //insertRow={true}
                    /*expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: expandColumnComponent,
                  columnWidth: 50,
                }}*/
                    version="4"
                    data={agentHistory}
                    hover
                  >
                    <TableHeaderColumn
                      dataField="IdAgentAccounting"
                      isKey={true}
                      hidden
                    >
                      Agent ID
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      filter={{ type: 'TextFilter', delay: 1000 }}
                      dataField="LastModification"
                    >
                      Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      filter={{ type: 'TextFilter', delay: 1000 }}
                      dataField="TransactionType"
                    >
                      Transaction
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      filter={{ type: 'TextFilter', delay: 1000 }}
                      dataField="Description"
                    >
                      Description
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      filter={{ type: 'TextFilter', delay: 1000 }}
                      dataField="Amount"
                    >
                      Amount
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              )}
            </React.Fragment>
          )}
          <DialogActions>
            <Button
              color="inherit"
              startIcon={<CancelIcon />}
              onClick={handleShow}
            >
              Show
            </Button>
            <Button
              color="inherit"
              startIcon={<CancelIcon />}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  };

  return printList();
}
