import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';



const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function UserBooks(props) {
  const classes = useStyles();
  const [sysUser, setSysUser] = React.useState([]);
  const [sysUserBooks, setSysUserBooks] = React.useState([]);
  const [books, setBooks] = React.useState([]);
  const [period, setPeriod] = React.useState(['0']);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  let IdUser = props.sysUser.IdUser;

  useEffect(() => {
    GetBooks();
    GetBooksSelected();
  }, []);
  
  const GetBooks = () => {
    const url = process.env.REACT_APP_API_URL + '/BOOK';
    axios({
      method: 'get',
      url: url,
    }).then((response) => {
      console.log('PROPS UserBook ====== REST THEN>', response.data.records);
      console.log('PROPS UserBook ====== REST THEN>', response.data.records[0]);
      setBooks(response.data.records);
    });
  };

  const GetBooksSelected = () => {
    console.log('PROPS User Settings period======>', props);
    console.log('PROPS User Settings period======>', period);
    const url =
      process.env.REACT_APP_API_URL +
      '/USERSBOOK' +
      '?filter=IdUser,eq,' +
      props.sysUser.IdUser +
      '&join=BOOK';
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      let mappedArr = response.data.records.map((x) => {
        return {
          Id: x.IdBook.IdBook,
        };
      });
      let array = [];
      mappedArr.forEach((element) => array.push(element.Id));
      setSysUserBooks(array);
    });
  };
  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
    },
  };

  const onRowSelect = (row, isSelected, e) => {
    if (isSelected) {
      axios({
        method: 'post',
        //url:'https://localhost:44337/api/User/UsersBook_Insert?idBook=' + row.IdBook + '&idUser='+ props.sysUser.IdUser,
        url:
          process.env.REACT_APP_API2_URL +
          '/User/UsersBook_Insert?idBook=' +
          row.IdBook +
          '&idUser=' +
          props.sysUser.IdUser,
        /*  REPLACE WITH SELECT DATA */
      }).then((response) => {
        if (response.data === 0) {          
          alert('User Book Successfully Added');
          GetBooks();
          GetBooksSelected();
        }
      });
    } else if (!isSelected) {
      axios({
        method: 'post',
        //url:'http://192.168.3.22:4587/api/User/UsersBook_Delete?idBook=' + row.IdBook + '&idUser='+ props.sysUser.IdUser,
        url:
        process.env.REACT_APP_API2_URL + '/User/UsersBook_Delete?idBook=' +
          row.IdBook +
          '&idUser=' +
          props.sysUser.IdUser,
      }).then((response) => {
        if (response.data === 0) {          
          alert('User Book Successfully Removed');
          GetBooks();
          GetBooksSelected();
        }
      });
    }
  };

  const onSelectAll = (isSelected, rows) => {
    if (isSelected) {
      axios({
        method: 'post',
        //url:'http://192.168.3.22:4587/api/User/UsersBook_Insert?idBook=' + rows.IdBook + '&idUser='+ props.sysUser.idUser,
        url:
        process.env.REACT_APP_API2_URL + '/User/UsersBook_Insert?idBook=' +
          rows.IdBook +
          '&idUser=' +
          props.sysUser.idUser,
      }).then((response) => {
        if (response.data === 0) {
          GetBooks();
        }
      });
    } else if (!isSelected) {
      axios({
        method: 'post',
        //url:'http://192.168.3.22:4587/api/User/UsersBook_Delete?idBook=' + row.IdBook + '&idUser='+ props.sysUser.idUser,
        url:
        process.env.REACT_APP_API2_URL + '/User/UsersBook_Delete?idBook=' +
          rows.IdBook +
          '&idUser=' +
          props.sysUser.idUser,
      }).then((response) => {
        if (response.data === 0) {
          GetBooks();
        }
      });
    }
  };

  const selectRowProp = {
    mode: 'checkbox',
    selected: sysUserBooks,
    clickToSelect: true,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };

  console.log('PROPS====== Settings setPeriodOnMem>', isLoading);
  return (
    <React.Fragment>
      <div class="main-div">
        <Table>
          <TableRow>
            <TableCell colSpan="2">
              <b>Profiles</b>
              <br></br>
              <BootstrapTable
                data={books}
                selectRow={selectRowProp}
                options={options}
                version="4"
                hover
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdBook"
                >
                  IdBook
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Description">
                  Description
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </React.Fragment>
  );
}
