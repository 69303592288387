import React, { useState } from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import Loader from "../../components/common/loader/Loader";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";

function CreatePitcher (props) {
    const useStyles = makeStyles((theme) => ({        
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },
        center: {
            margin: "0 auto",
            border: "1px grey black",
            textAlign: "center",
            width: "600px",
            height: "250px",
            "& .MuiTextField-root": {
              margin: theme.spacing(1),
              width: 500,
            },
            "& .MuiFormGroup-row": {
              //justifyContent: "center",
            },
            "& .MuiFormControl-root": {
              justifyContent: "center",
            },
            "& .MuiSelect-select": {
                width: 220,
                spacing: theme.spacing(1),
            }
        },        
    }));

    const classes = useStyles();
    const [open, setOpen] = useState(true);
    //const [pitcherTeam, setPitcherTeam] = useState(props.isModify ? props.pitcherSelected[0].TeamName : '');
    const [pitcherName, setPitcherName] = useState(props.isModify ? props.pitcherSelected[0].PitcherName : '');
    const [isInserted, setIsInserted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRed, setIsRed] = useState(false);
    var today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    const [userInfo, setUserInfo] = useState(
        JSON.parse(sessionStorage.getItem("userInfo"))
      ); 

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
        setOpen(false);
    }

    // const handleChangeVisitorTeam = (event, values) => {
    //     setPitcherTeam(event);
    // };

    const handleChangePitcherName =(event) => {
        setPitcherName(event.target.value);
    };

    const insertPitcher = () => {
        setIsLoading(true);
        let pitcher = {
            PitcherName: pitcherName,
            LastModification: today,
            LastModificationUser: userInfo.UserId,
            //TeamName: pitcherTeam
        }
        
        Axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + "/PITCHER",
            data: pitcher,
        }).then((response) => {
            console.log("Response",response);
            setIsInserted(true);
            setIsLoading(false);
            setPitcherName('');
            //setPitcherTeam('');
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
    }

    const updatePitcher = () => {
        setIsLoading(true);
        let pitcher = {
            PitcherName: pitcherName,
            LastModification: today,
            LastModificationUser: userInfo.UserId,
            //TeamName: pitcherTeam
        }
        Axios({
            method: 'put',
            url: process.env.REACT_APP_API_URL + "/PITCHER/"+props.pitcherSelected[0].IdPitcher,
            data: pitcher,
        }).then((response) => {
            console.log("Response",response);
            setIsInserted(true);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
    }

    const handleOk = () => {
        if(pitcherName === ''){
            setIsRed(true);
            alert("Please provide the required fields");
        }else{
            props.isModify ? updatePitcher() : insertPitcher();            
        }
    }

    return(        
        <React.Fragment>
            <Dialog 
                style={{width: "50%", height: "38%", margin: "0 auto", marginTop: "100px"}}
                open={open} 
                onClose={handleClose}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form className={classes.center}>
                    <Divider />
                    <FormGroup row>                                
                        <TextField                                
                        variant="outlined"                            
                        label="Pitcher Name"
                        type="text"
                        value={pitcherName}
                        {...isRed && pitcherName===''?(
                            {error:true}
                        ):("")}
                        onChange={handleChangePitcherName}
                        fullWidth                                                                       
                        />                        
                    </FormGroup>
                    {/* <FormGroup row>
                        <Teams 
                            callBackFn={handleChangeVisitorTeam}
                            teamName={pitcherTeam}                        
                            isModify={false}
                            idSport={"MLB"}                            
                            filterByTeamName={false} 
                            label={"Pitcher Team Name"}                            
                            error={isRed && pitcherTeam===''?(true):(false)}                                                    
                        ></Teams>
                    </FormGroup> */}
                </form>
                <React.Fragment>
                    <DialogActions>
                        {isInserted ? (                                
                            <FormControl className={classes.formControl}>
                                <InputBase
                                style={{
                                    color: "red",
                                    fontSize: 35,
                                    fontWeight: "bold",
                                }}
                                className={classes.margin}
                                defaultValue="Done!"
                                inputProps={{ "aria-label": "naked" }}
                                />
                            </FormControl>                                
                        ) : (
                            ""
                        )}
                        {isLoading ? (
                        <Loader height={100} width={100}></Loader>
                        ) : (
                        <div>                                
                            <Button
                            startIcon={<SaveIcon />}
                            color="inherit"
                            onClick={handleOk}
                            >
                            Ok
                            </Button>
                        </div>
                        )}                            
                    </DialogActions>   
                                            
                </React.Fragment>
            </Dialog>
        </React.Fragment>
    );    
} 
export default CreatePitcher;