import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import './UserRights.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import axios from 'axios';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export default function ProfileStraight(props) {
  const classes = useStyles();
  const [selectedApp, setSelectedApp] = useState(0);
  const [rights, setRights] = useState();
  const [userRights, setUserRights] = useState();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );

  useEffect(() => {
    GetRights(selectedApp);
    GetUserRights(selectedApp);
  }, []);

  const GetRightsForClient = (idApp) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/GetListForApp.php',
      data: {
        appId: 2,
      },
    }).then((response) => {
      setRights(response.data.data)
    });
  };

  const GetRights = (idApp) => {
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/User/UserRights_GetListForApp?idApp=' +
        idApp,
    }).then((response) => {
      setRights(response.data);
    });
  };

  const GetUserRights = (idApp) => {
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/User/UserRights_GetUserRights?idUser=' +
        props.sysUser.IdUser +
        '&idApp=' +
        idApp,
      //url:'https://localhost:44337/api/User/UserRights_GetUserRights?idUser=' +props.sysUser.IdUser +'&idApp=' +idApp,
    }).then((response) => {
      let mappedArr = response.data.map((x) => {
        return {
          Id: x.IdRight,
        };
      });
      let array = [];
      mappedArr.forEach((element) => array.push(element.Id));
      setUserRights(array);
    });
  };

  const GetUserRightsForClient = (idApp) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/GetUserRights.php',
      data: {
        appId: 2,
        idUser: props.sysUser.IdUser
      },
    }).then((response) => {
      let mappedArr = response.data.data.map((x) => {
        return {
          Id: x.IdRight,
        };
      });
      let array = [];
      mappedArr.forEach((element) => array.push(element.Id));
      setUserRights(array)
    });
  };

  const handleChange = (event) => {
    setSelectedApp(event.target.value);
    if(event.target.value==2){
      GetRightsForClient(event.target.value)  
      GetUserRightsForClient(event.target.value)    
    }else{
      GetRights(event.target.value)
      GetUserRights(event.target.value)
    }    
  };

  const onRowSelect = (row, isSelected, e) => {
    let RequestDTO = {
      AppId: selectedApp,
      UserId: props.sysUser.IdUser,
      IdRight: row.IdRight,
      IdForm: 1,
      IdUser: userInfo.UserId,
    };
    if (isSelected) {
      axios({
        method: 'post',
        url: process.env.REACT_APP_API2_URL + '/User/UserRights_AddRight',
        data: RequestDTO,
      }).then((response) => {
        if (response.data === 0) {
          alert('Right Successfully Added');
          if(RequestDTO.AppId==2){
            GetRightsForClient(RequestDTO.AppId)  
            GetUserRightsForClient(RequestDTO.AppId)    
          }else{
            GetRights(RequestDTO.AppId)
            GetUserRights(RequestDTO.AppId)
          } 
        }
      });
    } else if (!isSelected) {
      axios({
        method: 'post',
        url: process.env.REACT_APP_API2_URL + '/User/UserRights_RemoveRight',
        data: RequestDTO,
      }).then((response) => {
        if (response.data === 0) {
          alert('Right Successfully Removed');
          if (response.data === 0) {
            alert('Right Successfully Added');
            if(RequestDTO.AppId==2){
              GetRightsForClient(RequestDTO.AppId)  
              GetUserRightsForClient(RequestDTO.AppId)    
            }else{
              GetRights(RequestDTO.AppId)
              GetUserRights(RequestDTO.AppId)
            } 
          }
        }
      });
    }
  };

  const selectRowProp = {
    mode: 'checkbox',
    selected: userRights,
    onSelect: onRowSelect,
  };

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 22, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 1, // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button

    //onRowClick: selectedValues,
  };

  return (
    <Table>
      <TableRow>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Sport
          </InputLabel>

          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={selectedApp}
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            <MenuItem value={0}>BET TICKER</MenuItem>
            <MenuItem value={1}>BONUS POINTS</MenuItem>
            <MenuItem value={2}>CLIENT</MenuItem>
            <MenuItem value={3}>CUSTOMER SERVICE</MenuItem>
            <MenuItem value={4}>GAME IMPORTER</MenuItem>
            <MenuItem value={5}>MANAGER</MenuItem>
            <MenuItem value={6}>LINEMANAGER</MenuItem>
            <MenuItem value={7}>REPORTER</MenuItem>
            <MenuItem value={8}>DBSGAMEIMPORTER</MenuItem>
            <MenuItem value={9}>BRGAMEIMPORTER</MenuItem>
            <MenuItem value={10}>SOGAMEIMPORTER</MenuItem>
            <MenuItem value={11}>DBSGAMEIMPORTERSVC</MenuItem>
            <MenuItem value={12}>BRGAMEIMPORTERSVC</MenuItem>
            <MenuItem value={13}>SOGAMEIMPORTERSVC</MenuItem>
            <MenuItem value={14}>LANGCONFIG</MenuItem>
            <MenuItem value={15}>ENCRYPTER</MenuItem>
            <MenuItem value={16}>LAUNCHER</MenuItem>
          </Select>
          <FormHelperText>Select Sport</FormHelperText>
        </FormControl>
      </TableRow>
      <TableRow>
        <BootstrapTable
          data={rights}
          options={options}
          selectRow={selectRowProp}
          version="4"
          hover
        >
          <TableHeaderColumn visible="none" hidden isKey dataField="IdRight">
            IdRight
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="Description"
            filter={{ type: 'TextFilter', delay: 1000 }}
          >
            Description
          </TableHeaderColumn>
        </BootstrapTable>
      </TableRow>
    </Table>
  );
}
