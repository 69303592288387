import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from '@material-ui/core/TableContainer';
import { width } from '@mui/system';
import Paper from "@material-ui/core/Paper"
import './ProfileTeaser.css'

export default function ProfileInfo(props) {
  //const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },
    table: {
      minWidth: 650
    },
    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      }
    }
  }));
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [tie, setTie] = useState()
  const [profileTeasers, setProfileTeaser] = React.useState();
  const [openSpot,setOpenSpot] = useState(props.Profile.TL_OpenLose);
  const [noBet,setNoBet] = useState(props.Profile.TL_TeaserCancel);

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
    afterInsertRow: onAfterInsertRow, // A hook for after insert rows
  };

  function onAfterInsertRow(row) {
    let Request = {
      IdProfile: row.IdProfile,
      Name: row.Name,
      Enabled: row.Enabled,
      TeaserSize: row.TeaserSize,
      Odds: row.Odds,
      BuyHalf: row.BuyHalf,
      Buy1: row.Buy1,
      Tie: row.Tie,
      TieId: row.TieId,
      NFLSide: row.NFLSide,
      NFLTotal: row.NFLTotal,
      NBASide: row.NBASide,
      NBATotal: row.NBATotal,
      CFBSide: row.CFBSide,
      CFBTotal: row.CFBTotal,
      CBBSide: row.CBBSide,
      CBBTotal: row.CBBTotal,
      MaxSide: row.MaxSide,
      MaxTotal: row.MaxTotal,
      DropHalfPoint: row.DropHalfPoint,
      Special: row.Special,
      Cancel: row.Cancel,
      WaitForDetails: row.WaitForDetails,
      MaxOpenSpots: row.MaxOpenSpots,
      MaxRisk: row.MaxRisk,
      IdUser: userInfo.UserId,
      IdWagerType: row.IdWagerType,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/Profile/PlayerProfileCustomTeaser_Insert',
      //url:'https://localhost:44337/api/Profile/PlayerProfileCustomTeaser_Insert',
      data: Request,
    }).then((response) => {
      if (response.data === '0') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  }

  useEffect(() => {
    console.log("props", props.Profile);
  },[])

  useEffect(() => {
    // Change for server api address
    const url =
      process.env.REACT_APP_API_URL +
      '/PLAYERPROFILECUSTOMTEASER' +
      '?filter=IdProfile,eq,' +
      props.Profile.IdProfile;
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    })
      .then((response) => response.data)
      .then((data) => {
        let mappedArr = data.records.map((x) => {
          setTie(x['Tie'])
          return {
            IdProfile: x['IdProfile'],
            IdWagerType: x['IdWagerType'],
            Name: x['Name'],
            Enabled: x['Enabled'],
            TeaserSize: x['TeaserSize'],
            Odds: x['Odds'],
            BuyHalf: x['BuyHalf'],
            Buy1: x['Buy1'],
            Tie: x['Tie'],
            TieId: x['TieId'],
            NFLSide: x['NFLSide'],
            NFLTotal: x['NFLTotal'],
            NBASide: x['NBASide'],
            NBATotal: x['NBATotal'],
            CFBSide: x['CFBSide'],
            CFBTotal: x['CFBTotal'],
            CBBSide: x['CBBSide'],
            CBBTotal: x['CBBTotal'],
            MaxSide: x['MaxSide'],
            MaxTotal: x['MaxTotal'],
            DropHalfPoint: x['DropHalfPoint'],
            Special: x['Special'],
            Cancel: x['Cancel'],
            WaitForDetails: x['WaitForDetails'],
            MaxOpenSpots: x['MaxOpenSpots'],
            LastModification: x['LastModification'],
            LastModificationUser: x['LastModificationUser'],
            MaxRisk: x['MaxRisk'],
            Demotes: [x['Tie'], 'WINS', 'LOSES', 'NO BET', 'NO BET REGARDLESS'],            
          };          
        });
        setProfileTeaser(mappedArr);
      });
  }, [setProfileTeaser]);

  const onBeforeSaveCell = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){
      done(true);
    }else{
      done(false);
    }  
    return 1
  } 

  function onAfterSaveCell(row, cellName, cellValue) {
    ProfileTeaserUpdate(row);
  }

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    beforeSaveCell: onBeforeSaveCell,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  const updateProfile = (openSpot, noBets) => {
    let RequestDTO = {
      IdProfile:props.Profile.IdProfile,
      ProfileName:props.Profile.ProfileName,
      Notes:props.Profile.Notes,
      AlternateProfile:props.Profile.AlternateProfile,
      AlternateEnable:props.Profile.AlternateEnable,
      PromotionPoints:props.Profile.PromotionPoints,
      SL_IfBets:props.Profile.SL_IfBets,
      SL_AlwaysActionMLBTotals:props.Profile.SL_AlwaysActionMLBTotals,
      PL_MaxGames:props.Profile.PL_MaxGames,
      PL_AllowProps:props.Profile.PL_AllowProps,
      PL_AllowGolf:props.Profile.PL_AllowGolf,
      PL_AllowMatchUps:props.Profile.PL_AllowMatchUps,
      PL_NoListedPitchers:props.Profile.PL_NoListedPitchers,
      PL_Juice2TeamParlay:props.Profile.PL_Juice2TeamParlay,
      PL_TrueOddsOption:props.Profile.PL_TrueOddsOption,
      PL_MaxOdds:props.Profile.PL_MaxOdds,
      PL_MaxPayout:props.Profile.PL_MaxPayout,
      PL_MaxTeamBuyPoints:props.Profile.PL_MaxTeamBuyPoints,
      PL_MaxBuyPoints:props.Profile.PL_MaxBuyPoints,
      PL_AlwaysAction:props.Profile.PL_AlwaysAction,
      PL_AllowRunLineTotal:props.Profile.PL_AllowRunLineTotal,
      PL_AllowSpreadTotalHK:props.Profile.PL_AllowSpreadTotalHK,
      PL_AllowSpreadTotalSOC:props.Profile.PL_AllowSpreadTotalSOC,
      PL_TieLoses:props.Profile.PL_TieLoses,
      PL_ParlayFormula:props.Profile.PL_ParlayFormula,
      PL_AllowOpenPlays:props.Profile.PL_AllowOpenPlays,
      PL_OddsDefault:props.Profile.PL_OddsDefault,
      PL_LowerUseDefault:props.Profile.PL_LowerUseDefault,
      PL_MaxDogsSide:props.Profile.PL_MaxDogsSide,
      PL_MaxDogsTotal:props.Profile.PL_MaxDogsTotal,
      PL_MaxDogsMoney:props.Profile.PL_MaxDogsMoney,
      PL_MaxSumOdds:props.Profile.PL_MaxSumOdds,
      PL_UseMaxSumOdds:props.Profile.PL_UseMaxSumOdds,
      IL_MaxParlays:props.Profile.IL_MaxParlays,
      IL_MaxLevelParlays:props.Profile.IL_MaxLevelParlays,
      IL_MaxTeasers:props.Profile.IL_MaxTeasers,
      IL_MaxLevelTeasers:props.Profile.IL_MaxLevelTeasers,
      IL_MaxNumberSB:props.Profile.IL_MaxNumberSB,
      IL_AskAmount:props.Profile.IL_AskAmount,
      IL_AllowChildHigher:props.Profile.IL_AllowChildHigher,
      PC_PitcherChangeType:props.Profile.PC_PitcherChangeType,
      TL_OpenLose:openSpot,
      TL_TeaserCancel:noBets,
      SG_CancelSide:props.Profile.SG_CancelSide,
      SG_CancelTotal:props.Profile.SG_CancelTotal,
      SG_CancelRunLine:props.Profile.SG_CancelRunLine,
      PC_SkipSide:props.Profile.PC_SkipSide,
      PC_SkipTotal:props.Profile.PC_SkipTotal,
      PC_SkipRunLine:props.Profile.PC_SkipRunLine,
      Soc_Hookups:props.Profile.Soc_Hookups,
      Rev_AllowOpen:props.Profile.Rev_AllowOpen,
      DuplicateBetsCheckLineChange:props.Profile.DuplicateBetsCheckLineChange,
      DuplicateBetsCheckParlays:props.Profile.DuplicateBetsCheckParlays,
      DuplicateBetsCheckTeasers:props.Profile.DuplicateBetsCheckTeasers,
      DuplicateBetsCheckReverses:props.Profile.DuplicateBetsCheckReverses,
      DuplicateBetsCheckIfbets:props.Profile.DuplicateBetsCheckIfbets,
      DuplicateBetsCheckIfSBRev:props.Profile.DuplicateBetsCheckIfSBRev,
      DuplicateBetsCheckParlaysSB:props.Profile.DuplicateBetsCheckParlaysSB,
      DuplicateBetsCheckTeasersSB:props.Profile.DuplicateBetsCheckTeasersSB,
      DuplicateBetsCheckSameParlay:props.Profile.DuplicateBetsCheckSameParlay,
      DuplicateBetsCheckSameTeaser:props.Profile.DuplicateBetsCheckSameTeaser,
      FPMaxFav:props.Profile.FPMaxFav,
      FPMaxPayout:props.Profile.FPMaxPayout,
      FPMaxTeams:props.Profile.FPMaxTeams,
      FPOddsLimit:props.Profile.FPOddsLimit,
      FPAllowDuplicatedBets:props.Profile.FPAllowDuplicatedBets,
      FPAllowBothSides:props.Profile.FPAllowBothSides,
      FPCheckOfficeFilters:props.Profile.FPCheckOfficeFilters,
      LastModification:props.Profile.LastModification,
      LastModificationUser:props.Profile.LastModificationUser,
      CLMaxWager:props.Profile.CLMaxWager,
      ILOddslimit:props.Profile.ILOddslimit,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Profile/PlayerProfile_Update',
      data: RequestDTO,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });  
  }

  const ProfileTeaserUpdate = (row) => {
    let Request = {
      IdProfile: row.IdProfile,
      IdWagerType: row.IdWagerType,
      Name: row.Name,
      Enabled: row.Enabled,
      TeaserSize: row.TeaserSize,
      Odds: row.Odds,
      BuyHalf: row.BuyHalf,
      Buy1: row.Buy1,
      Tie: row.Tie,
      TieId: row.TieId,
      NFLSide: row.NFLSide,
      NFLTotal: row.NFLTotal,
      NBASide: row.NBASide,
      NBATotal: row.NBATotal,
      CFBSide: row.CFBSide,
      CFBTotal: row.CFBTotal,
      CBBSide: row.CBBSide,
      CBBTotal: row.CBBTotal,
      MaxSide: row.MaxSide,
      MaxTotal: row.MaxTotal,
      DropHalfPoint: row.DropHalfPoint,
      Special: row.Special,
      Cancel: row.Cancel,
      WaitForDetails: row.WaitForDetails,
      MaxOpenSpots: row.MaxOpenSpots,
      MaxRisk: row.MaxRisk,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/Profile/PlayerProfileCustomTeaser_Update',
      //url:'https://localhost:44337/api/Profile/PlayerProfileCustomTeaser_Update',
      data: Request,
    }).then((response) => {
      if (response.data === '0') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  };

  const optionsTie = ["WINS", "LOSES","NO BET", "NO BET REGARDLESS"]

  return (
    <React.Fragment>
      {profileTeasers && (
        <Paper style={{overflowX:"auto"}}>
              <BootstrapTable
                data={profileTeasers}
                cellEdit={cellEditProp}                
                options={options}
                insertRow={true}
                bootstrap4
                striped
                exportCSV
                containerStyle={ { width: '300%' } }
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdWagerType"  
                >
                  ID
                </TableHeaderColumn>

                <TableHeaderColumn 
                  dataField="Name" 
                  tdAttr={{colspan:'2'}}
                  colSpan='2'
                >
                  Name
                </TableHeaderColumn>
                <TableHeaderColumn 
                  dataField="TeaserSize"
                >Size
                </TableHeaderColumn>
                <TableHeaderColumn 
                  dataField="NFLSide"
                >
                  NFL Side Base
                </TableHeaderColumn>
                <TableHeaderColumn 
                  dataField="NFLTotal"
                >
                  NFL Total Base
                </TableHeaderColumn>
                <TableHeaderColumn 
                  dataField="NBASide" 
                >
                  NBA Side Base
                </TableHeaderColumn>
                <TableHeaderColumn 
                  dataField="NBATotal"
                >
                  NBA Total Base
                </TableHeaderColumn>
                <TableHeaderColumn 
                  dataField="CFBSide">
                  CFB Side Base
                </TableHeaderColumn>
                <TableHeaderColumn dataField="CFBTotal">
                  CFB Total Base
                </TableHeaderColumn>
                <TableHeaderColumn dataField="CBBSide">
                  CBB Side Base
                </TableHeaderColumn>
                <TableHeaderColumn dataField="CBBTotal">
                  CBB Total Base
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="DropHalfPoint"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  Drop Half Point
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="Cancel"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  Cancel
                </TableHeaderColumn>
                <TableHeaderColumn dataField="MaxSide">
                  Max Sides
                </TableHeaderColumn>
                <TableHeaderColumn dataField="MaxTotal">
                  Max Total
                </TableHeaderColumn>
                <TableHeaderColumn dataField="MaxOpenSpots">
                  Max Open Spots
                </TableHeaderColumn>
                <TableHeaderColumn dataField="MaxRisk">
                  Max Risk
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Odds">
                  Odds
                </TableHeaderColumn>
                <TableHeaderColumn dataField="BuyHalf">
                  Buy Half
                </TableHeaderColumn>
                <TableHeaderColumn                
                  dataField="Tie"
                  editable={{
                    type: 'select', 
                    options: { values: optionsTie },
                  }}
                >
                  Tie
                </TableHeaderColumn>
              </BootstrapTable>     
             
            
              <FormControlLabel
                style={{ color: "#333" }}
                label="Open Spot + Lose = Lose (Using NO BET REGARDLESS)"
                control={
                  <Checkbox
                    checked={openSpot}
                    onChange={() => {
                      if(window.confirm("Are you sure you want to edit the selected item?")){
                        setOpenSpot(!openSpot)
                        updateProfile(!openSpot,noBet)
                      }
                    }}
                    name="Open Spot"
                    color="secondary"
                  />
                }
              />
              <FormControlLabel
                style={{ color: "#333" }}
                label="NO BET when a game is cancelled"
                control={
                  <Checkbox
                    checked={noBet}
                    onChange={() => {
                      if(window.confirm("Are you sure you want to edit the selected item?")){
                        setNoBet(!noBet)
                        updateProfile(openSpot,!noBet)
                      }                    
                    }}
                    name="NO BET"
                    color="secondary"
                  />
                }
              />
            
          </Paper>
      )}
    </React.Fragment>
  );
}
