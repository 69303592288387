import React, { useState, useEffect, Component } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import TableRow from "@material-ui/core/TableRow";
import { DataGrid } from '@material-ui/data-grid'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './Users.css';
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import CreateUser from './userComponents/CreateUser';
import Loader from '../common/loader/Loader';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Users(props) {
  const [users, setUsers] = useState();
  const [books, setBooks] = useState([]);
  const [schema, setSchema] = useState();
  const [record, setRecord] = useState();
  const [language, setLanguage] = useState();
  
  const [isNew, setIsNew] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [filter, setFilter] = useState("")
  const [value, setValue] = React.useState(0);
  const [selectedIdAgent, setSelectedIdAgent] = React.useState(0);
  const [selectedIdCurrency, setSelectedIdCurrency] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);  
  const [userSelected, setUserSelected] = useState();
  const [logons, setLogons] = useState({})

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let history = useHistory();
  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    },
  });

  const classes = useStyles();

  const getLogons =  () => {
    const url = process.env.REACT_APP_DEF_PHP_URL + '/GetUserLogons.php'
    axios({
        method: 'post',
        url: url,
        data: {
            idUser : userInfo.UserId
          },
    }).then((response) => {         
        setLogons(response.data.data[0]);
    });
  }

  useEffect(() => {
    loadUser();
    getLogons();
  }, []);

  const loadUser = () => {
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/USERS';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setUsers(data.records);

        console.log('data', data.records);
        console.log('sschema', data.records[0]);
        console.log('sschema', schema);
        console.log('agents', users);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + '/BOOK';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let bookInfoInfo = data.records.map((v) => {
          v.value = v.IdBook;
          v.text = v.Description;
          return v;
        });
        setBooks(bookInfoInfo);
      });
  }, []);

  
  
  const handleCloseCreate = function () {
    setIsNew(false); 
  };

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  }

  function DeleteUser() {
    if(window.confirm("Are you sure you want to edit the selected item?")){   
      let Request = {
        logonSessionId : logons.LogonSessionID,
        idUser : userSelected.IdUser,
        result: 0,                   
    };

    axios({
      method: "post",
      url: process.env.REACT_APP_DEF_PHP_URL + '/UserDelete.php',
      data: Request,
    }).then((response) => {      
      alert("The Agent: " + userSelected.IdUser + " has been succesfully deleted")
      loadUser()
      setFilter("")
    }).catch( (e) => {
      console.log('objMultisend error', e.response.data);
    });
    }
  }

  const handleRowSelection = (row) => {
    setUserSelected(users.filter(x => x.IdUser == row[0])[0])           
  }

  if (redirect) {
    let pushURL = '/user/' + redirect.IdUser;

    return (
      <React.Fragment>
        {/* //this.props.location.state. asi se agarran los datos en el otro componente */}

        {console.log('User Info props', redirect)}
        <div>
          {history.push(pushURL)}       
        </div>
      </React.Fragment>
    );
  }

  function renderManage(params) { 
    return( 
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          window.location.href='/user/'+params.row.IdUser
        }}
      >
        Manage
      </Button>
    )
  }

  return (
    <Paper className={classes.root}>
      <br />
      <br /> <h2>Users</h2>
      <div style={{display:"flex", flexDirection:"row", alignContent:"space-between", alignItems:"center"}}>
        <label style={{padding:"8px",fontSize:"150%"}}>
          <AddBoxIcon                 
            aria-label="newGame"
            size="small"
            className={classes.fab}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsNew(true);                      
            }}
          />{" "}
          Add New
        </label> 
        {userSelected ? (
          <label style={{padding:"8px",fontSize:"150%"}}>
            <DeleteForeverIcon                 
              aria-label="newGame"
              size="small"
              className={classes.fab}
              style={{ cursor: "pointer" }}
              onClick={() => {
                DeleteUser()                      
              }}
            />{" "}
            Delete User
          </label>  
        ):("")}
      </div> 
      {isLoading ? <Loader></Loader> : ""}
      <Table sx={{minWidth:650}} aria-label="simple table">
        <TableBody className={showInfo ? "show-table" : "hide-table"}>
          <TableRow>
            {users && (
              <div>
                <TextField      
                  style={{padding:"8px"}}                          
                  variant="outlined"                            
                  label="Search"
                  type="text"
                  value={filter}
                  onChange={handleChangeFilter}                                                             
                />
                <Box sx={{ height: 520, width: '100%' }}>
                  <DataGrid
                      rows={filter != "" ? users.filter(x => x.Name.toUpperCase().includes(filter.toUpperCase()) || x.LoginName.toUpperCase().includes(filter.toUpperCase())) : users}
                      getRowId={(row) => Object.values(row)[0]}
                      columns={[
                        {
                          field: "",
                          headerName: "",
                          align: "right",
                          headerAlign: "center",
                          width: 100,
                          editable: false,
                          sortable: false,
                          filterable: false,
                          renderCell: renderManage,
                        }, 
                        {field: 'IdUser' , headerName: 'Id User', width: 200, editable: false, sortable: false, filterable: false},
                        {field: 'LoginName' , headerName: 'User', width: 200, editable: false, sortable: false, filterable: false},
                        {field: 'Name' , headerName: 'Name', width: 200, editable: false, sortable: false, filterable: false},    
                      ]}
                      pageSize={100}
                      rowsPerPageOptions={[5]}            
                      disableColumnSelector
                      disableColumnMenu       
                      onSelectionModelChange={handleRowSelection}                             
                      disableMultipleSelection={true}                     
                  />
                </Box>                            
              </div>
            )}
          </TableRow>
        </TableBody>
      </Table>
      {isNew ?(  
              
          <CreateUser
            onClose={handleCloseCreate}
            title={"New User"}
            userSelected={userSelected}
            loadUser={loadUser}
          />          
      ):(
        "" 
      )} 
    </Paper>
  );
  // return (
  //   <div id="main-div">
  //     <Paper className={classes.root}>
  //       <br />
  //       <br /> <h2>Users</h2>
  //       {isLoading ? <Loader></Loader> : ''}
  //       <Table className={classes.table} aria-label="simple table">
  //         <TableBody className={showInfo ? 'show-table' : 'hide-table'}>
  //           {agents && (
  //             <div>
  //               <BootstrapTable
  //                 pagination={true}
  //                 options={options}
  //                 selectRow={selectRowProp} //Da problemas con el Expand
  //                 insertRow={true}
  //                 version="4"
  //                 data={agents}
  //                 deleteRow={true}
  //                 hover
  //               >
  //                 <TableHeaderColumn
  //                   dataField="IdUser"
  //                   hiddenOnInsert
  //                   isKey={true}
  //                   autoValue={true}
  //                 >
  //                   User ID
  //                 </TableHeaderColumn>
  //                 <TableHeaderColumn
  //                   filter={{ type: 'TextFilter', delay: 1000 }}
  //                   dataField="LoginName"
  //                 >
  //                   User
  //                 </TableHeaderColumn>

  //                 <TableHeaderColumn
  //                   filter={{ type: 'TextFilter', delay: 1000 }}
  //                   dataField="Name"
  //                   hiddenOnInsert
  //                 >
  //                   Name
  //                 </TableHeaderColumn>
  //               </BootstrapTable>
  //             </div>
  //           )}
  //         </TableBody>
  //       </Table>
  //     </Paper>
  //   </div>
  // );
}

export default Users;
